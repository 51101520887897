import { Component/*, ViewChild */} from '@angular/core';
import { Location } from '@angular/common';
import { TokenStorageService } from './_services/token-storage.service';

enum UserRole {
  PUNTAL_SUPER = 'PUNTAL_SUPER',
  ADMIN = 'USER_ADMIN',
  USER = 'USER'
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
//  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username?: string;
  company?: string;
  role?: UserRole;
  isUserAdmin?: boolean;
  sidenavWidth = 16;
  showSidenav = this.isLoggedIn;

  //@ViewChild('nav', {static: false}) nav: MatSidenav;
  
  constructor(private tokenStorageService: TokenStorageService, public location: Location) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    this.company = 'Puntal Agro';
    this.showSidenav = this.isLoggedIn;
    
    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
    /*  console.log(user);*/
      this.username = user.username;
      this.role = user.role;
      this.isUserAdmin = user.isUserAdmin;

/*      this.roles = user.roles;

      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');*/

      
    //  console.log(user);
      this.showSidenav = this.isLoggedIn;
    }


  }

  isAuthenticated() {
    return this.isLoggedIn;
  }

  isLoginRoute() {
    return this.location.isCurrentPathEqualTo('/login');
  }

  toggleSidenav(nav: any) {
  //  this.nav.toggle().catch(err => console.error(err));
    if (nav.opened) {
      nav.close()
    } else {
      nav.open();
    }  
  }

  isSuperUser() {
    return ((this.role) && (this.role == UserRole.PUNTAL_SUPER));
  //  return true;
//    return this.authService.isSuperUser();
  }  

  isAdmin() {
    return this.isUserAdmin;
    //return ((this.role) && ((this.role == UserRole.PUNTAL_SUPER || this.role == UserRole.ADMIN)));
  }  

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }
}
