import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatIconModule} from '@angular/material/icon';
import { MatBadgeModule} from '@angular/material/badge';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatListModule} from '@angular/material/list';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input';
import { MatSelectModule} from '@angular/material/select';
import { MatRadioModule} from '@angular/material/radio';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule} from '@angular/material/core';
import { MatChipsModule} from '@angular/material/chips';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatTableModule} from '@angular/material/table';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatSortModule} from '@angular/material/sort';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { MatCardModule} from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';

/*import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';*/

@NgModule({
    imports: [
       CommonModule,
       MatButtonModule,
       MatToolbarModule,
       MatIconModule,
       MatSidenavModule,
       MatBadgeModule,
       MatListModule,
       MatGridListModule,
       MatInputModule,
       MatSelectModule,
       MatRadioModule,
       MatDatepickerModule,
       MatNativeDateModule,
       MatChipsModule,
       MatTooltipModule,
       MatTableModule,
       MatPaginatorModule,
       MatSortModule,
       MatCheckboxModule,
       MatSnackBarModule,
       MatCardModule,
       MatExpansionModule,
       MatDialogModule
    ],
    exports: [
      CommonModule,
      MatButtonModule,
      MatToolbarModule,
      MatIconModule,
      MatSidenavModule,
      MatBadgeModule,
      MatListModule,
      MatGridListModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatRadioModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatChipsModule,
      MatTooltipModule,
      MatTableModule,
      MatPaginatorModule,
      MatSortModule,
      MatCheckboxModule,
      MatSnackBarModule,
      MatCardModule,
      MatExpansionModule,
      MatDialogModule
    ],
    providers: [
       MatDatepickerModule,
       {provide: MAT_DATE_LOCALE, useValue: 'es-AR' }
    ]
 })
 
 export class AngularMaterialModule { }
 