<ng-container *ngIf="loading">
  <!-- <ng-container *ngIf="!scout "> -->
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>

<div class="container" *ngIf="!loading">
    <h2 class="title">{{title}}</h2>
  
    <form class="example-form" [formGroup]="clientFormControl">
  
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Cliente" formControlName="name" [errorStateMatcher]="matcher" (input)="onNameInputChange()" oninput="this.value = this.value.toUpperCase()" required>
        <mat-error *ngIf="(clientFormControl.get('name')!.invalid)">{{getFirstNameErrorMessage()}}</mat-error>
      </mat-form-field>
  
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Correo electrónico" formControlName="email" [errorStateMatcher]="matcher" (input)="onEmailInputChange()" oninput="this.value = this.value.toLowerCase()" required>
        <mat-error *ngIf="(clientFormControl.get('email')!.invalid)">{{getEmailErrorMessage()}}</mat-error>
      </mat-form-field>
  
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Teléfono" formControlName="phone" [errorStateMatcher]="matcher">
        <mat-error *ngIf="clientFormControl.get('phone')!.invalid">{{getPhoneErrorMessage()}}</mat-error>
      </mat-form-field>
  
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Contacto" formControlName="position" [errorStateMatcher]="matcher">
        <mat-error *ngIf="clientFormControl.get('position')!.invalid">{{getPositionErrorMessage()}}</mat-error>
      </mat-form-field>
  
      <!-- <div class="container"> -->
        <div class="title">Datos de Facturación</div>
    
        <mat-form-field class="example-full-width">
          <input matInput placeholder="CUIT" formControlName="cuit" [errorStateMatcher]="matcher">
          <mat-error *ngIf="clientFormControl.get('cuit')!.invalid">{{getCuitErrorMessage()}}</mat-error>
        </mat-form-field>
    
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Dirección" formControlName="address" [errorStateMatcher]="matcher">
          <mat-error *ngIf="clientFormControl.get('address')!.invalid">{{getAddressErrorMessage()}}</mat-error>
        </mat-form-field>
    
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Razón Social" formControlName="social_reason" [errorStateMatcher]="matcher" oninput="this.value = this.value.toUpperCase()">
          <mat-error *ngIf="clientFormControl.get('social_reason')!.invalid">{{getSocialReasonErrorMessage()}}</mat-error>
        </mat-form-field>
    
    <!-- </div> -->

    <div *ngIf="operacion == 'C'" class="buttons">
      <button mat-stroked-button color="primary" (click)="close()">Cerrar</button>
    </div>

    <div *ngIf="operacion != 'C'" class="buttons">
      <button mat-stroked-button color="primary"  (click)="close()">Cancelar</button>
      <button mat-raised-button color="primary" class="accept-button" [disabled]="clientFormControl.invalid" (click)="save()">Guardar</button>
    </div>


 
    </form>

  </div>
  