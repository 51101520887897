import {FilterInfoGetter} from './multi-filter.component';

export interface Decisions<T> {
  implementation_quality: FilterInfoGetter<T>;
  weeds: FilterInfoGetter<T>;
  pests: FilterInfoGetter<T>;
  diseases: FilterInfoGetter<T>;
  adversities: FilterInfoGetter<T>;
}

export function buildDecisionFilters<T>(decisions: Decisions<T>) {
  return {
    info: {
      implementation_quality: {
        name: 'Implantación',
        getter: decisions.implementation_quality
      },
      weeds: {
        name: 'Malezas',
        getter: decisions.weeds
      },
      pests: {
        name: 'Plagas',
        getter: decisions.pests
      },
      diseases: {
        name: 'Enfermedades',
        getter: decisions.diseases
      },
      adversities: {
        name: 'Adversidades',
        getter: decisions.adversities
      },
    },
    order: ['implementation_quality', 'weeds', 'pests', 'diseases', 'adversities'],
    values: ['Sin datos', 'No intervenir', 'Revisar en \n 3-7 días', 'Intervenir'],
    select: {},
    changed: {},
  };
}

export interface Semaphores<T> extends Decisions<T> {
  general: FilterInfoGetter<T>;
}

export function buildSempahoresFilters<T>(semaphores: Semaphores<T>) {
  return {
    info: {
      state: {
        name: 'Condición general',
        getter: semaphores.general
      },
      implementation_quality: {name: 'Implantación', getter: semaphores.implementation_quality},
      weeds: {name: 'Malezas', getter: semaphores.weeds},
      pests: {name: 'Plagas', getter: semaphores.pests},
      diseases: {name: 'Enfermedades', getter: semaphores.diseases},
      adversities: {name: 'Adversidades', getter: semaphores.adversities},
    },
    order: ['state', 'implementation_quality', 'weeds', 'pests', 'diseases', 'adversities'],
    values: ['no-data', 0, 1, 2, 3, 4],
    select: {},
    changed: {}
  };
}
