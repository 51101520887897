<ng-container *ngIf="loading">
  <!-- <ng-container *ngIf="!scout "> -->
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>

<div class="container" *ngIf="!loading">
    <h2 class="title">{{title}}</h2>
  
    <form class="example-form" [formGroup]="usuarioFormControl" autocomplete="off">
  
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Usuario" formControlName="name" [errorStateMatcher]="matcher"  oninput="this.value = this.value.toLowerCase()" required>
        <mat-error *ngIf="usuarioFormControl.get('name')!.invalid">{{getNameErrorMessage()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input matInput placeholder="Correo electrónico" formControlName="email" [errorStateMatcher]="matcher">
        <mat-error *ngIf="usuarioFormControl.get('email')!.invalid">{{getEmailErrorMessage()}}</mat-error>
      </mat-form-field>

      <div *ngIf="usuario.id == 0">
        <mat-form-field class="example-full-width">
        <input matInput placeholder="Contraseña" formControlName="password" [errorStateMatcher]="matcher">
        <mat-error *ngIf="usuarioFormControl.get('password')!.invalid">{{getPasswordErrorMessage()}}</mat-error>
      </mat-form-field>      
    </div>
      
      <mat-form-field class="example-full-width">
        <mat-label>Tipo</mat-label>
        <mat-select [(value)]="tipo_seleccionado"> 
          <mat-option *ngFor="let tipo of tipos_de_usuario" [value]="tipo.value">
            {{ tipo.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
     

      <div class="buttons">
        <button mat-stroked-button color="primary"  (click)="close()">Cancelar</button>
        <button mat-raised-button color="primary" class="accept-button" [disabled]="usuarioFormControl.invalid" (click)="save()">Guardar</button>
      </div>
  
    </form>
  
  </div>
  
  
