import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSelect, MatSelectChange} from '@angular/material/select';
//import {isString} from 'util';

export declare type FilterInfoGetter<T> = (item: T) => number | string;

export interface Matcher<T> {
  match: (item: T) => boolean;
}

export interface FilterInfo<T> {
  name: string;
  getter: FilterInfoGetter<T>;
}

export interface Filter<T> {
  info: { [key: string]: FilterInfo<T> };
  order: string[];
  values: (string | number)[] |{ [key: string]: Set<string> } ;
  select: { [key: string]: Set<any> };
  changed: { [key: string]: MatSelect };
}

@Component({
  selector: 'multi-filter',
  templateUrl: './multi-filter.component.html',
  styleUrls: ['./multi-filter.component.scss']
})
export class MultiFilterComponent<T> implements OnInit {
  @Input() filters: Filter<T>;
  @Input() isSemaphore = false;
  @Output() update = new EventEmitter<Matcher<T>>();

  constructor() { }

  ngOnInit() {
    this.filters.order.forEach(filter => {
       this.filters.select[filter] = new Set();
    });
  }

  match(item: T) {
    const matchAll = Object.entries(this.filters.info).map(([key, data]) => {
      const set = this.filters.select[key];
      if (set.size === 0 || set.size === this.filters.values.length) {
        return true;
      }
       var value = data.getter(item);
       return set.has(value);
    });
    return matchAll.every(Boolean);
  }

  get matcher(): Matcher<T> {
    return {match: (item: T) => this.match(item)};
  }

  changeSelect($event: MatSelectChange, field: string) {
    this.filters.select[field] = new Set(($event.value || []).map(v => /*isString(v)*/(typeof v === 'string')  ? v.toLowerCase() : v));
    this.filters.changed[field] = ($event.source);
    this.update.emit(this.matcher);
  }

  allSelected(field: string) {
    const set = this.filters.select[field] || new Set();
    return set.size === 0 || set.size === this.filters.values.length;
  }

  selectedTextPretty(field: string) {
    const selectedElements = this.filters.select[field];
    const values = Array.from(selectedElements);
    return values.join(', ');
  }

  semaphoreClassType(value, field) {
  //  console.info(value,field);
    if (field == 'state') {
      return value === 'no-data' ? 'no-data' : `s${value}g`;
    }
    else {
      return value === 'no-data' ? 'no-data' : `s${value}`;
    }
    
  }

  semaphoreClass(value) {
    return value === 'no-data' ? 'no-data' : `s${value}`;
  }

  anyFilter() {
    return Object.values(this.filters.changed).length > 0;
  }

  clean() {
    this.filters.order.forEach(filter => {
      this.filters.select[filter] = new Set();
    });
    Object.values(this.filters.changed).forEach(model => {
      setTimeout(() => {
        model._selectionModel.clear();
        model.toggle();
        model.close();
      }, 100);
    });
    this.filters.changed = {};
    this.update.emit({match: () => true});
  }

}
