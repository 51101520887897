const SEMAPHORE = {
    '0': 'GRIS',
    '1': 'VERDE',
    '2': 'AMARILLO',
    '3': 'NARANJA',
    '4': 'ROJO'
  }
  
  const GENERAL_SEMAPHORE = {
    '0': 'VERDE OSCURO',
    '1': 'VERDE',
    '2': 'GRIS',
    '3': 'NARANJA',
    '4': 'ROJO'
  }
  
  function strip(value) {
    return (value || '').replace('\n', '');
  }
  
  function semaphore(valor, data) {
    if (!valor) {
      return 'No definido';
    }
    const map = (data && data.general) ? GENERAL_SEMAPHORE : SEMAPHORE;
    return map[valor] || 'No definido';
  }
  
  function ambientConvertCSV(ambient) {
    ambient = ambient || {};
    return {
      "nombre": strip(ambient.name),
      "napa": strip(ambient.nappa),
      "calidad napa": strip(ambient.nappa_quality),
      "pendiente napa": strip(ambient.nappa_pendent),
      "nivel de recarga": semaphore(ambient.recharge_level),
      "horizonte seco": strip((ambient.dry_horizon == null) ? 'No definido' : (ambient.dry_horizon?'SI':'NO') ),
      "humedad de thapto": strip(ambient.thapto_humidity),
      "riesgo de anegamieto": strip(ambient.waterlogging_risk),
      "cobertura de rastrojo": strip(ambient.stubble_cover),
      "nombre predecesor": strip(ambient.predecessor_name),
      "observaciones": strip(ambient.observations)
    }
  }
  
  function sowingConvertCSV(sowing) {
    sowing = sowing || {};
    return {
      "fecha": fecha(sowing.date_time),
      "resiembra": sowing.replant ? "SI" : "NO",
      "area": sowing.area,
      "Genotipo": sowing.cultivar_name,
      "calidad": sowing.quality,
      "contratista": strip(sowing.contractor),
      "distancia entre filas": sowing.row_spacing,
      "Densidad sem/m2": sowing.density_sem_m2,
      "densidad Kg/Ha": sowing.density_kg_ha,
      "observaciones": strip(sowing.observations),
      "fertilizaciones": (sowing.fertilizations || []).map(f => {
        return {
          "fecha": f.date_time,
          "nombre": strip(f.fertilization),
          "modo": strip(f.fertilization_mode),
          "dosis": f.fertilization_dose,
          "area": f.fertilization_area
        }
      }),
    }
  }
  
  function fecha(fechahora) {
    if ((fechahora === null) || (typeof fechahora === 'undefined')) {
      return '';
    } 
    const f = new Date(fechahora).toLocaleString();
    return f;
  }

  function scoutConvertCSV(scout) {
    return {
      "ID": scout.id,
      "Cliente": scout.client,
      "Empresa": scout.company,
      "Campo": scout.farm,
      "Lote": scout.plot,
      "Cultivo": scout.sub_type,      
      "ID actividad": scout.activity_id,
      "Fecha Recorrido": fecha(scout.date_time),
      "Recorredor": userConvertCSV(scout.users),
      "Estado": scout.state,
      "Geoposicionn": !!scout.geo_position ? `${scout.geo_position.latitude}, ${scout.geo_position.longitude}` : 'No definido',
      "Semaforo General": semaphore(scout.general_state, {general: true}),
      "Cobertura de suelo": scout.ground_cover,
      "Observaciones": strip(scout.observations),
      "Ambiente": ambientConvertCSV(scout.ambient),
      "Siembra": sowingConvertCSV(scout.sowing),
      "Calidad de implantacion": implementationQualityConvertCSV(scout.implementation_quality),
      "Malezas": weedsConvertCSV(scout.weeds),
      "Enfermedades": diseasesConvertCSV(scout.diseases),
      "Plagas": pestsConvertCSV(scout.pests),
      "Adversidades": adversitiesConvertCSV(scout.adversities),
      "Rendimiento": cropYieldConvertCSV(scout.crop_yield),
    }
  }
  
  function cropYieldConvertCSV(cropYield) {
    cropYield = cropYield || {};
    return {
      "real": cropYield.real,
      "estimada": cropYield.budged,
      "esperada vs estimada": cropYield.expected_vs_budged_per,
      "estimada real": cropYield.estimated,
      "cosecha TN": cropYield.harvest_tn,
      "cosecha area": cropYield.harvest_area,
      "cosecha humedad": cropYield.harvest_humidity,
      "fecha cosecha": fecha(cropYield.harvest_date),
      "ultima cosecha":  cropYield.harvested ? "SI" : "NO",           
      "observaciones": strip(cropYield.observations)
    };
  }
  
  function userConvertCSV(users) {
    if (!users || !Array.isArray(users) || users.length === 0) {
      return '';
    }
    if (users[0].user) {
      return users[0].user.email;
    }
    else {
      return users[0].email;
    }
  }

 /* function userConvertCSV(users) {
    if (!users || !Array.isArray(users) || users.length === 0) {
      return '';
    }
    return users[0].user.email;
  }*/

  function weedsConvertCSV(weeds) {
    weeds = weeds || {};
    return {
      "cobertura": weeds.cover,
      "semaforo": semaphore(weeds.semaphore),
      "decision": strip(weeds.decision),
      "observaciones": strip(weeds.observations),
      "cobertura verde": weeds.green_coverage_percentage,
      "malezas": (weeds.weeds || []).map(w => {
        return {
          "nombre": strip(w.weed_name),
          "estado": strip(w.weed_state_name),
          "distribucion": w.distribution,
          "frecuencia": w.frequency,
          "desidad": w.density
        }
      })
    }
  }
  
  function implementationQualityConvertCSV(impl) {
    impl = impl || {};
    return {
      "calidad": semaphore(impl.quality),
      "plantas logradas": impl.plants_accomplished,
      "decision resimbre": strip(impl.replant_decision),
      "uniformidad espacial": impl.spatial_uniformity,
      "uniformidad temporal": impl.temporal_uniformity,
      "observaciones": strip(impl.observations)
    }
  }
  
  function diseasesConvertCSV(diseases) {
    diseases = diseases || {};
    return {
      "semaforo": semaphore(diseases.semaphore),
      "decision": strip(diseases.decision),
      "observaciones": strip(diseases.observations),
      "enfermedades": (diseases.diseases || []).map(d => {
        return {
          "nombre": strip(d.disease_name),
          "estado": strip(d.state)
        }
      })
    }
  }
  
  function pestsConvertCSV(pests) {
    pests = pests || {};
    return {
      "semaforo": semaphore(pests.semaphore),
      "decision": strip(pests.decision),
      "observaciones": strip(pests.observations),
      "plagas": (pests.pests || []).map(p => {
        return {
          "name": strip(p.pest_name),
          "estado": p.state
        }
      })
    }
  }
  
  function adversitiesConvertCSV(adversities) {
    adversities = adversities || {};
    return {
      "Semaforo": semaphore(adversities.semaforo),
      "Observacion": strip(adversities.observations),
      "decision": strip(adversities.decision),
      "adversidades": (adversities.adversities || []).map(a => {
        return {
          "name": strip(a.adversity_name),
          "nivel dano": a.damage_level,
          "porcentaje": a.percentage,
          "area": a.area
        }
      })
    }
  }
  
  module.exports = {
    convert: scoutConvertCSV
  }