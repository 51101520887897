export class Usuario {
    id: number;
    name: string;
    email: string;
    password: string;
    creation_date: Date;
    down_date?: Date;
    type: string;
    user_admin: number;
    last_campaign: number;
    last_client: number;
    editable?: number;
}    

