 <div class="signin-wrapper" fxLayout="row" fxLayoutAlign="center center" >

  <mat-card class="box mat-app-background">
    <mat-card-header>
      <mat-card-title>Iniciar Sesión</mat-card-title>
    </mat-card-header>

    <form class="example-form">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput name="u" placeholder="Email de usuario" [(ngModel)]="form.email">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput name="p" placeholder="Contraseña" [type]="hidePwd ? 'password' : 'text'" [(ngModel)]="form.password" autocomplete="off">
          <button type="button" mat-icon-button matSuffix (click)="hidePwd = !hidePwd" [attr.aria-label]="'Ocultar password'" [attr.aria-pressed]="hidePwd" tabindex="-1">
            <mat-icon>{{hidePwd ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        
      </mat-card-content>

     <div *ngIf="errorMessage"> 
        <h4 style="color:red; display:flex; align-items: center; justify-content: center;">{{errorMessage}}</h4>
     </div> 

      <button mat-stroked-button color="accent" class="btn-block" (click)="onSubmit()" [disabled]="!form.email || !form.password" >Iniciar Sesión</button>
    </form>
  </mat-card>

</div> 