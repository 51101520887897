<ng-container  *ngIf="isAuthenticated()">
    <!-- <div class="download-report-container add-element-container" *ngIf="canAdd">
     <button mat-raised-button color="accent" (click)="addClient()">
       Agregar Cliente
     </button>
   </div> -->
 
   <div class="campanias-table">
     <puntal-table
       tableTitle="Campañas"
       [dataSource]="campanias"
       [loading]="loading"
       [options]="tableOptions"
       [displayedColumns]="header"
       (onEdit)="editRow($event)"
       (onDelete)="deleteRow($event)"
       (onRowClick)="null"
       (onAdd)="addRow()"
       (onBack)="goBack()"
       showAddButton="S"
       showBackButton="S"
       (onShowPermissionClick)="openPermission($event)"
 
       
     >
   </puntal-table>
   </div>
 
   </ng-container>
