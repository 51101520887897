import {Component, OnInit, Inject, Input} from '@angular/core';

import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelectModule} from '@angular/material/select';
import { AuthService } from '../../../_services/auth.service';
import { Usuario } from '../../../modelo/usuario';
import { ToastService } from '../../../shared/toast/toast.service';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.scss']
})

export class UsuarioFormComponent implements OnInit {
  public matcher: ErrorStateMatcher;
  public usuarioFormControl: FormGroup;
  public titulo : string;
  public tipo_seleccionado: string;
  public loading = true;

  tipos_de_usuario = [
  /*  {value: 'PUNTAL_SUPER', viewValue: 'Usuario Puntal'},*/
  /*  {value: 'USER_ADMIN', viewValue: 'Administrador'},*/
    {value: 'USER', viewValue: 'Usuario'},
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public usuario: Usuario,
    private dialogRef: MatDialogRef<UsuarioFormComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private usuarioService: UserService
    ) { }

    ngOnInit() {
      this.usuarioFormControl = this.createFormControl();
      this.matcher = new ShowOnDirtyErrorStateMatcher();
      if (this.authService.isSuperUser()) {
        this.tipos_de_usuario.push({value: 'PUNTAL_SUPER', viewValue: 'Superusuario Puntal'});
      }
      this.loading = false;
    }

    private createFormControl(): FormGroup {
      if ((!this.usuario) || (Object.keys(this.usuario).length === 0)) {
        this.usuario = {
          id: 0,
          name: '',
          email: '',
          password: '',
          type: '',
          user_admin: this.authService.getId()
        } as Usuario;
        if  (!this.authService.isSuperUser()) {
          this.usuario.type = 'USER';
          this.tipo_seleccionado = 'USER';}
        this.titulo = 'Agregar Usuario';  
      }
      else {
        this.titulo = 'Editar Usuario';
        this.tipo_seleccionado = this.usuario.type;
      }
      return this.formBuilder.group({
        name: [this.usuario.name, [Validators.required, Validators.minLength(8)]],
        email: [this.usuario.email, [Validators.required, Validators.email]],
        password: [this.usuario.password, [Validators.minLength(8)]],
        type: [this.usuario.type]
      });
    }
 
    async usuarios_repetidos(userId,email,name) : Promise<string> {
      if (!(email) || (email == null) || !(name) || (name == null)) {
        return '';
      } 
      
      return await this.usuarioService.getUsuariosRepetidos(userId,email,name).toPromise()
      .then((usuarios_repe) => {console.info(usuarios_repe);if ((!usuarios_repe) || (usuarios_repe.length < 1)) {return '';} else {return usuarios_repe[0].name;}});
    }


    async usuario_valido(): Promise<boolean> {
      // ver que no exista otro usuario con el mismo mail o con el mismo nombre
  
    try {
      if (!(this.usuario) || (this.usuario == null)) {
        this.toastService.openSnackBar(`Minimamente debe seleccionar un usuario`, 'OK', 300000);
        return false;
      }
      if (!(this.usuario.email) || (this.usuario.email == null) || (this.usuario.email == '')) {
        this.toastService.openSnackBar(`Ingrese el email`, 'OK', 300000);
        return false;
      }
      if (!(this.usuario.name) || (this.usuario.name == null) || (this.usuario.name == '')) {
        this.toastService.openSnackBar(`Ingrese el nombre del usuario`, 'OK', 300000);
        return false;
      }        
      let usuarios_repetidos = await this.usuarios_repetidos(this.usuario.id,this.usuario.email,this.usuario.name);
      if (usuarios_repetidos) {
        this.toastService.openSnackBar(`El usuario ingresado coincide con '${usuarios_repetidos}' por tener igual mail o nombre`, 'OK', 300000);
        return false;
      } 
  
      return true;
  
    }
    catch (err) {
      console.error(err);
      this.toastService.openSnackBar('Se produjo un error al validar usuario ya ingresado para el usuario ('+err+')', 'OK', 300000);
      return false;
    }
  }


    save() {
      if (this.usuarioFormControl.invalid) { return; }
      let usuarioData = Object.assign(this.usuario, this.usuarioFormControl.value);
      usuarioData.type = this.tipo_seleccionado;

      this.usuario_valido().then((data) => {
        const valido = data;
       // console.log('EN EL SAVE',data);
        if (!valido)  { return; }
        this.dialogRef.close(usuarioData);
      });   
    }    

    close() {
      this.dialogRef.close(null);
    }

    public getNameErrorMessage(): string {
      return this.usuarioFormControl.get('name')!.hasError('required') ? 'Debe especificar un nombre' : ''; 
    }

    public getEmailErrorMessage(): string {
      return this.hasError('email', 'email') ? 'No es un mail válido' : '';
    }

    public getPasswordErrorMessage(): string {
      return '';
    }

    public getFechaAltaErrorMessage(): string {
      return '';
    }

    public getFechaBajaErrorMessage(): string {
      return '';
    }

    public getTipoErrorMessage(): string {
      return '';
    }

    public getPositionErrorMessage(): string {
      return '';
    }

    private hasError(field: string, errorCode: string) {
      return this.usuarioFormControl.hasError(field, errorCode);
    }

    get title() {
      return this.titulo; 
    }
}
