<div class="puntal-table-container">
    <div class="puntal-table mat-elevation-z8">
      <div class="puntal-table-title" color="primary"> 
        <!-- <div class="header" color="primary"> -->
        <div class="content">
          <div>{{tableTitle}}</div>
            <span class="spacer"></span>
            <div *ngIf="((showFiltersButton == 'S') || (showFiltersButton == 'H'))">
              
              <a mat-mini-fab color="accent" (click)="ShowFiltersHandler($event)">
                <mat-icon *ngIf="(showFiltersButton == 'S')" matTooltip="Mostrar Filtros">filter_alt</mat-icon>
                <mat-icon *ngIf="(showFiltersButton == 'H')" matTooltip="Ocultar Filtros"class="tachado">filter_alt</mat-icon>
              </a> 
            </div>             
             <div *ngIf="(showAddButton == 'S') && !noEdit">
                <a mat-mini-fab color="accent" (click)="addHandler($event)"  >
                  <mat-icon aria-label="add" matTooltip="Agregar">add</mat-icon>
                </a> 
              </div>      
              <div *ngIf="(showDownloadGridButton == 'S')">
                <a mat-mini-fab color="accent" (click)="DownLoadGridHandler($event)">
                  <mat-icon aria-label="download" matTooltip="Descargar">download</mat-icon>
                </a> 
              </div> 
              <div *ngIf="(showBackButton == 'S')">
                <a mat-mini-fab color="accent" (click)="backHandler($event)">
                  <mat-icon aria-label="back" matTooltip="Volver">arrow_back</mat-icon>
                </a> 
              </div>                  
            </div>        
            <div *ngIf="loading" class="loading">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
        <div id="htmlData">
      <div class="mat-table-wrapper" #table_wrapper id = 'table_wrapper'>
        <table mat-table [dataSource]="matDataSource" matSort (matSortChange)="sortData($event)">
          <ng-container *ngFor="let col of displayedColumns; index as i"  [matColumnDef]="col.value" [sticky]="(col.sticky)">
              <th mat-header-cell *matHeaderCellDef 
                  mat-sort-header="{{col.value}}"
                  [ngClass]="{center: col.center}"
                  matTooltip="{{col.tooltip}}" matTooltipPosition="above" 
                  [matTooltipDisabled]="((!col.tooltip) || (col.tooltip == ''))"
              >{{col.label | uppercase}}<mat-icon class="ocultar_al_imprimir" style = "margin-left: 17px;">{{col.img}}</mat-icon></th>
              
              <td
                [class]="isActionColumn(i) ? 'puntal-table-row puntal-action-column' : 'puntal-table-row'" 
                mat-cell
                [ngClass]="{center: col.center}"
                *matCellDef="let row"
                (click)="rowClickHandler(i, row)"
                >
                <ng-container *ngIf="col.semaphore; else onlyValue">
                  <ng-container *ngIf="value(col, row) as val">
                    <ng-container *ngIf="getTypeSemaphore(val).semaphore === 'no-data'; else semaphoreColor">
                      <div class="semaphore no-data">Sin datos</div>
                    </ng-container>
                    <ng-template #semaphoreColor>
                      <div class="semaphore" [ngClass]="getTypeSemaphore(val).semaphore">{{getTypeSemaphore(val).decision || '&nbsp;'}}</div>
                    </ng-template>
                  </ng-container>
                </ng-container>
                <ng-template #onlyValue>
                  <ng-container *ngIf="col.button && value(col, row) as buttonText; else rawValue">
                      <button mat-button (click)="colButtonClick($event, col, row)">{{buttonText}}</button>
                  </ng-container>
                  <ng-template #rawValue>
                    <label class="cell" [ngClass]="{small: col.small}">{{value(col, row)}}</label>
                  </ng-template>
                </ng-template>
                <div data-html2canvas-ignore="true">
                <ng-container *ngIf="isActionColumn(i)" class="puntal-action-column">
                  <button *ngIf="!noDownload && canDownload(row)" mat-icon-button color="accent" (click)="downloadHandler($event, row)">
                    <mat-icon>download</mat-icon>
                  </button>
                  <button *ngIf="!noEdit && canEdit(row)" mat-icon-button color="primary" (click)="editHandler($event, row)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button *ngIf="!noDelete && canDelete(row)" mat-icon-button color="warn" (click)="deleteHandler($event, row)">
                    <mat-icon>delete</mat-icon>
                  </button>
                  <button *ngIf="(showPermissionButton == 'S' && canShowDetail(row))" mat-icon-button color="primary" (click)="permissionHandler($event, row)">
                    <mat-icon>speaker_notes</mat-icon>
                  </button>
                </ng-container>
              </div>
              </td>
            
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="colValues; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: colValues;" attr.data-row-index="{{ dataSource.indexOf(row) }}"></tr>
        </table>
      </div>
      </div>
    </div>
  </div>
  
