import {Component, OnInit, Inject, Input} from '@angular/core';

import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { AuthService } from '../../../_services/auth.service';

/* Lotes */
//import { IClient } from '~core/models/lotes-section.models';
import { Lote } from '../../../modelo/lote';


@Component({
  selector: 'app-lote-form',
  templateUrl: './lote-form.component.html',
  styleUrls: ['./lote-form.component.scss']
})
export class LoteFormComponent implements OnInit {

  public matcher: ErrorStateMatcher;
  public loteFormControl: FormGroup;
 // readonly billingGetter = {get: () => null};
  public titulo : string;
  public operacion: string;
  public loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public lote: Lote /*IClient*/,
    private dialogRef: MatDialogRef<LoteFormComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService
    ) { }

  ngOnInit() {
    this.loading = true;
    if ((!this.lote) || (Object.keys(this.lote).length === 0)) {
      this.lote = {
        id: 0,
        name: '',
        polygon: '',
        area: 0
      } as Lote /*IClient*/;
      this.titulo = 'Agregar Lote';  
      this.operacion = 'A'; 
      this.loteFormControl = this.createFormControl();
      this.loading = false;
    }
    else {
      //this.authService.CanEdit('plot',this.lote.id) 
      let campo = this.lote.farm_id;
      if (!campo) {campo = -1;}
      this.authService.CanEdit('farm',campo)
      .then((puede_editar) => { 
        if (puede_editar) {
          this.operacion = 'E'; 
          this.titulo = 'Editar Lote';
        } 
        else {
          this.titulo = 'Consultar Lote';
          this.operacion = 'C'; 
        }           
        this.loteFormControl = this.createFormControl();
        this.loading = false;
      });
    }    
    this.matcher = new ShowOnDirtyErrorStateMatcher();

  }

  private createFormControl(): FormGroup {
     console.warn('LOTE',this.lote);
     console.warn('NAME',this.lote.name);
     return this.formBuilder.group({
      name: [{value:this.lote.name,disabled: (this.operacion === 'C')}, [Validators.required, Validators.minLength(3)]],
      polygon: [{value:this.lote.polygon || '',disabled: (this.operacion === 'C')}],
      area: [{value:this.lote.area || '',disabled: (this.operacion === 'C')}]
    });
  }

  save() {
    if (this.loteFormControl.invalid) { return; }
    const loteData = Object.assign(this.lote, this.loteFormControl.value);
//    loteData.billing = this.billingGetter.get();
    this.dialogRef.close(loteData);
  }

  close() {
      this.dialogRef.close(null);
  }

  public getNameErrorMessage(): string {
    if (this.loteFormControl.get('name')) {
      return (this.loteFormControl.get('name'))?.hasError('required') ? 'Debe especificar un nombre' : '';
    }
    return '';
  }

  getPolygonErrorMessage() {
    return '';
  }

  public getAreaErrorMessage(): string {
    if (this.loteFormControl.get('area')) {
      return (this.loteFormControl.get('area')?.hasError('required') || this.loteFormControl.get('area')?.hasError('pattern'))
      ? 'Ingrese un valor númerico para la superficie'
      : '';
    }
    return '';
  }
  
  private hasError(field: string, errorCode: string) {
    return this.loteFormControl.hasError(field, errorCode);
  }

  get title() {
    return this.titulo; //'Agregar Lote';
  }

}
