<div class="admin-catalog-container">
    <div *ngIf="catalogs; else loading" class="catalog-table-container">
      <puntal-table
        tableTitle="Catalogos"
        [dataSource]="catalogs"
        [displayedColumns]="tableColumns"
        [noEdit]="true"
        [noDelete]="true"
        (onBack)="goBack()"
        (onRowClick)="openCatalog($event)"
        showPermissionButton='N'
      >
      </puntal-table>
    </div>
    <ng-template #loading>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-template>
  </div>
  
