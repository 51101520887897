<ng-container *ngIf="loading">
  <!-- <ng-container *ngIf="!scout "> -->
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>

<div class="container" *ngIf="!loading">
    <h2 class="title">{{title}}</h2>
  
    <form class="example-form" [formGroup]="campaniaFormControl">
  
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Campaña" formControlName="name" [errorStateMatcher]="matcher"  oninput="this.value = this.value.toUpperCase()" required>
        <mat-error *ngIf="campaniaFormControl.get('name')!.invalid">{{getNameErrorMessage()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input matInput [matDatepicker]="picker" placeholder="Fecha de inicio" formControlName="from_date" [min]="minDate" [errorStateMatcher]="matcher" [max]="maxDate" readonly required>
        <mat-error *ngIf="((campaniaFormControl.get('from_date')!.invalid) || (campaniaFormControl.get('to_date') != null && (campaniaFormControl.get('to_date')!.value < campaniaFormControl.get('from_date')!.value))) ">{{getFromDateErrorMessage()}}</mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker></mat-datepicker>
      </mat-form-field>
  
       <mat-form-field class="example-full-width">
        <input matInput [matDatepicker]="picker_from" placeholder="Fecha de fin" formControlName="to_date" [min]="minDate" [max]="maxDate" [errorStateMatcher]="matcher" readonly required>
        <mat-error *ngIf="((campaniaFormControl.get('to_date')?.invalid) || (campaniaFormControl.get('from_date') != null && (campaniaFormControl.get('to_date')!.value < campaniaFormControl.get('from_date')!.value))) ">{{getToDateErrorMessage()}}</mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker_from"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker_from></mat-datepicker>
      </mat-form-field> 
      <mat-checkbox [disabled]="operacion === 'C'" [checked]="(campania.active == 'S')" (change)="onChange($event)">Activo</mat-checkbox>

    <div *ngIf="operacion == 'C'" class="buttons">
      <button mat-stroked-button color="primary" (click)="close()">Cerrar</button>
    </div>

    <div *ngIf="operacion != 'C'" class="buttons">
        <button mat-stroked-button color="primary"  (click)="close()">Cancelar</button>
        <button mat-raised-button color="primary" class="accept-button" [disabled]="campaniaFormControl.invalid" (click)="save()">Guardar</button>
      </div>
  
  </form>
</div>
  
