import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

/* Angular Material */
import { AngularMaterialModule } from '../angular-material.module';

/* Dialogs module */
import { DialogsModule } from './dialogs/dialogs.module';
import { ToastService } from './toast/toast.service';
/*import { PuntalTableComponent } from './table/table.component';*/
//import { ClientFormComponent } from '../clientes/forms/cliente-form/cliente-form.component';
//import { PuntalFormsModule } from './forms/forms.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule} from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        AngularMaterialModule,
        MatProgressBarModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule        
    ],
  declarations: [
  /*  PuntalTableComponent*/
  ],
  exports: [
  /*  PuntalTableComponent,*/
    DialogsModule,
    AngularMaterialModule
  ],
  providers: [
    ToastService
  ],
  schemas: [  ]
})

export class SharedModule { }
