//import { Campo } from "./campo";

export class Lote {
    id: number;
    name: string;
    polygon: string;
    area: number;
    creation_date?: Date;
    down_date?: Date;
    farm_id?: number;
}
