import { Injectable } from '@angular/core';
//import { SkipSelf } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { getServerErrorMessage } from '../shared/utility-functions';
import { catchError } from 'rxjs/operators';
import { throwError } from "rxjs";

import { Lote } from '../modelo/lote';
import { environment } from './../../environments/environment';

const API_URL = environment.API_URL;


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class LoteService {
//  errorMsg: string;
//@SkipSelf() 
  constructor(private http: HttpClient) {}

  getLotes(campoId: number): Observable<Lote[]> {
    return this.http.get<Lote[]>(API_URL + 'lote/getAll/'+campoId, { responseType: 'json' });
  }

  getLotesActivos(campoId: number): Observable<Lote[]> {
    return this.http.get<Lote[]>(API_URL + 'lote/getAllActive/'+campoId, { responseType: 'json' });
  }

  deleteLote(idLote:number): Observable<Lote>  {
    return this.http.delete<any>(API_URL + 'lote/'+idLote)
  }

  updateLote(lote:Lote): Observable<Lote>  {
    return this.http.put<any>(API_URL + 'lote', lote , { responseType: 'json' })
  }  

  addLote(lote:Lote): Observable<Lote>  {
    let errorMsg: string;
    errorMsg = '';
    return this.http.post<any>(API_URL + 'lote/add', lote , { responseType: 'json' })
  }

}
