import { Component, OnInit } from '@angular/core';
import { MatNavList, MatListItem } from '@angular/material/list' ;
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss','../../app.component.scss']
})
export class SubmenuComponent implements OnInit {

  constructor(private authService: AuthService) { }
  sidenavWidth = 16;
  isUserAdmin= false;
  isSuperUser= false;
  
  ngOnInit(): void {
    this.isUserAdmin = this.authService.isUserAdmin();
    this.isSuperUser  = this.authService.isSuperUser();
  }

 
}
