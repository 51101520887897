import { Injectable } from '@angular/core';
//import { SkipSelf } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { getServerErrorMessage } from '../shared/utility-functions';
import { catchError } from 'rxjs/operators';
import { throwError } from "rxjs";

import { Cliente, IClientRole } from '../modelo/cliente';
import { environment } from './../../environments/environment';

const API_URL = environment.API_URL;


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class ClienteService {
//  errorMsg: string;
//@SkipSelf() 
  constructor(private http: HttpClient) {}

  getClientes(): Observable<Cliente[]> {
    return this.http.get<Cliente[]>(API_URL + 'cliente/getAll', { responseType: 'json' });
  }

  getClientsAdmin(): Observable<IClientRole[]> {
    return this.http.get<IClientRole[]>(API_URL + 'cliente/getAllAdmin', { responseType: 'json' });
  }

  getClientesActivos(): Observable<Cliente[]> {
    return this.http.get<Cliente[]>(API_URL + 'cliente/getAllActive', { responseType: 'json' });
  }  

  deleteCliente(idCliente:number): Observable<Cliente>  {
    return this.http.delete<any>(API_URL + 'cliente/'+idCliente)
  }

  updateCliente(cliente:Cliente): Observable<Cliente>  {
    return this.http.put<any>(API_URL + 'cliente', cliente , { responseType: 'json' })
  }  

  addCliente(cliente:Cliente): Observable<Cliente>  {
      return this.http.post<any>(API_URL + 'cliente/add', cliente , { responseType: 'json' })
  }

  getClientesRepetidos(clientId:number, email: string, name:string) : Observable<Cliente[]> {
    let data = {"email" : encodeURIComponent(email),"name" : encodeURIComponent(name)};
   // return this.http.get<Usuario[]>(API_URL + 'user/getUserMail',{params: data, responseType: 'json' })
   return this.http.get<Cliente[]>(API_URL + 'cliente/getClientMail/'+clientId+'/'+encodeURIComponent(email)+'/'+encodeURIComponent(name),{responseType: 'json' })
  }

}
