import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

/* Angular Material */
import { AngularMaterialModule } from '../../angular-material.module';

/* Service to open Material Design modal dialogs. */
import { MatDialogModule }  from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from "@angular/forms";
import { MatIconModule} from '@angular/material/icon';

/* Componentes */
import { ConfirmedDialogComponent } from './confirmed-dialog/confirmed-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatIconModule
  ],
  declarations: [
    ConfirmedDialogComponent
  ],
  exports: [
    ConfirmedDialogComponent
  ],
  entryComponents: [
    ConfirmedDialogComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class DialogsModule { }
