<div class="confirmed-dialog-container">
    <h3 mat-dialog-title>{{ title }}</h3>
    <mat-dialog-content>
        <p>{{ message }}</p>
    </mat-dialog-content>
    <ng-container *ngIf="!!this.validation">
      <p>
        <mat-form-field appearance="outline" class="validator">
          <mat-label>Ingrese nombre para eliminar</mat-label>
          <label>
            <input matInput placeholder="Nombre" (keyup)="setInput($event)">
          </label>
          <mat-icon matSuffix>{{validate ? 'sentiment_very_satisfied' : 'highlight_off'}}</mat-icon>
        </mat-form-field>
      </p>
    </ng-container>
    <mat-dialog-actions class="actions">
        <button mat-button color="accent"
                class="btn-block confirm"
                [disabled]="!validate"
                [mat-dialog-close]=true cdkFocusInitial>
                {{button_text ? button_text : 'Eliminar'}}
        </button>
        <button mat-stroked-button color="accent" class="btn-block" mat-dialog-close>
          Cancelar
        </button>
    </mat-dialog-actions>
  </div>
  
