import { Injectable } from '@angular/core';
import { isEmpty } from '../shared/utility-functions';
//import { ConsoleReporter } from 'jasmine';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const CAMPAIGN_KEY = 'last-campaign';
const CLIENT_KEY = 'last-client';
const ACTIVITY_FILTER_KEY = 'stFilterActividades';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
      return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public getLastCampaign(): any {
    const preff = window.sessionStorage.getItem(CAMPAIGN_KEY);
    if (preff) {
      const campanias = JSON.parse(preff);
      if (!isEmpty(campanias)) {return campanias;/*[0];*/}
      return  {};
    }
    return {};

  }

  public getLastClient(): any {
    const preff = window.sessionStorage.getItem(CLIENT_KEY);
    if (preff) {
      return preff;
    }

    return '';
  }

  public saveCampaign(preff: any): void {
    window.sessionStorage.removeItem(CAMPAIGN_KEY);
    window.sessionStorage.setItem(CAMPAIGN_KEY, JSON.stringify(preff));
  } 

  public saveClient(preff: any): void {
    window.sessionStorage.removeItem(CLIENT_KEY);
    window.sessionStorage.setItem(CLIENT_KEY,  JSON.stringify(preff));
  }  

  public getLastActivitiesFilter() {
    const preff = window.sessionStorage.getItem(ACTIVITY_FILTER_KEY);
    if (preff) {
      return JSON.parse(preff);
    }
    return {};
  }

  
  public saveLastActivitiesFilter(preff: any): void {
    window.sessionStorage.removeItem(ACTIVITY_FILTER_KEY);
    window.sessionStorage.setItem(ACTIVITY_FILTER_KEY,  JSON.stringify(preff));
  }  
}
