export class Cliente {
    id: number;
    name: string;
    last_name?: string;
    email?: string;
    creation_date?: Date;
    down_date?: Date;
    phone?: string;
    position?: string;
    cuit?: string;
    social_reason?: string;
    address?: string;
}
export class IClientRole {
    id: number;
    name: string;
    role: string
}

/*
export interface IClient {
    id: string;
    name: string;
    last_name: string;
    email: string;
    phone: string;
    position: string;
    billing?: IBilling;
    from_admin?: boolean;
  }*/