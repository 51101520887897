import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { ActividadService } from '../../../../_services/actividad.service';
import { ToastService } from '../../../../shared/toast/toast.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* if (this.automatic_slide) {
import {
  interval,
  Observable,
  startWith,
  Subject,
  switchMap,
  timer,
} from 'rxjs';*/

/*interface SlideInterface {
  url: string;
  title: string;
}*/

@Component({
  selector: 'app-media-form',
  templateUrl: './media-form.component.html',
  styleUrls: ['./media-form.component.scss']
})



export class MediaFormComponent implements OnInit, OnDestroy  {

   public titulo : string;
   public loading = true;
   public scout = -1;
   slides = [];
   currentIndex: number = 0;
   notas = '';
   notas_original = '';
  // timeoutId?: number;
  // automatic_slide = false;
   
 constructor(
    @Inject(MAT_DIALOG_DATA) public lote: any,
    private dialogRef: MatDialogRef<MediaFormComponent>,
     private actividadService: ActividadService,
     private toastService: ToastService
     ) { }
 
   ngOnInit() {
  //  if (this.automatic_slide) { this.resetTimer(); }
     this.loading = true;
     
     this.scout = this.lote.scout_id;
     this.titulo = 'Recorrida '+this.scout; 
     this.notas = this.lote.observations;
     this.notas_original = this.notas;
     this.retrieveMedia(this.scout);
     this.loading = false;
console.log('entro con ',this.notas);
   }
   ngOnDestroy() {
  //  window.clearTimeout(this.timeoutId);
  }

/*  resetTimer() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = window.setTimeout(() => this.goToNext(), 3000);
  }*/

  goToPrevious(): void {
    const isFirstSlide = this.currentIndex === 0;
    const newIndex = isFirstSlide
      ? this.slides.length - 1
      : this.currentIndex - 1;

    //  if (this.automatic_slide) {this.resetTimer();}
    this.currentIndex = newIndex;
  }

  goToNext(): void {
    const isLastSlide = this.currentIndex === this.slides.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex + 1;

  //  if (this.automatic_slide) {this.resetTimer();}
    this.currentIndex = newIndex;
  }

  goToSlide(slideIndex: number): void {
  //  if (this.automatic_slide) {this.resetTimer();}
    this.currentIndex = slideIndex;
  }

  getCurrentSlideUrl() {
   // const url = 'http://158.69.146.130:3001/media/scout_'+this.scout+'/'+this.slides[this.currentIndex];
    //console.log(url);
 //   console.log(this.slides[this.currentIndex]);
    return `'${this.slides[this.currentIndex]}'`;
  //  return this.slides[this.currentIndex];
    //return `url('${url+this.slides[this.currentIndex]}')`;
    //return this.slides[this.currentIndex];
  }

   async retrieveMedia (scout_id) {
     this.actividadService.getMedia(scout_id).subscribe(media => {
      this.slides = media.media.map(img => 'http://158.69.146.130:3001/media/scout_'+scout_id+'/'+img);
      this.goToSlide(0);
     },
     error => {
       this.toastService.openSnackBar(`No hay imágenes para la recorrida: ${error}`, 'OK');
     });
   }
 
   onChange(valor) {
        if (this.notas != valor) {
        this.notas = valor;
      }
  }

  close() {
      this.dialogRef.close(null);
  }
 
  save() {
    this.loading = true;
    try {    
      if (this.notas_original != this.notas) {
        this.actividadService.updateNotes(this.scout, 'scout', this.notas).subscribe(()  => {
         },
        error => {
          this.toastService.openSnackBar(`Error al actualizar observaciones de la recorrida: ${error}`, 'OK');
        });
      }
        
    } catch (error) {
      this.toastService.openSnackBar(`Error al registrar observaciones de la recorrida: ${error}`, 'OK');
      this.loading = false;
      return;

    } finally {
      this.dialogRef.close(this.notas);
    }
 }
 
 }
 