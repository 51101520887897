//import { Cliente } from "./cliente";

export class Empresa {
        id: number;
        name: string;
        phone?: string;
        email?: string;
        contact?: string;
        creation_date?: Date;
        down_date?: Date;
        cuit?: string;
        social_reason?: string;
        address?: string;
        client_id: number;
      }

export class ICompanyRole {
  id: number;
  name: string;
  role: string
}            