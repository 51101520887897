import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Cliente } from '../modelo/cliente';
import { ClienteService } from '../_services/cliente.service';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../shared/table/table.component';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientFormComponent } from './forms/cliente-form/cliente-form.component';
import { ConfirmedDialogComponent } from '../shared/dialogs/confirmed-dialog/confirmed-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { ToastService } from '../shared/toast/toast.service';
import { VerPermisosFormComponent } from '../shared/ver-permisos-form/ver-permisos-form.component';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})

export class ClientesComponent implements OnInit {
  loading = false;
  sus : any;
  isLoggedIn = false;

  @ViewChild(PuntalTableComponent,{static:true}) table: PuntalTableComponent<any>;

  public clientes: Cliente[] = []; 
  
    readonly header: ITableHeader<Cliente>[] = [
/*     {
      label: 'id',
      value: 'id',
    }, */
    {
      label: 'cliente',
      value: 'Cliente',
      valueFunction: client => `${client.name}${client.last_name ?  ' ' + client.last_name : ''}`
    },
    {
      label: 'email',
      value: 'email',
    },
    {
      label: 'telefono',
      value: 'phone',
    },
    {
      label: 'contacto',
      value: 'position',
    },
    {
      label: 'estado',
      value: 'down_date',
      valueFunction: client => {return (client.down_date == null) ?  ' ' : 'Inactivo'}
    }
  ];

  readonly tableOptions: ITableOptions<Cliente> = {
    canEdit: (cliente) => true /*(this.canAddRecord == 'S')*/, 
    canDelete: (cliente) => ((this.canAddRecord == 'S') && (cliente.down_date == null)),
    canShowDetail: (cliente) => (this.canAddRecord == 'S')
  };
  canAddRecord = 'N';

  constructor(private clienteService: ClienteService,   private dialog: MatDialog, private location: Location ,
  private router: Router,
  private route: ActivatedRoute,
  private authService: AuthService,
  private toastService: ToastService) { }


  ngOnInit(): void {
  /*  this.retrieveData();
    this.canAddRecord = (this.authService.CanAdd('client') ? 'S' : 'N' );*/

    this.authService.CanAdd('client') 
        .then((data) => { 
          this.canAddRecord = (data ? 'S' : 'N'); 
          this.retrieveData();
        });


/*    if (!this.authService.isSuperUser()) {
      this.authService.getPermisoAddClient().subscribe((permiso) => {
        this.canAddRecord = (permiso.permiso ? 'S' : 'N' );
       });
    } 
    else {
      this.canAddRecord = 'S';
    }    */
  }

  retrieveData(){
    this.loading = true;
    this.sus = this.clienteService.getClientes().subscribe((clientes) => {
      this.clientes = clientes;
      this.loading = false;
    },
    error => {
      this.toastService.openSnackBar(`Error al leer clientes: ${error}`, 'OK');
      this.loading = false;
    });
  }


  editRow(row: Cliente) {
    const dialogRef = this.openModal(row);
    dialogRef.afterClosed().subscribe(
      (newClient: Cliente) => {
        if (newClient) {
          this.clienteService.updateCliente(newClient).subscribe( ()  => {
          },
          error => {
            this.retrieveData();
            this.toastService.openSnackBar(`Error al actualizar el campo ${row.name}: ${error}`, 'OK');
          });
        }
      }
    );
  }

  deleteRow(row: Cliente) {
    const data = {
      title: `ATENCIÓN: Borrar cliente '${row.name}'`,
      message: 'Está seguro? Al borrar cliente se perderan todos sus lotes con sus actividades y sus recorridas',
      validation: row.name
    };
    const dialogRef = this.dialog.open(ConfirmedDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      width: 'auto',
      data
    });
    const id_a_borrar = row.id;
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.clienteService.deleteCliente(id_a_borrar).subscribe(
            ()  => {
              this.retrieveData();
            },
            error => {
              this.toastService.openSnackBar(`Error al eliminar el cliente ${row.name}: ${error}`, 'OK');
            }
          );
        }
      }
    );
  }

  navigateTo(row: Cliente) {
//    console.log('should navigate to company with id: ', row.id);
    this.router.navigate([`${row.id}/empresas`], {state: {data:row.name}, relativeTo: this.route});
  }


  goBack() {
    this.location.back();
  }


  addRow() {
    const dialogRef = this.openModal();
    dialogRef.afterClosed().subscribe(
      (newClient: Cliente) => {
        if (newClient) {
          this.clienteService.addCliente(newClient).subscribe(
            (cliente)  => {
              this.retrieveData();
            },
          error => {
            this.toastService.openSnackBar(`Error al agregar el cliente ${newClient.name}: ${error}`, 'OK');
          }
        )
      }
    }
    )
  }

  private openModal(client?: Cliente): MatDialogRef<ClientFormComponent, any> {
    return this.dialog.open(ClientFormComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: true,
      data: client || {}
    });
  }
 
 /* get canAdd() {
    return this.authService.CanAdd('client');
//  return true;
  }*/

   openPermission(client: Cliente) {
      this.dialog.open(VerPermisosFormComponent, {
        width: '1000px',
        disableClose: true,
        autoFocus: true,
        data: {
          id: client.id,
          description: client.name,
          type: 'client'
        }
      });
    }  

  ngOndestroy() {
    this.sus.unsubscribe();
  }

  isAuthenticated() {
    return true;
  }
}
