import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { getServerErrorMessage } from '../shared/utility-functions';  

@Injectable()
export class PuntalagroInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem("auth-user");
    let request = httpRequest;
//    console.log('interceptor: ' + token);
 //   console.log(request);
    if (token) {
      request = httpRequest.clone({ headers: httpRequest.headers.set('x-access-token', token),
        withCredentials: true
      });
    }
    return next.handle(request).pipe(catchError(err => this.errorHandler(err))
      );
  }

  errorHandler(errorResponse: HttpErrorResponse) {
    const { status, error, url } = errorResponse;
 //   console.log("error interceptor: ", errorResponse);
    if ((status === 401 || status === 403)) {
      this.router.navigate(['/login']);
    }
   // console.log('en interceptor',errorResponse);
  /*  return throwError(errorResponse.error);*/
    let errorMessage = '';
    if (error instanceof ErrorEvent) {
        // client-side error
        errorMessage = 'Error: ${error.error.message}';
    } else {
        // server-side error
        console.log(errorResponse);
        if ((errorResponse.error.message) && (errorResponse.error.message != undefined) && (errorResponse.error.message != '')) {
          errorMessage = 'Error: '+errorResponse.error.message;
        }
        else {
          errorMessage = getServerErrorMessage(errorResponse); //Error Code: ${error.status}\nMessage: ${error.message};
        }
    }
    return throwError(errorMessage);    
  }

}

