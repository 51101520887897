import {Component, OnInit, Inject, Input} from '@angular/core';

import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { AuthService } from '../../../_services/auth.service';
/* Clientes */
//import { IClient } from '~core/models/clients-section.models';
import { Cliente } from '../../../modelo/cliente';
import { ClienteService } from '../../../_services/cliente.service';

@Component({
  selector: 'cliente-form',
  templateUrl: './cliente-form.component.html',
  styleUrls: ['./cliente-form.component.scss']
})
export class ClientFormComponent implements OnInit {

  public matcher: ErrorStateMatcher;
  public clientFormControl: FormGroup;
  public titulo : string;
  public operacion: string;
  public loading = true;
  errorMessages: { [key: string]: string } = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public client: Cliente,
    private clienteService: ClienteService, 
    private dialogRef: MatDialogRef<ClientFormComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService
    ) { }

  ngOnInit() {
    this.errorMessages['name'] = '';
    this.errorMessages['email'] = '';

    if ((!this.client) || (Object.keys(this.client).length === 0)) {
      this.client = {
        id: 0,
        name: '',
      } as Cliente;
      this.titulo = 'Agregar Cliente'; 
      this.operacion = 'A'; 
      this.clientFormControl = this.createFormControl();
      
      this.loading = false;
    }
    else {
      this.authService.CanEdit('client',this.client.id) 
      .then((puede_editar) => { 
        if (puede_editar) {
          this.operacion = 'E'; 
          this.titulo = 'Editar Cliente';
        } 
        else {
          this.titulo = 'Consultar Cliente';
          this.operacion = 'C'; 
        }           
        this.clientFormControl = this.createFormControl();
        
        this.loading = false;
      });

    }    
    this.matcher = new ShowOnDirtyErrorStateMatcher();
  }

  onNameInputChange() {
    const nameControl = this.clientFormControl.get('name');
    if (nameControl) {
      this.errorMessages['name'] = '';
      nameControl.updateValueAndValidity(); // Actualiza la validez del control
    }
  }

  onEmailInputChange() {
    const nameControl = this.clientFormControl.get('email');
    if (nameControl) {
      this.errorMessages['email'] = '';
      nameControl.updateValueAndValidity(); // Actualiza la validez del control
    }
  }

  private createFormControl(): FormGroup {

    return this.formBuilder.group({
      name: [{value:this.client.name,disabled: (this.operacion === 'C')}, [Validators.required, Validators.minLength(3), this.customNameValidator.bind(this)]],
      last_name: [{value:this.client.last_name,disabled: (this.operacion === 'C')}],
      email: [{value:this.client.email,disabled: (this.operacion === 'C')}, [Validators.required, Validators.email, this.customEmailValidator.bind(this)]],
      phone: [{value:this.client.phone,disabled: (this.operacion === 'C')}, [Validators.pattern(/[+]?[0-9\- ]*$/)]],
      position: [{value:this.client.position,disabled: (this.operacion === 'C')}],
      cuit: [{value:this.client.cuit,disabled: (this.operacion === 'C')}],
      address: [{value:this.client.address,disabled: (this.operacion === 'C')}],
      social_reason: [{value:this.client.social_reason,disabled: (this.operacion === 'C')}]
    });
  }

  customNameValidator(control: FormControl) {
    if (this.errorMessages['name'] != '') {
      return { customError: true };
    }
    return null;
  }  

  customEmailValidator(control: FormControl) {
    if (this.errorMessages['email'] != '') {
      return { customError: true };
    }
    return null;
  }  


  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  save() {

    if (this.clientFormControl.invalid) { return; }
    
    this.cliente_valido(this.clientFormControl.get('email')!.value,this.clientFormControl.get('name')!.value).then((data) => {
      // En data está el nombre del cliente con el que choca
      if (data != '')  { 
        const valor_repetido = data.trim();
        const valor_control = this.clientFormControl.get('name')!.value.trim();
        if (valor_control == valor_repetido) {
          this.errorMessages['name'] = 'Ya existe un cliente con el mismo nombre';
          this.clientFormControl.get('name')!.updateValueAndValidity();
          this.errorMessages['name'] = 'Ya existe un cliente con el mismo nombre';
        }
        else {
          this.errorMessages['email'] = 'El email ingresado pertenece al cliente '+valor_repetido;
          this.clientFormControl.get('email')!.updateValueAndValidity();
          this.errorMessages['email'] = 'El email ingresado pertenece al cliente '+valor_repetido;
        }
      //  this.markFormGroupTouched(this.clientFormControl);
        
        return; 
      }
      else {
        const clientData = Object.assign(this.client, this.clientFormControl.value);
        this.dialogRef.close(clientData);
      }
    });      
    //this.dialogRef.close(clientData);
  }

  close() {
      this.dialogRef.close(null);
  }

  public getFirstNameErrorMessage(): string {
    return this.clientFormControl.get('name')!.hasError('required') ? 'Debe especificar un nombre' : this.errorMessages['name']; 
  }

  public getLastNameErrorMessage(): string {
    return this.clientFormControl.get('last_name')!.hasError('required') ? 'Debe especificar un apellido' : '';
  }

  public getEmailErrorMessage(): string {
    return this.clientFormControl.get('email')!.hasError('required') ? 'No es un mail válido' : this.errorMessages['email'];
  }

  public getCuitErrorMessage(): string {
    const cuit = this.clientFormControl.get('cuit');
    if (cuit === null) {
      return 'Debe especificar un CUIT' ;
    }
    else {
      return '';
    }

  }

  public getAddressErrorMessage(): string {
    if (this.clientFormControl.get('address') === null) {
      return 'Debe especificar una dirección'
    }
    return '';
  }

  public getSocialReasonErrorMessage(): string {
    if (this.clientFormControl.get('social_reason') === null) {
      return 'Debe especificar la Razón Social'
    }
    return '';    
  }
 

  public getPhoneErrorMessage(): string {
    return this.hasError('phone', 'pattern') ? 'El número telefónico debe tener solo números (y un + opcional al inicio)' : '';
  }

  public getPositionErrorMessage(): string {
    return '';
  }

  private hasError(field: string, errorCode: string) {
    
    return this.clientFormControl.hasError(field, errorCode);
  }


  async clientes_repetidos(clientId,email,name) : Promise<string> {
    if (!(email) || (email == null) || !(name) || (name == null)) {
      return '';
    } 
    
    return await this.clienteService.getClientesRepetidos(clientId,email,name).toPromise()
    .then((clientes_repe) => {console.info(clientes_repe);if ((!clientes_repe) || (clientes_repe.length < 1)) {return '';} else {return clientes_repe[0].name;}});
  }


  async cliente_valido(email,name): Promise<string> {
    // ver que no exista otro cliente con el mismo mail o con el mismo nombre

  try {
    if (!(this.client) || (this.client == null)) {
      //this.toastService.openSnackBar(`Minimamente debe seleccionar un cliente`, 'OK', 300000);
      return 'Minimamente debe seleccionar un cliente'; //false;
    }
    if (!(email) || (email == null) || (email == '')) {
      //this.toastService.openSnackBar(`Ingrese el email`, 'OK', 300000);
      return 'Ingrese el email'; //false;
    }
    if (!(name) || (name == null) || (name == '')) {
      //this.toastService.openSnackBar(`Ingrese el nombre del cliente`, 'OK', 300000);
      return 'Ingrese el nombre del cliente'; //false;
    }        
    
    let clientes_repetidos = await this.clientes_repetidos(this.client.id,email,name);
    if (clientes_repetidos) {
      //this.toastService.openSnackBar(`El cliente ingresado coincide con '${clientes_repetidos}' por tener igual mail o nombre`, 'OK', 300000);
      //return `El cliente ingresado coincide con '${clientes_repetidos}' por tener igual mail o nombre`; //false;
      return `${clientes_repetidos}`; 
    } 

    return ''; //true;

  }
  catch (err) {
    console.error(err);
    //this.toastService.openSnackBar('Se produjo un error al validar cliente ya ingresado para el cliente ('+err+')', 'OK', 300000);
    return 'Se produjo un error al validar cliente ya ingresado para el cliente ('+err+')'; //false;
  }
}

  get title() {
    return this.titulo;
  }

}
