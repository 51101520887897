<ng-container  *ngIf="isAuthenticated()">
    <!-- <div class="download-report-container add-element-container" *ngIf="canAdd">
     <button mat-raised-button color="accent" (click)="addClient()">
       Agregar Cliente
     </button>
   </div> -->
 
   <div class="plot-table">
     <puntal-table
       tableTitle={{titulo}}
       [dataSource]="lotes"
       [loading]="loading"
       [options]="tableOptions"
       [displayedColumns]="header"
       (onEdit)="editRow($event)"
       (onDelete)="deleteRow($event)"
       (onRowClick)="null"
       (onAdd)="addRow()"
       (onBack)="goBack()"
       [showAddButton]="canAddRecord"
       showBackButton="S"
       (onShowPermissionClick)="openPermission($event)"
       [showPermissionButton]="canAddRecord"
       
     >
   </puntal-table>
   </div>
 
   </ng-container>
