import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Empresa } from '../modelo/empresa';
import { EmpresaService } from '../_services/empresa.service';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../shared/table/table.component';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmpresaFormComponent } from './forms/empresa-form/empresa-form.component';
import { ConfirmedDialogComponent } from '../shared/dialogs/confirmed-dialog/confirmed-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { ToastService } from '../shared/toast/toast.service';
import { VerPermisosFormComponent } from '../shared/ver-permisos-form/ver-permisos-form.component';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['../clientes/clientes.component.scss']
})


export class EmpresasComponent implements OnInit {
  loading = false;
  sus : any;
  isLoggedIn = false;
  clientId: number;
  titulo: string = 'Empresas';

  @ViewChild(PuntalTableComponent,{static:true}) table: PuntalTableComponent<any>;

  public empresas: Empresa[] = []; 
  
    readonly header: ITableHeader<Empresa>[] = [
/*     {
      label: 'id',
      value: 'id',
    }, */
    {
      label: 'empresa',
      value: 'Empresa',
      valueFunction: empresa => `${empresa.name}`
    },
    {
      label: 'email',
      value: 'email',
    },
    {
      label: 'telefono',
      value: 'phone',
    },
    {
      label: 'contacto',
      value: 'contact',
    },
    {
      label: 'Facturación',
      value: 'cuit'
    },
    {
      label: 'estado',
      value: 'down_date',
      valueFunction: empresa => {return (empresa.down_date == null) ?  ' ' : 'Inactivo'}
    },
  ];

  readonly tableOptions: ITableOptions<Empresa> = {
    canEdit: (empresa) => true /*(this.canAddRecord == 'S')*/, 
    canDelete: (empresa) => ((this.canAddRecord == 'S') && (empresa.down_date == null))
  };
  canAddRecord = 'N';

  constructor(private empresaService: EmpresaService,   private dialog: MatDialog, private location: Location ,
  private router: Router,
  private route: ActivatedRoute,
  private authService: AuthService,
  private toastService: ToastService) { }


  ngOnInit(): void {
    this.loading = true;
    this.route.paramMap.subscribe(params => {
      if (params.get('clientId')) {
        this.clientId = Number(params.get('clientId'));
        // Proponemos el título de la ventana
        if(history.state.data){
          this.titulo = 'Empresas de '+history.state.data;
          sessionStorage.setItem('ec_'+this.clientId, history.state.data);
        }
        else{
          let titulo_guardado = sessionStorage.getItem('ec_'+this.clientId)
          if (titulo_guardado) {
            this.titulo = 'Empresas de '+titulo_guardado;
          }
          else {
            this.titulo = 'Empresas';
          }
        }      
      /*  this.canAddRecord = (this.authService.CanAdd('company',params.get('clientId')) ? 'S' : 'N' );
        this.retrieveData();*/
        this.authService.CanAdd('company',params.get('clientId')) 
        .then((data) => { 
          this.canAddRecord = (data ? 'S' : 'N'); 
          this.retrieveData();
        });        
     }
    });    
  }

  private retrieveData(){
    this.loading = true;
    this.sus = this.empresaService.getEmpresas(this.clientId).subscribe((empresas) => {
      this.empresas = empresas;
      this.loading = false;
    },
      error => {
      this.toastService.openSnackBar(`Error al leer empresas: ${error}`, 'OK');
      this.loading = false;
    });
  }

  editRow(row: Empresa) {
    const dialogRef = this.openModal(row);
    dialogRef.afterClosed().subscribe(
      (newEmpresa: Empresa) => {
        if (newEmpresa) {
          this.empresaService.updateEmpresa(newEmpresa).subscribe( ()  => {
          },
          error => {
            this.retrieveData();
            this.toastService.openSnackBar(`Error al actualizar el campo ${row.name}: ${error}`, 'OK');
          });
        }
      }
    );
  }

  deleteRow(row: Empresa) {
    const data = {
      title: `ATENCIÓN: Borrar empresa '${row.name}'`,
      message: 'Está seguro? Al borrar la empresa se perderan todos sus lotes con sus actividades y sus recorridas',
      validation: row.name
    };
    const dialogRef = this.dialog.open(ConfirmedDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      width: 'auto',
      data
    });
    const id_a_borrar = Number(row.id);
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.empresaService.deleteEmpresa(id_a_borrar).subscribe(
            ()  => {
              this.retrieveData();
            },
            error => {
              this.toastService.openSnackBar(`Error al eliminar la empresa ${row.name}: ${error}`, 'OK');
            }
          );
        }
      }
    );
  }

  navigateTo(row: Empresa) {
    this.router.navigate([`${row.id}/campos`], {state: {data:row.name}, relativeTo: this.route});
  }


  goBack() {
    this.location.back();
  }


  addRow() {
    const dialogRef = this.openModal();
    dialogRef.afterClosed().subscribe(
      (newEmpresa: Empresa) => {
        if (newEmpresa) {
          newEmpresa.client_id = this.clientId;
          this.empresaService.addEmpresa(newEmpresa).subscribe(
            (empresa)  => {
              this.retrieveData();
            },
          error => {
            console.log('ERROR',error);
            this.toastService.openSnackBar(`Error al agregar la empresa ${newEmpresa.name}: ${error}`, 'OK');
          }
        )
      }
    }
    )
  }

  private openModal(empresa?: Empresa): MatDialogRef<EmpresaFormComponent, any> {
    return this.dialog.open(EmpresaFormComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: true,
      data: empresa || {}
    });
  }
 
  get canAdd() {
  //  return this.authService.CanAdd('company', null, this.empresaId, null, null) ;
  return true;
  }

   openPermission(empresa: Empresa) {
      this.dialog.open(VerPermisosFormComponent, {
        width: '1000px',
        disableClose: true,
        autoFocus: true,
        data: {
          id: empresa.id,
          description: empresa.name,
          type: 'company'
        }
      });
    }  

  ngOndestroy() {
    this.sus.unsubscribe();
  }

  isAuthenticated() {
    return true;
  }
 
}


