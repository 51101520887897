import {Component, OnInit, Inject, Input} from '@angular/core';

import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { AuthService } from '../../../_services/auth.service';

/* Empresas */
//import { IClient } from '~core/models/empresas-section.models';
import { Empresa } from '../../../modelo/empresa';


@Component({
  selector: 'app-empresa-form',
  templateUrl: './empresa-form.component.html',
  styleUrls: ['./empresa-form.component.scss']
})
export class EmpresaFormComponent implements OnInit {

  public matcher: ErrorStateMatcher;
  public empresaFormControl: FormGroup;
 // readonly billingGetter = {get: () => null};
  public titulo : string;
  public operacion: string;
  public loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public empresa: Empresa /*IClient*/,
    private dialogRef: MatDialogRef<EmpresaFormComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService
    ) { }

  ngOnInit() {
    this.loading = true;
    if ((!this.empresa) || (Object.keys(this.empresa).length === 0)) {
      this.empresa = {
        id: 0,
        name: '',
      } as Empresa;
      this.titulo = 'Agregar Empresa';  
      this.operacion = 'A'; 
      this.empresaFormControl = this.createFormControl();
      this.loading = false;
    } else {
      this.authService.CanEdit('company',this.empresa.id) 
      .then((puede_editar) => { 
        if (puede_editar) {
          this.operacion = 'E'; 
          this.titulo = 'Editar Empresa';
        } 
        else {
          this.titulo = 'Consultar Empresa';
          this.operacion = 'C'; 
        }           
        this.empresaFormControl = this.createFormControl();
        this.loading = false;
      });
    }
    this.matcher = new ShowOnDirtyErrorStateMatcher();
  }

  private createFormControl(): FormGroup {
       return this.formBuilder.group({
        name: [{value:this.empresa.name,disabled: (this.operacion === 'C')}, [Validators.required, Validators.minLength(3)]],
        email: [{value:this.empresa.email,disabled: (this.operacion === 'C')}, [Validators.required, Validators.email]],
        phone: [{value:this.empresa.phone,disabled: (this.operacion === 'C')}, [Validators.pattern(/[+]?[0-9\- ]*$/)]],
        contact: [{value:this.empresa.contact,disabled: (this.operacion === 'C')}],
        cuit: [{value:this.empresa.cuit,disabled: (this.operacion === 'C')}],
        address: [{value:this.empresa.address,disabled: (this.operacion === 'C')}],
        social_reason: [{value:this.empresa.social_reason,disabled: (this.operacion === 'C')}]
      });      
    }
 
  save() {
    if (this.empresaFormControl.invalid) { return; }
    const empresaData = Object.assign(this.empresa, this.empresaFormControl.value);
//    empresaData.billing = this.billingGetter.get();
    this.dialogRef.close(empresaData);
  }

  close() {
      this.dialogRef.close(null);
  }

  public getFirstNameErrorMessage(): string {
      return this.empresaFormControl.get('name')!.hasError('required') ? 'Debe especificar un nombre' : ''; 
  }

    public getEmailErrorMessage(): string {
    return this.hasError('email', 'email') ? 'No es un mail válido' : '';
  }

  public getCuitErrorMessage(): string {
    const cuit = this.empresaFormControl.get('cuit');
    if (cuit === null) {
      return 'Debe especificar un CUIT' ;
    }
    else {
      return '';
    }

  }

  public getAddressErrorMessage(): string {
    if (this.empresaFormControl.get('address') === null) {
      return 'Debe especificar una dirección'
    }
    return '';
  }

  public getSocialReasonErrorMessage(): string {
    if (this.empresaFormControl.get('social_reason') === null) {
      return 'Debe especificar la Razón Social'
    }
    return '';    
  }
 

  public getPhoneErrorMessage(): string {
    return this.hasError('phone', 'pattern') ? 'El número telefónico debe tener solo números (y un + opcional al inicio)' : '';
  }

  
  private hasError(field: string, errorCode: string) {
    return this.empresaFormControl.hasError(field, errorCode);
  }

  get title() {
    return this.titulo; //'Agregar Empresa';
  }

}
