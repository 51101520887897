import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Campo } from '../modelo/campo';
import { CampoService } from '../_services/campo.service';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../shared/table/table.component';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CampoFormComponent } from './forms/campo-form/campo-form.component';
import { ConfirmedDialogComponent } from '../shared/dialogs/confirmed-dialog/confirmed-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { ToastService } from '../shared/toast/toast.service';
import { VerPermisosFormComponent } from '../shared/ver-permisos-form/ver-permisos-form.component';

@Component({
  selector: 'app-campos',
  templateUrl: './campos.component.html',
  styleUrls: ['../clientes/clientes.component.scss']
})


export class CamposComponent implements OnInit {
  loading = false;
  sus : any;
  isLoggedIn = false;
  empresaId: number;
  ok: boolean;
  titulo: string = 'Campos';
  
  @ViewChild(PuntalTableComponent,{static:true}) table: PuntalTableComponent<any>;

  public campos: Campo[] = []; 
  
    readonly header: ITableHeader<Campo>[] = [
/*     {
      label: 'id',
      value: 'id',
    }, */
    {
      label: 'campo',
      value: 'Campo',
      valueFunction: campo => `${campo.name}`
    },
    {
      label: 'poligono',
      value: 'polygon',
    },
    {
      label: 'plano URL',
      value: 'map',
    },
    {
      label: 'estado',
      value: 'down_date',
      valueFunction: campo => {return (campo.down_date == null) ?  ' ' : 'Inactivo'}
    }
  ];

  readonly tableOptions: ITableOptions<Campo> = {
    canEdit: (campo) => true /*(this.canAddRecord == 'S')*/, 
    canDelete: (campo) => ((this.canAddRecord == 'S') && (campo.down_date == null))
  };
  canAddRecord = 'N'; 
  
  constructor(private campoService: CampoService,   private dialog: MatDialog, private location: Location ,
  private router: Router,
  private route: ActivatedRoute,
  private authService: AuthService,
  private toastService: ToastService  ) { }


  ngOnInit(): void {
    this.loading = true;
    this.route.paramMap.subscribe(params => {
      if (params.get('empresaId')) {
        this.empresaId = Number(params.get('empresaId'));     
      // Proponemos el título de la ventana
      if(history.state.data){
        this.titulo = 'Campos de '+history.state.data;
        sessionStorage.setItem('ce_'+this.empresaId, history.state.data);
      }
      else{
        let titulo_guardado = sessionStorage.getItem('ce_'+this.empresaId)
        if (titulo_guardado) {
          this.titulo = 'Campos de '+titulo_guardado;
        }
        else {
          this.titulo = 'Campos';
        }
      }      
      this.authService.CanAdd('farm',params.get('clientId'),params.get('empresaId')) 
        .then((data) => { 
          this.canAddRecord = (data ? 'S' : 'N'); 
          this.retrieveData();
        });

  // this.canAddRecord = (this.authService.CanAdd('farm',params.get('clientId'),params.get('empresaId')) ? 'S' : 'N' );
  // this.retrieveData();
     
    }     
  });    
  }

  retrieveData(){
    this.loading = true;
    this.sus = this.campoService.getCampos(this.empresaId).subscribe((campos) => {
      this.campos = campos;
      this.loading = false;
    },
    error => {
      this.toastService.openSnackBar(`Error al leer campos: ${error}`, 'OK');
      this.loading = false;
    });
  }
  
  canEdit(row: Campo) : boolean {
    if (this.authService.isSuperUser()) {return true;}
    this.authService.getPermisoEditFarm(row.id).subscribe((permiso) => {
      if (permiso) {
        return permiso.permiso;
      }
      else {return false;} 
      }
     );
     return false;
  }


  
  editRow(row: Campo) {
    const dialogRef = this.openModal(row);
    dialogRef.afterClosed().subscribe(
      (newCampo: Campo) => {
        if (newCampo) {
          this.campoService.updateCampo(newCampo).subscribe( ()  => {
            },
            error => {
              this.retrieveData();
              this.toastService.openSnackBar(`Error al actualizar el campo ${row.name}: ${error}`, 'OK');
            });
        }
      }
    );
  }

  deleteRow(row: Campo) {
    const data = {
      title: `ATENCIÓN: Borrar campo '${row.name}'`,
      message: 'Está seguro? Al borrar campo se perderan todos sus lotes con sus actividades y sus recorridas',
      validation: row.name
    };
    const dialogRef = this.dialog.open(ConfirmedDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      width: 'auto',
      data
    });
    const id_a_borrar = Number(row.id);
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.campoService.deleteCampo(id_a_borrar).subscribe(
            ()  => {
              this.retrieveData();
            },
            error => {
              this.toastService.openSnackBar(`Error al eliminar el campo ${row.name}: ${error}`, 'OK');
            }
          );
        }
      }
    );
  }

  navigateTo(row: Campo) {
    this.router.navigate([`${row.id}/lotes`], {state: {data:row.name}, relativeTo: this.route});
  }


  goBack() {
    this.location.back();
  }


  addRow() {
    const dialogRef = this.openModal();
    dialogRef.afterClosed().subscribe(
      (newCampo: Campo) => {
        if (newCampo) {
          newCampo.company_id = this.empresaId;
          this.campoService.addCampo(newCampo).subscribe(
            (campo)  => {
              this.retrieveData();
          },
          error => {
            this.toastService.openSnackBar(`Error al agregar el campo ${newCampo.name}: ${error}`, 'OK');
          }
        )
      }
    }
    )
  }

  private openModal(campo?: Campo): MatDialogRef<CampoFormComponent, any> {
    return this.dialog.open(CampoFormComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: true,
      data: campo || {}
    });
  }
 
/*  canAdd(): boolean {
    return this.authService.CanAdd('farm', null, this.empresaId, null, null);
    //if (this.authService.CanAdd('farm', null, this.empresaId, null, null)) {this.canAddRecord = 'S';console.log('SI');} else {console.log('NO!!');};
  }*/

  openPermission(campo: Campo) {
    this.dialog.open(VerPermisosFormComponent, {
      width: '1000px',
      disableClose: true,
      autoFocus: true,
      data: {
        id: campo.id,
        description: campo.name,
        type: 'farm'
      }
    });
  }  

  ngOndestroy() {
    this.sus.unsubscribe();
  }

  isAuthenticated() {
    return true;
  }
}
