
 <div class="properties-view-container">
  <p> En desarrollo: consulta de las propiedades del insumo </p>
    <div class="properties-view-list">
     
<!--      <ng-container *ngIf="prop_List; else loading">
        <mat-list>
          <mat-list-item *ngFor="let propiedad of prop_List">
             <mat-icon matListIcon>{{permissionType[user.type].icon}}</mat-icon>
            <h3 matLine><b>{{propiedad.name}}</b></h3>
            <p matLine>
               <span> {{permissionType[user.type].name}} en {{user.level}}</span>
              <span class="datetime"> ({{relative(propiedad.from_date)}})</span> 
            </p>
            <mat-icon class="delete-permission" *ngIf="canAdd && user.canDelete" (click)="edit(user)">edit</mat-icon>
            <mat-icon class="delete-permission" *ngIf="canAdd && user.canDelete" (click)="delete(user)">clear</mat-icon>
             <mat-icon class="delete-permission" *ngIf="canAdd && user.canDelete" (click)="reset_password(user)">password</mat-icon>
          </mat-list-item>
        </mat-list>
      </ng-container>
      <ng-template #loading>
        <div class="loading">
          Cargando propiedades del insumo ...
        </div>
      </ng-template>
    </div>
    <div class="property-view-add">
     <div *ngIf="canAdd">
        <workspace-view-add #add [url]="url" [validators]="addValidators" [types]="permissionList" (newUser)="addProperty($event)"></workspace-view-add>
      </div> -->
      <button mat-raised-button color="accent" (click)="close()">CERRAR</button>
    </div>
  </div>
  
  
