import { Component, OnInit, Inject, Input, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PermisoService } from '../../_services/permiso.service';
import { ToastService } from '../toast/toast.service';
import { IPermisosEfectivos } from '../../modelo/permiso-otorgado.model';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../table/table.component';
import { nombre_rol } from '../utility-functions';

interface IData {
  id: number;
  description: string;
  type: string;
}


@Component({
  selector: 'app-ver-permisos-form',
  templateUrl: './ver-permisos-form.component.html',
  styleUrls: ['./ver-permisos-form.component.scss']
})


export class VerPermisosFormComponent implements OnInit {
  public titulo : string;
  public loading: boolean;
  public permisos: IPermisosEfectivos[];
  sus : any;
  
  @ViewChild(PuntalTableComponent,{static:true}) table: PuntalTableComponent<any>;

  readonly header: ITableHeader<IPermisosEfectivos>[] = [
    {
      label: 'Usuario',
      value: 'user_name',
    },
    {
      label: 'Permiso',
      value: 'role',
      valueFunction: role => nombre_rol(role.role)
    },
  ];

  readonly tableOptions: ITableOptions<IPermisosEfectivos> = {
    canEdit: (user) => false,
    canDelete: (user) => false
  };
  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IData,
    private dialogRef: MatDialogRef<VerPermisosFormComponent>,
    private permisoService: PermisoService,
    private toastService: ToastService) { }

  ngOnInit(): void {
    this.loading = true;
    this.titulo = 'Permisos de ' + this.data.description;
    this.retrieveData();
  }

  retrieveData(){
    this.loading = true;
    this.sus = this.permisoService.getPermisosEfectivos(this.data.type,this.data.id).subscribe((permisos) => {
      this.permisos = permisos;
      this.loading = false;
    },
    error => {
      this.toastService.openSnackBar(`Error al leer permisos: ${error}`, 'OK');
      this.loading = false;
    });
    this.loading = false;
  }

 close() {
      this.dialogRef.close(null);
  }

  isAuthenticated() {
    return true;
  }
}
