import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { getServerErrorMessage } from '../shared/utility-functions';
import { catchError } from 'rxjs/operators';
import { throwError } from "rxjs";

import { Usuario } from '../modelo/usuario';
import { environment } from './../../environments/environment';


const API_URL = environment.API_URL; 


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class UserService {
  constructor(private http: HttpClient) { }

  getUsuarios(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(API_URL + 'user/getAll', { responseType: 'json' });
  }

  getUsuario(idUsuario:number): Observable<Usuario> {
    return this.http.get<Usuario>(API_URL + 'user/' + idUsuario, { responseType: 'json' });
  }

  deleteUsuario(idUsuario:number): Observable<Usuario>  {
    return this.http.delete<any>(API_URL + 'user/'+idUsuario)
  }

  updateUsuario(usuario:Usuario): Observable<Usuario>  {
    return this.http.put<any>(API_URL + 'user', usuario , { responseType: 'json' })
  }  

  reset_password(usuario:Usuario): Observable<Usuario>  {
    return this.http.put<any>(API_URL + 'user/resetPassw', usuario , { responseType: 'json' })
  }    
  
  saveLastCampaign(idCampaign: number): Observable<Usuario>  {
    return this.http.put<any>(API_URL + 'user/setCampaign/'+idCampaign, { responseType: 'json' })
  }  


  getUsuariosRepetidos(userId:number, email: string, name:string) : Observable<Usuario[]> {
    let data = {"email" : encodeURIComponent(email),"username" : encodeURIComponent(name)};
   // return this.http.get<Usuario[]>(API_URL + 'user/getUserMail',{params: data, responseType: 'json' })
   return this.http.get<Usuario[]>(API_URL + 'user/getUserMail/'+userId+'/'+encodeURIComponent(email)+'/'+encodeURIComponent(name),{responseType: 'json' })
  }

  addUsuario(usuario:Usuario): Observable<Usuario>  {
    let errorMsg: string;
    errorMsg = '';
    return this.http.post<any>(API_URL + 'user/add', usuario , { responseType: 'json' })
    .pipe(    
      catchError(error => {
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              errorMsg = getServerErrorMessage(error);
          }
          console.log(errorMsg);
          return throwError(errorMsg);

      })
    );
  }

}