import * as moment from 'moment';
import 'moment/locale/es';
import {ITableHeader, SemaphoreDecision} from '../shared/table/table.component';
import { IActivityFull } from '../modelo/actividad';
//import {isNumber} from 'util';
import {getScoutDecision, getScoutGeneral, getScoutSemaphore} from '../_helpers/semaphore.helper';

export const ACTIVITY_FIELDS: ITableHeader<IActivityFull>[] = [
    {
    label: 'Campo',
    value: 'farm_name',
    sticky: 'sticky',
    valueFunction: (activity: any) => activity.farm_name
  },
  {
    label: 'Lote',
    value: 'plot_name',
    sticky: 'sticky',
    valueFunction: (activity: any) => activity.plot_name
  },
  {
    label: 'Cultivo',
    value: 'type',
    valueFunction: activity => activity.sub_type
  },
  {
    label: 'Ambiente',
    value: 'ambient',
    center: false,
    valueFunction: activity => activity.ambient || ''
  },
  {
    label: 'Siembra',
    value: 'sowing_date',
    center: false,
    valueFunction: activity => {
      if (!activity.sowing_date  || !activity.last_scout ||  !activity.last_scout.date_time) {
        return '';
      }
      const scoutDate = activity.sowing_date;
      const date = new Date(scoutDate);
      const relative = moment(date).fromNow(true);
      var mas_siembras = '';
      if (activity.count_sowing && (activity.count_sowing > 1)) {
        const n_cant_sowing = Number(activity.count_sowing) - 1;
        mas_siembras = ' ('+ n_cant_sowing  +'+)';
      }
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}${mas_siembras}`/* (${relative})`*/;
    }
  },
  {
    label: 'Sup. sembr.',
    value: 'sowed_area',
    center: false,
    valueFunction: activity => activity.sowed_area || ''
  },
  {
    label: 'Genotipo',
    value: 'genotype',
    center: false,
    valueFunction: activity => activity.genotype || ''
  },
  {
    label: 'Cant. Recorr.',
    value: 'scouts',
    center: true,
    valueFunction: activity => activity.scout_count || 0
  },
  {
    label: 'última recorrida',
    value: 'last_scout_date',
    valueFunction: activity => {
      if (!activity.last_scout || !activity.last_scout.date_time) {
        return 'Sin recorrer';
      }
      const scoutDate = activity.last_scout.date_time;
      const date = new Date(scoutDate);
      const relative = moment(date).fromNow(true);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`/* (${relative})`*/;
    },
    sortValue: activity => {
      if (!activity.last_scout || !activity.last_scout.date_time) {
        return '0000-00-00T00:00:00.000Z';
      }
      return activity.last_scout.date_time;
    }
  },
  {
    label: 'Etapa cultivo',
    value: 'etapa',
    center: false,
    valueFunction: activity => activity.state || ''
  },
  {
    label: 'Cond. Gral.',
    value: 'state',
    semaphore: true,
    valueFunction: activity => general(activity)
  },
  {
    label: '',
    value: 'implementation_quality',
    semaphore: true,
    valueFunction: activity => getSemaphoreDecision(activity, 'implementation_quality'),
    img: 'spa',
    tooltip: 'Implantación'
  },
  {
    label: '',
    value: 'weeds',
    semaphore: true,
    valueFunction: activity => getSemaphoreDecision(activity, 'weeds'),
    img: 'local_florist',
    tooltip: 'Malezas'
  },
  {
    label: '',
    value: 'pests',
    semaphore: true,
    valueFunction: activity => getSemaphoreDecision(activity, 'pests'),
    img: 'adb',
    tooltip: 'Plagas'
  },
  {
    label: '',
    value: 'diseases',
    semaphore: true,
    valueFunction: activity => getSemaphoreDecision(activity, 'diseases'),
    img: 'local_hospital',
    tooltip: 'Enfermedades'
  },
  {
    label: '',
    value: 'adversities',
    semaphore: true,
    valueFunction: activity => getSemaphoreDecision(activity, 'adversities'),
    img: 'ac_unit',
    tooltip: 'Adversidades'
  }
];

export function getSemaphore(activity, field) {
  if (!activity.last_scout || !activity.last_scout[field]) {
    return 's0';
  }
  return getScoutSemaphore(activity.last_scout, field);
}

export function getDecision(activity: IActivityFull, field) {
  if (!activity.last_scout || !activity.last_scout[field]) {
    return 'Sin datos';
  }
  return getScoutDecision(activity.last_scout, field);
}

export function getSemaphoreDecision(activity: IActivityFull, field): SemaphoreDecision {
  return {
    semaphore: getSemaphore(activity, field),
    decision: getDecision(activity, field)
  };
}

function general(activity: IActivityFull) {
  return getScoutGeneral(activity.last_scout);
}
