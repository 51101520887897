<ng-container *ngIf="loading">
<!-- <ng-container *ngIf="!scout "> -->
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>

<br/>

<div class="container" *ngIf="!loading">

    <div color="primary" >
      <div class="puntal-title">
        <div >Recorrida {{activity.plot_name}}: {{activity.sub_type}} - {{scoutDate}}</div>
        <span class="spacer"></span>
        <a mat-mini-fab color="accent" (click)="goBack()">
          <mat-icon aria-label="back">arrow_back</mat-icon>
        </a> 
      </div>
    </div><br><br><br>
    <div class="activity-scout-container">

      <mat-accordion>
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Recorrida</b>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <table>
              <tr>
                <td class = 'scout-label'>Recorredor:</td><td>{{scout?.scoutter}}</td>
              </tr>
              <tr>
                <td class = 'scout-label'>Etapa:</td><td>{{scout?.state}}</td>
              </tr>
              <tr>
                <td class = 'scout-label'>Cobertura del suelo por cultivo:</td><td>{{scout?.ground_cover}}</td>
              </tr>
              <tr>
                <td class = 'scout-label'>Geoposición:</td><td>{{geoposicion(scout)}}</td>
              </tr>
              <tr>
                <td class = 'scout-label'>Observaciones:</td><td>{{scout?.observations}}</td>
                </tr>    
              </table>               
          </mat-panel-description>
        </mat-expansion-panel>
    
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Ambiente</b>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
              <table>
                <tr>
                  <td class = 'scout-label'>Nombre:</td><td>{{scout.ambient?.name}}</td>
                </tr>
                <tr>
                  <td class = 'scout-label'>Antecesor:</td><td>{{scout.ambient?.predecessor_name}}</td>
                </tr>
                <tr>
                  <td class = 'scout-label'>Horizonte saturado (napa freática):</td><td>{{scout.ambient?.nappa}}</td>
                  </tr>
                  <tr>
                    <td class = 'scout-label'>Calidad Napa:</td><td>{{scout.ambient?.nappa_quality}}</td>
                  </tr>
                  <tr>
                    <td class = 'scout-label'>Horizonte saturado (napa colgante):</td><td>{{scout.ambient?.nappa_pendent}}</td>
                  </tr>
                  <tr>
                    <td class = 'scout-label'>Nivel de Recarga:</td><td>
                      <span class="levels" [ngClass]="LevelClass(scout.ambient?.recharge_level)">{{scout.ambient?.recharge_level === 'no-data' ? 'Sin datos' : '⬤'}}</span>                  
                  </tr>
                <!--   <tr>
                    <td class = 'scout-label'>Horizonte Seco:</td><td>{{scout.ambient?.dry_horizon ? "SI" : "NO"}}</td>
                  </tr> -->
                  <tr>
                    <td class = 'scout-label'>Humedad del thapto o Bt:</td><td>{{scout.ambient?.thapto_humidity}}</td>
                  </tr>
                  <tr>
                    <td class = 'scout-label'>Riesgo de anegamiento:</td><td>{{scout.ambient?.waterlogging_risk}}</td>
                  </tr>
                  <tr>
                    <td class = 'scout-label'>Cobertura de rastrojo:</td><td>{{scout.ambient?.stubble_cover}}</td>
                  </tr>
                  <tr>
                    <td class = 'scout-label'>Observaciones:</td><td>{{scout.ambient?.observations}}</td>
                </tr>
              </table>
            </mat-panel-description>
        </mat-expansion-panel>
        <ng-container *ngIf="!loading && scout.sowing">
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Siembra</b>
            </mat-panel-title>
           <!--  <mat-panel-description  class="right-aligned">
              <span class="semaphore_medium" [ngClass]="semaphoreClass(sowing_semaphore)"> &nbsp;</span>
            </mat-panel-description> -->
          </mat-expansion-panel-header>
          <mat-panel-description>
            <table>
              <tr>
                <td class = 'scout-label'>Fecha de siembra:</td><td>{{prettydate(scout.sowing.date_time)}}</td>
              </tr>
              <tr>
                <td class = 'scout-label'>Contratista:</td><td>{{scout.sowing?.contractor}}</td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Genotipo:</td><td>{{scout.sowing?.cultivar_name}}</td>
              </tr>                                
              <tr>
                <td class = 'scout-label'>Superficie sembrada (ha):</td><td>{{scout.sowing?.area}}</td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Distancia entre hileras:</td><td>{{scout.sowing?.row_spacing}}</td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Densidad de siembra (sem/m2):</td><td>{{scout.sowing?.density_sem_m2}}</td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Densidad de siembra (kg/ha):</td><td>{{scout.sowing?.density_kg_ha}}</td>
              </tr>   
              <tr>
                <td class = 'scout-label'>Calidad:</td><td><span class="levels" [ngClass]="LevelClass(scout.sowing?.quality)">{{scout.sowing?.quality === 'no-data' ? 'Sin datos' : '⬤'}}</span></td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Es resiembra?:</td><td>{{scout.sowing?.replant ? "SI" : "NO"}}</td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Observaciones:</td><td>{{scout.sowing?.observations}}</td>
              </tr> 
              <br>
              <tr class="totalizador">
                <td class = 'scout-label'>Sup. sembrada total al {{scoutDate}} (ha):</td><td>{{total.area_sowed}}</td>
              </tr>   
              <tr class="totalizador">
                <td class = 'scout-label'>Sup. resembrada total al {{scoutDate}} (ha):</td><td>{{total.area_resowed}}</td>
              </tr>               
            </table>               
          </mat-panel-description>
        </mat-expansion-panel>
    
        <ng-container *ngIf="!loading && scout.sowing">
          <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Fertilizaciones</b>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <table>
            <ng-container *ngFor="let ferti of scout.sowing.fertilizations">
              <tr>
            <table>
              <tr>
                <th class = 'scout-group' rowspan="4">{{prettydate(ferti.date_time)}}</th>
                <td class = 'scout-label'>Fertilizante:</td><td>{{ferti.fertilization}}</td>
              </tr>            
              <tr>
                <td class = 'scout-label'>Superficie (ha):</td><td>{{ferti.fertilization_area}}</td>
              </tr>       
              <tr>
                <td class = 'scout-label'>Dosis (kg/ha):</td><td>{{ferti.fertilization_dose}}</td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Modo:</td><td>{{ferti.fertilization_mode}}</td>
              </tr>    
              <br>           
            </table>   
          </tr>    
          </ng-container>  
        </table>      
          </mat-panel-description>
        </mat-expansion-panel>
      </ng-container>    
    </ng-container>
    
      <ng-container *ngIf="!loading && scout.implementation_quality">
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Calidad de implantación</b>
            </mat-panel-title>
            <mat-panel-description  class="right-aligned">
              <span class="semaphore_medium" [ngClass]="semaphoreClass(implementation_quality_semaphore)">{{replant_decision}}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <table>
             <!--  <tr>
                <td class = 'scout-label'>Calidad:</td><td><span class="levels" [ngClass]="LevelClass(scout.implementation_quality?.quality)">{{scout.implementation_quality?.quality === 'no-data' ? 'Sin datos' : '⬤'}}</span></td>
              </tr> -->
              <tr>
                <td class = 'scout-label'>Plantas logradas:</td><td>{{scout.implementation_quality?.plants_accomplished}}</td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Uniformidad espacial:</td><td>{{scout.implementation_quality?.spatial_uniformity}}</td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Uniformidad temporal:</td><td>{{scout.implementation_quality?.temporal_uniformity}}</td>
              </tr>    
             <!--  <tr>
                <td class = 'scout-label'>Decisión de resiembra:</td><td>{{scout.implementation_quality?.replant_decision}}</td>
              </tr>   -->  
              <tr>
                <td class = 'scout-label'>Observaciones:</td><td>{{scout.implementation_quality?.observations}}</td>
              </tr>    
             </table>               
          </mat-panel-description>
        </mat-expansion-panel>    
      </ng-container>  
    
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Malezas</b>
            </mat-panel-title>
            <mat-panel-description  class="right-aligned">
              <span class="semaphore_medium" [ngClass]="semaphoreClass(weeds_semaphore)"> {{weeds_decision}}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <table>
              <!-- <tr>
                <td class = 'scout-label'>Cobertura:</td><td colspan="2">{{scout.weeds?.cover}}</td>
              </tr> -->
              <tr>
                <td class = 'scout-label'>Cobertura verde malezas:</td><td colspan="2">{{Level6(scout.weeds?.green_coverage_percentage)}}</td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Observaciones:</td><td colspan="2">{{scout.weeds?.observations}}</td>
              </tr>    
              <ng-container *ngIf="!loading && (scout.weeds)">
                <br>
                <ng-container *ngFor="let item of scout.weeds.weeds">
                  <tr class = 'scout-first-detail-row'>
                    <th class = 'scout-group' rowspan="4">{{item.weed_name}}</th>
                    <td class = 'scout-label'>Estado:</td><td>{{item.weed_state_name}}</td>
                  </tr>    
                  <tr>
                    <td class = 'scout-label'>Distribución:</td><td>{{item.distribution}}</td>
                  </tr>    
                  <tr>
                    <td class = 'scout-label'>Frecuencia:</td><td>{{item.frequency}}</td>
                  </tr>    
                  <tr>
                    <td class = 'scout-label'>Densidad:</td><td>{{item.density}}</td>
                  </tr>   
                  <br> 
                </ng-container>  
              </ng-container>
          </table>      
               
          </mat-panel-description>
        </mat-expansion-panel>    
    
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Enfermedades</b>
            </mat-panel-title>
            <mat-panel-description  class="right-aligned">
              <span class="semaphore_medium" [ngClass]="semaphoreClass(diseases_semaphore)"> {{diseases_decision}}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <table>
              <tr>
                <td class = 'scout-label'>Observaciones:</td><td colspan="2">{{scout.diseases?.observations}}</td>
              </tr>   
              <ng-container *ngIf="!loading && (scout.diseases)">
                <br>
                <ng-container *ngFor="let item of scout.diseases.diseases">
                  <tr class = 'scout-first-detail-row'>
                    <th class = 'scout-group'>{{item.disease_name}}</th>
                    <td class = 'scout-label'>Estado:</td><td>{{Level2(item.state)}}</td>
                  </tr>  
                  <br>  
                </ng-container>  
              </ng-container>
             </table>               
          </mat-panel-description>
        </mat-expansion-panel>        
    
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Plagas</b>
            </mat-panel-title>
            <mat-panel-description  class="right-aligned">
              <span class="semaphore_medium" [ngClass]="semaphoreClass(pests_semaphore)"> {{pests_decision}}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <table>
              <tr>
                <td class = 'scout-label'>Observaciones:</td><td colspan="2">{{scout.pests?.observations}}</td>
              </tr>    
              <ng-container *ngIf="!loading && (scout.pests)">
                <br>
                <ng-container *ngFor="let item of scout.pests.pests">
                  <tr class = 'scout-first-detail-row'>
                    <th class = 'scout-group'>{{item.pest_name}}</th>
                    <td class = 'scout-label'>Estado:</td><td>{{Level2(item.state)}}</td>
                  </tr>  
                  <br>
                </ng-container>  
              </ng-container>  
            </table>               
          </mat-panel-description>
        </mat-expansion-panel>        
        
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Adversidades</b>
            </mat-panel-title>
            <mat-panel-description  class="right-aligned">
              <span class="semaphore_medium" [ngClass]="semaphoreClass(adversities_semaphore)"> {{adversities_decision}}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <table>
              <tr>
                <td class = 'scout-label'>Observaciones:</td><td colspan="2">{{scout.adversities?.observations}}</td>
              </tr>   
              <ng-container *ngIf="!loading && (scout.adversities)">
                <br>
                <ng-container *ngFor="let item of scout.adversities.adversities">
                  <tr class = 'scout-first-detail-row'>
                    <th class = 'scout-group' rowspan="3">{{item.adversity_name}}</th>
                    <td class = 'scout-label'>Nivel de daño:</td><td>{{Level(item.damage_level)}}</td>
                  </tr>    
                  <tr>
                    <td class = 'scout-label'>Superficie afectada %:</td><td>{{item.percentage}}</td>
                  </tr>    
                  <tr>
                    <td class = 'scout-label'>Superficie afectada (ha):</td><td>{{item.area}}</td>
                  </tr>  
                  <br>  
                </ng-container>  
              </ng-container>
            </table>               
          </mat-panel-description>
        </mat-expansion-panel>        

        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Calidad de cosecha</b>
            </mat-panel-title>
            <mat-panel-description  class="right-aligned">
              <span class="semaphore_medium" [ngClass]="semaphoreClass(harvest_quality_semaphore)"> &nbsp;</span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <table>
              <tr>
                <td class = 'scout-label'>Pérdidas naturales:</td><td>{{Level3(scout.harvest_quality?.natural_losses)}}</td>
              </tr>
              <tr>
                <td class = 'scout-label'>Pérdidas por máquina:</td><td>{{Level3(scout.harvest_quality?.lost_by_machine)}}</td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Calidad de corte:</td><td>{{Level4(scout.harvest_quality?.cut_quality)}}</td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Distribución de rastrojo</td><td>{{Level4(scout.harvest_quality?.stubble_distribution)}}</td>
              </tr>    
                  
              <tr>
                <td class = 'scout-label'>Contratista:</td><td>{{scout.harvest_quality?.contractor}}</td>
              </tr>               
              <tr>
                <td class = 'scout-label'>Observaciones:</td><td colspan="2">{{scout.harvest_quality?.observations}}</td>
              </tr>    
              </table>               
          </mat-panel-description>
        </mat-expansion-panel>                


        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Rendimiento</b>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <table>
              <tr>
                <td class = 'scout-label'>Rinde presupuesto (kg/ha):</td><td>{{scout.crop_yield?.budged}}</td>
              </tr>              
              <tr>
                <td class = 'scout-label'>Rinde esperado vs presupuesto %:</td><td>{{scout.crop_yield?.expected_vs_budged_per}}</td>
              </tr>              
              <tr>
                <td class = 'scout-label'>Rinde estimado (kg/ha):</td><td>{{scout.crop_yield?.estimated}}</td>
              </tr>              
             
              <tr>
                <td class = 'scout-label'>Lote finalizado?:</td><td>{{scout.crop_yield?.harvested? "SI" : "NO"}}</td>
              </tr>              
              <tr>
                <td class = 'scout-label'>Fecha cosecha:</td>
                <td><div *ngIf="!loading && (scout.crop_yield != null) && (scout.crop_yield.harvest_date)">{{prettydate(scout.crop_yield?.harvest_date)}}</div></td>
              </tr>    
              <tr>
                <td class = 'scout-label'>Tn cosechadas:</td><td>{{scout.crop_yield?.harvest_tn}}</td>
              </tr>              
              <tr>
                <td class = 'scout-label'>Sup.cosechada (ha):</td><td>{{scout.crop_yield?.harvest_area}}</td>
              </tr>              
              <tr>
                <td class = 'scout-label'>Humedad de cosecha %:</td><td>{{scout.crop_yield?.harvest_humidity}}</td>
              </tr>              
              <tr>
                <td class = 'scout-label'>Observaciones:</td><td>{{scout.crop_yield?.observations}}</td>
              </tr>    

              <br>
         <!--      <tr class="totalizador">
                <td class = 'scout-label'>Total tn sembradas: </td><td>{{total.area_sowed}}</td>
              </tr>  
              <tr class="totalizador">
                <td class = 'scout-label'>Total tn resembradas: </td><td>{{total.area_resowed}}</td>
              </tr>                  --> 
              <tr class="totalizador">
                <td class = 'scout-label'>Tn cosechadas totales al {{scoutDate}}: </td><td>{{total.harvested_tn}}</td>
              </tr>    
              <tr class="totalizador">
                <td class = 'scout-label'>Superficie cosechada total al {{scoutDate}}(ha):</td><td>{{total.harvested_ha}}</td>
              </tr>    
              <tr class="totalizador">
                <td class = 'scout-label'>Rinde Real/sup. sembrada (kg/ha):</td><td>{{ total.real_sowed | number:'1.2-2' }}</td>
              </tr>    
              <tr class="totalizador">
                <td class = 'scout-label'>Rinde Real/sup. cosechada (kg/ha):</td><td>{{total.real_harvested | number:'1.2-2'}}</td>
              </tr>   
             

             </table>               
          </mat-panel-description> 
        </mat-expansion-panel>            


        <!-- <ng-container *ngIf="scout.implementation_quality"> -->
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b>Condición General vs Presupuesto</b>
              </mat-panel-title>
              <mat-panel-description  class="right-aligned">
                <span class="semaphore_medium" [ngClass]="semaphoreGeneralClass(general_semaphore)"> {{general_decision}}</span>
              </mat-panel-description>
            </mat-expansion-panel-header>
           <!--  <mat-panel-description>
            </mat-panel-description> -->
          </mat-expansion-panel>    
<!--         </ng-container>   -->


      </mat-accordion>
  
    </div>
  </div>