import { Injectable } from '@angular/core';
//import { SkipSelf } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { getServerErrorMessage } from '../shared/utility-functions';
import { catchError } from 'rxjs/operators';
import { throwError } from "rxjs";

import { Campania } from '../modelo/campania';
import { environment } from './../../environments/environment';

const API_URL = environment.API_URL;


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class CampaniaService {
//  errorMsg: string;
//@SkipSelf() 
  constructor(private http: HttpClient) {}

  getCampanias(): Observable<Campania[]> {
    return this.http.get<Campania[]>(API_URL + 'campania/getAll', { responseType: 'json' });
  }

  getCampania(idCampania:number): Observable<Campania> {
    return this.http.get<Campania>(API_URL + 'campania/get/'+idCampania, { responseType: 'json' });
  }

  getActiveCampaign(): Observable<Campania> {
    return this.http.get<Campania>(API_URL + 'campania/getActive', { responseType: 'json' });
  }

  deleteCampania(idCampania:number): Observable<Campania>  {
    return this.http.delete<any>(API_URL + 'campania/'+idCampania)
  }

  updateCampania(campania:Campania): Observable<Campania>  {
    return this.http.put<any>(API_URL + 'campania', campania , { responseType: 'json' })
  }  

  addCampania(campania:Campania): Observable<Campania>  {
    let errorMsg: string;
    errorMsg = '';
    return this.http.post<any>(API_URL + 'campania/add', campania , { responseType: 'json' })
  }


}
