//import { Empresa } from "./empresa";

export class Campo {
        id: number;
        name: string;
        polygon?: string;
        map?: string;
        creation_date?: Date;
        down_date?: Date;
        company_id?: number;
      }

export class IFarmRole {
  id: number;
  name: string;
  role: string
}      