<ng-container  *ngIf="isAuthenticated()">
   <!-- <div class="download-report-container add-element-container" *ngIf="canAdd">
    <button mat-raised-button color="accent" (click)="addClient()">
      Agregar Cliente
    </button>
  </div> -->
  <div class="clients-table">
    <puntal-table
      tableTitle="Clientes"
      [dataSource]="clientes"
      [loading]="loading"
      [options]="tableOptions"
      [displayedColumns]="header"
      (onEdit)="editRow($event)"
      (onDelete)="deleteRow($event)"
      (onRowClick)="navigateTo($event)"
      (onAdd)="addRow()"
      (onBack)="goBack()"
      [showAddButton]="canAddRecord"
      showBackButton="S"
      (onShowPermissionClick)="openPermission($event)"
      [showPermissionButton]="canAddRecord"
    >
  </puntal-table>
  </div>
  </ng-container>