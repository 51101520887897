import { Injectable } from '@angular/core';
//import { SkipSelf } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { getServerErrorMessage } from '../shared/utility-functions';
import { catchError } from 'rxjs/operators';
import { throwError } from "rxjs";

import { Campo, IFarmRole } from '../modelo/campo';
import { environment } from './../../environments/environment';

const API_URL = environment.API_URL;


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class CampoService {
//  errorMsg: string;
//@SkipSelf() 
  constructor(private http: HttpClient) {}

  getCampos(idEmpresa:number): Observable<Campo[]> {
    return this.http.get<Campo[]>(API_URL + 'campo/getAll/'+idEmpresa, { responseType: 'json' });
  }

  getCamposActivos(idEmpresa:number): Observable<Campo[]> {
    return this.http.get<Campo[]>(API_URL + 'campo/getAllActive/'+idEmpresa, { responseType: 'json' });
  }

  getCamposAdmin(idEmpresa:number): Observable<IFarmRole[]> {
    return this.http.get<IFarmRole[]>(API_URL + 'campo/getAllAdmin/'+idEmpresa, { responseType: 'json' });
  }
  
  deleteCampo(idCampo:number): Observable<Campo>  {
    return this.http.delete<any>(API_URL + 'campo/'+idCampo)
  }

  updateCampo(campo:Campo): Observable<Campo>  {
    return this.http.put<any>(API_URL + 'campo', campo , { responseType: 'json' })
  }  

  addCampo(campo:Campo): Observable<Campo>  {
    let errorMsg: string;
    errorMsg = '';
    return this.http.post<any>(API_URL + 'campo/add', campo , { responseType: 'json' })
  }

}
