import { Injectable } from '@angular/core';
//import { SkipSelf } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { getServerErrorMessage } from '../shared/utility-functions';
import { catchError } from 'rxjs/operators';
import { throwError } from "rxjs";

import { Permiso } from '../modelo/permiso';
import { PermisoOtorgado, IPermisosEfectivos } from '../modelo/permiso-otorgado.model';
import { environment } from './../../environments/environment';

const API_URL = environment.API_URL;
interface IClientID {"client_id" : number}
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})



export class PermisoService {

  constructor(private http: HttpClient) {}

  async rol_nodo(tipo_nodo, id_nodo, user_id) : Promise<string> {
    if (!(id_nodo) || (id_nodo == null)) {
      return '';
    }  
    return await this.getPermisoEfectivo(tipo_nodo,id_nodo,user_id).toPromise()
    .then((permisos) => {if ((!permisos) || (permisos.length < 1)) {return '';} else {return permisos[0].role;}});
  }

  getPermisos(id:number): Observable<PermisoOtorgado[]> {
    return this.http.get<PermisoOtorgado[]>(API_URL + 'auth/permiso/grants/' + id, { responseType: 'json' });
  }

  deletePermiso(permiso:PermisoOtorgado): Observable<PermisoOtorgado>  {
    if ((permiso.farm_id) && (permiso.farm_id != null) && (permiso.farm_id > 0)) {
      return this.http.delete<any>(API_URL + 'auth/permiso/farm/'+permiso.user_id+'/'+permiso.farm_id);
    }
    if ((permiso.company_id) && (permiso.company_id != null) && (permiso.company_id > 0)) {
      return this.http.delete<any>(API_URL + 'auth/permiso/company/'+permiso.user_id+'/'+permiso.company_id);
    }    
    return this.http.delete<any>(API_URL + 'auth/permiso/client/'+permiso.user_id+'/'+permiso.client_id);
  }

  updatePermiso(permiso:Permiso): Observable<Permiso>  {
    return this.http.put<any>(API_URL + 'auth/', permiso , { responseType: 'json' })
  }  

  addPermiso(permiso:Permiso): Observable<Permiso>  {
    let errorMsg: string;
    errorMsg = '';
    return this.http.post<any>(API_URL + 'permiso/add', permiso , { responseType: 'json' });
 /*   .pipe(    
      catchError(error => {
          if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
          } else {
              errorMsg = getServerErrorMessage(error);
          }
          return throwError(errorMsg);
    //      return throwError(() => new Error(this.errorMsg))
      })
  );*/
  }

  addPermisoOtorgado(permisofull:PermisoOtorgado): Observable<Permiso>  {
    let errorMsg: string;
    errorMsg = '';
  //  var permiso: Permiso;
    var rol_a_borrar : string;

    console.log('estoy es lo que llega al add del service',permisofull);
    
    if (permisofull.role == 'ADMIN') {
      rol_a_borrar = 'todos';
    }
    else {
      rol_a_borrar = permisofull.role;
    }

    if ((permisofull.farm_id != null) && (permisofull.farm_id > 0)) {
      return this.http.post<any>(API_URL + 'auth/permiso/addFarmPermission', permisofull , { responseType: 'json' });
    }
    else {
      if ((permisofull.company_id != null) && (permisofull.company_id > 0)) {
        return this.http.post<any>(API_URL + 'auth/permiso/addCompanyPermission', permisofull , { responseType: 'json' });
      }
      else {
        return this.http.post<any>(API_URL + 'auth/permiso/addClientPermission', permisofull , { responseType: 'json' });
      }
    }
//    return this.http.post<any>(API_URL + 'permiso/add', permiso , { responseType: 'json' });
  }

  getPermisosEfectivos(tipo: string, id:number): Observable<IPermisosEfectivos[]> {
    switch (tipo) {
      case 'client':
        return this.http.get<IPermisosEfectivos[]>(API_URL + 'auth/permiso/getClientUsers/' + id, { responseType: 'json' });
        break;
      case 'company':
        return this.http.get<IPermisosEfectivos[]>(API_URL + 'auth/permiso/getCompanyUsers/' + id, { responseType: 'json' });
        break;
      default:
        return this.http.get<IPermisosEfectivos[]>(API_URL + 'auth/permiso/getFarmUsers/' + id, { responseType: 'json' });
        break;
    }
  }

  getClientforUser(user:number) : Observable<IClientID[]> {
    return this.http.get<IClientID[]>(API_URL + 'auth/permiso/getClientforUser/' + user, { responseType: 'json' })
  }  

  getPermisoEfectivo(tipo: string, id:number, user:number) : Observable<IPermisosEfectivos[]> {
    switch (tipo) {
      case 'client':
        return this.http.get<IPermisosEfectivos[]>(API_URL + 'auth/permiso/getClientUser/' + id + '/' + user, { responseType: 'json' })
  /*      .toPromise()
        .then(res => {return <IPermisosEfectivos[]> res;})
        .then(data => { return data; console.log('data',data)});*/
        break;
      case 'company':
        return this.http.get<IPermisosEfectivos[]>(API_URL + 'auth/permiso/getCompanyUser/' + id + '/' + user, { responseType: 'json' })
     /*   .toPromise()
        .then(res => <IPermisosEfectivos[]> res)
        .then(data => { return data; });*/
        break;
      default:
        return this.http.get<IPermisosEfectivos[]>(API_URL + 'auth/permiso/getFarmUser/' + id + '/' + user, { responseType: 'json' })
   /*     .toPromise()
        .then(res => <IPermisosEfectivos[]> res)
        .then(data => { return data; });*/
        break;
    }    
}

}
