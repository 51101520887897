import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { CampaniaService } from '../_services/campania.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../app.component.scss', './login.component.scss']
})

export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null
  };
  nextPage = '';
  hidePwd = true;
  isLoggedIn = false;
  isLoginFailed = false;
  public errorMessage = '';
  roles: string[] = [];
  
  user: any = {
    username: null,
    token: null,
    role: null,
    id: null,
    isUserAdmin: false
};
  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, 
    private router: Router, private campaniaService: CampaniaService) { }

  ngOnInit(): void {
    // get return url from navigation state or default to '/'
    this.nextPage = history.state.nextPage || '/';  
    this.isLoggedIn = this.authService.isAuthenticated();   
    if (this.isLoggedIn) {
      this.roles = this.authService.getRoles();
    }
  }

  onSubmit(): void {
    const { email, password } = this.form;
    this.authService.login(email, password).subscribe((data) => {
        this.tokenStorage.saveToken(data.token);
        this.user.username = data.username;
        this.user.token = data.token;
        this.user.role = data.type;
        this.user.id = data.id;
        this.authService.setUserStorage(this.user);
        this.authService.GetisUserAdmin().then((data_gua) => {
          this.user.isUserAdmin = data_gua;
          this.authService.setUserStorage(this.user);
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.roles = this.authService.getRoles();
          this.nextPage = history.state.nextPage || '/';
          if (data.last_campaign){
            this.campaniaService.getCampania(data.last_campaign).subscribe((campania) => {
              if (campania) {this.tokenStorage.saveCampaign(campania[0]);}
              window.location.href = this.nextPage;           
              });
          }
          else {
            window.location.href = this.nextPage;
          } 
        });
        }
        ,error => {
       // console.log(error);
        this.errorMessage = error;
        this.isLoginFailed = true;
      }
    );
  }

/*  reloadPage(): void {
    window.location.reload();
  }*/
}
