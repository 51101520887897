import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PuntalagroInterceptor } from './shared/puntalagro-interceptor';
import { SharedModule } from './shared/shared.module';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
//import { BoardAdminComponent } from './board-admin/board-admin.component';
//import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
//import { BoardUserComponent } from './board-user/board-user.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ClientFormComponent } from './clientes/forms/cliente-form/cliente-form.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { EmpresaFormComponent } from './empresas/forms/empresa-form/empresa-form.component';
import { CamposComponent } from './campos/campos.component';
import { CampoFormComponent } from './campos/forms/campo-form/campo-form.component';
import { LotesComponent } from './lotes/lotes.component';
import { LoteFormComponent } from './lotes/forms/lote-form/lote-form.component';
import { PermisosComponent } from './permisos/permisos.component';
import { PermisoFormComponent } from './permisos/forms/permiso-form/permiso-form.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { UsuarioFormComponent } from './usuarios/forms/usuario-form/usuario-form.component';
import { CampaniasComponent } from './campanias/campanias.component';
import { CampaniaFormComponent } from './campanias/forms/campania-form/campania-form.component';
import { ActividadesComponent } from './actividades/actividades.component';
import { ActividadFormComponent } from './actividades/forms/actividad-form/actividad-form.component';
import { MultiFilterComponent } from './actividades/multi-filter/multi-filter.component';
import { SubmenuComponent} from './submenu/submenu/submenu.component';
import { ScoutsComponent } from './actividades/scouts/scouts.component';
import { ScoutComponent } from './actividades/scouts/scout/scout.component';
import { CatalogosComponent } from './catalogos/catalogos.component';
import { CatalogosViewComponent } from './catalogos/catalogos-view/catalogos-view.component';
import { CatalogosViewMoreComponent } from './catalogos/catalogos-view-more/catalogos-view-more.component';
import { CatalogosPropertiesComponent } from './catalogos/catalogos-properties/catalogos-properties.component';
import { PropertyAddComponent } from './catalogos/catalogos_properties/property-add/property-add.component';
import { SemaforoFormComponent } from './actividades/scouts/forms/semaforo-form/semaforo-form.component';
import { NotesFormComponent } from './actividades/scouts/forms/notes-form/notes-form.component';
import { MediaFormComponent } from './actividades/scouts/forms/media-form/media-form.component';
import { MapaComponent } from './mapa/mapa.component';

//import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

/* Angular Material */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PuntalTableComponent } from './shared/table/table.component';
import { VerPermisosFormComponent } from './shared/ver-permisos-form/ver-permisos-form.component';

//import { format } from 'path';

//import { SubtypeService } from './_services/subtype.service';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ProfileComponent,
    ClientesComponent,
    PuntalTableComponent,
    ClientFormComponent,
    PermisosComponent,
    PermisoFormComponent,
    EmpresasComponent,
    CamposComponent,
    LotesComponent,        
    EmpresaFormComponent,
    CampoFormComponent,
    LoteFormComponent,
    UsuariosComponent,
    UsuarioFormComponent,
    CampaniasComponent,
    CampaniaFormComponent,
    ActividadesComponent,
    ActividadFormComponent,
    SubmenuComponent,
    MultiFilterComponent,
    ScoutsComponent,
    ScoutComponent,
    CatalogosComponent,
    CatalogosViewComponent,
    CatalogosViewMoreComponent,
    CatalogosPropertiesComponent,
    PropertyAddComponent,
    VerPermisosFormComponent,
    SemaforoFormComponent,
    NotesFormComponent,
    MediaFormComponent,
    MapaComponent,
  ],
  imports: [
    AngularMaterialModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule /*,
    NgMultiSelectDropDownModule.forRoot()*/
  ],
  providers: [ {provide: HTTP_INTERCEPTORS, useClass: PuntalagroInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
