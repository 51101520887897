<ng-container *ngIf="loading">
  <!-- <ng-container *ngIf="!scout "> -->
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>

<div class="container" *ngIf="!loading">
 <!-- <div class="activity-form-container"> -->
    <div class="activity-form mat-elevation-z1">
      <h2 class="title">Crear actividad en lote</h2>
      <!-- <form class="example-form"> -->
      <div class="form" [formGroup]="actividadFormControl"> 
      <!-- <form [formGroup]="actividadFormControl"> -->
         <div class="activity-form-clients-section">
          <div class="activity-form-clients-section-select-container">
            <mat-form-field>
              <mat-label>Campaña</mat-label>
              <mat-select [disabled]="campaniaList.length === 0"  [(value)]="selectedCampaign" disableRipple (selectionChange)="onCampaignSelect($event.value)" formControlName="campaign_id" [errorStateMatcher]="matcher" >
                <mat-option *ngFor='let campaign of campaniaList' [value]="campaign.id">{{campaign.name}}</mat-option>
              </mat-select>
              <mat-error *ngIf="actividadFormControl.get('campaign_id')!.invalid">Complete la campaña</mat-error>
            </mat-form-field>
          </div>
  <!--         <div class="activity-form-clients-section-select-container">
             <mat-form-field class="date-pick">
              <mat-label>Fecha de inicio</mat-label>
              <input matInput [matDatepicker]="picker"  [min]="minDate" [max]="maxDate" [(value)]="startDate"
              formControlName="start_date" [errorStateMatcher]="matcher" readonly required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker  [startAt]="startDate" touchUi #picker></mat-datepicker>
              <mat-error *ngIf="actividadFormControl.get('start_date')!.hasError('matDatepickerMin')">
                La fecha de inicio no puede ser anterior al inicio de la campaña.
              </mat-error>
              <mat-error *ngIf="actividadFormControl.get('start_date')!.hasError('matDatepickerMax')">
                La fecha de inicio no puede ser posterior al inicio de la campaña.
              </mat-error>              
            </mat-form-field>              
          </div> -->
         
          <div class="activity-form-clients-section-select-container">
            <mat-form-field>
              <mat-label>Cliente</mat-label>
              <mat-select [disabled]="clientList$.length === 0" [(value)]="selectedClient" disableRipple (selectionChange)="onClientSelect($event.value)" [errorStateMatcher]="matcher" >
                <mat-option *ngFor='let client of clientList$' [value]="client.id">{{client.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-mini-fab color="accent" class= "round_button" (click)="addEntity('client')" [disabled]="AddDisabled('client')">
              <mat-icon aria-label="add">+</mat-icon>
            </button>
          </div>
  
          <div class="activity-form-clients-section-select-container">
            <mat-form-field>
              <mat-label>Empresa</mat-label>
              <mat-select [(value)]="selectedCompany" [disabled]="!selectedClient || companyList$.length === 0" disableRipple (selectionChange)="onCompanySelect($event.value)"  [errorStateMatcher]="matcher" >
                <mat-option *ngFor='let company of companyList$' [value]="company">{{company.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-mini-fab [disabled]="!selectedClient" color="accent" class= "round_button" (click)="addEntity('company')" [disabled]="AddDisabled('company')" >
              <mat-icon aria-label="add">+</mat-icon>
            </button>
          </div>
  
  
          <div class="activity-form-clients-section-select-container">
            <mat-form-field>
              <mat-label>Campo</mat-label>
              <mat-select [(value)]="selectedFarm" [disabled]="!selectedCompany || farmList$.length === 0" disableRipple (selectionChange)="onFarmSelect($event.value)"  [errorStateMatcher]="matcher"  >
                <mat-option *ngFor='let farm of farmList$' [value]="farm">{{farm.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-mini-fab [disabled]="!selectedCompany" color="accent" class= "round_button" (click)="addEntity('farm')" [disabled]="AddDisabled('farm')" >
              <mat-icon aria-label="add">+</mat-icon>
            </button>
          </div>
  
          <div class="activity-form-clients-section-select-container">
            <mat-form-field>
              <mat-label>Lote</mat-label>
              <mat-select [disabled]="!selectedFarm" disableRipple (selectionChange)="onPlotSelect($event.value)"  [errorStateMatcher]="matcher" >
                <mat-option *ngFor='let plot of plotList$' [value]="plot">{{plot.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button [disabled]="!selectedFarm" mat-mini-fab color="accent" class= "round_button" (click)="addEntity('plot')" [disabled]="AddDisabled('plot')" >
              <mat-icon aria-label="add">+</mat-icon>
            </button>
          </div>
  
<!--           <div>
            <mat-form-field>
              <mat-label>Tipo</mat-label>
              <input matInput disabled value="Cultivo">
            </mat-form-field>
          </div>
   -->
          <div>
            <mat-form-field>
              <mat-label>Cultivo:</mat-label>
              <mat-select disableRipple [(value)]="selectedSubType" formControlName="sub_type" [errorStateMatcher]="matcher" >
                <mat-option *ngFor='let subType of subtypeList' [value]="subType">{{subType}}</mat-option>
              </mat-select>
  
  
            </mat-form-field>
          </div>
        </div>
  
   <!--    </div> -->
<!--     </form> -->

      <div class="buttons">
        <button mat-stroked-button color="primary"  (click)="close()">Cancelar</button>
        <button mat-raised-button color="primary" class="accept-button" [disabled]="!canCreate" (click)="create()">Guardar</button>
      </div>
<!--     </form> -->
    </div>
  </div>
</div>
