export interface CatalogsResponseDTO {
    catalogs: string[];
  }

  export interface Catalog {
    id: string;
    name: string;
    n_fields? : number;
  }

  export interface Catalog_item_list {
    id: number;
    name: string;
  }
  
  export interface IProperty {
    input_property_id: string;
    name: string;
    input_id: number;
    value: number;
    text_value: string;
  }

  /*export interface ICropSpeciePest {
  //  pest_id: number;
    id: number;
    name: string;
  
  }*/

  export const catalogo_array: Catalog[] = [
	{"id":"stubble_cover_level", "name":"Cobertura de rastrojo", "n_fields": 4},
	{"id":"input_company", "name":"Compañia de insumos", "n_fields": 4},
	{"id":"crop", "name":"Cultivo", "n_fields": 7},
	{"id":"achieved_density", "name":"Densidad lograda", "n_fields": 5},
	{"id":"disease", "name":"Enfermedad", "n_fields": 6},
	{"id":"crop_specie", "name":"Especie de cultivo", "n_fields": 4},
	{"id":"crop_stage", "name":"Etapa de cultivo", "n_fields": 6},
	{"id":"input", "name":"Insumo", "n_fields": 7},
	{"id":"weed", "name":"Maleza", "n_fields": 6},
	{"id":"pest", "name":"Plaga", "n_fields": 5},
	{"id":"input_property", "name":"Propiedad de insumo", "n_fields": 4},
	{"id":"crop_sowing", "name":"Siembra de cultivo", "n_fields": 4},
	// {"id":"crop_specie_pest", "name":"Plaga de Cultivo", "n_fields": 6},
	{"id":"cultivar_technology", "name":"Tecnología", "n_fields": 5},
	{"id":"input_type", "name":"Tipo de insumo", "n_fields": 4},
	{"id":"weed_type", "name":"Tipo de maleza", "n_fields": 5},
	{"id":"crop_use", "name":"Uso de cultivo", "n_fields": 4}
];

export const CATALOGS_NAME = {
    achieved_density: 'Densidad lograda',
    crop: 'Cultivo',
    crop_sowing: 'Siembra de cultivo',
    crop_specie: 'Especie de cultivo',
 /*   crop_specie_pest: 'Plaga de Cultivo',*/
    crop_stage: 'Etapa de cultivo',
    crop_use: 'Uso de cultivo',
    cultivar_technology: 'Tecnología',
    disease: 'Enfermedad',
    input: 'Insumo',
    input_company: 'Compañia de insumos',
    input_property: 'Propiedad de Insumo',
    input_type: 'Tipo de Insumo',
    pest: 'Plaga',
    stubble_cover_level: 'Cobertura de rastrojo',
    weed: 'Maleza',
    weed_type: 'Tipo de maleza'
  };
  
export const TABLE = [
    'id',
    'name',
    'creation_date',
    ''
  ];

  export enum CatalogValue {
    INTEGER = 'INTEGER', TEXT = 'TEXT', FLOAT = 'FLOAT'
  }
  
  export const VALUE_CONVERTERS = {
    [CatalogValue.FLOAT]: value => value ? parseFloat(value) : null,
    [CatalogValue.INTEGER]: value => value ? parseInt(value, 10) : null,
    [CatalogValue.TEXT]: value => value,
  };
  
  export interface CatalogMetadata {
    [key: string]: CatalogValue | CatalogDependency;
  }
  
  export enum CatalogDependencyType {
    CATALOG = 'CATALOG'
  }
  
  export enum CatalogDependencyGroup {
    LIST = 'LIST'
  }
  
  export interface CatalogDependency {
    group?: CatalogDependencyGroup;
    metadata?: CatalogMetadata;
    type?: CatalogDependencyType;
    name?: string;
    joiner: string;
  }

  export interface CatalogDictionary {
    [key: number]: CatalogContentItem;
  }  

  export interface CatalogData {
    metadata: CatalogMetadata;
    content: CatalogContentItem[];
    contentById: {CatalogDictionary}; //{[key: number]: CatalogContentItem};
  }
  
  /*
  just by using a IDictionary<TValue> { [key: string]: TValue } since numeric values will be automatically converted to string.

Here is an example of usage:

interface IDictionary<TValue> {
    [id: string]: TValue;
}

class Test {
    private dictionary: IDictionary<string>;

    constructor() {
       this.dictionary = {}
       this.dictionary[9] = "numeric-index";
       this.dictionary["10"] = "string-index"

       console.log(this.dictionary["9"], this.dictionary[10]);
    }
}
// result => "numeric-index string-index"
As you can see string and numeric indices are interchangeable. 
  */


  export interface CatalogContentItem_SinProp {
    [key: string]: any;
    name?: string;
    id?: number;
    creation_date?: string;
    down_date?: Date | undefined;
    scientific_name?: string;
    crop_specie_id?:number;
    pest_id?:number;
    description?: string;
    weed_type_id?:number;
    crop_use_id?:number;
    crop_sowing_id?:number;
    input_type_id?:number;
    input_company_id?:number| null;
    d_especie?:string;
    species?: string;
  }

export interface CatalogContentItem extends CatalogContentItem_SinProp {
  properties?: IProperty[];
  specie_list?: number[];
}


/*    export class CatalogItem4 {
      id?: number;
      name?: string;
      creation_date?: Date | string | undefined;
      down_date?: Date | undefined;
  }*/
  
  export class CatalogItemModal {
      operation: string;
      catalog_id: string;
      n_fields: number;
      record? : CatalogContentItem;
  }