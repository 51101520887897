<div class="container">
 
 <form *ngIf="catalogoMoreFormControl" class="example-form" [formGroup]="catalogoMoreFormControl">
  <h3 class="title">{{titulo}}</h3><br>
  <div *ngIf="loading" class="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="!loading">
  <div *ngIf="itemCatalogo.name != undefined">
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Nombre" formControlName="name" [errorStateMatcher]="matcher" required>
      <mat-error *ngIf="catalogoMoreFormControl.get('name')?.invalid">{{getNameErrorMessage()}}</mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="catalogoMoreFormControl.get('scientific_name') != undefined">
  <mat-form-field class="example-full-width">
    <input matInput placeholder="Nombre Cientifico" formControlName="scientific_name" [errorStateMatcher]="matcher"  oninput="this.value = this.value.toUpperCase()" required>
    <mat-error *ngIf="catalogoMoreFormControl.get('scientific_name')?.invalid">Complete el nombre científico</mat-error> 
  </mat-form-field>
  </div>

  <div *ngIf="catalogoMoreFormControl.get('description') != undefined">
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Descripción" formControlName="description" [errorStateMatcher]="matcher"  oninput="this.value = this.value.toUpperCase()" required>
      <mat-error *ngIf="catalogoMoreFormControl.get('scientific_name')?.invalid">Complete la descripción</mat-error> 
    </mat-form-field>
  </div>

  <div *ngIf="(itemCatalogo.weed_type_id)!= undefined"> 
    <mat-form-field class="example-full-width">
      <mat-label>Tipo de maleza</mat-label>
      <mat-select [disabled]="(operacion == 'C')" [value]="selectedweed_type"  (selectionChange)="onweed_typeSelect($event.value)" disableRipple formControlName="weed_type_id"  >
        <mat-option *ngFor='let registro of weed_type_List' [value]="registro.id">{{registro.name}}</mat-option>
      </mat-select>
      <!-- <mat-error *ngIf="catalogoMoreFormControl.get('weed_type_id').invalid">Complete el tipo de maleza</mat-error> -->
      <mat-error *ngIf="catalogoMoreFormControl.get('weed_type_id').hasError('required')">
        Complete el tipo de maleza
      </mat-error>      
    </mat-form-field>
  </div>  

  <div *ngIf="(itemCatalogo.pest_id)!= undefined"> 
    <mat-form-field class="example-full-width">
      <mat-label>Plaga</mat-label>
      <mat-select [disabled]="(operacion == 'C')"  [value]="selectedpest"  (selectionChange)="onpestSelect($event.value)" disableRipple formControlName="pest_id" [errorStateMatcher]="matcher" >
        <mat-option *ngFor='let registro of pest_List' [value]="registro.id">{{registro.name}}</mat-option>
      </mat-select> 
      <mat-error *ngIf="catalogoMoreFormControl.get('pest_id').hasError('required')">
        Complete la plaga
      </mat-error>      
    </mat-form-field>
  </div>

  <div *ngIf="(itemCatalogo.input_type_id)!= undefined"> 
    <mat-form-field class="example-full-width">
      <mat-label>Tipo de insumo</mat-label>
      <mat-select [disabled]="(operacion == 'C')"  [value]="selectedinput_type"  (selectionChange)="oninput_typeSelect($event.value)" disableRipple formControlName="input_type_id" [errorStateMatcher]="matcher" >
        <mat-option *ngFor='let registro of input_type_List' [value]="registro.id">{{registro.name}}</mat-option>
      </mat-select> 
      <mat-error *ngIf="catalogoMoreFormControl.get('input_type_id').hasError('required')">
        Complete el tipo de insumo
      </mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="(((itemCatalogo.crop_specie_id)!= undefined) && (((itemCatalogo.input_type_id)== undefined) || (itemCatalogo.input_type_id == k_tipo_insumo_semillas )))"> 
    <mat-form-field class="example-full-width">
      <mat-label>Especie</mat-label>
      <mat-select [disabled]="(operacion == 'C')"  [value]="selectedcrop_specie"  (selectionChange)="oncrop_specieSelect($event.value)" disableRipple formControlName="crop_specie_id" [errorStateMatcher]="matcher" >
        <mat-option *ngFor='let registro of crop_specie_List' [value]="registro.id">{{registro.name}}</mat-option>
      </mat-select> 
      <mat-error *ngIf="catalogoMoreFormControl.get('crop_specie_id').hasError('required')">
        Complete la especie de cultivo
      </mat-error>      
    </mat-form-field>
  </div>

 
  <div *ngIf="(itemCatalogo.crop_use_id)!= undefined"> 
    <mat-form-field class="example-full-width">
      <mat-label>Uso del cultivo</mat-label>
      <mat-select [disabled]="(operacion == 'C')"  [value]="selectedcrop_use"  (selectionChange)="oncrop_useSelect($event.value)" disableRipple formControlName="crop_use_id" [errorStateMatcher]="matcher" >
        <mat-option *ngFor='let registro of crop_use_List' [value]="registro.id">{{registro.name}}</mat-option>
      </mat-select> 
      <mat-error *ngIf="catalogoMoreFormControl.get('crop_use_id').hasError('required')">
        Complete el uso del cultivo
      </mat-error>  
    </mat-form-field>
  </div>

  <div *ngIf="(itemCatalogo.crop_sowing_id)!= undefined"> 
    <mat-form-field class="example-full-width">
      <mat-label>Momento</mat-label>
      <mat-select [disabled]="(operacion == 'C')"  [value]="selectedcrop_sowing"  (selectionChange)="oncrop_sowingSelect($event.value)" disableRipple formControlName="crop_sowing_id" [errorStateMatcher]="matcher" >
        <mat-option *ngFor='let registro of crop_sowing_List' [value]="registro.id">{{registro.name}}</mat-option>
      </mat-select> 
      <mat-error *ngIf="catalogoMoreFormControl.get('crop_sowing_id').hasError('required')">
        Complete el momento del cultivo
      </mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="(itemCatalogo.input_company_id !== undefined)"> 
    <mat-form-field class="example-full-width">
      <mat-label>Compañía del insumo</mat-label>
      <mat-select [disabled]="(operacion == 'C')"  [value]="selectedinput_company"  (selectionChange)="oninput_companySelect($event.value)" disableRipple formControlName="input_company_id" [errorStateMatcher]="matcher" >
        <mat-option *ngFor='let registro of input_company_List' [value]="registro.id">{{registro.name}}</mat-option>
      </mat-select> 
      <!-- <mat-error *ngIf="catalogoMoreFormControl.get('input_company_id')!.invalid">Complete la especie</mat-error> -->
    </mat-form-field>
  </div> 

  <div *ngIf="(itemCatalogo.species !== undefined)"> 
   <mat-form-field>
    <mat-label>Especies afectadas</mat-label>
    <mat-select  #select multiple [(ngModel)]="crop_specie_pest_List" [ngModelOptions]="{standalone: true}">
      <div class="select-all">
          <mat-checkbox [(ngModel)]="allSelected"
                          [ngModelOptions]="{standalone: true}"
                          (change)="toggleAllSelection()">Todas</mat-checkbox>
      </div>
      <mat-option (click)="optionClick()" *ngFor="let reg of crop_specie_List" [value]="reg.id">
        {{reg.name}}
      </mat-option>
    </mat-select>
  </mat-form-field> 
  </div>

  <div class="properties-view-container" *ngIf="((((itemCatalogo.input_type_id) != undefined) && (itemCatalogo.input_type_id != k_tipo_insumo_semillas )))">
    
      <div class="properties-view-list" *ngIf="(prop_List.length > 0)" >
        
        <ng-container *ngIf="prop_List; else loading">        
          
          
          <!-- <mat-divider></mat-divider> -->
          <mat-list>
            <h4>Propiedades del insumo</h4>
            <mat-list-item *ngFor="let propiedad of prop_List; let i = index; trackBy:trackByIndex;" [attr.data-index]="i">
              <!-- <h5>{{propiedad.name}}</h5> -->
              <p mat-line class="label_propiedad"> {{propiedad.name}} </p> 
              <input type="number" placeholder="Valor numérico" class = "valor_numerico" [(ngModel)]="prop_List[i].value" [ngModelOptions]="{standalone: true}" [disabled]="operacion == 'C'"/>
              <input type="text" placeholder="Valor en texto" class = "valor_texto" [(ngModel)]="prop_List[i].text_value" [ngModelOptions]="{standalone: true}" [disabled]="operacion == 'C'"/>
            </mat-list-item>
            </mat-list>

        </ng-container>
        <ng-template #loading>
          <div class="loading">
            Cargando propiedades del insumo ...
          </div>
        </ng-template>
      </div>
    <!--   <div class="property-view-add">
       <div *ngIf="canAdd">
          <workspace-view-add #add [url]="url" [validators]="addValidators" [types]="permissionList" (newUser)="addProperty($event)"></workspace-view-add>
        </div> 
        <button mat-raised-button color="accent" (click)="close()">CERRAR</button>
      </div> -->
    </div>
    

  <div *ngIf="operacion == 'C'">
    <mat-form-field class="example-full-width">
    <input matInput placeholder="Fecha de Creación" [value]="creationDate"  disabled>
    </mat-form-field>
  </div>

  <div *ngIf="operacion == 'C'" class="buttons">
    <button mat-stroked-button color="primary" (click)="close()">Cerrar</button>
  </div>

  <div *ngIf="operacion != 'C'" class="buttons">
    <button mat-stroked-button color="primary" (click)="close()">Cancelar</button>
    <button mat-raised-button color="primary" class="accept-button" [disabled]="catalogoMoreFormControl.invalid" (click)="save()">Guardar</button>
  </div>
</div>
</form>

</div>

