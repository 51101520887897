    <ng-container  *ngIf="isAuthenticated()">

       <div class="permisos-efectivos-table">
         <puntal-table
           tableTitle={{titulo}}
           [dataSource]="permisos"
           [loading]="loading"
           [options]="tableOptions"
           [displayedColumns]="header"
            (onBack)="close()"
            noEdit = true
            noDelete = true
           showBackButton="S"
         >
       </puntal-table>
       </div>
     
       </ng-container>

