import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';

  export function getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 400: {
        return `${error.message}`;
      }
      case 404: {
            return `${error.message}`;
        }
        case 403: {
            return `Acceso denegado: ${error.message}`;
        }
        case 500: {
            return `Error interno: ${error.message}`;
        }
        default: {
             return `${error.error.message}`;
        }

    }
}

export function isEmpty(obj) {
    for(var prop in obj) {
      if(Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
  
    return JSON.stringify(obj) === JSON.stringify({});
  }

  export function formatear_fecha(fecha) {
    const date = new Date(fecha);
    const relative = moment(date).fromNow(true);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} (${relative})`;
  }

  export function nombre_rol(role) {
    switch (role) {
      case 'ADMIN': {return 'Administrador'; break;}
      case 'FULL_VIEW': {return 'Consulta/Reportes'; break;}
      case 'VIEW': {return 'Consulta'; break;}
      case 'NO_USER': {return 'Sin Permiso'; break;}
      default: {return role;}
    }
  }