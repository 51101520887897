
   <ng-container  *ngIf="isAuthenticated()">
   <div class="usuarios-table">
     <puntal-table
       tableTitle="Usuarios"
       [dataSource]="usuarios"
       [loading]="loading"
       [options]="tableOptions"
       [displayedColumns]="header"
       (onEdit)="editRow($event)"
       (onDelete)="deleteRow($event)"
       (onRowClick)="navigateTo($event)"
       (onAdd)="addRow()"
       (onBack)="goBack()"
       showAddButton="S"
       showBackButton="S"
       (onShowPermissionClick)="reset_password($event)"
       showPermissionButton='S'
     >
    </puntal-table>
   </div>
 
   </ng-container>