<div class="current-activities-container" *ngIf="isAuthenticated()">
  
   <div class="activities-table">

  <!--   <div style="text-align: right; margin-bottom: 20px; height: 18px">
      <button mat-raised-button id="showFilters" class="right-aligned angosto" (click)="toggleDiv()" color="accent">{{ showFilters ? 'Ocultar filtros' : 'Mostrar filtros' }}</button>
    </div> -->

   <!--  <a href="#" (click)="toggleDiv()">Ocultar/Mostrar</a> -->
    <div class="accordion-container" *ngIf="showFilters">
      
      <mat-accordion class="example-headers-align" multi>


        <mat-expansion-panel [expanded]="!loading">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>{{campania_y_cultivo_elegidos}}</b>
            </mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="filters">
            <ng-container>   
              <div>
              <mat-form-field>
                <mat-label>Cliente</mat-label>
                <mat-select [disabled]="clientList$.length === 0" [(value)]="selectedClientBuscar" disableRipple (selectionChange)="onClientSelect($event.value,null,null)" id="client_id" [errorStateMatcher]="matcher" >
                  <mat-option *ngFor='let client of clientList$' [value]="client.id">{{client.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div><div>
            <mat-form-field>
              <mat-label>Empresa</mat-label>
              <mat-select [(value)]="selectedCompanyBuscar" [disabled]="!selectedClientBuscar || companyList$.length === 0" disableRipple (selectionChange)="onCompanySelect($event.value,null)" id="company_id" [errorStateMatcher]="matcher" >
                <mat-option *ngFor='let company of companyList$' [value]="company">{{company.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Campo</mat-label>
              <mat-select [(value)]="selectedFarmBuscar" [disabled]="!selectedCompanyBuscar || farmList$.length === 0" disableRipple (selectionChange)="onFarmSelect($event.value)" id="farm_id" [errorStateMatcher]="matcher" >
                <mat-option *ngFor='let farm of farmList$' [value]="farm">{{farm.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          </ng-container>
        </div>
          <div class="filters">
          <ng-container>
              <div>
                <mat-form-field>
                  <mat-label>Campaña</mat-label>
                  <mat-select [disabled]="campaniaList.length === 0" [(value)]="selectedCampaign" disableRipple (selectionChange)="onCampaignSelect($event)" >
                    <mat-option *ngFor='let campania of campaniaList' [value]="campania.id">{{campania.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                </div>

                <div>
                  <mat-form-field>
                    <mat-label>Cultivo</mat-label>
                    <mat-select [disabled]="subtypeList.length === 0" [value]="this.subtype_elegido" disableRipple (selectionChange)="onSubTypeSelect($event)" >
                      <mat-option *ngFor='let sub_type of subtypeList' [value]="sub_type.sub_type">{{sub_type.sub_type}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  </div>

                  <div *ngIf="(subtype_elegido !== undefined) && (subtype_elegido !== '-1')&& (crop_specie_stage_List !== undefined) && (crop_specie_stage_List.length >= 0)">
                    <mat-form-field>
                     <mat-label>Etapas</mat-label>
                     <mat-select  #select multiple [ngModelOptions]="{standalone: true}"  [(ngModel)]="crop_stage_List">
                   <!--    <mat-select-trigger>
                        {{toppings.value ? toppings.value[0] : ''}}
                        
                      </mat-select-trigger> -->

                       <div class="select-all">
                           <mat-checkbox [(ngModel)]="allDataSelected"
                                           [ngModelOptions]="{standalone: true}"
                                           (change)="toggleAllSelection()">Todas</mat-checkbox>
                       </div>
                       <mat-option (click)="optionClick()" *ngFor="let reg of crop_specie_stage_List" [value]="reg.name">
                         {{reg.name}}
                       </mat-option>
                     </mat-select>
                   </mat-form-field> 
                   </div>



                  <div style="display:inline-block">
                    <mat-form-field >
                      <mat-label>Rango de fechas de recorridas</mat-label>
                      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate id="start" formControlName="start" #dateRangeStart placeholder="Desde" (dateChange)="dateRangeChange($event.value)" readonly>
                        <input matEndDate id="end" formControlName="end" #dateRangeEnd placeholder="Hasta" (dateChange)="dateEndRangeChange($event.value)" readonly>
                        
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker (closed)="closed(dateRangeStart, dateRangeEnd)">
                        <mat-date-range-picker-actions>
                          <button mat-button matDateRangePickerCancel>Cancelar</button>
                          <button mat-raised-button color="primary" matDateRangePickerApply>Aplicar Filtro</button>
                        </mat-date-range-picker-actions>                        
                      </mat-date-range-picker>
                      
                    </mat-form-field>
                  <button mat-icon-button aria-label="Limpiar filtro de fechas" style="float:right;" [ngClass]="{disable: (dateFilter.activo == 0)}" class="clean" (click)="cleanDateFilters()">
                    <mat-icon style="vertical-align: bottom;">delete_sweep</mat-icon>
                  </button>     
                             
              </div>
            </ng-container>
           <!--  <div class="buttons">
            <button mat-stroked-button color="primary" (click)="buscar()">Buscar</button> -->
            <button mat-raised-button (click)="buscar()" color="accent">Buscar</button>
          <!-- </div> -->
          </div>
         </mat-expansion-panel>
<!-- 
        <mat-expansion-panel> 
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Cliente</b>&nbsp;>&nbsp;<b>Empresa</b>&nbsp;>&nbsp;<b>Campo</b>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="filters">
            <ng-container *ngFor="let field of clientFilter.order">
              <div>
                <mat-form-field>
                  <mat-label>{{clientFilter.info[field].name}}</mat-label>
                  <mat-select [disabled]="disableClientSelect(field)" (selectionChange)="changeClientSelect($event, field)" [value]="valueIfOne(field)">
                    <mat-select-trigger>
                      <ng-container *ngIf="allSelected(clientFilter, field); else showNames">
                        Mostrar todos
                      </ng-container>
                      <ng-template #showNames>
                        <ng-container>
                          <span>{{textPrettyFilter(clientFilter.select[field])}}</span>
                        </ng-container>
                      </ng-template>
                    </mat-select-trigger>
                    <mat-option *ngFor="let value of clientFilter.values[field]" [value]="value">
                      <span>{{value}}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
            <div class="clean-button-container">
              <button mat-icon-button aria-label="Limpiar filtros" [ngClass]="{disable: !anyFilter(clientFilter)}" class="clean" (click)="cleanClientFilters()">
                <mat-icon>delete_sweep</mat-icon>
              </button>
            </div>
          </div>

        </mat-expansion-panel>
 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Semáforo</b>
            </mat-panel-title>
          </mat-expansion-panel-header>
            <multi-filter [filters]="semaphoreFilters" (update)="this.updateFilter($event)" [isSemaphore]="true"></multi-filter>
         </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Decisión</b>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <multi-filter [filters]="decisionFilters" (update)="this.updateFilter($event)"></multi-filter>
        </mat-expansion-panel> 

      </mat-accordion>
    </div>
    <mat-paginator *ngIf="paginar" #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
    </mat-paginator>
     <!-- <div *ngIf="actividadList.length > 0">  -->
    
     <puntal-table *ngIf="actividadList && !loading"
       tableTitle="Actividades"
       [dataSource]="actividadList"
       [loading]="loading"
       [options]="tableOptions"
       [displayedColumns]="header"
       [noEdit]="false"
       [noDelete]="false"      
       [noDownload]="false"
       (onEdit)="null"
       (onDelete)="deleteRow($event)"
       (onDownload)="downloadRow($event)"
       (onRowClick)="navigateTo($event)"
       (onAdd)="addRow()"
       (onBack)="goBack()"
       (onDownLoadGrid)="downloadGrid()"
       (onShowFilters)="toggleDiv()"
       [showAddButton]="canAddRecord"
       showBackButton="S"
       showDownloadGridButton="S"
       [showFiltersButton]="showFilterMode"
       (onShowPermissionClick)="openMedia($event)"
       [showPermissionButton]="canAddRecord"
     >
   </puntal-table>
  
    <!-- </div>  -->
  </div>
 
  </div>

