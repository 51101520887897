import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { ICrop } from '../modelo/subtype'; 
import { Observable, of } from 'rxjs';

const API_URL = environment.API_URL;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SubtypeService {

  public SubTypesList:string[] = [];

  constructor(private http: HttpClient) {
    this.getSubTypes().subscribe((crops) => {
      for (var i = 0; i < crops.length; i++) {
         this.SubTypesList.push(crops[i].name);
        }
    });
  }

  getSubTypes(): Observable<ICrop[]> {
    return this.http.get<ICrop[]>(API_URL + 'subtype/getAll', { responseType: 'json' });
  }

}
