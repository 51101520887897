import { Injectable } from '@angular/core';
//import { SkipSelf } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { getServerErrorMessage } from '../shared/utility-functions';
import { catchError } from 'rxjs/operators';
import { throwError } from "rxjs";

import { Empresa, ICompanyRole } from '../modelo/empresa';
import { environment } from './../../environments/environment';

const API_URL = environment.API_URL;


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class EmpresaService {
//  errorMsg: string;
//@SkipSelf() 
  constructor(private http: HttpClient) {}

  getEmpresas(idCliente:number): Observable<Empresa[]> {
    return this.http.get<Empresa[]>(API_URL + 'empresa/getAll/'+idCliente, { responseType: 'json' });
  }
  
  getEmpresasActivas(idCliente:number): Observable<Empresa[]> {
    return this.http.get<Empresa[]>(API_URL + 'empresa/getAllActive/'+idCliente, { responseType: 'json' });
  }

  getEmpresasAdmin(idCliente:number): Observable<ICompanyRole[]> {
    return this.http.get<ICompanyRole[]>(API_URL + 'empresa/getAllAdmin/'+idCliente, { responseType: 'json' });
  }

  deleteEmpresa(idEmpresa:number): Observable<Empresa>  {
    return this.http.delete<any>(API_URL + 'empresa/'+idEmpresa)
  }

  updateEmpresa(empresa:Empresa): Observable<Empresa>  {
    return this.http.put<any>(API_URL + 'empresa', empresa , { responseType: 'json' })
  }  

  addEmpresa(empresa:Empresa): Observable<Empresa>  {
      return this.http.post<any>(API_URL + 'empresa/add', empresa , { responseType: 'json' })
  }

}
