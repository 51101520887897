import {Component, OnDestroy, OnInit, Inject} from '@angular/core';
import { Actividad } from '../../../modelo/actividad';
import { Cliente } from '../../../modelo/cliente';
import { Campo } from '../../../modelo/campo';
import { Lote } from '../../../modelo/lote';
import { Empresa } from '../../../modelo/empresa';
import { Campania } from '../../../modelo/campania';
import {FormControl} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { Location } from '@angular/common';
import { ActividadService } from '../../../_services/actividad.service';
import { CampaniaService } from '../../../_services/campania.service';
import { ClienteService } from '../../../_services/cliente.service';
import { EmpresaService } from '../../../_services/empresa.service';
import { CampoService } from '../../../_services/campo.service';
import { LoteService } from '../../../_services/lote.service';
import { TokenStorageService } from '../../../_services/token-storage.service';
import { isEmpty } from '../../../shared/utility-functions';
import { ClientFormComponent } from '../../../clientes/forms/cliente-form/cliente-form.component';
import { EmpresaFormComponent } from '../../../empresas/forms/empresa-form/empresa-form.component';
import { CampoFormComponent } from '../../../campos/forms/campo-form/campo-form.component';
import { LoteFormComponent } from '../../../lotes/forms/lote-form/lote-form.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthService } from '../../../_services/auth.service';
import { SubtypeService } from '../../../_services/subtype.service';


/*import {combineLatest, Observable, Subscription} from 'rxjs';
import {Cliente, Empresa, Campo, Lote} from '~core/models/clients-section.models';
import {IAppState} from '~core/store/state/app.state';
import {Store, select} from '@ngrx/store';
import {selectClientsList, selectCompaniesList, selectFarmsList, selectPlotList} from '~core/store/selectors/clients-section.selectors';
import {ActivityFormService} from './activity-form.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog, MatDialogRef, MatSelect} from '@angular/material';
import {FarmFormComponent} from 'app/shared/forms/farm-form/farm-form.component';
import {PlotFormComponent} from 'app/shared/forms/plot-form/plot-form.component';
import {ClientFormComponent} from 'app/shared/forms/client-form/client-form.component';
import {CompanyFormComponent} from 'app/shared/forms/company-form/company-form.component';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {IActivity} from '~core/models/activities.models';
import {selectActivitiesList} from '~core/store/selectors/activities.selectors';
import {delay, filter, map} from 'rxjs/operators';
import {AuthService} from '../../auth/auth.service';

export const DATE_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}; 
*/
@Component({
  selector: 'actividad-form',
  templateUrl: './actividad-form.component.html',
  styleUrls: ['./actividad-form.component.scss']
})
export class ActividadFormComponent implements OnInit, OnDestroy {
  public matcher: ErrorStateMatcher;
  public actividadFormControl: FormGroup;

/*  clientList$: Observable<Cliente[]> = this.store.pipe(select(selectClientsList),
    map(clients => clients.filter(c => this.authService.canCreateActivity(c.id))));
  companyList$: Observable<Empresa[]> = this.store.pipe(select(selectCompaniesList),
    map(companies => companies.filter(c => this.authService.canCreateActivity(c.client.id, c.id))));
  farmList$: Observable<Campo[]> = this.store.pipe(select(selectFarmsList),
    map(farms => farms.filter(f => this.authService.canCreateActivity(f.company.client.id, f.company.id, f.id))));
  plotList$: Observable<Lote[]>;
  activitiesList$: Observable<IActivity[]> = this.store.pipe(select(selectActivitiesList));*/

  public campaniaList: Campania[] = []; 
  public subtypeList: String[] = [];
  public clientList$: Cliente[] = [];
  public companyList$: Empresa[] = [];
  public farmList$: Campo[] = [];
  public plotList$: Lote[] = [];
  public loading = true;
  public selectedCampaign: number | null; //Campania | null;
  selectedClient: number | null; //Cliente | null;
  selectedCompany: Empresa | null;
  selectedFarm: Campo | null;
  selectedPlot: Lote | null;
  selectedSubType: String | null = null;
  //startDateFormControl = new FormControl(new Date().toISOString());
  startDate: Date | null = null;
  selectedCc: boolean;
  minDate: Date;
  maxDate: Date;
  canAddClient = 'N';

 /* private subscriptions: Subscription[] = [];*/

  constructor(
 /*   private store: Store<IAppState>,
    private authService: AuthService,*/
    @Inject(MAT_DIALOG_DATA) public actividad: Actividad /*IClient*/,
    private dialogRef: MatDialogRef<EmpresaFormComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private actividadService: ActividadService,
    private campaniaService: CampaniaService, 
    private clienteService: ClienteService, 
    private empresaService: EmpresaService, 
    private campoService: CampoService, 
    private loteService: LoteService, 
    private subtypeService: SubtypeService, 
    private dialog: MatDialog, private location: Location, 
    private tokenStorageService: TokenStorageService ) {


    this.campaniaService.getCampanias().subscribe((campanias) => {
      var fechaD: string;
      var fechaH: string;
      var fecha_d: Date;
      fechaD = '';
      fechaH = '';
      for (let c of campanias) { 
        try {
          if (c.from_date == undefined) {
            fechaD = '-';
          } else {
            fecha_d = new Date(c.from_date);
            fechaD = `${fecha_d.getDate()}/${fecha_d.getMonth() + 1}/${fecha_d.getFullYear()}`;
          }
          if (c.to_date == undefined) {
            fechaH = '-';
          } else {
            fecha_d = new Date(c.to_date);
            fechaH =  `${fecha_d.getDate()}/${fecha_d.getMonth() + 1}/${fecha_d.getFullYear()}`;
          }
        }
        finally {
          c.name = c.name + '  ( '+ fechaD  + '  a  '+ fechaH +' )';
        }
      }      
      this.campaniaList = campanias;

    });   
    this.clienteService.getClientesActivos().subscribe((clientes) => {
      this.clientList$ = clientes;
    });    
    this.subtypeList = subtypeService.SubTypesList; //this.subTypes();

    
  }

  ngOnInit(): void {
    this.loading = true;
    if ((this.authService.isSuperUser()) || (this.authService.isUserAdmin())) {
      this.canAddClient = 'S'
    }else {
      this.canAddClient = 'N';
    }   
  
    const last_campaign = this.tokenStorageService.getLastCampaign();
    const currentYear = moment().year();
    
    this.minDate = new Date(currentYear - 10, 0, 1);
    this.maxDate = new Date(currentYear + 10, 0, 1);
    if ((last_campaign) && (last_campaign.id)) {
        this.selectedCampaign = last_campaign.id;
        this.minDate = new Date(last_campaign.from_date);
        this.maxDate = new Date(last_campaign.to_date);
        this.startDate = this.minDate;
        this.actividadFormControl = this.createFormControl();
    } 
      else {
        this.campaniaService.getActiveCampaign().subscribe((campania) => {
        if (campania) {
          this.selectedCampaign = campania[0].id;
          this.minDate = new Date(campania[0].from_date);
          this.maxDate = new Date(campania[0].to_date);
          this.startDate = this.minDate;
          this.actividadFormControl = this.createFormControl();
        } 
        else {
          this.actividadFormControl = this.createFormControl();
        }
      }
      );
      
    }
    
    
   // this.setDefaults();
    this.matcher = new ShowOnDirtyErrorStateMatcher();
        
  }
  /*public setDefaults() {
    if (this.selectedCampaign) {
      this.actividad.campaign_id = this.selectedCampaign;
    }    
    this.actividad.start_date = this.minDate;
    if (this.actividad.client_id > 0) {
      console.info('ACTIVIDAD en setDefaults:',this.actividad);
      this.onClientSelect(this.actividad.client_id);
      
       if (this.actividad.company_id > 0) {
    //  console.log(1.2);

          this.onCompanySelect({'id': this.actividad.company_id, 'name': '', 'client_id': this.actividad.client_id} as Empresa); 
          if (this.actividad.farm_id > 0) {
        //    console.log(1.3);
          
            this.onFarmSelect({'id': this.actividad.farm_id, 'name': '', 'company_id': this.actividad.company_id} as Campo); 
            this.loading = false;
          } 
          else {this.loading = false;}
        }
        else {this.loading = false;}
    }
    else {this.loading = false;}    
  }*/

  private createFormControl(): FormGroup {
    if ((!this.actividad) || (Object.keys(this.actividad).length === 0)) {
      this.actividad = {
        id: 0,
        plot_id: 0,
        farm_id: 0,
        company_id: 0,
        client_id: 0,
        type: '',
        sub_type: '',
        campaign_id: 0,
        start_date: this.minDate     
      } as Actividad;
      if (this.selectedCampaign) {
        this.actividad.campaign_id = this.selectedCampaign;
      }
      this.loading = false;
    }
    else {
      if (this.actividad.client_id > 0) {
        this.selectedClient = this.actividad.client_id;
        this.empresaService.getEmpresasActivas(this.actividad.client_id).subscribe((empresas) => {
          this.companyList$ = empresas;
          if (this.actividad.company_id > 0) {
            const company_default = this.companyList$.find(e => e.id === this.actividad.company_id);
            if (company_default) {
              this.selectedCompany = company_default;
              this.campoService.getCamposActivos(this.selectedCompany.id).subscribe((campos) => {
                this.farmList$ = campos;
                if (this.actividad.farm_id > 0 ) {
                  const campo_default = this.farmList$.find(e => e.id === this.actividad.farm_id);
                  if (campo_default) {
                    this.selectedFarm = campo_default;
                    this.loteService.getLotesActivos(this.selectedFarm.id).subscribe((lotes) => {
                      this.plotList$ = lotes;
                      this.loading = false;
                    });   
                  }
                } else {this.loading = false;}
              }
            );
          }
        } else {this.loading = false;}
      });            

      } else {this.loading = false;}
    }
    if (!this.actividad.start_date || (this.actividad.start_date < this.minDate) ) {this.actividad.start_date = this.minDate;}
    return this.formBuilder.group({
      start_date: [this.actividad.start_date, [Validators.required, Validators.max, Validators.min]],
      sub_type: [this.actividad.sub_type, [Validators.required]],
      campaign_id: [this.actividad.campaign_id, [Validators.required]]
    });
    this.loading = false;
  }


  public dateFilter = (d: Date): boolean => {
    return (d >= this.minDate) && (d <= this.maxDate);
  }

  onCampaignSelect(campaign: number) { // Campania) {
    if (this.selectedCampaign && this.selectedCampaign === campaign) {
      return;
    }
    this.selectedCampaign = campaign;
    const last_campaign = this.campaniaList.find(e => e.id === this.selectedCampaign);
      if (last_campaign) {
        if (last_campaign.from_date) 
        this.minDate = last_campaign.from_date
      if (last_campaign.to_date) 
        this.maxDate = last_campaign.to_date;
    }
    this.actividadFormControl.controls['start_date'].setValue(this.minDate);
    this.actividadFormControl.controls['start_date'].setErrors(null);
  }

  onClientSelect(client: number) { //Cliente) {
   /* if (this.selectedClient && this.selectedClient === client) {
      return;
    }*/
    this.selectedClient = client;
    this.selectedCompany =  null;
    this.selectedFarm =  null;
    this.selectedPlot =  null;
    this.empresaService.getEmpresasActivas(client).subscribe((empresas) => {
      this.companyList$ = empresas;
    });    
  }

  onCompanySelect(company: Empresa) {
   /* if (this.selectedCompany && this.selectedCompany.id === company.id) {
      return;
    }*/
    this.selectedCompany = company;
    this.selectedFarm =  null;
    this.selectedPlot =  null;
    this.campoService.getCamposActivos(company.id).subscribe((campos) => {
      this.farmList$ = campos;
    });    
  }

  onFarmSelect(farm: Campo) {
  /*  if (this.selectedFarm && this.selectedFarm.id === farm.id) {
      return;
    }*/
  //  const elementoEncontrado = this.farmList$.find(elemento => elemento.id === farm.id);
  //if(elementoEncontrado) {this.selectedFarm = farm;}
    this.selectedFarm = farm;
    this.selectedPlot =  null;
    this.loteService.getLotesActivos(farm.id).subscribe((lotes) => {
      this.plotList$ = lotes;
    });        
  }

  onPlotSelect(plot: Lote) {
    this.selectedPlot = plot;
  }

  close() {
    this.dialog.closeAll();
  }  
  
  AddDisabled (type: string) {
    // Si es superusuario puede todo
    if (this.authService.isSuperUser()) {return false;}

    switch (type) {
      case 'client': 
        return  (this.canAddClient = 'N');
        break;
      case 'company': 
      if ((this.selectedClient) && (this.selectedClient != undefined)) {
        return false;
        //  return !this.authService.isAdmin('client', this.selectedClient) ;
        }
        break;
      case 'farm':
      case 'plot': 
        if ((this.selectedCompany) && (this.selectedCompany != undefined)) {
          return false;
        // return !this.authService.isAdmin('company', this.selectedCompany) ;
        }
        break;
       default:
        return false;
    }   
    return false; 
  }

  addEntity(type: string) {
    let dialogRef: MatDialogRef<any, any>;
    switch (type) {
      case 'client':
        dialogRef = this.openModal(ClientFormComponent);
        dialogRef.afterClosed().subscribe((newEntity: Cliente) => {
          if (newEntity) {
            this.clienteService.addCliente(newEntity).subscribe(()  => {
              this.clienteService.getClientes().subscribe((clientes) => this.clientList$ = clientes);
            });
          }
        });
        break;
      case 'company':
        if ((this.selectedClient) && (this.selectedClient != null)) {
          dialogRef = this.openModal(EmpresaFormComponent);
          dialogRef.afterClosed().subscribe((newEntity: Empresa) => {
          if (newEntity) {
            newEntity.client_id = this.selectedClient!;
            this.empresaService.addEmpresa(newEntity).subscribe((empresa_nueva)  => {
              this.selectedCompany = empresa_nueva;
              this.empresaService.getEmpresas(this.selectedClient!).subscribe((empresas) => this.companyList$ = empresas);
            });
          }
        });
      }
        break;
     case 'farm':
       if ((this.selectedCompany) && (this.selectedCompany != null)) {
          dialogRef = this.openModal(CampoFormComponent);
          dialogRef.afterClosed().subscribe((newEntity: Campo) => {
            if (newEntity) {
              newEntity.company_id = this.selectedCompany!.id;
              this.campoService.addCampo(newEntity).subscribe(()  => {
                this.selectedFarm = newEntity;
                this.campoService.getCampos(this.selectedCompany!.id).subscribe((campos) => this.farmList$ = campos);
              });
            }
          });
       }
        break;
      case 'plot':
        if ((this.selectedFarm) && (this.selectedFarm != null)) {
          dialogRef = this.openModal(LoteFormComponent);
          dialogRef.afterClosed().subscribe((newEntity: Lote) => {
          if (newEntity) {
            newEntity.farm_id = this.selectedFarm!.id;
            this.loteService.addLote(newEntity).subscribe(()  => {
              this.loteService.getLotes(this.selectedFarm!.id).subscribe((lotes) => this.plotList$ = lotes);
            });
          }
        });
      }
        break;        
      default:
        break;
    }
  }

  private openModal(component: any): MatDialogRef<any, any> {
    return this.dialog.open(component, {
      width: '500px',
      disableClose: true,
      autoFocus: true
    });
  }

  get canCreate() {
    return !!this.selectedSubType && !!this.selectedPlot && !!this.actividadFormControl.controls['start_date'] && !!this.selectedCampaign && (this.selectedCampaign > 0);
  }

  goBack() {
    this.location.back();
  }

/*  save() {
    if (this.actividadFormControl.invalid) { return; }
    const actividadData = Object.assign(this.actividad, this.actividadFormControl.value);
    this.dialogRef.close(actividadData);
  }*/

  create() {
  //  this.startDate = this.actividadFormControl.controls['start_date'].value;
  
    if (this.actividadFormControl.invalid) { return; } 

    this.actividadService.addActividad({
      plot_id: this.selectedPlot!.id,
      farm_id: this.selectedFarm!.id,
      company_id: this.selectedCompany!.id,
      client_id: this.selectedClient,  
      campaign_id: this.selectedCampaign,  
      start_date: this.startDate,
      type: 'Cultivo',
      sub_type: this.selectedSubType
    } as Actividad).subscribe((actividad)  => { 
      this.dialogRef.close(actividad);
    });
  }


private  subTypes() {
      return [
        'Alfalfa',
        'Arveja',
        'Avena',
        'Cebada',
        'Centeno',
        'Colza',
        'Festuca',
        'Girasol',
        'Maíz',
        'Nabo forrajero',
        'Rabanito forrajero',
        'Soja',
        'Sorgo',
        'Trebol Blanco',
        'Trebol Rojo',
        'Trigo',
        'Vicia sativa',
        'Vicia villosa',
        'Agropiro',
        'Lotus tenuis',
        'Rye grass anual',
        'Multiespecie',
        'Otros'
      ]
  }

  ngOnDestroy(): void {
  /*  this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];*/
  }

}
