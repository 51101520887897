import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ToastService} from '../../../shared/toast/toast.service';
//import {$e} from 'codelyzer/angular/styles/chars';

export interface DialogData {
  title: string;
  message: string;
  validation?: string;
  button_text: string; // mariana
}

@Component({
  selector: 'app-confirmed-dialog',
  templateUrl: './confirmed-dialog.component.html',
  styleUrls: ['./confirmed-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmedDialogComponent implements OnInit {
  title: string;
  message: string;
  validation: string;
  inputValidation: string;
  button_text: string; 

  constructor(
    public dialogRef: MatDialogRef<ConfirmedDialogComponent>,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.title = this.data.title;
    this.message = this.data.message;
    this.validation = this.data.validation!;
    this.button_text = this.data.button_text; 
  }

  get validate() {
    if (!this.validation) {
      return true;
    }
    return this.inputValidation === this.validation;
  }

  onNoClick(): void {
    if (!this.validate) {
      this.toastService.openSnackBar('Escribe el nombre para poder aplicar', 'ERROR', 2000);
      return;
    }
    this.dialogRef.close();
  }

  setInput($event: any) {
    this.inputValidation = $event.target.value;
  }
}
