import { Component, OnInit, ViewChild, OnDestroy  } from '@angular/core';
import { Actividad, IActivityFull, ISubType } from '../../modelo/actividad';
import { Scout, ScoutsPage, Semaforos, Semaforo } from '../../modelo/scout';
import { ActividadService } from '../../_services/actividad.service';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../../shared/table/table.component';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Filter, Matcher } from './../multi-filter/multi-filter.component';
import { MatSelectChange } from '@angular/material/select';
import { TokenStorageService } from '../../_services/token-storage.service';
import { SCOUTS_FIELDS, getSemaphoreDecision } from './recorridas.fields';
import { isEmpty } from '../../shared/utility-functions';
import { buildDecisionFilters, buildSempahoresFilters } from '../multi-filter/multi-filter.common.factory';
import * as converter from 'json-2-csv';
import * as scoutConverter from '../../_services/scout-report.service';
import { ConfirmedDialogComponent } from '../../shared/dialogs/confirmed-dialog/confirmed-dialog.component';
import { SemaforoFormComponent } from './forms/semaforo-form/semaforo-form.component';
/*import { NotesFormComponent } from './forms/notes-form/notes-form.component';*/
import { MediaFormComponent } from './forms/media-form/media-form.component';

import * as moment from 'moment';
import {
  getScoutDecision,
  getScoutGeneral,
  scoutDecisionFiler,
  scoutSemaphoreDecision,
  scoutSempahoreFilter
} from '../../_helpers/semaphore.helper';
import { ToastService } from '../../shared/toast/toast.service';

/*import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Subject} from 'rxjs';*/

@Component({
  selector: 'app-scouts',
  templateUrl: './scouts.component.html',
  styleUrls: ['./scouts.component.scss']
})
export class ScoutsComponent   {
    loading = false;
    private activityId;
    private f_matcher: any = null;
    scouts: Scout[];
    scoutsFull: Scout[];    
    titulo: string = 'Recorridas';
    canAddRecord: string = 'S';
    showFilterMode = 'H';
    filtroSemaforoAbierto = false;
    filtroDecisionAbierto = false;
    showFilters = true;
 //   scoutsSubject = new BehaviorSubject<Scout[]>([]);

    readonly scoutColumns: ITableHeader<Scout>[] = [
      {
        label: 'ID',
        value: 'id'
      },
      {
        label: 'Recorredor',
        value: 'scoutter'
        //valueFunction: scout => Array.isArray(scout.users) && scout.users.length > 0 ? scout.users[0].user.email : '-'
      },
      {
        label: 'fecha',
        value: 'last_scout_date',
        valueFunction: scout => {
          const scoutDate = scout.date_time;
          const date = new Date(scoutDate);
          const relative = moment(date).fromNow(true);
          return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} (${relative})`;
        },
        sortValue: scout => {
          if (!scout.date_time) {
            return '0000-00-00T00:00:00.000Z';
          }
          return scout.date_time;
        }
      },
      {
        label: 'Ambiente',
        value: 'ambient',
        center: false,
        valueFunction: scout => scout.ambient.name || ''
      },
      {
        label: 'Siembra',
        value: 'sowing_date',
        center: false,
        valueFunction: scout => {
          if (!scout.sowing  || !scout ||  !scout.sowing.date_time) {
            return '';
          }
          const scoutDate = scout.sowing.date_time;
          const date = new Date(scoutDate);
          const relative = moment(date).fromNow(true);
          return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`/* (${relative})`*/;
        }
      },
      {
        label: 'Sup. sembr.',
        value: 'sowed_area',
        center: false,
      //  valueFunction: scout => scout.sowing.area || ''
        valueFunction: scout => {
          if (!scout.sowing  || !scout || !scout.sowing.area) {
            return '';
          }
          const replant = ((scout.sowing.replant != null) && scout.sowing.replant)?' (ReSbra)':'';
          const scoutArea = scout.sowing.area + replant;
          return `${scoutArea}`;
        }
      },
      {
        label: 'Genotipo',
        value: 'cultivar_name',
        center: false,
        valueFunction: scout => scout.sowing.cultivar_name || ''
      },
      {
        label: 'Etapa cultivo',
        value: 'etapa',
        center: false,
        valueFunction: scout => scout.state || ''
      },
      {
        label: 'Cond. Gral.',
        value: 'state',
        semaphore: true,
        valueFunction: scout => getScoutGeneral(scout)
      },
      {
        label: '',
        value: 'implementation_quality',
        semaphore: true,
        valueFunction: scout => scoutSemaphoreDecision(scout, 'implementation_quality'),
        img: 'spa',
        tooltip: 'Implantación'
      },
      {
        label: '',
        value: 'weeds',
        semaphore: true,
        valueFunction: scout => scoutSemaphoreDecision(scout, 'weeds'),
        img: 'local_florist',
        tooltip: 'Malezas'
      },
      {
        label: '',
        value: 'pests',
        semaphore: true,
        valueFunction: scout => scoutSemaphoreDecision(scout, 'pests'),
        img: 'adb',
        tooltip: 'Plagas'
      },
      {
        label: '',
        value: 'diseases',
        semaphore: true,
        valueFunction: scout => scoutSemaphoreDecision(scout, 'diseases'),
        img: 'local_hospital',
        tooltip: 'Enfermedades'
      },
      {
        label: '',
        value: 'adversities',
        semaphore: true,
        valueFunction: scout => scoutSemaphoreDecision(scout, 'adversities'),
        img: 'ac_unit',
        tooltip: 'Adversidades'
      } ,     
 /*     {
        label: 'Condicion general',
        value: 'state',
        semaphore: true,
        valueFunction: scout => getScoutGeneral(scout)
      },
      {
        label: 'Implantación',
        value: 'implementation_quality',
        semaphore: true,
        valueFunction: scout => scoutSemaphoreDecision(scout, 'implementation_quality')
      },
      {
        label: 'Malezas',
        value: 'weeds',
        semaphore: true,
        valueFunction: scout => scoutSemaphoreDecision(scout, 'weeds')
      },
      {
        label: 'Plagas',
        value: 'pests',
        semaphore: true,
        valueFunction: scout => scoutSemaphoreDecision(scout, 'pests')
      },
      {
        label: 'Enfermedades',
        value: 'diseases',
        semaphore: true,
        valueFunction: scout => scoutSemaphoreDecision(scout, 'diseases')
      },
      {
        label: 'Adversidades',
        value: 'adversities',
        semaphore: true,
        valueFunction: scout => scoutSemaphoreDecision(scout, 'adversities')
      },*/
      {
        label: '',
        value: 'action'
      }
    ];
  
    readonly semaphoreFilters = buildSempahoresFilters<Scout>({
    //  general: (activity) => activity.last_scout ? (((activity.last_scout.general_state) || (activity.last_scout.general_state == 0)) ? activity.last_scout.general_state : 'no-data') : 'no-data',
      general: (scout) => (scout.general_state || (scout.general_state == 0)) ? scout.general_state : 'no-data',
      implementation_quality: (scout) => scoutSempahoreFilter(scout, 'implementation_quality'),
      weeds: (scout) => scoutSempahoreFilter(scout, 'weeds'),
      pests: (scout) => scoutSempahoreFilter(scout, 'pests'),
      diseases: (scout) => scoutSempahoreFilter(scout, 'diseases'),
      adversities: (scout) => scoutSempahoreFilter(scout, 'adversities')
    });
  
    readonly decisionFilters = buildDecisionFilters<Scout>({
      implementation_quality: (scout) => scoutDecisionFiler(scout, 'implementation_quality'),
      weeds: (scout) => scoutDecisionFiler(scout, 'weeds'),
      pests: (scout) => scoutDecisionFiler(scout, 'pests'),
      diseases: (scout) => scoutDecisionFiler(scout, 'diseases'),
      adversities: (scout) => scoutDecisionFiler(scout, 'adversities')
    });
  
    constructor(
      private route: ActivatedRoute,
      private actividadService: ActividadService,
    //  private $http: HttpClient,
      private router: Router,
      private dialog: MatDialog, private location: Location,
      private toastService: ToastService
    ) {
      moment.updateLocale('es', {});
      this.route.paramMap.subscribe(params => {
        this.activityId = params.get('activityId');
        this.retrieveScouts(true);
      });
    }

 /*   ngAfterViewInit() {
    //  this.dataSource.paginator = this.paginator;
      console.log('seteo en el ngAfterInit');
      this.Setear_Ancho_Tabla();
    }*/

    private adjustFilters() {
      if (this.f_matcher) {
        this.loading = true;
        var matcher = this.f_matcher;
        this.scouts = this.scoutsFull;
        this.scouts = this.scouts.filter(scout => matcher.match(scout));    
        this.loading = false;
      }      
    }

    getUsername = (email) => {
      return email.substring(0, email.lastIndexOf("@"));
   }

    private retrieveScouts($loading_page) {
  //    this.$http.get<ScoutsPage>(`/api/activities/${this.activityId}/scouts`)
    this.loading = true;

    this.actividadService.getScouts(this.activityId)
      .subscribe(scouts=> {     
        scouts.forEach((value: Scout) => {  
          value.scoutter = this.getUsername(value.scoutter);  
        });  
          this.scoutsFull = scouts;
          this.scouts = scouts;
          if ($loading_page) {
            let filtros = (localStorage.getItem('stFilter'));
            if (filtros) {
              let stFilter: any = JSON.parse(filtros);
              if (stFilter && stFilter.prevPage === 'st-scouts') {

                let stFilterSemaforo : any = JSON.parse(stFilter.advanceSemaphoreFilter);
                let stFilterDecision : any = JSON.parse(stFilter.advanceDecisionFilter);

                const myObjFilterSemaforo = {};
                const keys = Object.keys(stFilterSemaforo);
                for (let i = 0; i < keys.length; i++) {
                  const key = keys[i];
                  myObjFilterSemaforo[keys[i]] = new Set (stFilterSemaforo[key]);

                }
                this.semaphoreFilters.select = myObjFilterSemaforo;
              }
            }
          }
          else {
            this.adjustFilters();  
          }
           
          // Proponemos el título de la ventana
          if(history.state.data){
            this.titulo = 'Recorridas de '+history.state.data;
            sessionStorage.setItem('rl_'+this.activityId, history.state.data);
          }
          else {
            let titulo_guardado = sessionStorage.getItem('rl_'+this.activityId)
            if (titulo_guardado) {
              this.titulo = 'Recorridas de '+titulo_guardado;
            }
            else {
              this.titulo = 'Recorridas';
            }
          }       
          
          this.loading = false;
          setTimeout(() => {
            this.Setear_Ancho_Tabla(); 
          }, 300); 
          
        },
        error => {
          this.toastService.openSnackBar(`Error al leer recorridas: ${error}`, 'OK');
        });

  
    }

    goBack() {
      this.location.back();
    }

    navigateTo(row: Scout) {
      let filtroSemaforo = JSON.stringify(
        this.semaphoreFilters.select,
        (_key, value) => (value instanceof Set ? [...value] : value)
      );
      let filtroDecision = JSON.stringify(
        this.decisionFilters.select,
        (_key, value) => (value instanceof Set ? [...value] : value)
      );
      let stFilter: any = JSON.stringify({
        advanceSemaphoreFilter:filtroSemaforo/*this.semaphoreFilters.select*/,
        advanceDecisionFilter:filtroDecision/*this.decisionFilters.select*/,
        prevPage: 'st-scouts'
      });
     
      localStorage.setItem('stFilter', stFilter);
      this.router.navigate([`${row.id}/scout`], {relativeTo: this.route});
    }
    
    updateFilter(matcher: Matcher<Scout>) {
      this.f_matcher = matcher;
      this.adjustFilters();
    //  this.scouts = this.scoutsFull;
    //  this.scouts = this.scouts.filter(scout => matcher.match(scout));
    }
  
//    get scouts$() {
//      return this.scoutsSubject.asObservable();
 //   }
  
    deleteRow(row: Scout) {
      const data = {
        title: `Borrar Recorrida ${row.id}`,
        message: 'Está seguro? Al borrar la recorrida se perderan todos sus datos'
      };
      const dialogRef = this.dialog.open(ConfirmedDialogComponent, {
        disableClose: true,
        autoFocus: true,
        height: 'auto',
        width: 'auto',
        data
      });
  
      dialogRef.afterClosed().subscribe(
        result => {
          if (result) {
            this.actividadService.deleteScout(row.id).subscribe(res => {
                this.retrieveScouts(false)
              },
              error => {
                this.toastService.openSnackBar(`Error al eliminar la recorrida: ${error}`, 'OK');
              })
          }
        }
      );
    }
  
 /*    downloadReport() {
      window.open(`/api/activities/${this.activityId}/report.csv`, 'Download');
    } */

    async downloadRow(scout: Scout){
      if (!scout) {
        return;
      }
        const scouts = this.actividadService.getScoutsCSV(scout.activity_id,scout.id).subscribe((scouts) => {
        
          var options = {
            unwindArrays: false,
            expandArrayObjects: true,
            excelBOM: true,
            emptyFieldValue: '',
            delimiter: { field : ';', eol: '\r\n' }
          }
    
          let sct = scouts.map(scout => scoutConverter.convert(scout));
          converter.json2csv(sct, function (err, csv) {
            if (err) {
                throw err;
            }
        
          let blob = new Blob(['\ufeff' + csv], {type: 'text/csv;charset=utf-8;'});
          let dwldLink = document.createElement("a");
          let url = URL.createObjectURL(blob);
          let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 ;
          navigator.userAgent.indexOf('Chrome') == -1;
          
          //if Safari open in new window to save file with random filename.
          if (isSafariBrowser) { 
              dwldLink.setAttribute("target", "_blank");
          }
          dwldLink.setAttribute("href", url);
          dwldLink.setAttribute("download", "Recorrida " + scout.id + "_Actividad "+ scout.activity_id +  ".csv");
          dwldLink.style.visibility = "hidden";
          document.body.appendChild(dwldLink);
          dwldLink.click();
          document.body.removeChild(dwldLink);
        }, options);
      },
      error => {
        this.toastService.openSnackBar(`Error al obtener recorridas para descargar: ${error}`, 'OK');
      });
    }

    private openModal(semaforo?: Semaforos): MatDialogRef<SemaforoFormComponent, any> {
      return this.dialog.open(SemaforoFormComponent, {
        width: '500px',
        disableClose: true,
        autoFocus: true,
        data: semaforo || {}
      });
    }
    
    editRow(row: any) {
   
      var semaforos: Semaforos = {
        scout_id : row.id,
        general : {semaphore: row.general_state},
        implantacion : {semaphore: row.implementation_quality.semaphore, decision: row.implementation_quality.decision},
        maleza :  {semaphore: row.weeds.semaphore, decision: row.weeds.decision},
        enfermedad : {semaphore: row.diseases.semaphore, decision: row.diseases.decision},
        plaga : {semaphore: row.pests.semaphore, decision: row.pests.decision},
        adversidad : {semaphore: row.adversities.semaphore, decision: row.adversities.decision}
      };
      const dialogRef = this.openModal(semaforos);
      dialogRef.afterClosed().subscribe(
        (newSemaforo: any) => {
          if (newSemaforo) {
            this.actividadService.updateSemaforos(newSemaforo).subscribe( ()  => {
                this.retrieveScouts(false);      
              },
              error => {
                this.toastService.openSnackBar(`Error al actualizar semáforos/decisiones: ${error}`, 'OK');
              });
          }
        }
      );
    }

/*    private openNotes(row: Scout): MatDialogRef<NotesFormComponent, any> {
      let operacion = ((this.canAddRecord == 'S')?'E':'C');
      let titulo = 'Observaciones recorrida '+row.id;
      console.log(row);
      return this.dialog.open(NotesFormComponent, {
        width: '500px',
        disableClose: true,
        autoFocus: true,
        data: {'scout_id' : row.id, 'operacion' : operacion, 'titulo' : titulo}
      });
    }*/

    private openMedia(row: Scout) {
      let operacion = ((this.canAddRecord == 'S')?'E':'C');
      let titulo = 'Observaciones recorrida '+row.id;
  
      const dialogRef = this.dialog.open(MediaFormComponent, {
        id: 'dialog_media',
        width: '90%',
        height: '90%',
        disableClose: true,
        autoFocus: true,
        panelClass: ['panel_oscuro'], 
        data: {'scout_id' : row.id, 'operacion' : operacion, 'titulo' : titulo, 'observations': row.observations}
      });
      dialogRef.afterClosed().subscribe(
        (notes: string) => {
          if (notes != null) {
            row.observations = notes;
          }
          
        }
      );
    }



    Setear_Ancho_Tabla(){
      const table_wrapper_id = document.getElementById('table_wrapper');
      let ancho = 350;
    console.log('table_wrapper_id',table_wrapper_id);
      if (table_wrapper_id) {
        if (this.showFilters){
          if (this.filtroSemaforoAbierto){ ancho = ancho - 60;}
          if (this.filtroDecisionAbierto){ ancho = ancho - 60;}
          table_wrapper_id.style.height = ancho+'px';
        }
        else {
          table_wrapper_id.style.height = '480px';
        } 
      }   
    }
    toggleDiv() {
      this.showFilters = !this.showFilters;
      if (this.showFilters) {
        this.showFilterMode = 'H';
      }
      else {
        this.showFilterMode = 'S';
      }
       
      this.Setear_Ancho_Tabla();
      
    }
    onOpenedSemaforo(){
      this.filtroSemaforoAbierto = true;
      this.Setear_Ancho_Tabla();
    }
    onClosedSemaforo(){
      this.filtroSemaforoAbierto = false;
      this.Setear_Ancho_Tabla();
    }
    onOpenedDecision(){
      this.filtroDecisionAbierto = true;
      this.Setear_Ancho_Tabla();
    }
    onClosedDecision(){
      this.filtroDecisionAbierto = false;
      this.Setear_Ancho_Tabla();
    }    
  }
  