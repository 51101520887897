import { Component, OnInit } from '@angular/core';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../shared/table/table.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Catalog, CATALOGS_NAME, catalogo_array } from '../modelo/catalogo';
import { CatalogoService } from '../_services/catalogo.service';
import { ToastService } from '../shared/toast/toast.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-catalogos',
  templateUrl: './catalogos.component.html',
  styleUrls: ['./catalogos.component.scss'],
})

export class CatalogosComponent implements OnInit {
  catalogs: Catalog[];
  loading = false;
 // sus : any;

  readonly tableColumns: ITableHeader<Catalog>[] = [
    {
      label: 'Nombre',
      value: 'name',
    /*  submenu: (catalogo: Catalog) => (catalogo.id == 'input' ? ['hola','chau'] : []),*/
    }/*,
    {
      label: 'ID',
      value: 'id'
    }*/
  ];
 
  constructor(private catalogoService: CatalogoService, private location: Location, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.retrieveData();
/*    this.$http.get<CatalogsResponseDTO>('/api/catalogs', {params: {names: 'true'}})
      .pipe(
        map(response => response.catalogs),
        catchError(e => this.getError(e)))
      .subscribe(catalogs => {
        this.catalogs = catalogs.map(id => ({name: CATALOGS_NAME[id], id}));
      });*/

  }

  retrieveData(){
    this.loading = true;
    this.catalogs = catalogo_array;

/*    this.sus = this.catalogoService.getCatalogos().subscribe((catalogos) => {
      this.catalogs = catalogos.map(id => ({name: CATALOGS_NAME[id], id}));
      this.loading = false;
    },
    error => {
      this.toastService.openSnackBar(`Error al leer catalogos: ${error}`, 'OK');
      this.loading = false;
    });*/
  }

  goBack() {
    this.location.back();
  }

  openCatalog(catalog: Catalog) {
    this.router.navigate([`${catalog.id}`], {relativeTo: this.route});
  }
}
