
<ng-container *ngIf="loading">
    <!-- <ng-container *ngIf="!scout "> -->
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>
<!--   
scout.observations
scout_adversities.observations
scout_ambient.observations
scout_crop_yield.observations
scout_diseases.observations
scout_implementation_quality.observations
scout_harvest_quality.observations
scout_pests.observations
scout_weeds.observations
scout_sowing.observations
 -->
  <div class="container" *ngIf="!loading">
    <h2 class="title">{{titulo}}</h2>
    <form class="example-form">
  
        <div *ngFor="let item of valores_notas; let i = index">
            <mat-form-field>
              <mat-label>{{valores_notas[i]?.rotulo}}</mat-label>
                <textarea matInput [ngModel]="valores_notas[i]?.value" [name]="valores_notas[i]?.tabla"
                   (change)="onChange($event.target.value, i)">
                   {{valores_notas[i]?.value}}
                </textarea>
              </mat-form-field> 
        </div>



<!--       <mat-form-field class="example-full-width">
        <input matInput placeholder="Observaciones generales" formControlName="name" required>
      </mat-form-field>
  
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Superficie (ha)" autocomplete="off" formControlName="area" type="number" >
      </mat-form-field>
  
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Poligono url" isdisabled formControlName="polygon">
      </mat-form-field> -->
  
      <div *ngIf="operacion == 'C'" class="buttons">
        <button mat-stroked-button color="primary" (click)="close()">Cerrar</button>
      </div>
  
      <div *ngIf="operacion != 'C'" class="buttons">
        <button mat-stroked-button color="primary" (click)="close()">Cancelar</button>
        <button mat-raised-button color="primary" class="accept-button" (click)="save()">Guardar</button>
      </div>
  
    </form>
  </div>
  
