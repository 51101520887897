//import { Cliente } from "./cliente";

export class Campania {
        id: number;
        name: string;
        from_date?: Date;
        to_date?: Date;
        creation_date?: Date;
        down_date?: Date;
        active?: string;
      }