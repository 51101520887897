import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Usuario } from '../modelo/usuario';
import { UserService } from '../_services/user.service';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../shared/table/table.component';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsuarioFormComponent } from './forms/usuario-form/usuario-form.component';
import { ConfirmedDialogComponent } from '../shared/dialogs/confirmed-dialog/confirmed-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../shared/toast/toast.service';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {
    loading = false;
    sus : any;
    isLoggedIn = false;

    @ViewChild(PuntalTableComponent,{static:true}) table: PuntalTableComponent<any>;
  
    public usuarios: Usuario[] = []; 
    
      readonly header: ITableHeader<Usuario>[] = [
      {
        label: 'id',
        value: 'id',
      },
      {
        label: 'name',
        value: 'name',
      },
      {
        label: 'email',
        value: 'email',
      },
      {
        label: 'type',
        value: 'type',
      },
    ];
  
    readonly tableOptions: ITableOptions<Usuario> = {
      canEdit: (usuario) => (usuario.editable == 1),
      canDelete: (usuario) => (usuario.editable == 1),
      canShowDetail: (usuario) => (usuario.editable == 1)
    };
  
  
    constructor(private usuarioService: UserService, private dialog: MatDialog, private location: Location ,
    private router: Router,
    private toastService: ToastService,     
    private route: ActivatedRoute,
    private authService: AuthService) { }
  
  
    ngOnInit(): void {
      this.loading = true;
      this.sus = this.usuarioService.getUsuarios().subscribe((usuarios) => {this.usuarios = usuarios;this.loading = false;});
      
    }
  
    
    editRow(row: Usuario) {
    const dialogRef = this.openModal(row);
      dialogRef.afterClosed().subscribe(
        (newUsuario: Usuario) => {
          if (newUsuario) {
            this.usuarioService.updateUsuario(newUsuario).subscribe();
          }
        }
      )
    }

    generate_random_passw(): string {
      var chars = "0123456789abcdefghijklmnopqrstuvwxyz!*ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var passwordLength = 5;
      var password = "";
      for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber +1);
     }
     return password;
    }

    reset_password(row: Usuario) {
      const data = {
        title: `ATENCION: cambio de clave de acceso`,
        message: `Se modificará la clave de ${row.name}`,
        button_text : 'Confirmar'
      };
      const dialogRef = this.dialog.open(ConfirmedDialogComponent, {
        disableClose: false,
        autoFocus: true,
        height: 'auto',
        width: 'auto',
        data
      });
      // MARIANA
/*      dialogRef.afterClosed()
        .pipe(filter(result => !!result))
        .subscribe(() => {
          this.usuarioService.reset_password(row).subscribe(res => {
              this.toastService.openSnackBar(`La nueva clave para acceder es ${res}`, 'OK', 300000);
            });
          }
        );*/
        dialogRef.afterClosed().subscribe((result) => {
          if (result === true) {
            row.password = this.generate_random_passw();
            this.usuarioService.reset_password(row).subscribe(res => {
              this.toastService.openSnackBar(`La nueva clave para acceder es ${row.password}`, 'OK', 300000);
            });
          }
      });
      }

    deleteRow(row: Usuario) {
      let own_userId = this.authService.getId();
      if (row.id == own_userId) {
        this.toastService.openSnackBar(`El usuario no puede eliminarse a sí mismo`, 'OK', 300000);
        return;
      }
      const data = {
        title: `ATENCIÓN: Borrar usuario '${row.name}'`,
        message: 'Está seguro? Al borrar el usuario, este no podrá ingresar más. Los datos actualizados por el usuario no se perderán.',
        validation: row.name
      };
      const dialogRef = this.dialog.open(ConfirmedDialogComponent, {
        disableClose: true,
        autoFocus: true,
        height: 'auto',
        width: 'auto',
        data
      });
      const id_a_borrar = row.id;
      dialogRef.afterClosed().subscribe(
        result => {
          if (result) {
           this.usuarioService.deleteUsuario(id_a_borrar).subscribe(()  => {
              this.usuarioService.getUsuarios().subscribe((usuarios) => this.usuarios = usuarios);
            });
          }
        }
      );
    }
  
    addRow() {
      const dialogRef = this.openModal();
      dialogRef.afterClosed().subscribe(
        (newUsuario: Usuario) => {
          if (newUsuario) {
            this.usuarioService.addUsuario(newUsuario).subscribe(
              (usuario)  => {
              this.usuarioService.getUsuarios().subscribe((usuarios) => this.usuarios = usuarios);
            }
          )
        }
      }
      )
    }


    navigateTo(row: Usuario) {
      if (row.type == 'PUNTAL_SUPER') {
        this.toastService.openSnackBar(`El usuario es SUPERUSUARIO de PUNTAL, por lo tanto no requiere definir permisos`, 'OK', 300000);
      } else {
        this.router.navigate([`/permisos/${row.id}`], {state: {data:row.name,editable:row.editable}, relativeTo: this.route});
      }
    }
  
  
    goBack() {
      this.location.back();
    }

    private openModal(usuario?: Usuario): MatDialogRef<UsuarioFormComponent, any> {
      return this.dialog.open(UsuarioFormComponent, {
        width: '500px',
        disableClose: true,
        autoFocus: true,
        data: usuario || {}
      });
    }

    get canAdd() {
    //  return this.authService.isSuperUser();
     return true;
    }
  
   /*  openPermission(usuario: Usuario) {
      this.dialog.open(WorkspaceViewComponent, {
        width: '500px',
        disableClose: true,
        autoFocus: true,
        data: {
          ref: client,
          type: WorkspaceElementType.CLIENT
        }
      });
    }  */
  
    ngOndestroy() {
      this.sus.unsubscribe();
    }
  
    isAuthenticated() {
      return true;
    }
  }
  