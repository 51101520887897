import {Scout} from '../modelo/scout';

function isNumber(value: string | number): boolean
{
   return ((value != null) &&
           (value !== '') &&
           !isNaN(Number(value.toString())));
}

export function getScoutSemaphore(scout: Scout, field: string) {
  if ((scout) && (scout.id == null)) { return 'no-data-fill';}
  const semaphore = (scout[field] || {}).semaphore;
  if (semaphore === null || semaphore === undefined) {
    return 's0';
  }
  return `s${semaphore}`;
}

export function getScoutSemaphoreValue(value: number) {
  const semaphore = value.toString();
  if (semaphore === null || semaphore === undefined) {
    return 's0';
  }
  return `s${semaphore}`;
}

export function getScoutDecision(scout: Scout, field: string) {
  const decision = (scout[field] || {}).decision;
  return (!!decision) ? decision.replace(/\n/g, '') : 'Sin decisión';
}

export function getScoutDecisionValue(value: string) {
  const decision = value;
  return (!!decision) ? decision.replace(/\n/g, '') : 'Sin decisión';
}


export function getScoutGeneral(scout?: Scout) {
  
  const hasSemaphore = scout && isNumber(scout.general_state);
  //console.log('hasSemaphore',hasSemaphore,scout,'scout.general_state',scout?.general_state);
  return {
    semaphore: hasSemaphore ? `s${scout.general_state}g-fill` : 'no-data-fill',
    decision: hasSemaphore ? 'condicion general' : 'sin cond. general'
  };
}

export function getScoutGeneralSemaphore(scout?: Scout) {
  const hasSemaphore = scout && isNumber(scout.general_state);
  return (hasSemaphore ? `s${scout.general_state}g-fill` : 'no-data-fill');
    decision: hasSemaphore ? 'condicion general' : 'sin cond. general'
}
export function getScoutGeneralDecision(scout?: Scout) {
  const hasSemaphore = scout && isNumber(scout.general_state);
  return (hasSemaphore ? 'condicion general' : 'sin cond. general');
}
export function scoutSemaphoreDecision(scout: Scout, field: string) {
  return {
    semaphore: getScoutSemaphore(scout, field),
    decision: getScoutDecision(scout, field)
  };
}

export function scoutDecisionFiler(scout: Scout, field: string) {
  if (!scout) {
    return 'sin datos';
  }
  return scout[field] && scout[field].decision ? scout[field].decision.toLowerCase() : 'sin datos';
}

export function scoutSempahoreFilter(scout: Scout, field: string) {
//  console.log('scoutSempahoreFilter',scout,field);
  if (!scout) {
 //   console.log('Devuelve NODATA');
    return 'no-data';
  }
//  console.log('DEVUELVE scout[field]',scout[field],'y isNumber(scout[field].semaphore)',isNumber(scout[field].semaphore))
  return scout[field] && isNumber(scout[field].semaphore) ? scout[field].semaphore : 'no-data';
}
