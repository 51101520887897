import { Component, OnInit, ViewChild } from '@angular/core';
import { Scout, ScoutsPage } from '../../../modelo/scout';
import { ActividadService } from '../../../_services/actividad.service';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../../../shared/table/table.component';
import { Location } from '@angular/common';
import { MatAccordion } from '@angular/material/expansion';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import {
  getScoutDecision,
  getScoutGeneral,
  scoutDecisionFiler,
  scoutSemaphoreDecision,
  scoutSempahoreFilter,
  getScoutSemaphore,
  getScoutGeneralSemaphore,
  getScoutGeneralDecision,
  getScoutSemaphoreValue,
  getScoutDecisionValue
} from '../../../_helpers/semaphore.helper';
import { IActivity, IActivityFull } from 'src/app/modelo/actividad';
import { ToastService } from '../../../shared/toast/toast.service';


@Component({
  selector: 'app-scout',
  templateUrl: './scout.component.html',
  styleUrls: ['./scout.component.scss']
})

export class ScoutComponent implements OnInit {
  loading: Boolean = true;
  private scoutId: number;
  private activityId: number;
  activity: IActivityFull;
  scout: Scout;    
  scouts: Scout[] = [];
  panelOpenState = false;
  //activitySubject = new BehaviorSubject<IActivity>(null);
  accordionList:any;
  implementation_quality_semaphore: string;
  weeds_semaphore: string;
  pests_semaphore: string;
  diseases_semaphore: string;
  adversities_semaphore: string;
  general_semaphore: string;
  weeds_decision: string;
  pests_decision: string;
  diseases_decision: string;
  adversities_decision: string;
  general_decision: string;  
  replant_decision: string;
  sowing_semaphore: string;
  harvest_quality_semaphore: string;
  scoutDate: any;
  total = {
    'area_sowed' : 0,
    'area_resowed' : 0,
    'harvested_tn' : 0,
    'harvested_ha' : 0,
    'real_sowed' : 0,
    'real_harvested' : 0
  }
  @ViewChild('accordion',{static:true}) Accordion: MatAccordion

  readonly scoutColumns: ITableHeader<Scout>[] = [
        {
          label: 'Recorredor',
          value: 'scoutter',
//          valueFunction: scout => Array.isArray(scout.users) && scout.users.length > 0 ? scout.users[0].email : '-'
        },
        {
          label: 'Condicion general',
          value: 'state',
          semaphore: true,
          valueFunction: scout => getScoutGeneral(scout)
        },
        {
          label: 'Geoposicion',
          value: 'weeds',
          valueFunction: scout => !!scout.geo_position ? `${scout.geo_position.latitude}, ${scout.geo_position.longitude}` : 'No definido'
        },
        {
          label: 'Cobertura de suelo',
          value: 'ground_cover'
        }
      ]; 
    

      
  constructor(private route: ActivatedRoute,private actividadService: ActividadService,
    private router: Router, private location: Location,
    private toastService: ToastService) {
    moment.updateLocale('es', {});
    this.route.paramMap.subscribe(params => {
      this.scoutId = Number(params.get('scoutId'));
      this.retrieveScout();
      //this.loading = false;
    });
  }

  ngOnInit(): void {
  }

  geoposicion(scout) {
    if (scout.geo_position) {
      return `${scout.geo_position.latitude}, ${scout.geo_position.longitude}`;
    }
    return 'No definido';
  }

  private retrieveScout() {
    this.loading = true;
      this.actividadService.getScout(this.scoutId)
        .subscribe(scout=> {
            this.scout = scout[0];
            this.scoutDate = this.prettydate(this.scout.date_time);
            const scoutDate = this.scout.date_time;
            const date = new Date(scoutDate);
            this.scoutDate = moment(date).format("DD/MM/YYYY");// ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} (${relative});
          //  this.implementation_quality_semaphore = getScoutSemaphore(this.scout,'implementation_quality');
            this.implementation_quality_semaphore = getScoutSemaphoreValue(this.scout.implementation_quality.quality);
            this.replant_decision = getScoutDecisionValue(this.scout.implementation_quality.replant_decision);
            this.sowing_semaphore = getScoutSemaphoreValue(this.scout.sowing.quality);
          //  console.log('implementation_quality_semaphore',this.implementation_quality_semaphore);
            this.weeds_semaphore = getScoutSemaphore(this.scout,'weeds');
            this.pests_semaphore = getScoutSemaphore(this.scout,'pests');
            this.harvest_quality_semaphore= getScoutSemaphore(this.scout,'harvest_quality');
            this.diseases_semaphore = getScoutSemaphore(this.scout,'diseases');
            this.adversities_semaphore = getScoutSemaphore(this.scout,'adversities');    
            this.general_semaphore = getScoutGeneralSemaphore(this.scout);
            this.weeds_decision = getScoutDecision(this.scout,'weeds');
            this.pests_decision = getScoutDecision(this.scout,'pests');
            this.diseases_decision = getScoutDecision(this.scout,'diseases');
            this.adversities_decision = getScoutDecision(this.scout,'adversities'); 
            
            this.general_decision = getScoutGeneralDecision(this.scout);             
            this.activityId = this.scout.activity_id;
            this.scouts.push(this.scout);   
            this.retrieveActivity(this.activityId) ; 
          },
          error => {
            this.toastService.openSnackBar(`Error al leer recorrida: ${error}`, 'OK');
            this.loading = false;
          });
      }

      async fetchData(scout_id) {
        try {
        //   let fetchDiseasesPromise = new Promise ((resolve,reject)  => { 
        //    this.retrieveDiseases(scout_id).then(res => { resolve();},
        //                            err => { reject(err);}
        //    )
        //  });
        //   let fetchPestsPromise = new Promise ((resolve,reject)  => { 
        //    this.retrievePests(scout_id).then(res => { resolve();},
        //                            err => { reject(err);}
        //    )
        //  });
        //   let fetchAdversitiesPromise = new Promise ((resolve,reject)  => { 
        //    this.retrieveAdversities(scout_id).then(res => { resolve();},
        //                            err => { reject(err);}
        //    )
        //  });
        //   let fetchWeedsPromise = new Promise ((resolve,reject)  => { 
        //    this.retrieveWeeds(scout_id).then(res => { resolve();},
        //                            err => { reject(err);}
        //    )
        //  });     
        // let fetchSowingPromise = new Promise ((resolve,reject)  => { 
        //    this.retrieveSowing(scout_id).then(res => { resolve();},
        //                            err => { reject(err);}
        //    )
        //  });                      
          const fetchDiseasesPromise = this.retrieveDiseases(scout_id);
          const fetchPestsPromise = this.retrievePests(scout_id);
          const fetchAdversitiesPromise = this.retrieveAdversities(scout_id);
          const fetchWeedsPromise = this.retrieveWeeds(scout_id);
          const fetchSowingPromise = this.retrieveSowing(scout_id);
          await Promise.all([fetchDiseasesPromise, fetchPestsPromise, fetchAdversitiesPromise, fetchWeedsPromise, fetchSowingPromise]);  
        } catch (error) {
          this.toastService.openSnackBar(`Error al obtener datos de la recorrida: ${error}`, 'OK');
          this.loading = false;
        } finally {
          this.loading = false;

        }
        return;
      }

      retrieveActivity(actividadId){
        this.actividadService.getActividad(actividadId).subscribe(actividad => {
          if (actividad) {
            this.activity = actividad[0];
            this.actividadService.getSowingSummary(this.scout.id).subscribe(summary => {
              this.total.area_sowed   = summary.area_sowed; 
              this.total.area_resowed = summary.area_resowed; 
              this.total.harvested_tn = summary.harvested_tn; 
              this.total.harvested_ha = summary.harvested_ha; 
              this.total.real_sowed   = summary.real_sowed; 
              this.total.real_harvested = summary.real_harvested; 
           //   console.log(this.total);
              this.fetchData(this.scout.id);
           /*   if (this.scout.diseases) {this.retrieveDiseases(this.scout.id)}; 
              if (this.scout.pests) {this.retrievePests(this.scout.id)};          
              if (this.scout.adversities) {this.retrieveAdversities(this.scout.id)};          
              if (this.scout.weeds) {this.retrieveWeeds(this.scout.id)}; 
              if (this.scout.sowing) {this.retrieveSowing(actividadId)}; 
              this.loading = false;*/
            },
            error => {
              this.toastService.openSnackBar(`Error al obtener resumen de siembra: ${error}`, 'OK');
              this.loading = false;
            });
          }
        },
        error => {
          this.toastService.openSnackBar(`Error al obtener actividad: ${error}`, 'OK');
          this.loading = false;
        });
      }

      retrieveSowing(actividadId){
        this.actividadService.getActivityFertilizations(actividadId).subscribe(ferti => {
          if (ferti) {
            this.scout.sowing.fertilizations = ferti;
          }
        },
        error => {
          this.toastService.openSnackBar(`Error al leer datos de fertilizaciones de la actividad: ${error}`, 'OK');
        });
      }

      retrieveDiseases(scoutId){
        if (! this.scout.diseases) { return; }
        this.actividadService.getDiseases(scoutId).subscribe(enfermedades => {
          if (enfermedades) {
            this.scout.diseases.diseases = enfermedades;
          }
        },
        error => {
          this.toastService.openSnackBar(`Error al leer datos de enfermedades de la recorrida: ${error}`, 'OK');
        });
      }
      retrievePests(scoutId){
        if (! this.scout.pests) { return; }
        this.actividadService.getPests(scoutId).subscribe(plagas => {
          if (plagas) {
            this.scout.pests.pests = plagas;
          }
        },
        error => {
          this.toastService.openSnackBar(`Error al leer datos de plagas de la recorrida: ${error}`, 'OK');
        });
      }
      retrieveAdversities(scoutId){
        if (! this.scout.adversities) { return; }
        this.actividadService.getAdversities(scoutId).subscribe(adversidades => {
          if (adversidades) {
            this.scout.adversities.adversities = adversidades;
          }
        },
        error => {
          this.toastService.openSnackBar(`Error al leer datos de adversidades de la recorrida: ${error}`, 'OK');
        });
      }
      retrieveWeeds(scoutId){
        if (! this.scout.weeds) { return; }
        this.actividadService.getWeeds(scoutId).subscribe(malezas => {
          if (malezas) {
            this.scout.weeds.weeds = malezas;
          }
        },
        error => {
          this.toastService.openSnackBar(`Error al leer datos de malezas de la recorrida: ${error}`, 'OK');
        });
      }

 
      semaphoreClass(value) {
        return value === 'no-data' ? 'no-data' : `${value}`;
      }
      semaphoreGeneralClass(value) {
        return value === 'no-data' ? 'no-data' : `${value}`;
      }      

      LevelClass(value) {
        return value === 'no-data' ? 'no-data' : `s${value}`;
      }

      Level(value) {
        if ((value == null)/* || (value == 0)*/) return '';
        switch (value) {
          case 0: return 'Bajo'; break;
          case 1: return 'Medio'; break;
          case 2: return 'Alto'; break;
          default: return value;
        }
      }
      

      LevelClass2(value) {
        return value === 'no-data' ? 'no-data' : `s${value}`;
      }

      Level2(value) {
        if ((value == null) /*|| (value == 0)*/) return '';
        switch (value) {
          case 0: return 'Incipiente'; break;
          case 1: return 'Bajo'; break;
          case 2: return 'Medio'; break;
          case 3: return 'Alto'; break;
          default: return value;
        }
      }

      Level3(value) {
        if ((value == null) /*|| (value == 0)*/) return '';
        switch (value) {
          case 0: return 'Nulo'; break;
          case 1: return 'Bajo'; break;
          case 2: return 'Medio'; break;
          case 3: return 'Alto'; break;
          default: return value;
        }
      }
      
      Level4(value) {
        if ((value == null) /*|| (value == 0)*/) return '';
        switch (value) {
          case 0: return 'Buena'; break;
          case 1: return 'Regular'; break;
          case 2: return 'Mala'; break;
          default: return value;
        }
      }

      Level5(value) {
        if ((value == null) /*|| (value == 0)*/) return '';
        switch (value) {
          case 0: return 'Húmedo'; break;
          case 1: return 'Moderadamente Húmedo'; break;
          case 2: return 'Seco'; break;
          default: return value;
        }
      }

      Level6(value) {
        if ((value == null) /*|| (value == 0)*/) return '';
        switch (value) {
          case 10: return '0-20%'; break;
          case 30: return '20-40%'; break;
          case 50: return '40-60%'; break;
          case 70: return '60-80%'; break;
          case 90: return '80-100%'; break;          
          default: return value;
        }
      }

      prettydate(p_datetime) {
        if ((!p_datetime) || (p_datetime == null)) {return '';}
        const scoutDate = p_datetime; 
        const date = new Date(scoutDate);
        return moment(date).format("DD/MM/YYYY");// ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} (${relative});
      }

      goBack() {
        this.location.back();
      }
        

}
