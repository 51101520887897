import {Component, OnInit, Inject, Input} from '@angular/core';

//import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { AuthService } from '../../../../_services/auth.service';
import { Semaforo, Semaforos } from '../../../../modelo/scout';

@Component({
  selector: 'app-semaforo-form',
  templateUrl: './semaforo-form.component.html',
  styleUrls: ['./semaforo-form.component.scss']
})
export class SemaforoFormComponent implements OnInit {

//  public matcher: ErrorStateMatcher;
 // public semaforoFormControl: FormGroup;
 // readonly billingGetter = {get: () => null};
  public titulo : string;
  public loading = true;
  public valores_semaforos = [0,1,2,3,4];
  public decision_List = ['','No intervenir', 'Revisar en \n 3-7 días', 'Intervenir'];

  constructor(@Inject(MAT_DIALOG_DATA) public semaforos: Semaforos,
    private dialogRef: MatDialogRef<SemaforoFormComponent>,
   // private formBuilder: FormBuilder,
    private authService: AuthService) { }

  ngOnInit(): void {
    if ((!this.semaforos) || (Object.keys(this.semaforos).length === 0)) {
      this.close();
    }
   // console.log(this.semaforos);
  //  this.semaforoFormControl = this.createFormControl();
    this.loading = false;  
    //console.log(this.semaforos);
 //   this.matcher = new ShowOnDirtyErrorStateMatcher();  
  }

 /* private createFormControl(): FormGroup {
    console.log('this.semaforos.general.semaphore: ',this.semaforos.general.semaphore);
    return this.formBuilder.group({
      s_general: this.semaforos.general.semaphore,
      s_implantacion: this.semaforos.implantacion.semaphore,
      s_maleza: this.semaforos.maleza.semaphore,
      s_enfermedad: this.semaforos.enfermedad.semaphore,
      s_plaga: this.semaforos.plaga.semaphore,
      s_adversidad: this.semaforos.adversidad.semaphore
    });
  }*/
  
  semaphoreClass(item,value) {
    let clase = '';
    switch (item) {
      case 'enfermedad' : 
        if (this.semaforos.enfermedad.semaphore == value) {clase = 'borde_grueso';}
        break;
      case 'plaga' : 
        if (this.semaforos.plaga.semaphore == value) {clase = 'borde_grueso';}
        break;
      case 'maleza' : 
        if (this.semaforos.maleza.semaphore == value) {clase = 'borde_grueso';}
        break;
      case 'implantacion' : 
        if (this.semaforos.implantacion.semaphore == value) {clase = 'borde_grueso';}
        break;    
      case 'adversidad' : 
        if (this.semaforos.adversidad.semaphore == value) {clase = 'borde_grueso';}
        break;                        
    }
    return value === 'no-data' ? clase + ' no-data' : clase +` s${value}-fill`;
  }

  semaphoreGeneralClass(value) {
    let clase = '';
    if (this.semaforos.general.semaphore == value) {clase = 'borde_grueso';}
    return value === 'no-data' ? clase + ' no-data' : clase + ` s${value}g-fill`;
  }      

  
  setear_semaforo_plaga(valor) {
    this.semaforos.plaga.semaphore = valor;
  }
  setear_semaforo_enfermedad(valor) {
    this.semaforos.enfermedad.semaphore = valor;
  }
  setear_semaforo_implantacion(valor) {
    this.semaforos.implantacion.semaphore = valor;
  }
  setear_semaforo_maleza(valor) {
    this.semaforos.maleza.semaphore = valor;
  }
  setear_semaforo_adversidad(valor) {
    this.semaforos.adversidad.semaphore = valor;
  }
  setear_semaforo_general(valor) {
    this.semaforos.general.semaphore = valor;
  }

  onimplantacion_decisionSelect(valor){
    if (valor == '') { valor = null; }
    this.semaforos.implantacion.decision = valor;
  }
  onadversidad_decisionSelect(valor){
    if (valor == '') { valor = null; }
    this.semaforos.adversidad.decision = valor;
  }
  onenfermedad_decisionSelect(valor){
    if (valor == '') { valor = null; }
    this.semaforos.enfermedad.decision = valor;
  }
  onmaleza_decisionSelect(valor){
    if (valor == '') { valor = null; }
    this.semaforos.maleza.decision = valor;
  }
  onplaga_decisionSelect(valor){
    if (valor == '') { valor = null; }
    this.semaforos.plaga.decision = valor;
  }


  save() {
    this.dialogRef.close(this.semaforos);
  
  }

  close() {
      this.dialogRef.close(null);
  }

 /* public getNameErrorMessage(): string {
    if (this.semaforoFormControl.get('name')) {
      return (this.semaforoFormControl.get('name'))?.hasError('required') ? 'Debe especificar un nombre' : '';
    }
    return '';
  }

  getPolygonErrorMessage() {
    return '';
  }

  getMapErrorMessage() {
    return '';
  }

  
  private hasError(field: string, errorCode: string) {
    return this.semaforoFormControl.hasError(field, errorCode);
  }

  get title() {
    return this.titulo; //'Agregar Campo';
  }
*/
}
