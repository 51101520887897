import {Component, OnInit, Inject, Input} from '@angular/core';

import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { AuthService } from '../../../_services/auth.service';

/* Campos */
//import { IClient } from '~core/models/campos-section.models';
import { Campo } from '../../../modelo/campo';


@Component({
  selector: 'app-campo-form',
  templateUrl: './campo-form.component.html',
  styleUrls: ['./campo-form.component.scss']
})
export class CampoFormComponent implements OnInit {

  public matcher: ErrorStateMatcher;
  public campoFormControl: FormGroup;
 // readonly billingGetter = {get: () => null};
  public titulo : string;
  public operacion: string;
  public loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public campo: Campo /*IClient*/,
    private dialogRef: MatDialogRef<CampoFormComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService
    ) { }

  ngOnInit() {
    
    if ((!this.campo) || (Object.keys(this.campo).length === 0)) {
      this.campo = {
        id: 0,
        name: '',
        polygon: '',
        map: '',
      } as Campo /*IClient*/;
      this.titulo = 'Agregar Campo';  
      this.operacion = 'A'; 
      this.campoFormControl = this.createFormControl();
      this.loading = false;
    }
    else {
      this.authService.CanEdit('farm',this.campo.id) 
      .then((puede_editar) => { 
        if (puede_editar) {
          this.operacion = 'E'; 
          this.titulo = 'Editar Campo';
        } 
        else {
          this.titulo = 'Consultar Campo';
          this.operacion = 'C'; 
        }           
        this.campoFormControl = this.createFormControl();
        this.loading = false;
      });

    }    
    this.matcher = new ShowOnDirtyErrorStateMatcher();
  }
  private createFormControl(): FormGroup {
    return this.formBuilder.group({
      name: [{value:this.campo.name,disabled: (this.operacion === 'C')}, [Validators.required, Validators.minLength(3)]],
      polygon: [{value:this.campo.polygon || '',disabled: (this.operacion === 'C')}],
      map: [{value:this.campo.map || '',disabled: (this.operacion === 'C')}]
    });
  }

  save() {
    if (this.campoFormControl.invalid) { return; }
    const campoData = Object.assign(this.campo, this.campoFormControl.value);
//    campoData.billing = this.billingGetter.get();
    this.dialogRef.close(campoData);
  }

  close() {
      this.dialogRef.close(null);
  }

  public getNameErrorMessage(): string {
    if (this.campoFormControl.get('name')) {
      return (this.campoFormControl.get('name'))?.hasError('required') ? 'Debe especificar un nombre' : '';
    }
    return '';
  }

  getPolygonErrorMessage() {
    return '';
  }

  getMapErrorMessage() {
    return '';
  }

  
  private hasError(field: string, errorCode: string) {
    return this.campoFormControl.hasError(field, errorCode);
  }

  get title() {
    return this.titulo; //'Agregar Campo';
  }

}
