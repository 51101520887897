import {Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewEncapsulation} from '@angular/core';
//import {isFunction, isObject, isString} from 'util';
import { MatTableDataSource } from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ViewChild} from '@angular/core';
import { ElementRef } from '@angular/core';
/*import {MatTable} from '@angular/material/table';
*/


declare type ValueFunction = (row: any) => string;

export interface SemaphoreDecision {
  semaphore: string;
  decision: string;
}

declare type ITableRowValue = string | number | SemaphoreDecision;



export interface ITableHeader<T> {
  label: string;
  value: string;
  semaphore?: boolean;
  small?: boolean;
  center?: boolean;
  valueFunction?: (row: T) => ITableRowValue;
  button?: (row: T) => void;
  sortValue?: ((row: T) => ITableRowValue) | ITableRowValue;
  img?: string;
  tooltip?:string;
  sticky?:string;
/*  submenu?: (row: T) => string[];*/
}

export interface ITableOptions<T> {
  canEdit?: (row: T) => boolean;
  canDelete?: (row: T) => boolean;
  canDownload?: (row: T) => boolean;
  canShowDetail?: (row: T) => boolean;
}

function isObject(value: any){
  return typeof value === 'object' && value !== null;
//  return ((typeof value !== 'object' && typeof value !== 'function') || value === null);
}

function isFunction(value: any){
  return typeof value === 'function' && value !== null;
}

@Component({
  selector: 'puntal-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


//export class TableComponent implements OnInit {
export class PuntalTableComponent<T> implements OnInit, OnChanges {
  @Input() displayedColumns: ITableHeader<T>[];
  @Input() dataSource: T[] = [];
  @Input() tableTitle: string;
  @Input() noEdit = false;
  @Input() noDelete = false;
  @Input() noDownload = true;
  @Input() showPermissionButton = 'N';
  @Input() showAddButton = 'N';
  @Input() showBackButton = 'S';
  @Input() showDownloadGridButton = 'N';
  @Input() showFiltersButton = 'N';
  @Input() loading = false;
  @Input() options: ITableOptions<T> = {};
  //@Input() max_height = '500px';
  @Output() onRowClick = new EventEmitter<T>();
  @Output() onEdit = new EventEmitter<T>();
  @Output() onDelete = new EventEmitter<T>();
  @Output() onDownload = new EventEmitter<T>();  
  @Output() onShowPermissionClick = new EventEmitter<T>();
  @Output() onAdd = new EventEmitter<T>();
  @Output() onBack = new EventEmitter<T>();
  @Output() onDownLoadGrid = new EventEmitter<T>();
  @Output() onShowFilters = new EventEmitter<T>();

  readonly ACTION_COLUMN = {label: ' ', value: 'action'};
  //colValues = [];
  colValues: any[] = []
  private columnsByValue = {};
  //private columnsByValue: {[key:string]: string} = {};
  //matDataSource: any;

  /*@ViewChild(MatTable) table: MatTable<any>;*/

  @ViewChild('table_wrapper') table_wrapper: ElementRef;

  matDataSource = new MatTableDataSource(this.dataSource);
  //matDataSource = new MatTableDataSource(this.dataSource);
  //matDataSource : MatTableDataSource;

  ngOnInit() {
    this.colValues = this.displayedColumns.map(elem => elem.value);
    const showActionColumn = this.hasActionColumn && (this.hasSubscriptors(this.onEdit) || this.hasSubscriptors(this.onDelete) || this.hasSubscriptors(this.onDownload));
    
    if (showActionColumn && !this.colValues.includes(this.ACTION_COLUMN.value)) {
      this.colValues.push(this.ACTION_COLUMN.value);
      this.displayedColumns.push(this.ACTION_COLUMN);
    }
    
    this.displayedColumns.forEach(header => this.columnsByValue[header.value] = header);
   // console.log('displayedColumns',this.displayedColumns);
    this.matDataSource = new MatTableDataSource(this.dataSource);
  }

/*  Render(){
    this.table.renderRows();
  }*/

  rowClickHandler(i: number, row: T) {
    if (this.isActionColumn(i)) {
      return;
    }
    this.onRowClick.emit(row);
  }

  editHandler(event: MouseEvent, row: T) {
    // event.stopPropagation();
    // TODO: disabled until backend is done
    this.onEdit.emit(row);
  }

  deleteHandler(event: MouseEvent, row: T) {
    // event.stopPropagation();
    // TODO: disabled until backend is done
    this.onDelete.emit(row);
  }

  downloadHandler(event: MouseEvent, row: T) {
    // event.stopPropagation();
    // TODO: disabled until backend is done
    this.onDownload.emit(row);
  }

  hasSubscriptors(emiter: EventEmitter<T>) {
    return emiter.observers.length > 0;
  }

  get hasActionColumn() {
    return !this.noEdit || !this.noDelete || !this.noDownload || (this.showPermissionButton == 'S');
  }

  getTypeSemaphore(item: string | number | SemaphoreDecision): SemaphoreDecision { 
    return (item as SemaphoreDecision);
  }

  isActionColumn(col: any) {
    return this.hasActionColumn && col === this.displayedColumns.length - 1;
  }

  value(col: ITableHeader<T>, row: T): ITableRowValue {
    if (!col.valueFunction) {
      return row[col.value];
    }
    return col.valueFunction(row);
  }

  sortValue(col: ITableHeader<T>, row: T): ITableRowValue {
    if (!col.sortValue) {
      return this.value(col, row);
    }
    if (isFunction(col.sortValue)) {
      const getter = col.sortValue as ((row: T) => ITableRowValue);
      return getter(row);
    }
    return col.sortValue as ITableRowValue;
  }

  sortData(sort: Sort) {
    const col = this.columnsByValue[sort.active];
    if (!col) { return; }
    const isAsc = sort.direction === 'asc';
    this.matDataSource.data = this.dataSource.sort((a, b) => {
      return compare(this.sortValue(col, a), this.sortValue(col, b), isAsc);
    });
  }


  ngOnChanges(): void {
    if ((this.dataSource) && (this.matDataSource)) { 
      this.matDataSource.data = this.dataSource;
    }
  }

  colButtonClick($event: MouseEvent, col: ITableHeader<T>, row: T) {
    $event.stopPropagation();
    if (col.button) {col.button(row)};
  }

  canEdit(row: T) {
    return !isFunction(this.options.canEdit) || (this.options.canEdit === undefined ? false : this.options.canEdit(row));
//      return !isFunction(this.options.canEdit) || this.options.canEdit(row);
  }

  canDelete(row: T) {
    return !isFunction(this.options.canDelete) || (this.options.canDelete === undefined ? false : this.options.canDelete(row));
    //  return !isFunction(this.options.canDelete) || this.options.canDelete(row);
  }

  canDownload(row: T) {
    return !isFunction(this.options.canDownload) || (this.options.canDownload === undefined ? false : this.options.canDownload(row));
    //  return !isFunction(this.options.canDownload) || this.options.canDownload(row);
  }
  
  canShowDetail(row: T) {
    return !isFunction(this.options.canShowDetail) || (this.options.canShowDetail === undefined ? false : this.options.canShowDetail(row));
    //  return !isFunction(this.options.canDownload) || this.options.canDownload(row);
  }
  permissionHandler(mouseEvent: MouseEvent, row: T) {
    this.onShowPermissionClick.emit(row);
  }

  addHandler(mouseEvent: MouseEvent) {
    this.onAdd.emit();
  }

  DownLoadGridHandler(mouseEvent: MouseEvent) {
    this.onDownLoadGrid.emit();
  }

  ShowFiltersHandler(mouseEvent: MouseEvent) {
    this.onShowFilters.emit();
  }

  backHandler(mouseEvent: MouseEvent) {
    this.onBack.emit();
  }
}

function compare(a: ITableRowValue, b: ITableRowValue, isAsc: boolean) : any {
  if (isObject(a)) {
    return compare((a as SemaphoreDecision).semaphore, (b as SemaphoreDecision).semaphore, isAsc);
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
