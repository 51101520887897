import { Component, Input , OnInit, ViewChild, OnDestroy } from '@angular/core';
import { PermisoOtorgado } from '../modelo/permiso-otorgado.model';
import { PermisoService } from '../_services/permiso.service';
import { Location } from '@angular/common';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../shared/table/table.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmedDialogComponent } from '../shared/dialogs/confirmed-dialog/confirmed-dialog.component';
import { MatSelectChange } from '@angular/material/select';
import { PermisoFormComponent } from './forms/permiso-form/permiso-form.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Usuario } from '../modelo/usuario';
import { Permiso } from '../modelo/permiso';
import { UserService } from '../_services/user.service';
import { Filter, Matcher } from '../actividades/multi-filter/multi-filter.component';
import { ToastService } from '../shared/toast/toast.service';
import { AuthService } from '../_services/auth.service';
import { nombre_rol } from '../shared/utility-functions';

@Component({
  selector: 'app-permisos',
  templateUrl: './permisos.component.html',
  styleUrls: ['./permisos.component.scss']
})

export class PermisosComponent implements OnInit {
  loading = false;
  sus : any;
  isLoggedIn = false;
  userLoggedIn: number;
  userId: number;
//  clientId: number;
  titulo: string = 'Permisos';
  titulo_guardado: string = '';
  usuario: Usuario;
  permisosOtorgados: PermisoOtorgado[] = [];
  permisosOtorgadosFull: PermisoOtorgado[] = [];
  permisoOtorgado: PermisoOtorgado;
  permiso: Permiso;

  @ViewChild(PuntalTableComponent,{static:true}) table: PuntalTableComponent<any>;


  selectedClient: number = -1;
  selectedCompany: number = -1;  
  selectedFarm: number = -1;


  readonly header: ITableHeader<Permiso>[] = [
    {
      label: 'id',
      value: 'client_id',
    },
    {
      label: 'cliente',
      value: 'client_name',
    },
    {
      label: 'empresa',
      value: 'company_name',
    },
    {
      label: 'campo',
      value: 'farm_name',
    },  
    {
      label: 'permisos',
      value: 'role',
      valueFunction: role => nombre_rol(role.role)
    },
  ];

  readonly tableOptions: ITableOptions<Permiso> = {
    canEdit: (user) => false /* Por ahora solo permiso altas y bajas de permisos (this.canAddRecord == 'S')/*this.authService.isAdmin(client.id)*/,
    canDelete: (user) => (this.canAddRecord == 'S')/*this.authService.isAdmin(client.id)*/
  };
  canAddRecord = 'S'; 

  readonly clientFilter: Filter<any> = {
    info: {
      client: {
        name: 'Cliente',
        getter: (permisoOtorgado) => permisoOtorgado.client_name
      },
      company: {
        name: 'Empresa',
        getter: (permisoOtorgado) => permisoOtorgado.company_name
      },
      farm: {
        name: 'Campo',
        getter: (permisoOtorgado) => permisoOtorgado.farm_name
      },
    },
    order: ['client', 'company', 'farm'],
    values: {},
    select: {},
    changed: {},
  };  

  constructor(private permisoService:  PermisoService, private dialog: MatDialog,private location: Location, 
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService, 
    private userService: UserService,
    private authService: AuthService) {
    this.clientFilter.order.forEach(filter => {
      this.clientFilter.select[filter] = new Set();
      this.clientFilter.values[filter] = new Set();
    })};
  
    ngOnInit(): void {
      this.loading = true;
      this.canAddRecord = 'S';
      this.userLoggedIn = this.authService.getId();
      this.route.paramMap.subscribe(params => {
        if (params.get('usuarioId')) {
          this.userId = Number(params.get('usuarioId'));
          //this.sus = this.permisoService.getPermisos(this.userId).subscribe((permisos) => {this.permisosOtorgados = permisos; console.log(this.permisosOtorgados);});
          this.retrievePermisosOtorgadosUsuario();
          
           // Proponemos el título de la ventana
          if(history.state.data){
            this.titulo = 'Permisos de '+history.state.data;
            sessionStorage.setItem('pu_'+this.userId, history.state.data);
          }else{
            this.titulo_guardado = sessionStorage.getItem('pu_'+this.userId) + '';
            if (this.titulo_guardado) {
              this.titulo = 'Permisos de '+ this.titulo_guardado;
            }else {
              this.titulo = 'Permisos';
            }
          }         
          if(history.state.editable != undefined){
            if (history.state.editable == '1'){
              this.canAddRecord = 'S';
            }
            else {
              this.canAddRecord = 'N';
            }
            sessionStorage.setItem('pue_'+this.userId, history.state.editable);
          }else{
            if (sessionStorage.getItem('pue_'+this.userId) == '1') {
              this.canAddRecord = 'S';
            }else {
              this.canAddRecord = 'N';
            }
          }                      
        }
        this.loading = false;
      });    
    }
    
    private retrievePermisosOtorgadosUsuario() {
      this.sus = this.permisoService.getPermisos(this.userId).subscribe((permisos) => {
        this.permisosOtorgados = permisos; 
        this.permisosOtorgadosFull = permisos;
        this.refreshClientFilterValues(this.permisosOtorgados); 
        this.updateFilter({match: (unPermiso) => this.matchFilter(unPermiso, this.clientFilter)});         
      },
      error => {
        this.toastService.openSnackBar(`Error al leer permisos otorgados: ${error}`, 'OK');
      });
    }

    private matchFilter(activity: PermisoOtorgado, filter: Filter<PermisoOtorgado>) {
      const matchAll = Object.entries(filter.info).map(([key, data]) => {
        const set = filter.select[key];
        if (set.size === 0 || set.size === filter.values.length) {
          return true;
        }
        const value = data.getter(activity);
        return set.has(value);
      });
      return matchAll.every(Boolean);
    }  

    cleanFilters(activityFilter: Filter<PermisoOtorgado>) {
      activityFilter.order.forEach(filter => {
        activityFilter.select[filter] = new Set();
      });
      Object.values(activityFilter.changed).forEach(model => {
        setTimeout(() => {
          model._selectionModel.clear();
          model.toggle();
          model.close();
        }, 100);
      });
      activityFilter.changed = {};
      this.selectedClient = -1;
      this.selectedCompany = -1;
      this.selectedFarm = -1;        
      this.updateFilter({match: (activity) => this.matchFilter(activity, this.clientFilter)});
    }
  

    updateFilter(matcher: Matcher<PermisoOtorgado>) {
      this.permisosOtorgados = this.permisosOtorgadosFull;
      this.permisosOtorgados = this.permisosOtorgados.filter(activity => matcher.match(activity));
      this.refreshClientFilterValues(this.permisosOtorgados);
    }

    allSelected(filter: Filter<PermisoOtorgado[]>, field: string) {
      const set = filter.select[field] || new Set();
      return set.size === 0 || set.size === filter.values.length;
    }

    private refreshClientFilterValues(permisosOtorgados: PermisoOtorgado[]) {
      permisosOtorgados
        .filter(Boolean)
        .forEach(unPermiso => {
          this.clientFilter.order.forEach(order => {
            const set = this.clientFilter.values[order] || new Set();
            const value = this.clientFilter.info[order].getter(unPermiso);
            set.add(value);
            this.clientFilter.values[order] = set;
          });
        });
    }  

    editRow(row: PermisoOtorgado) {
      const dialogRef = this.openModal(row);
      dialogRef.afterClosed().subscribe(
        (newPermiso: Permiso) => {
          if (newPermiso) {
            this.permisoService.updatePermiso(newPermiso).subscribe();
          }
        }
      );
    }
  
    async permiso_valido(row): Promise<boolean> {
      // obtener permiso actual sobre la fila
      try {
        if (this.authService.isSuperUser()) {
          return true;
        } 
        else {
          if ((row.farm_id != null) && (row.farm_id > 0)) {
            let permiso_userLoggin = await this.permisoService.rol_nodo('farm',row.farm_id,this.userLoggedIn);
            if (permiso_userLoggin != 'ADMIN') {
              return false;
            } 
          } 
          else {
            if ((row.company_id != null) && (row.company_id > 0)) {
              let permiso_userLoggin = await this.permisoService.rol_nodo('company',row.company_id,this.userLoggedIn);
              if (permiso_userLoggin != 'ADMIN') {
              //  this.toastService.openSnackBar(`Debe poder ser ADMIN en la empresa para eliminar este permiso`, 'OK', 300000);
                return false;
              } 
            }  
            else {
              let permiso_userLoggin = await this.permisoService.rol_nodo('client',row.client_id,this.userLoggedIn);
              if (permiso_userLoggin != 'ADMIN') {
              //  this.toastService.openSnackBar(`Debe poder ser ADMIN en el cliente para eliminar este permiso`, 'OK', 300000);
                return false;
              } 
            }
          } 
        }
        return true;
      }
      catch (err) {
        console.error(err);
        this.toastService.openSnackBar('Se produjo un error al validar permisos para el usuario ('+err+')', 'OK', 300000);
        return false;
      }
    }

    deleteRow(row: PermisoOtorgado) {
      this.permiso_valido(row).then((datap) => {
        const allowed = datap;

        if (!allowed)  { 
          this.toastService.openSnackBar(`Debe poder ser ADMIN en el campo para eliminar este permiso`, 'OK', 300000);
          return; 
        }

        const data = {
          title: `ATENCIÓN: Borrar permiso '${row.role}'`,
          message: 'Está seguro?'
        };
        const dialogRef = this.dialog.open(ConfirmedDialogComponent, {
          disableClose: true,
          autoFocus: true,
          height: 'auto',
          width: 'auto',
          data
        });

      //  const id_a_borrar = row.id;
        dialogRef.afterClosed().subscribe(
          result => {
            if (result) {
              this.permisoService.deletePermiso(row).subscribe(
                ()  => {
                  this.retrievePermisosOtorgadosUsuario();
                }
              );
            }
          }
        );
      });      
    }
  
    navigateTo(row: Permiso) {
      //console.log('should navigate to company with id: ', row.id);
   //   this.router.navigate([`${row.id}/empresas`], {relativeTo: this.route});
    }
  
  
    goBack() {
      this.location.back();
    }
  
    addRow() {
      if (!this.permisoOtorgado) {
        this.permisoOtorgado = { 
          user_id: 0,
          client_id: 0,
          client_name: '',
          company_id: 0,
          company_name: '',
          farm_id: 0,
          farm_name : '',
          role: ''
        } as PermisoOtorgado;
      }
      this.permisoOtorgado.user_id = this.userId;
      const dialogRef = this.openModal(this.permisoOtorgado);
      
      dialogRef.afterClosed().subscribe(
        (newPermiso: PermisoOtorgado) => {
          if (newPermiso) {
            this.permisoService.addPermisoOtorgado(newPermiso).subscribe(
              (permiso)  => {
                this.retrievePermisosOtorgadosUsuario();
  //            newClient = cliente;
  //            this.clientes.push(newClient);
  //            this.permisoService.getPermisos().subscribe((permisos) => this.permisos = permisos);
   //           this.table.dataSource = this.clientes;
    //          this.table.ngOnChanges();   
    //          this.table.Render();
          }
          )
          }
      }
        )
    }

  
    private openModal(permisoOtorgado?: PermisoOtorgado): MatDialogRef<PermisoFormComponent, any> {
      return this.dialog.open(PermisoFormComponent, {
        width: '380px',
        height: '450px',
        disableClose: true,
        autoFocus: true,
        data: permisoOtorgado || {}
      });
    }
 
    get canAdd() {
    //  return this.authService.isSuperUser();
    return true;
    }
  
     openPermission(client: Permiso) {
    /*  this.dialog.open(WorkspaceViewComponent, {
        width: '500px',
        disableClose: true,
        autoFocus: true,
        data: {
          ref: client,
          type: WorkspaceElementType.CLIENT
        }
      });*/
    }  
  
    ngOndestroy() {
      //console.log('Items destroyed');
      this.sus.unsubscribe();
    }
  
    isAuthenticated() {
      return true;
    }

    cleanClientFilters() {
      this.selectedClient = -1;
      this.selectedCompany = -1;    
      this.selectedFarm = -1;
      this.cleanFilters(this.clientFilter);
    }
    
    textPrettyFilter(selectElement: Set<any>) {
      const values = Array.from(selectElement);
      return values.join(', ');
    }


    valueIfOne(field: string) {
      const set = this.clientFilter.values[field];
      if (set && set.size === 1) {
        this.clientFilter.select[field] = set;
        return Array.from(set)[0];
      }
      return undefined;
    }

    disableClientSelect(field: string) {
      return !!this.valueIfOne(field);
    }
   
    anyFilter(filter: Filter<any>) {
      return Object.values(filter.changed).length > 0;
    }

    changeClientSelect($event: MatSelectChange, field: string) {
      // Aca llamar a algo que actualice los campos elegidos para cliente/empresa/campo
      this.clientFilter.select[field] = new Set([$event.value]);
      this.clientFilter.changed[field] = $event.source;
      Object.keys(this.clientFilter.select)
        .filter(selectField => selectField !== field)
        .forEach(selectField => {
          this.clientFilter.select[selectField] = new Set();
        });
      this.clientFilter.values = {[field]: this.clientFilter.values[field]};
      this.updateFilter({match: (permisoOtorgado) => this.matchFilter(permisoOtorgado, this.clientFilter)});
    }

  }
