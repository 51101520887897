<ng-container *ngIf="loading">
    <!-- <ng-container *ngIf="!scout "> -->
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>


  <div class="container" *ngIf="!loading && slides">
    <h2 class="title-white">{{titulo}}</h2> 
    <form class="example-form">
      
   <!--        <div *ngIf="slides.length <= 0"> 
            <mat-card class="card_left">
              <mat-card-header>
                <mat-card-title>Observaciones</mat-card-title>

              </mat-card-header>
              <mat-card-content>

            <mat-form-field style="display: block">
                <textarea class="notas" matInput rows="18" [(ngModel)]="notas"
                  (change)="onChange($event.target.value)">
                  {{notas}}
                </textarea>
              </mat-form-field> 
              <div class="buttons">
                <button mat-stroked-button color="primary"  (click)="close()">Cancelar</button>
                <button mat-raised-button color="primary" class="accept-button" (click)="save()">Guardar</button>      
              </div>
            </mat-card-content>
          </mat-card>
    
         </div>    -->
        <div class="div-flex">
          <!-- <h2 class="title_blanco">{{titulo}}</h2> -->
          <div style="flex-basis: 30%;">
            <mat-card class="card-left">
              <mat-card-header>
                <mat-card-title>Observaciones</mat-card-title>
            <!-- <h3 class="title">{{titulo}}</h3> -->

              </mat-card-header>
              <mat-card-content>

            <mat-form-field style="display: block">
              <!-- <mat-label>Observaciones</mat-label>  -->
                <textarea class="notas" matInput rows="18" [(ngModel)]="notas"
                  (change)="onChange($event.target.value)">
                  {{notas}}
                </textarea>
              </mat-form-field> 
              <div class="buttons">
                <button mat-stroked-button color="primary"  (click)="close()">Cancelar</button>
                <button mat-raised-button color="primary" class="accept-button" (click)="save()">Guardar</button>      
              </div>
            </mat-card-content>
          </mat-card>
          </div>
          <div style="flex-basis: 70%;">
            <mat-card class="card-right">
              <mat-card-header>
               <!--  <mat-card-title>{{titulo}}</mat-card-title> -->
            <!-- <h3 class="title">{{titulo}}</h3> -->

              

            <!-- <div class="container"> -->
              <div *ngIf="slides.length > 0" class="div-flex">
              <div class="numbertext">{{currentIndex + 1}} / {{slides.length}}</div><br>
              <div class="dotsContainer">
                <div *ngFor="let slide of slides; let slideIndex = index" class="dot" (click)="goToSlide(slideIndex)">
                  ●
                </div>
              </div>
            </div>
            </mat-card-header>
            <mat-card-content>
              <div *ngIf="slides.length > 0" class="div-flex">
                <div class="slider"> 
                  <div>
                    <div (click)="goToPrevious()" class="leftArrow">❰</div>
                    <div (click)="goToNext()" class="rightArrow">❱</div>
                  </div>
                  <div id="slides">
                    <img class="myslide" [src]="slides[currentIndex]" >
                  </div> 
                </div>
                
              </div>   
              <div *ngIf="slides.length <= 0" class="div-flex">
                <h3>No hay imágenes disponibles para la recorrida</h3>
              </div>        
          </mat-card-content>
        </mat-card>

      </div>
    </div>
   
     
 <!-- <mat-card>
  <mat-card-content>
    <form>
      <mat-form-field>
        <textarea matInput placeholder="Ingrese sus notas"></textarea>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Galería de Fotos</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="carousel">
      <div class="carousel-item">
        <img src="https://via.placeholder.com/150" alt="Imagen 1">
      </div>
      <div class="carousel-item">
        <img src="https://via.placeholder.com/150" alt="Imagen 2">
      </div>
      <div class="carousel-item">
        <img src="https://via.placeholder.com/150" alt="Imagen 3">
      </div>
      <div class="carousel-item">
        <img src="https://via.placeholder.com/150" alt="Imagen 4">
      </div>
      <div class="carousel-item">
        <img src="https://via.placeholder.com/150" alt="Imagen 5">
      </div>
    </div>
  </mat-card-content>
</mat-card>
 -->

 
<!--   <div style="display: flex">
  <div class="column left">
    <mat-card>
      <mat-card-content>
        <form>
          <mat-form-field>
            <textarea matInput placeholder="Ingrese sus notas"></textarea>
          </mat-form-field>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="column right">
    <mat-card>
      <mat-card-content>
        <div class="carousel">
          <div class="carousel-item active">
            <img src="https://via.placeholder.com/600x400" alt="Imagen 1">
          </div>
          <div class="carousel-thumbnails">
            <div class="carousel-thumbnail active">
              <img src="https://via.placeholder.com/150" alt="Imagen 1">
            </div>
            <div class="carousel-thumbnail">
              <img src="https://via.placeholder.com/150" alt="Imagen 2">
            </div>
            <div class="carousel-thumbnail">
              <img src="https://via.placeholder.com/150" alt="Imagen 3">
            </div>
            <div class="carousel-thumbnail">
              <img src="https://via.placeholder.com/150" alt="Imagen 4">
            </div>
            <div class="carousel-thumbnail">
              <img src="https://via.placeholder.com/150" alt="Imagen 5">
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div> -->

  </form>    

</div>
