<div class="container">
    <h2 class="title">{{titulo}}</h2>
    <div class="form" [formGroup]="permisosFormControl">  
    <div class="permisos-form-clients-section">

      <!-- CLIENTES -->
      <div class="permisos-form-clients-section-select-container">
        <mat-form-field>
          <mat-label>Cliente</mat-label>
          <mat-select [disabled]="clientList$.length === 0" [value]="this.selectedClient" disableRipple (selectionChange)="onClientSelect($event.value)" formControlName="client_id" [errorStateMatcher]="matcher" >
            <mat-option *ngFor='let client of clientList$' [value]="client.id">{{client.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- EMPRESAS -->
      <div class="permisos-form-clients-section-select-container">
        <mat-form-field>
          <mat-label>Empresa</mat-label>
          <mat-select [value]="this.selectedCompany" [disabled]="!selectedClient || companyList$.length === 0" disableRipple (selectionChange)="onCompanySelect($event.value)" formControlName="company_id" [errorStateMatcher]="matcher" >
            <mat-option *ngFor='let company of companyList$' [value]="company.id">{{company.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- CAMPOS -->
      <div class="permisos-form-clients-section-select-container">
        <mat-form-field>
          <mat-label>Establecimiento</mat-label>
          <mat-select [value]="this.selectedFarm" [disabled]="!selectedCompany || farmList$.length === 0" disableRipple (selectionChange)="onFarmSelect($event.value)" formControlName="farm_id" [errorStateMatcher]="matcher"  >
            <mat-option *ngFor='let farm of farmList$' [value]="farm.id">{{farm.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- PERMISO -->   
      <div class="permisos-form-clients-section-select-container" *ngIf="(selectedClientUserRole == 'ADMIN') || (selectedCompanyUserRole == 'ADMIN') || (selectedFarmUserRole == 'ADMIN') " >
        <mat-form-field>
          <mat-label>Permiso</mat-label>
          <mat-select [(value)]="tipo_seleccionado" formControlName="role"> 
            <mat-option *ngFor="let tipo of tipos_de_permiso" [value]="tipo.value">
              {{ tipo.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
     

      <div class="buttons">
        <button mat-stroked-button color="primary"  (click)="close()">Cancelar</button>
        <button mat-raised-button color="primary" class="accept-button" [disabled]="permisosFormControl.invalid" (click)="save()">Guardar</button>
      </div>

    </div>
   </div> 
</div>