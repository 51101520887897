import { Component, OnInit } from '@angular/core';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../../shared/table/table.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogItemModal, CatalogDictionary, Catalog, CATALOGS_NAME, catalogo_array, CatalogContentItem, CatalogData } from '../../modelo/catalogo';
import { CatalogoService } from '../../_services/catalogo.service';
import { ToastService } from '../../shared/toast/toast.service';
import { Location } from '@angular/common';
import { ConfirmedDialogComponent } from '../../shared/dialogs/confirmed-dialog/confirmed-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CatalogosViewMoreComponent } from '../catalogos-view-more/catalogos-view-more.component';
import { CatalogosPropertiesComponent } from '../catalogos-properties/catalogos-properties.component';
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: 'app-catalogos-view',
  templateUrl: './catalogos-view.component.html',
  styleUrls: ['./catalogos-view.component.scss']
})
export class CatalogosViewComponent implements OnInit {
  loading = false;
  sus : any;
  catalog: Catalog;
  noEdit: boolean;
  noDelete: boolean; 
  catalogData: CatalogContentItem; //CatalogData;
  selectedItem: CatalogContentItem | null;
  showTable = true;

  tableColumns: ITableHeader<CatalogContentItem>[];

 readonly tableColumnsSinEspecie: ITableHeader<CatalogContentItem>[] = [
    {
      label: 'Nombre',
      value: 'name'
    }
  ];


     /*   {
          label: 'VALORES',
          value: 'VALORES',
          valueFunction: (item) => 'VER MÁS',
          button: (item) => this.viewCatalogCard(item)
        }*/

      
  readonly tableOptions: ITableOptions<CatalogData> = {
    canEdit: (catalogo) => true, /*(this.canAddRecord == 'S'), */
    canDelete: (catalogo) => (this.canAddRecord == 'S')
  };
  canAddRecord = 'N';

  constructor(private catalogoService: CatalogoService, private location: Location , private router: Router, private route: ActivatedRoute, 
    private dialog: MatDialog,
    private toastService: ToastService,
    private authService: AuthService) {}


    ngOnInit(): void {
      
      this.route.paramMap.subscribe(params => {
        const id = params.get('catalogId');
        if (id) {
          this.catalog = {name: CATALOGS_NAME[id], id};
          this.canAddRecord = (this.authService.isSuperUser() ? 'S' : 'N' ); 
          this.noEdit = false; //(this.canAddRecord == 'N');
          this.noDelete = (this.canAddRecord == 'N');

/*          if (!this.authService.isSuperUser()) {
            this.authService.getPermisoAddCatalog().subscribe((permiso) => {
              this.canAddRecord = (permiso.permiso ? 'S' : 'N' );
             });
          } 
          else {
            this.canAddRecord = 'S';
          }     */     
          const c_f_creacion = {
            label: 'Fecha creacion',
            value: 'creation_date',
            valueFunction: catalogdata => {
              if (!catalogdata.creation_date) {
                return '';
              }
              const desde = catalogdata.creation_date; //.toDateString;
              const date = new Date(desde);
              return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            }
          }  
          const c_especie = {
            label: 'Especie',
            value: 'd_especie'
          }

          const c_tipo = {
            label: 'Tipo',
            value: 'd_tipo'
          }

          const c_cientifico = {
            label: 'N.Científico',
            value: 'scientific_name'
          }

          this.tableColumns = this.tableColumnsSinEspecie;
//console.log('CatalogID',id);
          if ((id == 'input') || (id == 'input_property')) {
            this.tableColumns.push(c_especie);
            this.tableColumns.push(c_tipo);
          }
          if (id == 'weed') {
            this.tableColumns.push(c_cientifico);
            this.tableColumns.push(c_tipo);
          }
          if (id == 'pest') {
            this.tableColumns.push(c_cientifico);
          }
          if ((id == 'achieved_density') || (id == 'cultivar_technology') || (id == 'crop') || 
            (id == 'crop_specie_pest') || (id == 'disease') || (id == 'crop_stage')) {
              this.tableColumns.push(c_especie);
          }
          this.tableColumns.push(c_f_creacion);
          this.retrieveData();
        }      
        else
        {
          this.toastService.openSnackBar(`Error al obtener tipo de catalogo`, 'OK');
          this.location.back();
      }});
    }
  
 /*   content_to_contentById (contenido_arr: CatalogContentItem[]): any {
      var contenido_x_id = { };
      for (var i = 0; i < contenido_arr.length; ++i) {
          var entry = contenido_arr[i];
          console.log('entry',entry);
          if (entry.id) {
            contenido_x_id[entry.id] = entry;
          }
       }
        return contenido_x_id;
    }*/

    retrieveData() {
     this.loading = true;
      this.sus = this.catalogoService.getCatalogo(this.catalog.id).subscribe((data) => {
        this.catalogData = data;
   /*     console.log(this.catalog.id);
        console.log(this.catalogData);*/
        this.selectedItem = null;
        this.showTable = true;        
        this.loading = false;
      },
      error => {
        this.toastService.openSnackBar(`Error al leer catalogo: ${error}`, 'OK');
        this.loading = false;
      });
    }
 
    get catalogName() {
      return this.catalog ? this.catalog.name : 'Cargando...';
    }
  
    openProperties(item: CatalogContentItem) {
        this.dialog.open(CatalogosPropertiesComponent, {
          width: '500px',
          disableClose: true,
          autoFocus: true,
          data: item
        });
    }

    viewCatalogCard(item: CatalogContentItem) {
      const dialogRef = this.openModal('C',4, item);
    }
  
    editItem(item: CatalogContentItem) {
      if (this.canAddRecord == 'N') {
        this.viewCatalogCard(item);
      }
      else {
        const dialogRef = this.openModal('E',4, item);
        dialogRef.afterClosed().subscribe(
          (newCatalogo: CatalogContentItem) => {
            if (newCatalogo) {
         //     console.log('Mando a grabar en el catalogo ',this.catalog.id, 'el registro ',newCatalogo);
              this.catalogoService.updateCatalogo(this.catalog.id,newCatalogo).subscribe( ()  => {
                this.retrieveData();
              },
              error => {
                this.toastService.openSnackBar(`Error al actualizar el valor ${item.name}: ${error}`, 'OK');
              });
            }
          }

        );
      }
    }

    private openModal(operation: string, n_fields: number,item?: CatalogContentItem): MatDialogRef<CatalogosViewMoreComponent, any> {
      let registro: CatalogItemModal;
      registro = {"catalog_id": this.catalog.id, "operation": operation, "n_fields": n_fields, "record": item}; 
      return this.dialog.open(CatalogosViewMoreComponent, {
        disableClose: true,
        autoFocus: true,
        height: 'auto',
        width: 'auto',
        data: registro || {}
      });      
    }

    addRow() {
      const dialogRef = this.openModal('A',4);
      dialogRef.afterClosed().subscribe(
        (newCatalogo: CatalogContentItem) => {
          if (newCatalogo) {
            this.catalogoService.addCatalogo(this.catalog.id,newCatalogo).subscribe(
              (catalog)  => {
                this.retrieveData();
              },
            error => {
              console.log('ERROR',error);
              this.toastService.openSnackBar(`Error al agregar el valor ${newCatalogo.name}: ${error}`, 'OK');
            }
          )
        }
      }
      )
    }

    deleteItem(row: CatalogContentItem) {
/*      const data = {
        title: `ATENCIÓN: Borrar item '${item.id}' de catalog '${this.catalogName}'`,
        message: 'Está seguro? Al borrar item ya no estara disponible donde es utilizado'
      };
      const dialogRef = this.dialog.open(ConfirmedDialogComponent, {
        disableClose: true,
        autoFocus: true,
        height: 'auto',
        width: 'auto',
        data
      });
  
      dialogRef.afterClosed()
        .pipe(filter(res => res))
        .subscribe(() => {
          this.$http.delete(`/api/catalogs/${this.catalog.id}/${item.id}`)
            .subscribe(() => this.catalogData.content = this.catalogData.content.filter(i => i.id !== item.id));
        }
      );*/
      const id_a_borrar = row.id;
      if (id_a_borrar) {
        const data = {
          title: `ATENCIÓN: Borrar item '${row.id}' de catalogo '${this.catalogName}'`,
          message: 'Está seguro? Al borrar el item, el mismo ya no estara disponible donde es utilizado'
        };
        const dialogRef = this.dialog.open(ConfirmedDialogComponent, {
          disableClose: true,
          autoFocus: true,
          height: 'auto',
          width: 'auto',
          data
        });
        
        dialogRef.afterClosed().subscribe(
          result => {
            if (result) {
              this.catalogoService.deleteCatalogo(this.catalog.id,id_a_borrar).subscribe(
                ()  => {
                  this.retrieveData();
                },
                error => {
                  this.toastService.openSnackBar(`Error al eliminar: ${error}`, 'OK');
                }
              );
            }
          }
        );      
      }
    }
  
    onCloseForm(newItem?: CatalogContentItem) {
/*      if (newItem) {
        const action = newItem.id ? () => this.updateItem(newItem) : () => this.createItem(newItem);
        action();
      }*/
      this.selectedItem = null;
      this.showTable = true;
    }
  
    private updateItem(item: CatalogContentItem) {
      this.catalogoService.updateCatalogo(this.catalog.id,item).subscribe( (result)  => {
     /*   if (result.id) {
          this.catalogData.contentById[result.id] = result;
        }*/
        this.retrieveData();
      },
      error => {
        this.retrieveData();
        this.toastService.openSnackBar(`Error al actualizar el catalogo ${item.name}: ${error}`, 'OK');
      });

/*      this.$http.put<CatalogContentItem>(`/api/catalogs/${this.catalog.id}/${item.id}`, item)
        .subscribe(result => {
          this.catalogData.contentById[result.id] = result;
        });*/
    }
  
    private createItem(item: CatalogContentItem) {
/*      this.$http.post<CatalogContentItem>(`/api/catalogs/${this.catalog.id}`, item)
        .subscribe(result => {*/
        this.catalogoService.addCatalogo(this.catalog.id,item).subscribe( (result)  => {        
     /*     this.catalogData.content = [...this.catalogData.content, result];*/
    /*      if (result.id) {
            this.catalogData.contentById[result.id] = result;
          }*/
          this.retrieveData();

        });
    }
  
    goBack() {
      this.location.back();
    }

 /*   goToCatalogs() {
      this.router.navigate(['/admin/catalogs']).catch(err => console.error(err));
    }*/
  
    add() {
      this.showTable = false;
    }

    ngOndestroy() {
      this.sus.unsubscribe();
    }
  
  }
  