import {Component, OnInit, Inject, Input} from '@angular/core';

import { FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { AuthService } from '../../../_services/auth.service';
import * as moment from 'moment';

/* Campanias */
//import { IClient } from '~core/models/campanias-section.models';
import { Campania } from '../../../modelo/campania';

function groupValidator(group: AbstractControl): ValidationErrors | null {
  const fromCtrl = group.get('from_date');
  const toCtrl = group.get('to_date');
  if ((fromCtrl == null) || (toCtrl == null )) {return null};
  return new Date(fromCtrl.value) > new Date(toCtrl.value) ? { message: 'La fecha de fin debe ser mayor que la fecha de inicio' } : null;
}


@Component({
  selector: 'campania-form',
  templateUrl: './campania-form.component.html',
  styleUrls: ['./campania-form.component.scss']
})
export class CampaniaFormComponent implements OnInit {

  public matcher: ErrorStateMatcher;
  public campaniaFormControl: FormGroup;
  public titulo : string;
  private campania_activa: string = 'S';
  minDate: Date;
  maxDate: Date;
  startDate: Date | null = null;
  public operacion: string;
  public loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public campania: Campania /*IClient*/,
    private dialogRef: MatDialogRef<CampaniaFormComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService
    ) { }

  ngOnInit() {
    this.loading = true;
    const currentYear = moment().year();
    this.minDate = new Date(currentYear - 10, 0, 1);
    this.maxDate = new Date(currentYear + 10, 0, 1);    
    this.campaniaFormControl = this.createFormControl();
    this.matcher = new ShowOnDirtyErrorStateMatcher();
    this.loading = false;

  }
  
  private createFormControl(): FormGroup {
    if ((!this.campania) || (Object.keys(this.campania).length === 0)) {
      this.campania = {
        id: 0,
        name: '',
        active: 'S'
      } as Campania;
      this.titulo = 'Agregar Campaña';  
      this.campania_activa = 'S';
      const currentYear = moment().year();
      this.startDate = new Date(currentYear, 0, 1);
      this.operacion = 'A'; 
    }
    else {
      if (this.authService.isSuperUser()) {
        this.operacion = 'E'; 
        this.titulo = 'Editar Campaña';
        if (this.campania.active) {
          this.campania_activa = this.campania.active;
        }
        else {
          this.campania_activa = 'N';
        }        
      } 
      else {
        this.titulo = 'Consultar Campaña';
        this.operacion = 'C'; 
      }
    }
    return this.formBuilder.group({
      name: [{value:this.campania.name,disabled: (this.operacion === 'C')}, [Validators.required, Validators.minLength(3)]],
      from_date: [{value:this.campania.from_date,disabled: (this.operacion === 'C')}, [Validators.required]],
      to_date: [{value:this.campania.to_date,disabled: (this.operacion === 'C')},  [Validators.required]],
      active: [{value:this.campania.active,disabled: (this.operacion === 'C')}, [Validators.required]]}, [groupValidator]
    ); 
  }

  onChange(e){
    if (e.checked) {
      this.campania_activa = 'S';
    } else {
      this.campania_activa = 'N';
    }
  }

  save() {
    if (this.campaniaFormControl.invalid) { return; }
    const campaniaData = Object.assign(this.campania, this.campaniaFormControl.value);
    campaniaData.active = this.campania_activa;
  
/*    if (campaniaData.to_date === null) {
      this.campaniaFormControl.get
      return; // 'Debe especificar una fecha final'
    }*/
    if (campaniaData.from_date && campaniaData.to_date) {
      if (campaniaData.from_date >= campaniaData.to_date) {
        return; // 'La fecha final debe ser posterior a la fecha inicial'
      }   
    } 
    this.dialogRef.close(campaniaData);
  }

  close() {
      this.dialogRef.close(null);
  }

  public getNameErrorMessage(): string {
      return this.campaniaFormControl.get('name')!.hasError('required') ? 'Debe especificar un nombre' : ''; 
  }


  public getFromDateErrorMessage(): string {
    if (this.campaniaFormControl.get('from_date') === null) {
      return 'Debe especificar una fecha inicial'
    }
    return '';
  }

  public getToDateErrorMessage(): string {
    if (this.campaniaFormControl.get('to_date') === null) {
      return 'Debe especificar una fecha final'
    }
    if ((this.campaniaFormControl.get('from_date')) && (this.campaniaFormControl.get('to_date'))) {
      if (this.campaniaFormControl.get('from_date')!.value >= this.campaniaFormControl.get('to_date')!.value ) {
        return 'La fecha final debe ser posterior a la fecha inicial'
      }   
    }
    
    return '';
  }

  private hasError(field: string, errorCode: string) {
    return this.campaniaFormControl.hasError(field, errorCode);
  }

  get title() {
    return this.titulo; //'Agregar Campania';
  }

}
