<ng-container *ngIf="loading">
    <!-- <ng-container *ngIf="!scout "> -->
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>
  
  <div class="container" *ngIf="!loading">
  <!--     <h2 class="title">Semáforos</h2><br> -->
  
    <form class="example-form">
  
      <table>
        <tr>
          <th></th>
          <th>Semáforo</th>
          <th>Decisión</th>
        </tr>
        <tr>
          <td>Condición General</td>
          <td class = "tira_semaforos">
            <div *ngFor="let item of valores_semaforos; let i = index">
              <div class="semaphore" [ngClass]="semaphoreGeneralClass(i)" (click)="setear_semaforo_general(i);" tabindex=0></div>
            </div>
          </td>
        </tr>        
        <tr>
          <td>Implantación</td>
          <td class = "tira_semaforos">
            <div *ngFor="let item of valores_semaforos; let i = index">
              <div class="semaphore" [ngClass]="semaphoreClass('implantacion',i)" (click)="setear_semaforo_implantacion(i);" tabindex=0></div>
            </div>
          </td>
          <td>
            <mat-form-field class="example-full-width">
              <mat-select [value]="semaforos.implantacion.decision"  (selectionChange)="onimplantacion_decisionSelect($event.value)" disableRipple  >
                <mat-option *ngFor='let registro of decision_List' [value]="registro">{{registro}}</mat-option>
              </mat-select> 
            </mat-form-field>            
          </td>
        </tr>        
        <tr>
          <td>Malezas</td>
          <td class = "tira_semaforos">
            <div *ngFor="let item of valores_semaforos; let i = index">
              <div class="semaphore" [ngClass]="semaphoreClass('maleza',i)" (click)="setear_semaforo_maleza(i);" tabindex=0></div>
            </div>
          </td>
          <td>
            <mat-form-field class="example-full-width">
              <mat-select [value]="semaforos.maleza.decision"  (selectionChange)="onmaleza_decisionSelect($event.value)" disableRipple  >
                <mat-option *ngFor='let registro of decision_List' [value]="registro">{{registro}}</mat-option>
              </mat-select> 
            </mat-form-field>            
          </td>
        </tr>                                
        <tr>
          <td>Plagas</td>
          <td class = "tira_semaforos">
            <div *ngFor="let item of valores_semaforos; let i = index">
              <div class="semaphore" [ngClass]="semaphoreClass('plaga',i)" (click)="setear_semaforo_plaga(i);" tabindex=0></div>
            </div>
          </td>
          <td>
            <mat-form-field class="example-full-width">
              <mat-select [value]="semaforos.plaga.decision"  (selectionChange)="onplaga_decisionSelect($event.value)" disableRipple  >
                <mat-option *ngFor='let registro of decision_List' [value]="registro">{{registro}}</mat-option>
              </mat-select> 
            </mat-form-field>            
          </td>
        </tr>        
        <tr>
          <td>Enfermedades</td>
          <td class = "tira_semaforos">
            <div *ngFor="let item of valores_semaforos; let i = index">
              <div class="semaphore" [ngClass]="semaphoreClass('enfermedad',i)" (click)="setear_semaforo_enfermedad(i);" tabindex=0></div>
            </div>
          </td>
          <td>
            <mat-form-field class="example-full-width">
              <mat-select [value]="semaforos.enfermedad.decision"  (selectionChange)="onenfermedad_decisionSelect($event.value)" disableRipple  >
                <mat-option *ngFor='let registro of decision_List' [value]="registro">{{registro}}</mat-option>
              </mat-select> 
            </mat-form-field>            
          </td>
        </tr>        
        <tr>
          <td>Adversidades</td>
          <td class = "tira_semaforos">
            <div *ngFor="let item of valores_semaforos; let i = index">
              <div class="semaphore" [ngClass]="semaphoreClass('adversidad',i)" (click)="setear_semaforo_adversidad(i);" tabindex=0></div>
            </div>
          </td>
          <td>
            <mat-form-field class="example-full-width">
              <mat-select [value]="semaforos.adversidad.decision"  (selectionChange)="onadversidad_decisionSelect($event.value)" disableRipple  >
                <mat-option *ngFor='let registro of decision_List' [value]="registro">{{registro}}</mat-option>
              </mat-select> 
            </mat-form-field>            
          </td>
        </tr>                
      </table>
      <br>
      <div class="buttons">
        <button mat-stroked-button color="primary"  (click)="close()">Cancelar</button>
        <button mat-raised-button color="primary" class="accept-button" (click)="save()">Guardar</button>      
      </div>
    </form>
</div>      