import { Scout } from './scout';

export class Actividad {
    id: number;
    plot_id: number;
    farm_id: number;
    company_id: number;
    client_id: number;
    start_date: Date;
    end_date: Date;
    type: string;
    sub_type: string;
    campaign_id: number;
}

export interface IActivity {
    id: string;
    plot_id: string;
    plot_name: string;
    plot_area: string;
    type: string;
    sub_type: string;
    start_date: Date;
  }
  
  export interface IActivityFull extends Actividad {
    date_time: string;
    scout_count: string;
    client_name: string;
    company_name: string;
    plot_name: string;
    farm_name: string;
    last_scout?: Scout;
    state?: string;
    ambient?: string;
    sowing_date?: string;
    count_sowing?:number;
    genotype?: string;
    sowed_area?:number;
    observations?: string;
  }

  export interface IActivityFullPage {
    cuantos: number;
    data : IActivityFull[];
  }

  export interface ISubType {
    sub_type: string;
  }