import { Injectable } from '@angular/core';
//import { SkipSelf } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { getServerErrorMessage } from '../shared/utility-functions';
import { catchError } from 'rxjs/operators';
import { throwError } from "rxjs";

import { Actividad, IActivityFull, ISubType, IActivityFullPage } from '../modelo/actividad';
import { environment } from './../../environments/environment';
import { Scout_CSV, Scout, Weed, Disease, Pest, Adversity, Fertilization, Semaforo, Semaforos } from '../modelo/scout';

const API_URL = environment.API_URL;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class ActividadService {

//  errorMsg: string;
//@SkipSelf() 
  constructor(private http: HttpClient) {}

/*  getActividadesCampo(campoId: number): Observable<Actividad[]> {
    return this.http.get<Actividad[]>(API_URL + 'actividad/getAll/'+campoId, { responseType: 'json' });
  }*/

  getActividad(actividadId: number): Observable<IActivityFull> {
    return this.http.get<IActivityFull>(API_URL + 'actividad/get/'+actividadId, { responseType: 'json' });
  }
  getActividades(campaniaId: number, clienteId: number, companyId: number, farmId: number, plotId: number, sub_type: string): Observable<Actividad[]> {
    return this.http.get<Actividad[]>(API_URL + 'actividad/getAll/'+campaniaId+'/'+clienteId+'/'+companyId+'/'+farmId+'/'+plotId+'/'+sub_type, { responseType: 'json' });
  }
  getActividadesFullPage(campaniaId: number, clienteId: number, companyId: number, farmId: number, plotId: number, sub_type: string, filtro_activo_por_fecha: number, fdesde: String, fhasta: String, currentpage: number, pagesize : number): Observable<IActivityFullPage> {
      return this.http.get<IActivityFullPage>(API_URL + 'actividad/getAllActivityScoutPage/'+campaniaId+'/'+clienteId+'/'+companyId+'/'+farmId+'/'+plotId+'/'+sub_type+'/'+filtro_activo_por_fecha+'/'+fdesde+'/'+fhasta+'/'+currentpage+'/'+pagesize, { responseType: 'json' });
  }  
  getActividadesFull(campaniaId: number, clienteId: number, companyId: number, farmId: number, plotId: number, sub_type: string, filtro_activo_por_fecha: number, fdesde: String, fhasta: String): Observable<IActivityFull[]> {
      return this.http.get<IActivityFull[]>(API_URL + 'actividad/getAllActivityScout/'+campaniaId+'/'+clienteId+'/'+companyId+'/'+farmId+'/'+plotId+'/'+sub_type+'/'+filtro_activo_por_fecha+'/'+fdesde+'/'+fhasta, { responseType: 'json' });
  }  
  getActividadesFullp(condiciones:any): Observable<IActivityFull[]> {
    return this.http.post<IActivityFull[]>(API_URL + 'actividad/getAllActivityScoutp', condiciones, { responseType: 'json' });
  }
  getActividadesFullPagep(condiciones:any): Observable<IActivityFullPage> {
    return this.http.post<IActivityFullPage>(API_URL + 'actividad/getAllActivityScoutPagep', condiciones, { responseType: 'json' });
  }  
  getFertilizations(idScout:number): Observable<Fertilization[]> {
      return this.http.get<Fertilization[]>(API_URL + 'actividad/getScoutFertilizations/'+idScout, { responseType: 'json' });
  }
  getActivityFertilizations(idActivity:number): Observable<Fertilization[]> {
    return this.http.get<Fertilization[]>(API_URL + 'actividad/getActivityFertilizations/'+idActivity, { responseType: 'json' });
  }  

  getSowingSummary(idActivity:number): Observable<any> {
    return this.http.get<any>(API_URL + 'actividad/getSowingSummary/'+idActivity, { responseType: 'json' });
  } 
  getDiseases(idScout:number): Observable<Disease[]> {
    return this.http.get<Disease[]>(API_URL + 'actividad/getScoutDiseases/'+idScout, { responseType: 'json' });
  }
  getPests(idScout:number): Observable<Pest[]> {
    return this.http.get<Pest[]>(API_URL + 'actividad/getScoutPests/'+idScout, { responseType: 'json' });
  }
  getAdversities(idScout:number): Observable<Adversity[]> {
    return this.http.get<Adversity[]>(API_URL + 'actividad/getScoutAdversities/'+idScout, { responseType: 'json' });
  }
  getWeeds(idScout:number): Observable<Weed[]> {
    return this.http.get<Weed[]>(API_URL + 'actividad/getScoutWeeds/'+idScout, { responseType: 'json' });
  }      

  getScouts(idActividad:number): Observable<Scout[]> {
      return this.http.get<Scout[]>(API_URL + 'actividad/getAllScouts/'+idActividad, { responseType: 'json' });
  }

  getScoutsCSV(idActividad:number, idScout: number): Observable<Scout_CSV[]> {
    return this.http.get<Scout_CSV[]>(API_URL + 'actividad/getAllScoutsCSV/'+idActividad+'/'+idScout, { responseType: 'json' });
  }

  getScout(idScout:number): Observable<Scout> {
    return this.http.get<Scout>(API_URL + 'actividad/getScout/'+idScout, { responseType: 'json' });
  }

  getSubTypes(): Observable<ISubType[]> {
    return this.http.get<ISubType[]>(API_URL + 'actividad/getCultivos', { responseType: 'json' });
  }  
  
  deleteActividad(idActividad:number): Observable<any>  {
    return this.http.post<any>(API_URL + 'actividad/delete/'+idActividad, { responseType: 'json' })
  }

  deleteFisicoActividad(idActividad:number): Observable<any>  {
    return this.http.delete<any>(API_URL + 'actividad/'+idActividad)
  }

  deleteScout(idScout:number): Observable<Scout>  {
    return this.http.delete<any>(API_URL + 'scout/'+idScout)
  }

  updateActividad(actividad:Actividad): Observable<Actividad>  {
    return this.http.put<any>(API_URL + 'actividad', actividad , { responseType: 'json' })
  }  

  addActividad(actividad:Actividad): Observable<Actividad>  {
    return this.http.post<any>(API_URL + 'actividad/add', actividad , { responseType: 'json' })
  }

  updateSemaforos(semaforos:Semaforos): Observable<Semaforos>  {
    return this.http.put<any>(API_URL + 'semaforos', semaforos , { responseType: 'json' })
  }

  getNotes(idScout:number, tabla: string): Observable<any> {
    return this.http.get<any>(API_URL + 'scout/getNotes/'+tabla+'/'+idScout, { responseType: 'json' });
  }

  updateNotes(scoutId: number, tabla: string, valor: string): Observable<any>  {
    const notas = {'scout_id': scoutId, 'tabla' : tabla, 'valor': valor};
    return this.http.put<any>(API_URL + 'scout/notes', notas , { responseType: 'json' })
  }  

  getMedia(idScout:number): Observable<any> {
    return this.http.get<any>(API_URL + 'scout/getMediaList/'+idScout, { responseType: 'json' });
  }

}