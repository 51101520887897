<ng-container *ngIf="!scouts or loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>
<div class="activity-scouts-container">
  <div class="accordion-container" *ngIf="showFilters">
    <mat-accordion multi>

      <mat-expansion-panel (opened)="onOpenedSemaforo()" (closed)="onClosedSemaforo()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b>Semáforo</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <multi-filter [filters]="semaphoreFilters" (update)="this.updateFilter($event)" isSemaphore="true"></multi-filter>

      </mat-expansion-panel>

      <mat-expansion-panel (opened)="onOpenedDecision()" (closed)="onClosedDecision()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b>Decisión</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <multi-filter [filters]="decisionFilters" (update)="this.updateFilter($event)"></multi-filter>

      </mat-expansion-panel>

    </mat-accordion>
  </div>
  <puntal-table
    *ngIf="scouts && !loading"
    tableTitle={{titulo}}
    [dataSource]="scouts"
    [displayedColumns]="scoutColumns"
    [noDownload]="false"
    (onEdit)="editRow($event)"
    (onDelete)="deleteRow($event)"
    (onRowClick)="navigateTo($event)"
    (onDownload)="downloadRow($event)"
    (onBack)="goBack()"
    (onShowPermissionClick)="openMedia($event)"
    (onShowFilters)="toggleDiv()"
    [showPermissionButton]="canAddRecord"
    [showFiltersButton]="showFilterMode"
    >
  </puntal-table>
</div>
