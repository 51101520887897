<div class="admin-catalog-view-container">
    <div *ngIf="catalogData; else loading" class="catalog-view-table-container">
      <ng-container>  <!-- *ngIf="showTable; else itemForm" -->
        <puntal-table
          tableTitle="{{catalogName}}"
          [dataSource]="catalogData" 
          [displayedColumns]="tableColumns"
          (onEdit)="editItem($event)"
          (onDelete)="deleteItem($event)"
          (onRowClick)="viewCatalogCard($event)"
          (onBack)="goBack()"
          (onAdd)="addRow()"
          [noEdit]="noEdit"
          [noDelete]="noDelete"          
          [showAddButton]="canAddRecord"
          [showPermissionButton]='N' 
          (onShowPermissionClick)="openProperties($event)"
          >
          <!-- [showPermissionButton]="catalogName =='Insumo'?'S':'N'"  -->
        </puntal-table>
<!--         <div class="buttons">
          <button mat-raised-button color="secondary" (click)="goToCatalogs()">CATALOGOS</button>
          <button mat-flat-button color="primary" (click)="add()">AGREGAR</button>
        </div> -->
      </ng-container>
<!--       <ng-template #itemForm>
        <ng-container *ngIf="catalogData && catalogData.metadata">
          <admin-catalog-form title="Agregar {{catalogName}}" [metadata]="catalogData.metadata" [values]="selectedItem" (exit)="onCloseForm($event)"></admin-catalog-form>
        </ng-container>
      </ng-template> -->
    </div>
    <ng-template #loading>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-template>
  </div>
  
