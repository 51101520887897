import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Lote } from '../modelo/lote';
import { LoteService } from '../_services/lote.service';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../shared/table/table.component';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoteFormComponent } from './forms/lote-form/lote-form.component';
import { ConfirmedDialogComponent } from '../shared/dialogs/confirmed-dialog/confirmed-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { ToastService } from '../shared/toast/toast.service';
import { VerPermisosFormComponent } from '../shared/ver-permisos-form/ver-permisos-form.component';

@Component({
  selector: 'app-lotes',
  templateUrl: './lotes.component.html',
  styleUrls: ['../clientes/clientes.component.scss']
})

export class LotesComponent implements OnInit {
  loading = false;
  sus : any;
  isLoggedIn = false;
  campoId: number;
  titulo: string = 'Lotes';
  
  @ViewChild(PuntalTableComponent,{static:true}) table: PuntalTableComponent<any>;

  public lotes: Lote[] = []; 
  
    readonly header: ITableHeader<Lote>[] = [
/*     {
      label: 'id',
      value: 'id',
    }, */
    {
      label: 'lote',
      value: 'Lote',
      valueFunction: lote => `${lote.name}`
    },
    {
      label: 'poligono',
      value: 'polygon',
    },
    {
      label: 'area',
      value: 'area',
      valueFunction: lote => { return (Math.round(lote.area * 100) / 100).toFixed(2) }
    },
    {
      label: 'estado',
      value: 'down_date',
      valueFunction: lote => {return (lote.down_date == null) ?  ' ' : 'Inactivo'}
    }
  ];

  readonly tableOptions: ITableOptions<Lote> = {
    canEdit: (lote) => true, 
    canDelete: (lote) => ((this.canAddRecord == 'S') && (lote.down_date == null))
  };
  canAddRecord = 'N';

  constructor(private loteService: LoteService,   private dialog: MatDialog, private location: Location ,
  private router: Router,
  private route: ActivatedRoute,
  private authService: AuthService,
  private toastService: ToastService) { }


  ngOnInit(): void {
    this.loading = true;
    this.route.paramMap.subscribe(params => {
      if (params.get('campoId')) {
        this.campoId = Number(params.get('campoId'));
        // Proponemos el título de la ventana
        if(history.state.data){
          this.titulo = 'Lotes de '+history.state.data;
          sessionStorage.setItem('lc_'+this.campoId, history.state.data);
        }
        else{
          let titulo_guardado = sessionStorage.getItem('lc_'+this.campoId)
          if (titulo_guardado) {
            this.titulo = 'Lotes de '+titulo_guardado;
          }
          else {
            this.titulo = 'Lotes';
          }
        }      
        /* this.canAddRecord = (this.authService.CanAdd('plot',params.get('clientId'),params.get('empresaId'),params.get('campoId')) ? 'S' : 'N' );  
        this.retrieveData(); */
        this.authService.CanAdd('plot',params.get('clientId'),params.get('empresaId'),params.get('campoId')) 
        .then((data) => { 
          this.canAddRecord = (data ? 'S' : 'N'); 
          this.retrieveData();
        });        
      }
    });    
  }

  private retrieveData(){
    this.loading = true;
    this.sus = this.loteService.getLotes(this.campoId).subscribe((lotes) => {
      this.lotes = lotes;
      this.loading = false;
    },
    error => {
      this.toastService.openSnackBar(`Error al leer lotes: ${error}`, 'OK');
      this.loading = false;
    });
  }

  editRow(row: Lote) {
    const dialogRef = this.openModal(row);
    dialogRef.afterClosed().subscribe(
      (newLote: Lote) => {
        if (newLote) {
          this.loteService.updateLote(newLote).subscribe( ()  => {
          },
          error => {
            this.retrieveData();
            this.toastService.openSnackBar(`Error al actualizar el campo ${row.name}: ${error}`, 'OK');
          });
        }
      }
    );
  }

  deleteRow(row: Lote) {
    const data = {
      title: `ATENCIÓN: Borrar lote '${row.name}'`,
      message: 'Está seguro? Al borrar lote se perderan todos sus lotes con sus actividades y sus recorridas',
      validation: row.name
    };
    const dialogRef = this.dialog.open(ConfirmedDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      width: 'auto',
      data
    });
    const id_a_borrar = row.id;
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.loteService.deleteLote(id_a_borrar).subscribe(
            ()  => {
              this.retrieveData();
            },
            error => {
              this.toastService.openSnackBar(`Error al eliminar el lote ${row.name}: ${error}`, 'OK');
            }
          );
        }
      }
    );
  }

  
  goBack() {
    this.location.back();
  }


  addRow() {
    const dialogRef = this.openModal();
    dialogRef.afterClosed().subscribe(
      (newLote: Lote) => {
        if (newLote) {
          newLote.farm_id = this.campoId;
          this.loteService.addLote(newLote).subscribe(
            (lote)  => {
              this.retrieveData();
          },
          error => {
            this.toastService.openSnackBar(`Error al agregar el lote ${newLote.name}: ${error}`, 'OK');
          }
        )
      }
    }
    )
  }

  private openModal(lote?: Lote): MatDialogRef<LoteFormComponent, any> {
    return this.dialog.open(LoteFormComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: true,
      data: lote || {}
    });
  }
 
  get canAdd() {
  //  return this.authService.canAdd();
  return true;
  }

   openPermission(lote: Lote) { 
    // Los permisos sobre el lote son los que tiene sobre el campo
      this.dialog.open(VerPermisosFormComponent, {
        width: '1000px',
        disableClose: true,
        autoFocus: true,
        data: {
          id: lote.farm_id,
          description: lote.name,
          type: 'farm'
        }
      });
    }  

  ngOndestroy() {
    this.sus.unsubscribe();
  }

  isAuthenticated() {
    return true;
  }
}
