<div class="filter-container">
    <div class="filters">
      <ng-container *ngFor="let field of filters.order">
        <div>
          <mat-form-field>
            <mat-label>{{filters.info[field].name}}</mat-label>
             <mat-select multiple (selectionChange)="changeSelect($event, field)">
              <mat-select-trigger>
                <ng-container *ngIf="allSelected(field); else showSemaphores">
                  Mostrar todos
                </ng-container>
                <ng-template #showSemaphores>
                  <ng-container *ngFor="let value of filters.select[field]">
                    <ng-container *ngIf="isSemaphore; else text">
                      <span class="semaphore small" [ngClass]="semaphoreClassType(value,field)">{{value === 'no-data' ? 'Sin datos' : '⬤'}}</span>
                    </ng-container>
                    <ng-template #text>
                      <span class="small-text">{{selectedTextPretty(field)}}</span>
                    </ng-template>
                  </ng-container>
                </ng-template>
              </mat-select-trigger>
              <mat-option *ngFor="let value of filters.values" [value]="value">
                <ng-container *ngIf="isSemaphore; else text">
                  <span class="semaphore" [ngClass]="semaphoreClassType(value,field)">{{value === 'no-data' ? 'Sin datos' : '⬤'}}</span>
                </ng-container>
                <ng-template #text>
                  <span>{{value}}</span>
                </ng-template>
              </mat-option>
            </mat-select> 
          </mat-form-field>
        </div>
      </ng-container>
      <div class="clean-button-container">
        <button mat-icon-button aria-label="Limpiar filtros" class="clean" [ngClass]="{disable: !anyFilter()}" (click)="clean()">
          <mat-icon>delete_sweep</mat-icon>
        </button>
      </div>
    </div>
  </div>
  