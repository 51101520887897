import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { PropertyAddComponent } from '../catalogos_properties/property-add/property-add.component';
import { CatalogoService } from '../../_services/catalogo.service';
import { IProperty, Catalog_item_list, Catalog, CatalogItemModal, CatalogContentItem, CATALOGS_NAME } from '../../modelo/catalogo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-catalogos-properties',
  templateUrl: './catalogos-properties.component.html',
  styleUrls: ['./catalogos-properties.component.scss']
})
export class CatalogosPropertiesComponent implements OnInit {
  public canAdd = false;
  public titulo : string;
  public prop_List: IProperty[] = []; 

  @ViewChild(PropertyAddComponent, {static: false}) addComponent: PropertyAddComponent;


  constructor(
    @Inject(MAT_DIALOG_DATA) public itemCatalogoModal: CatalogItemModal,
    private dialogRef: MatDialogRef<CatalogosPropertiesComponent>,
    private catalogoService: CatalogoService
  ) { }

  ngOnInit(): void {
    if ((!this.itemCatalogoModal) || (Object.keys(this.itemCatalogoModal).length === 0)) {
      this.dialogRef.close(null);
      return;
    }
//    this.matcher = new ShowOnDirtyErrorStateMatcher();
      this.titulo = 'Propiedades'; 
      if (this.itemCatalogoModal.record != undefined) {
        if (this.itemCatalogoModal.record.name != undefined) {
          this.titulo = 'Propiedades de ' + this.itemCatalogoModal.record.name; 
      }}
      
 
  }
  close() { 
    this.dialogRef.close(null);
}

}
