<!-- Sidenav -->
<!-- <mat-sidenav-container class="app-sidenav-container"> -->
<mat-sidenav #subnav class="sidenav" [ngStyle]="{ 'width.em': sidenavWidth }" [opened]="true" [fixedInViewport]="false" [fixedTopGap]="0" [fixedBottomGap]="0" mode="over">
    <mat-nav-list>
        <div *ngIf="isUserAdmin">
            <mat-list-item routerLink="/usuarios">
                <mat-icon mat-list-icon>person_add</mat-icon>
                <div fxFlex="10"></div>
                <div class="sidenav-item">
                    <h5 class="lead">Usuarios</h5>
                </div>
            </mat-list-item>
        </div>
        <div *ngIf="isSuperUser">
            <mat-list-item routerLink="/catalogos">
                <mat-icon mat-list-icon>settings</mat-icon>
                <div fxFlex="10"></div>
                <div class="sidenav-item">
                    <h5 class="lead">Catalogos</h5>
                </div>
            </mat-list-item>
        </div>
        <div *ngIf="isSuperUser">
            <mat-list-item routerLink="/home">
                <mat-icon aria-label="back" matTooltip="Volver">arrow_back</mat-icon>
                <div fxFlex="10"></div>
                <div class="sidenav-item">
                    <h5 class="lead">Volver</h5>
                </div>
            </mat-list-item>
        </div>
    </mat-nav-list>
</mat-sidenav>

<!-- </mat-sidenav-container> -->