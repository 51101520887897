export class PermisoOtorgado {
    user_id: number;
    client_id: number;
    client_name: string;
    company_id: number;
    company_name: string;
    farm_id: number;
    farm_name: string;
    role: string;
}

export interface IPermisosEfectivos {
    user_id: number;
    user_name: string;
    role: string;
}