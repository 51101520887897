import {Component, OnInit, Inject, Input, ViewChild} from '@angular/core';

import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { CatalogoService } from '../../_services/catalogo.service';
import { Catalog_item_list, Catalog, CatalogItemModal, CatalogContentItem, CATALOGS_NAME, IProperty } from '../../modelo/catalogo';
import * as moment from 'moment';

import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

//import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-catalogos-view-more',
  templateUrl: './catalogos-view-more.component.html',
  styleUrls: ['./catalogos-view-more.component.scss']
})
export class CatalogosViewMoreComponent implements OnInit {
  public matcher: ErrorStateMatcher;
  public catalogoMoreFormControl: FormGroup ;
  public titulo : string;
  public itemCatalogo: CatalogContentItem;
  public crop_specie_List: Catalog_item_list[] = []; 
  public pest_List: Catalog_item_list[] = []; 
  public weed_type_List: Catalog_item_list[] = []; 
  public crop_use_List: Catalog_item_list[] = []; 
  public crop_sowing_List: Catalog_item_list[] = []; 
  public input_type_List: Catalog_item_list[] = []; 
  public input_company_List: Catalog_item_list[] = []; 
  public prop_List: IProperty[] = []; 
  public selectedcrop_specie: number;
  public selectedpest: number;
  public selectedweed_type: number
  public selectedcrop_use: number;
  public selectedcrop_sowing: number;
  public selectedinput_type: number;
  public selectedinput_company: number;
  public loading = true;
  public operacion = 'C';
  public creationDate: any;
  private lista_vacia: Catalog_item_list;
  k_tipo_insumo_semillas = 9;

  
  @ViewChild('select') select: MatSelect;

  allSelected=false;
  crop_specie_pest_List : number[] = [];
  
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

   optionClick() {
    let newStatus = true;
    var i = 0;
    this.select.options.forEach((item: MatOption) => {
      
      if (!item.selected) {
        newStatus = false;
      }
      i++;
    });
    this.allSelected = newStatus;
  }

 /* retrieveOptions(){
    var i = 0;
    this.select.options.forEach((item: MatOption) => {
      console.log(this.crop_specie_pest_List[i]);
      if (this.crop_specie_pest_List[i].value == 0) {
        item.select();
      }
      else {
        item.deselect();
      }
      i = i + 1;
    });
  }*/

  constructor(
    @Inject(MAT_DIALOG_DATA) public itemCatalogoModal: CatalogItemModal,
    private dialogRef: MatDialogRef<CatalogosViewMoreComponent>,
    private catalogoService: CatalogoService, 
    private formBuilder: FormBuilder
    ) {}

  public ngOnInit() {
    if ((!this.itemCatalogoModal) || (Object.keys(this.itemCatalogoModal).length === 0)) {
      this.dialogRef.close(null);
      return;
    }
    this.lista_vacia = {
      id: 0,
      name: ''
    }
  //  this.catalogoMoreFormControl = this.createFormControl();
  //  console.log(this.catalogoMoreFormControl);
    this.matcher = new ShowOnDirtyErrorStateMatcher();
    this.operacion = this.itemCatalogoModal.operation;
    if (this.itemCatalogoModal.operation == 'A') {
      this.titulo = 'Agregar ' + CATALOGS_NAME[this.itemCatalogoModal.catalog_id];  
    }
    else {
      if (this.itemCatalogoModal.operation == 'E') {
        this.titulo = 'Editar ' + CATALOGS_NAME[this.itemCatalogoModal.catalog_id];  
      }
      else {
        this.titulo = 'Consultar ' + CATALOGS_NAME[this.itemCatalogoModal.catalog_id]; 
      }
    }

    const tabla = this.itemCatalogoModal.catalog_id;

    switch (tabla) {
      case 'crop_specie':
      case 'crop_use':
      case 'crop_sowing':
      case 'input_company':
      case 'input_type':  
      case 'stubble_cover_level':     
      case 'weed_type':            
        if ((!this.itemCatalogoModal.record) || !(this.itemCatalogoModal.record.id) || (this.itemCatalogoModal.operation == 'A')) {
          this.itemCatalogo = {
          id: 0,
          name: ''
          } as CatalogContentItem;
        }
        else
          this.itemCatalogo = this.itemCatalogoModal.record;

          this.catalogoMoreFormControl = this.formBuilder.group({
          name: [{value: this.itemCatalogo.name, disabled: (this.operacion === 'C') }, [Validators.required, Validators.minLength(3)]],
          creation_date: [{value: this.itemCatalogo.creation_date, disabled:true}]
        });
        this.loading = false;
        break;
      case 'achieved_density':
      case 'cultivar_technology':  
        if ((!this.itemCatalogoModal.record) || !(this.itemCatalogoModal.record.id) || (this.itemCatalogoModal.operation == 'A')) {
          this.itemCatalogo = {
          id: 0,
          name: '',
          crop_specie_id: 0
          } as CatalogContentItem;
        }
        else {
          this.itemCatalogo = this.itemCatalogoModal.record;
        }

        this.catalogoService.getCatalogoLista('crop_specie').subscribe((list) => {
          this.crop_specie_List = list;
          this.crop_specie_List.unshift(this.lista_vacia);
          if ((this.itemCatalogo.crop_specie_id) && (this.itemCatalogo.crop_specie_id != 0)) {
            this.selectedcrop_specie = this.itemCatalogo.crop_specie_id;
          }

          this.catalogoMoreFormControl = this.formBuilder.group({
          name: [{value: this.itemCatalogo.name, disabled: (this.operacion == 'C') }, [Validators.required, Validators.minLength(3)]],
          crop_specie_id: [{value: this.itemCatalogo.crop_specie_id, disabled: ((this.operacion == 'C') || (this.crop_specie_List.length == 0))}, [Validators.required]],
          creation_date: [{value: this.itemCatalogo.creation_date, disabled:true}]
          });
          this.loading = false;
        });   

        break;
     case 'crop_specie_pest':
   /*      if ((!this.itemCatalogoModal.record) || !(this.itemCatalogoModal.record.id) || (this.itemCatalogoModal.operation == 'A')) {
          this.itemCatalogo = {
          id: 0,
          name: '',
          crop_specie_id: 0,
          pest_id: 0
          } as CatalogContentItem;
        }
        else {
          this.itemCatalogo = this.itemCatalogoModal.record;
        }

        this.catalogoService.getCatalogoLista('crop_specie').subscribe((list) => {
          this.crop_specie_List = list;
          this.crop_specie_List.unshift(this.lista_vacia);
          if ((this.itemCatalogo.crop_specie_id) && (this.itemCatalogo.crop_specie_id != 0)) {
            this.selectedcrop_specie = this.itemCatalogo.crop_specie_id;
          }
          this.catalogoService.getCatalogoLista('pest').subscribe((list) => {
            this.pest_List = list;
            this.pest_List.unshift(this.lista_vacia);
            if ((this.itemCatalogo.pest_id) && (this.itemCatalogo.pest_id != 0)) {
              this.selectedpest = this.itemCatalogo.pest_id;
            }          
            this.catalogoMoreFormControl = this.formBuilder.group({
              name: [{value: this.itemCatalogo.name, disabled: (this.operacion === 'C') }, [Validators.required, Validators.minLength(3)]],
              pest_id: [this.itemCatalogo.pest_id, [Validators.required]],
           //   crop_specie_id: [{value: this.itemCatalogo.crop_specie_id, disabled: ((this.operacion == 'C') || (this.crop_specie_List.length == 0))}, [Validators.required]],
              creation_date: [{value: this.itemCatalogo.creation_date, disabled:true}]
            });              
            this.loading = false;
          });
        });     */       
        break;
      case 'crop_stage':
        if ((!this.itemCatalogoModal.record) || !(this.itemCatalogoModal.record.id) || (this.itemCatalogoModal.operation == 'A')) {
          this.itemCatalogo = {
          id: 0,
          name: '',
          crop_specie_id: 0
          } as CatalogContentItem;
        }
        else {
          this.itemCatalogo = this.itemCatalogoModal.record;
        }

        this.catalogoService.getCatalogoLista('crop_specie').subscribe((list) => {
          this.crop_specie_List = list;
          this.crop_specie_List.unshift(this.lista_vacia);
          if ((this.itemCatalogo.crop_specie_id) && (this.itemCatalogo.crop_specie_id != 0)) {
            this.selectedcrop_specie = this.itemCatalogo.crop_specie_id;
          }

          this.catalogoMoreFormControl = this.formBuilder.group({
            name: [{value: this.itemCatalogo.name, disabled: (this.operacion === 'C') }, [Validators.required, Validators.minLength(3)]],
            description: [{value: this.itemCatalogo.description, disabled: (this.operacion === 'C') }],
            crop_specie_id: [{value: this.itemCatalogo.crop_specie_id, disabled: ((this.operacion == 'C') || (this.crop_specie_List.length == 0))}, [Validators.required]],
            creation_date: [{value: this.itemCatalogo.creation_date, disabled:true}]
          });
          this.loading = false;
        });
        break;

      case 'disease':
        if ((!this.itemCatalogoModal.record) || !(this.itemCatalogoModal.record.id) || (this.itemCatalogoModal.operation == 'A')) {
          this.itemCatalogo = {
          id: 0,
          name: '',
          scientific_name: '', 
          crop_specie_id: 0
          } as CatalogContentItem;
        }
        else {
          this.itemCatalogo = this.itemCatalogoModal.record;
        }

        this.catalogoService.getCatalogoLista('crop_specie').subscribe((list) => {
          this.crop_specie_List = list;
          this.crop_specie_List.unshift(this.lista_vacia);
          if ((this.itemCatalogo.crop_specie_id) && (this.itemCatalogo.crop_specie_id != 0)) {
            this.selectedcrop_specie = this.itemCatalogo.crop_specie_id;
          }        
          this.catalogoMoreFormControl = this.formBuilder.group({
            name: [{value: this.itemCatalogo.name, disabled: (this.operacion === 'C') }, [Validators.required, Validators.minLength(3)]],
            scientific_name: [{value: this.itemCatalogo.scientific_name, disabled: (this.operacion === 'C') }],
            crop_specie_id: [{value: this.itemCatalogo.crop_specie_id, disabled: ((this.operacion == 'C') || (this.crop_specie_List.length == 0))}, [Validators.required]],
            creation_date: [{value: this.itemCatalogo.creation_date, disabled:true}]
          });
          this.loading = false;
        });
        break;
      case 'weed':
        if ((!this.itemCatalogoModal.record) || !(this.itemCatalogoModal.record.id) || (this.itemCatalogoModal.operation == 'A')) {
          this.itemCatalogo = {
          id: 0,
          name: '',
          scientific_name: '', 
          weed_type_id: 0
          } as CatalogContentItem;
        }
        else {
          this.itemCatalogo = this.itemCatalogoModal.record;
        }

        this.catalogoService.getCatalogoLista('weed_type').subscribe((list) => {
          this.weed_type_List = list;
          this.weed_type_List.unshift(this.lista_vacia);
         
          if (this.itemCatalogo.weed_type_id == null) {this.itemCatalogo.weed_type_id = 0;}
          if ((this.itemCatalogo.weed_type_id) && (this.itemCatalogo.weed_type_id != 0)) {
            this.selectedweed_type = this.itemCatalogo.weed_type_id;
          }                
          this.catalogoMoreFormControl = this.formBuilder.group({
            name: [{value: this.itemCatalogo.name, disabled: (this.operacion === 'C') }, [Validators.required, Validators.minLength(3)]],
            scientific_name: [{value: this.itemCatalogo.scientific_name, disabled: (this.operacion === 'C') }],
            weed_type_id: [this.itemCatalogo.weed_type_id, [Validators.required]],
            creation_date: [{value: this.itemCatalogo.creation_date, disabled:true}]
          });
          this.loading = false;
        });
        break;
      case 'crop':  
        if ((!this.itemCatalogoModal.record) || !(this.itemCatalogoModal.record.id) || (this.itemCatalogoModal.operation == 'A')) {
          this.itemCatalogo = {
          id: 0,
          name: '',
          crop_specie_id: 0,
          crop_use_id: 0,
          crop_sowing_id: 0
          } as CatalogContentItem;
        }
        else {
          this.itemCatalogo = this.itemCatalogoModal.record;
        }

        this.catalogoService.getCatalogoLista('crop_specie').subscribe((list) => {
          this.crop_specie_List = list;
          this.crop_specie_List.unshift(this.lista_vacia);
          if ((this.itemCatalogo.crop_specie_id) && (this.itemCatalogo.crop_specie_id != 0)) {
            this.selectedcrop_specie = this.itemCatalogo.crop_specie_id;
          }
          this.catalogoService.getCatalogoLista('crop_use').subscribe((list) => {
            this.crop_use_List = list;
            this.crop_use_List.unshift(this.lista_vacia);
            if ((this.itemCatalogo.crop_use_id) && (this.itemCatalogo.crop_use_id != 0)) {
              this.selectedcrop_use = this.itemCatalogo.crop_use_id;
            }    
            this.catalogoService.getCatalogoLista('crop_sowing').subscribe((list) => {
              this.crop_sowing_List = list;
              this.crop_sowing_List.unshift(this.lista_vacia);
              if ((this.itemCatalogo.crop_sowing_id) && (this.itemCatalogo.crop_sowing_id != 0)) {
                this.selectedcrop_sowing = this.itemCatalogo.crop_sowing_id;
              }          
              this.catalogoMoreFormControl = this.formBuilder.group({
                name: [{value: this.itemCatalogo.name, disabled: (this.operacion === 'C') }, [Validators.required, Validators.minLength(3)]],
                crop_specie_id: [{value: this.itemCatalogo.crop_specie_id, disabled: ((this.operacion == 'C') || (this.crop_specie_List.length == 0))}, [Validators.required]],
                crop_use_id: [this.itemCatalogo.crop_use_id, [Validators.required]],
                crop_sowing_id: [this.itemCatalogo.crop_sowing_id],
                creation_date: [{value: this.itemCatalogo.creation_date, disabled:true}]
              });     
            });                  
            this.loading = false;
          });
        });            
        break;
      case 'input':
        if ((!this.itemCatalogoModal.record) || !(this.itemCatalogoModal.record.id) || (this.itemCatalogoModal.operation == 'A')) {
          this.itemCatalogo = {
          id: 0,
          name: '',
          crop_specie_id: 0,
          input_type_id: 0,
          input_company_id: 0
          } as CatalogContentItem;
        }
        else {
          this.itemCatalogo = this.itemCatalogoModal.record;
          if (this.itemCatalogo.input_type_id != this.k_tipo_insumo_semillas) {
            this.catalogoService.getPropertyList(this.itemCatalogo.id,this.itemCatalogo.input_type_id)
            .subscribe((prop) => {/*this.prop_List = prop*/
            Object.assign(this.prop_List, prop);
            });
          } 
        }

        this.catalogoService.getCatalogoLista('crop_specie').subscribe((list) => {
          this.crop_specie_List = list;
          this.crop_specie_List.unshift(this.lista_vacia);
          if ((this.itemCatalogo.crop_specie_id) && (this.itemCatalogo.crop_specie_id != 0)) {
            this.selectedcrop_specie = this.itemCatalogo.crop_specie_id;
          }
          this.catalogoService.getCatalogoLista('input_type').subscribe((list) => {
            this.input_type_List = list;
            this.input_type_List.unshift(this.lista_vacia);
            if ((this.itemCatalogo.input_type_id) && (this.itemCatalogo.input_type_id != 0)) {
              this.selectedinput_type = this.itemCatalogo.input_type_id;
            }    
            this.catalogoService.getCatalogoLista('input_company').subscribe((list) => {
              this.input_company_List = list;
              this.input_company_List.unshift(this.lista_vacia);
              if ((this.itemCatalogo.input_company_id) && (this.itemCatalogo.input_company_id != 0)) {
                this.selectedinput_company = this.itemCatalogo.input_company_id;
              }          
              this.catalogoMoreFormControl = this.formBuilder.group({
                name: [{value: this.itemCatalogo.name, disabled: (this.operacion === 'C') }, [Validators.required, Validators.minLength(3)]],
                crop_specie_id: [{value: this.itemCatalogo.crop_specie_id, disabled: ((this.operacion == 'C') || (this.crop_specie_List.length == 0))}],
                input_type_id: [this.itemCatalogo.input_type_id, [Validators.required]],
                input_company_id: [this.itemCatalogo.input_company_id],
                creation_date: [{value: this.itemCatalogo.creation_date, disabled:true}]
              });
            });                  
            this.loading = false;
          });
        });            
        break;

      case 'input_property':
        if ((!this.itemCatalogoModal.record) || !(this.itemCatalogoModal.record.id) || (this.itemCatalogoModal.operation == 'A')) {
          this.itemCatalogo = {
          id: 0,
          name: '',
          input_type_id: 0,
          } as CatalogContentItem;
        }
        else {
          this.itemCatalogo = this.itemCatalogoModal.record;
        }

          this.catalogoService.getCatalogoLista('input_type').subscribe((list) => {
            this.input_type_List = list;
            this.input_type_List.unshift(this.lista_vacia);
            if ((this.itemCatalogo.input_type_id) && (this.itemCatalogo.input_type_id != 0)) {
              this.selectedinput_type = this.itemCatalogo.input_type_id;
            }    
              this.catalogoMoreFormControl = this.formBuilder.group({
                name: [{value: this.itemCatalogo.name, disabled: (this.operacion === 'C') }, [Validators.required, Validators.minLength(3)]],
                input_type_id: [this.itemCatalogo.input_type_id, [Validators.required]],
                creation_date: [{value: this.itemCatalogo.creation_date, disabled:true}]
              });
            });                  
            this.loading = false;

     
        break;
  
      case 'pest':
        
        if ((!this.itemCatalogoModal.record) || !(this.itemCatalogoModal.record.id) || (this.itemCatalogoModal.operation == 'A')) {
          this.itemCatalogo = {
          id: 0,
          name: '',
          scientific_name: '',
          species : 'T'              // Por default afecta a todas las especies
          } as CatalogContentItem;
        }
        else
          this.itemCatalogo = this.itemCatalogoModal.record;
          this.catalogoMoreFormControl = this.formBuilder.group({
            name: [{value: this.itemCatalogo.name, disabled: (this.operacion === 'C') }, [Validators.required, Validators.minLength(3)]],
            scientific_name: [{value: this.itemCatalogo.scientific_name, disabled: (this.operacion === 'C') }, [Validators.required, Validators.minLength(3)]],
            creation_date: [{value: this.itemCatalogo.creation_date, disabled:true}]
        });
        this.allSelected = (this.itemCatalogo.species == 'T');
        this.catalogoService.getCatalogoLista('crop_specie').subscribe((listEspecies) => {
          this.crop_specie_List = listEspecies;
          if ((this.itemCatalogoModal.operation != 'A') && (this.itemCatalogo.species != 'T')) {
          this.catalogoService.getCropSpecieList(this.itemCatalogo.id).subscribe((list) => {
            this.crop_specie_pest_List = list.map(reg => reg.id);
            this.loading = false;
          },
          error => {
          //  this.toastService.openSnackBar(`Error al leer especies asociadas: ${error}`, 'OK');
            this.loading = false;
          });
          
        }           
        else {
          this.crop_specie_pest_List = listEspecies.map(reg => reg.id);
          //this.crop_specie_pest_List = [];
          this.loading = false;
         }}
        ,
        error => {
        //  this.toastService.openSnackBar(`Error al leer especies asociadas: ${error}`, 'OK');
          this.loading = false;
        });
        break;
      default: 
      this.catalogoMoreFormControl = this.formBuilder.group({
          name: [{value: this.itemCatalogo.name, disabled: (this.operacion === 'C') }, [Validators.required, Validators.minLength(3)]],
          creation_date: [{value: this.itemCatalogo.creation_date, disabled:true}]
        });
        this.loading = false;
        break;
      }
      this.creationDate = moment(this.itemCatalogo.creation_date).format("DD/MM/YYYY");
     
}


   oncrop_specieSelect(valor: number) { // Campania) {
    if (this.selectedcrop_specie && this.selectedcrop_specie == valor) {
      return;
    }
    this.selectedcrop_specie = valor;
    this.itemCatalogo.crop_specie_id = valor;
  }
 onpestSelect(valor: number) { // Campania) {
    if (this.selectedpest && this.selectedpest == valor) {
      return;
    }
    this.selectedpest = valor;
    this.itemCatalogo.pest_id = valor;
  }
  onweed_typeSelect(valor: number) { // Campania) {
    if (this.selectedweed_type && this.selectedweed_type == valor) {
      return;
    }
    this.itemCatalogo.weed_type_id = valor;
    this.selectedweed_type = valor;
  }

  oncrop_useSelect(valor: number) { // Campania) {
    if (this.selectedcrop_use && this.selectedcrop_use == valor) {
      return;
    }
    this.selectedcrop_use = valor;
    this.itemCatalogo.crop_use_id = valor;    
  }

  oncrop_sowingSelect(valor: number) { // Campania) {
    if (this.selectedcrop_sowing && this.selectedcrop_sowing == valor) {
      return;
    }
    this.selectedcrop_sowing = valor;
    this.itemCatalogo.crop_sowing_id = valor;       
  }

  oninput_typeSelect(valor: number) { // Campania) {
    if (this.selectedinput_type && this.selectedinput_type == valor) {
      return;
    }
    this.selectedinput_type = valor;
    this.itemCatalogo.input_type_id = valor; 
    if (valor != this.k_tipo_insumo_semillas) {
      this.catalogoService.getPropertyList(this.itemCatalogo.id,this.selectedinput_type)
      .subscribe(prop => {/*this.prop_List = prop*/
      this.prop_List = Object.assign(this.prop_List, prop);
      this.prop_List.map((elem)=>{
        if (this.itemCatalogo['id']) {elem.input_id = this.itemCatalogo['id']}
        elem.text_value = '';
   
      });
     
      },
      err => {
          console.log('ERROR:',err);
      });


    }  
  }

  oninput_companySelect(valor: number) { // Campania) {
    if (this.selectedinput_company && this.selectedinput_company == valor) {
      return;
    }
    this.selectedinput_company = valor;
    this.itemCatalogo.input_company_id = valor;   
  }

  save() {
    if (this.catalogoMoreFormControl.invalid) { return; }
    var itemCatalogoModified: CatalogContentItem;
    itemCatalogoModified = Object.assign(this.itemCatalogo, this.catalogoMoreFormControl.value);

    if (this.itemCatalogo.input_type_id != undefined) {
    
      if ((this.selectedinput_type === undefined) || (this.selectedinput_type == 0)) {
        this.catalogoMoreFormControl.controls["input_type_id"].setErrors( { 'required': true } );
      
        return;
      }    
      if (this.itemCatalogo.input_type_id != this.k_tipo_insumo_semillas) {
        this.selectedcrop_specie = 0;
      
        itemCatalogoModified.properties = this.prop_List;
        
      }
      itemCatalogoModified.input_type_id = this.selectedinput_type;
     }          

    if (this.itemCatalogo.crop_specie_id != undefined) {
      if ((this.itemCatalogo.input_type_id == undefined) || (this.itemCatalogo.input_type_id == this.k_tipo_insumo_semillas)) { 
        if ((this.selectedcrop_specie === undefined) || (this.selectedcrop_specie == 0)) {
          this.catalogoMoreFormControl.controls["crop_specie_id"].setErrors( { 'required': true } );
          return;
        }      
      }
      itemCatalogoModified.crop_specie_id = this.selectedcrop_specie;      
     }

     if (this.itemCatalogo.pest_id != undefined) {
      if ((this.selectedpest === undefined) || (this.selectedpest == 0)) {
        this.catalogoMoreFormControl.controls["pest_id"].setErrors( { 'required': true } );
        return;
      }      
      itemCatalogoModified.pest_id = this.selectedpest;
     }

    if (this.itemCatalogo.weed_type_id != undefined) {
      if ((this.selectedweed_type === undefined) || (this.selectedweed_type == 0)) {
        this.catalogoMoreFormControl.controls["weed_type_id"].setErrors( { 'required': true } );
        return;
      }
      itemCatalogoModified.weed_type_id = this.selectedweed_type;
     }          
     if (this.itemCatalogo.crop_use_id != undefined) {
      if ((this.selectedcrop_use === undefined) || (this.selectedcrop_use == 0)) {
        this.catalogoMoreFormControl.controls["crop_use_id"].setErrors( { 'required': true } );
        return;
      }      
      itemCatalogoModified.crop_use_id = this.selectedcrop_use;
     }          
     if (this.itemCatalogo.crop_sowing_id != undefined) {
      if ((this.selectedcrop_sowing === undefined) || (this.selectedcrop_sowing == 0)) {
        this.catalogoMoreFormControl.controls["crop_sowing_id"].setErrors( { 'required': true } );
        return;
      }      
      itemCatalogoModified.crop_sowing_id = this.selectedcrop_sowing;
     }          

     if (this.itemCatalogo.input_company_id != undefined) {
      if ((this.selectedinput_company === undefined) || (this.selectedinput_company == 0)) {
      //  this.catalogoMoreFormControl.controls["input_company_id"].setErrors( { 'required': true } );
        itemCatalogoModified.input_company_id = null;
        
      }      
      else {
        itemCatalogoModified.input_company_id = this.selectedinput_company;
      }
      
     }          
     if (this.itemCatalogoModal.catalog_id == 'pest') {
      if (this.allSelected) {
        itemCatalogoModified.specie_list = [];
        itemCatalogoModified.species = 'T';
      }
      else {
        itemCatalogoModified.specie_list = this.crop_specie_pest_List;
        itemCatalogoModified.species = 'A';
      }
     }
     

    //console.log('vuelvo del modal con ',itemCatalogoModified);
    this.dialogRef.close(itemCatalogoModified);    
//    this.itemCatalogoModal.record = Object.assign(this.itemCatalogo, this.catalogoMoreFormControl.value);
//    this.dialogRef.close(itemCatalogoData);
    }

  close() {
      this.dialogRef.close(null);
  }

  public getNameErrorMessage(): string {
    if (this.catalogoMoreFormControl.get('name')) {
      return (this.catalogoMoreFormControl.get('name'))?.hasError('required') ? 'Debe especificar un nombre' : '';
    }
    return '';
  }

  
  private hasError(field: string, errorCode: string) {
    return this.catalogoMoreFormControl.hasError(field, errorCode);
  }

  get title() {
    return this.titulo; 
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  
}
