import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Catalog_item_list, Catalog, CatalogData, CatalogContentItem, IProperty } from '../modelo/catalogo';
import { environment } from './../../environments/environment';
import { Observable, of } from 'rxjs';

const API_URL = environment.API_URL;
@Injectable({
  providedIn: 'root'
})

export class CatalogoService {

  constructor(private http: HttpClient) {}

/*  getCatalogos(): Observable<Catalog[]> {
    return this.http.get<Catalog[]>(API_URL + 'catalogo/getAll', { responseType: 'json' });
  }*/

  getCatalogoLista(idCatalogo:string): Observable<Catalog_item_list[]> {
    return this.http.get<Catalog_item_list[]>(API_URL + 'catalogo/getList/'+idCatalogo, { responseType: 'json' });
  }

  getCatalogo(idCatalogo:string): Observable<CatalogContentItem[]> {
    return this.http.get<CatalogContentItem[]>(API_URL + 'catalogo/get/'+idCatalogo, { responseType: 'json' });
  }

  getPropertyList(idInput:any,idInputType:any): Observable<IProperty[]> {
    return this.http.get<IProperty[]>(API_URL + 'catalogo/getPropertyList/'+idInput+'/'+idInputType, { responseType: 'json' });
  }  
  
  getCropSpecieList(idPest:any): Observable<any[]> {
    return this.http.get<any[]>(API_URL + 'catalogo/getCropSpecieList/'+idPest, { responseType: 'json' });
  }  

  getCropStageList(): Observable<any[]> {
    return this.http.get<any[]>(API_URL + 'catalogo/getCropStageList', { responseType: 'json' });
  }    

  deleteCatalogo(idCatalogo:string,idItem:number): Observable<CatalogData>  {
    return this.http.delete<any>(API_URL + 'catalogo/'+idCatalogo+'/'+idItem);
  }

  updateCatalogo(idCatalogo:string,Item:CatalogContentItem): Observable<CatalogContentItem>  {
    return this.http.put<any>(API_URL + 'catalogo/'+idCatalogo, Item , { responseType: 'json' })
  }  

  addCatalogo(idCatalogo:string,Item:CatalogContentItem): Observable<CatalogContentItem>  {
    return this.http.post<any>(API_URL + 'catalogo/add/'+idCatalogo, Item , { responseType: 'json' })
  }


}
