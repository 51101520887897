import * as moment from 'moment';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Campania } from '../modelo/campania';
import { CampaniaService } from '../_services/campania.service';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../shared/table/table.component';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CampaniaFormComponent } from './forms/campania-form/campania-form.component';
import { ConfirmedDialogComponent } from '../shared/dialogs/confirmed-dialog/confirmed-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../shared/toast/toast.service';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-campanias',
  templateUrl: './campanias.component.html',
  styleUrls: ['./campanias.component.scss']
})

export class CampaniasComponent implements OnInit {
  loading = false;
  sus : any;
  isLoggedIn = false;

  @ViewChild(PuntalTableComponent,{static:true}) table: PuntalTableComponent<any>;

  public campanias: Campania[] = []; 
  
	    readonly header: ITableHeader<Campania>[] = [
/*     {
      label: 'id',
      value: 'id',
    }, */
    {
      label: 'Nombre',
      value: 'name',
      valueFunction: campania => `${campania.name}`
    },
    {
      label: 'Desde',
      value: 'from_date',
      valueFunction: campania => {
        if (!campania.from_date) {
          return '';
        }
        const desde = campania.from_date; //.toDateString;
        const date = new Date(desde);
//        const relative = moment(date).fromNow(true);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      },      
    },
    {
      label: 'Hasta',
      value: 'to_date',
      valueFunction: campania => {
        if (!campania.to_date) {
          return '';
        }
        const desde = campania.to_date; //.toDateString;
        const date = new Date(desde);
//        const relative = moment(date).fromNow(true);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      }, 
    },
    {
      label: 'Activa',
      value: 'active',
      valueFunction: campania => {
        return `${campania.active == 'S' ?  'Sí' : 'No'}`;
        }
    },
  ];

  readonly tableOptions: ITableOptions<Campania> = {
    canEdit: (campania) => true /*(this.canAddRecord == 'S')*/, 
    canDelete: (campania) => (this.canAddRecord == 'S')
  };
  canAddRecord = 'N';

  constructor(private campaniaService: CampaniaService,   private dialog: MatDialog, private location: Location ,
  private router: Router,
  private route: ActivatedRoute,
  private toastService: ToastService,
  private authService: AuthService) { }


  ngOnInit(): void {
    this.retrieveData();
    this.canAddRecord = (this.authService.isSuperUser() ? 'S' : 'N' );
  }

  private retrieveData() {
    this.loading = true;
    this.sus = this.campaniaService.getCampanias().subscribe((campanias) => {
      this.campanias = campanias;    
      this.loading = false;
    },
    error => {
      this.toastService.openSnackBar(`Error al leer campañas: ${error}`, 'OK');
      this.loading = false;
    });
  }

  editRow(row: Campania) {
    const dialogRef = this.openModal(row);
    dialogRef.afterClosed().subscribe(
      (newCampaign: Campania) => {
        if (newCampaign) {
          this.campaniaService.updateCampania(newCampaign).subscribe( ()  => {
          },
          error => {
            this.retrieveData();
            this.toastService.openSnackBar(`Error al actualizar la campaña ${row.name}: ${error}`, 'OK');
          });
        }
      }
    );
  }

  deleteRow(row: Campania) {
    const data = {
      title: `ATENCIÓN: Borrar campaña '${row.name}'`,
      message: 'Está seguro? Al borrar campaña se perderan todas sus actividades y sus recorridas',
      validation: row.name
    };
    const dialogRef = this.dialog.open(ConfirmedDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      width: 'auto',
      data
    });
    const id_a_borrar = row.id;
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.campaniaService.deleteCampania(id_a_borrar).subscribe(
            ()  => {
              this.retrieveData();
            },
            error => {
              this.toastService.openSnackBar(`Error al eliminar la campaña ${row.name}: ${error}`, 'OK');
            }
          );
        }
      }
    );
  }

/*  navigateTo(row: Campania) {
//    console.log('should navigate to company with id: ', row.id);
//    this.router.navigate([`${row.id}/empresas`], {relativeTo: this.route});
  }*/


  goBack() {
    this.location.back();
  }


  addRow() {
    const dialogRef = this.openModal();
    dialogRef.afterClosed().subscribe(
      (newCampaign: Campania) => {
        if (newCampaign) {
          this.campaniaService.addCampania(newCampaign).subscribe(
            (campania)  => {
              this.retrieveData();
            },
          error => {
            this.toastService.openSnackBar(`Error al agregar la campaña ${newCampaign.name}: ${error}`, 'OK');
          }
        )
      }
    }
    )
  }

  private openModal(campania?: Campania): MatDialogRef<CampaniaFormComponent, any> {
    return this.dialog.open(CampaniaFormComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: true,
      data: campania || {}
    });
  }
 
  get canAdd() {
  //  return this.authService.canAdd();
  return true;
  }

   openPermission(campania: Campania) {
  /*  this.dialog.open(WorkspaceViewComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: true,
      data: {
        ref: campania,
        type: WorkspaceElementType.CLIENT
      }
    });*/
  }  

  ngOndestroy() {
    this.sus.unsubscribe();
  }

  isAuthenticated() {
    return true;
  }
}
