<ng-container>
  <!-- Toolbar -->
    <mat-toolbar color="primary" class="app-header">
      <span class="nav-tool-items">
        <ng-template [ngIf]="isAuthenticated() && !isLoginRoute()">
          <a mat-icon-button (click)="toggleSidenav(nav)"><mat-icon>menu</mat-icon></a>
        </ng-template>
        <a mat-icon-button routerLink="/" routerLinkActive="active" class="title">{{company}}</a>
      </span>
      <!-- <p>IsLoggiin: {{isLoggedIn}}</p> -->
      <span class="nav-tool-items">
        <ng-template [ngIf]="!isAuthenticated()">
          <a mat-button routerLink="/login" [state]="{ nextPage: location.path() }" routerLinkActive="active">Iniciar sesión</a>
          <a *ngIf="false" mat-button mat-raised-button routerLink="" routerLinkActive="active">Registrarse</a>
        </ng-template>
        <ng-template [ngIf]="isAuthenticated()">
          <label class="user-name">{{username}}</label>
          <a mat-button (click)="logout()" >Cerrar sesión</a>
        </ng-template>
      </span>
    </mat-toolbar>



<!-- Sidenav -->
<mat-sidenav-container class="app-sidenav-container">
  <mat-sidenav #nav class="sidenav" [ngStyle]="{ 'width.em': sidenavWidth }"
    [opened]="isAuthenticated()"
    [fixedInViewport]="false"
    [fixedTopGap]="0"
    [fixedBottomGap]="0"
    mode="over"
  >

     <mat-nav-list>
      <mat-list-item routerLink="/home" (click)="toggleSidenav(nav)" routerLinkActive="active" >
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <div fxFlex="10"></div>
        <div *ngIf="sidenavWidth > 6" class="sidenav-item">
          <h5 class="lead">Información</h5>
        </div>
      </mat-list-item>

      <mat-list-item *ngIf="isSuperUser()" routerLink="/campanias" (click)="toggleSidenav(nav)" routerLinkActive="active" >
        <mat-icon mat-list-icon>calendar_today</mat-icon>
        <div fxFlex="10"></div>
        <div *ngIf="sidenavWidth > 6" class="sidenav-item">
          <h5 class="lead">Campa&ntilde;as</h5>
        </div>
      </mat-list-item>

      <mat-list-item routerLink="/clientes" (click)="toggleSidenav(nav)" routerLinkActive="active" >
        <mat-icon mat-list-icon>person</mat-icon>
        <div fxFlex="10"></div>
        <div *ngIf="sidenavWidth > 6" class="sidenav-item">
          <h5 class="lead">Clientes</h5>
        </div>
      </mat-list-item>

      <mat-list-item routerLink="/activities" (click)="toggleSidenav(nav)" routerLinkActive="active" >
        <mat-icon mat-list-icon>edit</mat-icon>
        <div fxFlex="10"></div>
        <div *ngIf="sidenavWidth > 6" class="sidenav-item">
          <h5 class="lead">Actividades</h5>
        </div>
      </mat-list-item> 

      <mat-list-item *ngIf="isAdmin()" (click)="toggleSidenav(nav)" routerLink="/admin" routerLinkActive="active">
        <mat-icon mat-list-icon>settings</mat-icon>
        <div fxFlex="10"></div>
        <div *ngIf="sidenavWidth > 6" class="sidenav-item">
          <h5 class="lead">Administración</h5>
        </div>
      </mat-list-item>


      
    </mat-nav-list> 

  </mat-sidenav>

  <router-outlet></router-outlet>

</mat-sidenav-container>

</ng-container>
