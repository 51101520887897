<div class="current-permisos-container" *ngIf="isAuthenticated()">
  <div class="permisos-table"> 

   <div class="accordion-container">
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b>Cliente</b>&nbsp;>&nbsp;<b>Empresa</b>&nbsp;>&nbsp;<b>Campo</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="filters">
        <!-- CLIENTE -->
            <ng-container *ngFor="let field of clientFilter.order">
              <div>
                <mat-form-field>
                  <mat-label>{{clientFilter.info[field].name}}</mat-label>
                  <mat-select [disabled]="disableClientSelect(field)" (selectionChange)="changeClientSelect($event, field)" [value]="valueIfOne(field)">
                    <mat-select-trigger>
                      <ng-container *ngIf="allSelected(clientFilter, field); else showNames">
                        Mostrar todos
                      </ng-container>
                      <ng-template #showNames>
                        <ng-container>
                          <span>{{textPrettyFilter(clientFilter.select[field])}}</span>
                        </ng-container>
                      </ng-template>
                    </mat-select-trigger>
                    <mat-option *ngFor="let value of clientFilter.values[field]" [value]="value">
                      <span>{{value}}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
        <!-- -->
          <div class="clean-button-container">
            <button mat-icon-button aria-label="Limpiar filtros" [ngClass]="{disable: !anyFilter(clientFilter)}" class="clean" (click)="cleanClientFilters()">
              <mat-icon>delete_sweep</mat-icon>
            </button>
          </div>
        </div>

      </mat-expansion-panel>
     </mat-accordion>
   </div>
  
   <puntal-table
    tableTitle={{titulo}}
    [dataSource]="permisosOtorgados"
    [loading]="loading"
    [options]="tableOptions"
    [displayedColumns]="header"
    (onEdit)="editRow($event)"
    (onDelete)="deleteRow($event)"
    (onRowClick)="navigateTo($event)"
    (onAdd)="addRow()"
    (onBack)="goBack()"
    [showAddButton]="canAddRecord"
    showBackButton="S"
    (onShowPermissionClick)="openPermission($event)"
    ></puntal-table>
  </div>
 
</div>