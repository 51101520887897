import {Component, OnInit, Inject, Input} from '@angular/core';
//import { Permiso } from '../../../modelo/permiso';
import { PermisoOtorgado } from '../../../modelo/permiso-otorgado.model';
import { IClientRole } from '../../../modelo/cliente';
import { IFarmRole  } from '../../../modelo/campo';
import { ICompanyRole } from '../../../modelo/empresa';
import { ClienteService } from '../../../_services/cliente.service';
import { EmpresaService } from '../../../_services/empresa.service';
import { CampoService } from '../../../_services/campo.service';
import { PermisoService} from '../../../_services/permiso.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelectModule} from '@angular/material/select';
import { AuthService } from '../../../_services/auth.service';
import { Usuario } from '../../../modelo/usuario';
import { TokenStorageService } from '../../../_services/token-storage.service';
import { ToastService } from '../../../shared/toast/toast.service';

interface IClientID {"client_id" : number}

@Component({
  selector: 'app-permiso-form',
  templateUrl: './permiso-form.component.html',
  styleUrls: ['./permiso-form.component.scss']
})

export class PermisoFormComponent implements OnInit {
  public matcher: ErrorStateMatcher;
  public permisosFormControl: FormGroup;
  private permisoOtorgado: PermisoOtorgado;
  public titulo : string = "Alta de Permisos";
  public tipo_seleccionado: string;

  public clientList$: IClientRole[] = [];
  public companyList$: ICompanyRole[] = [];
  public farmList$: IFarmRole[] = [];

  selectedClient: number | null;
  selectedCompany: number | null; //ICompanyRole | null;
  selectedFarm: number | null; //IFarmRole | null;
  selectedClientUserRole: string | null;
  selectedCompanyUserRole: string | null;
  selectedFarmUserRole: string | null;

  // tipo de role en el nodo:
  tipos_de_permiso = [
    {value: 'ADMIN', viewValue: 'Administrador'},
    {value: 'FULL_VIEW', viewValue: 'Consulta/Reportes'},
    {value: 'VIEW', viewValue: 'Consulta'},
    {value: 'NO_USER', viewValue: 'Sin Permiso'},
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public unpermisoOtorgado: PermisoOtorgado,
    private dialogRef: MatDialogRef<PermisoFormComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private clienteService: ClienteService, 
    private empresaService: EmpresaService, 
    private campoService: CampoService, 
    private permisoService: PermisoService,
    private toastService: ToastService,
    private dialog: MatDialog, 
    private tokenStorageService: TokenStorageService ) {
      this.clienteService.getClientsAdmin().subscribe((clientes) => {
        this.clientList$ = clientes;
      });  
    }

  ngOnInit(): void {
    this.titulo = ' Permiso ';
    this.permisosFormControl = this.createFormControl();
  }

  private createFormControl(): FormGroup {
 //   this.permisoOtorgado = this.unPermisoOtorgado;
    if ((!this.unpermisoOtorgado) || (Object.keys(this.unpermisoOtorgado).length === 0)) {
      this.permisoOtorgado = { 
        user_id: 0,
        client_id: 0,
        client_name: '',
        company_id: 0,
        company_name: '',
        farm_id: 0,
        farm_name : '',
        role: ''
      } as PermisoOtorgado;
      this.titulo = 'Agregar Permiso';  
/*      // Si el usuario que está dando permisos es admin en el cliente, puede otorgar permisos sobre el cliente.
      // Si no lo es, no puede otorgar permisos sobre el cliente
      if (this.clientList$.length > 0){
        if (this.clientList$.length == 1) {
          this.permisoOtorgado.client_id = this.clientList$[0].id;
          this.permisoOtorgado.client_name = this.clientList$[0].name;
          this.onClientSelect(this.permisoOtorgado.client_id);
        }
      }*/
    }
    else {
  //    if (this.permisoOtorgado.client_id == 0){
        this.titulo = 'Agregar Permiso';
        this.permisoOtorgado = { 
          user_id: this.unpermisoOtorgado.user_id,
          client_id: 0,
          client_name: '',
          company_id: 0,
          company_name: '',
          farm_id: 0,
          farm_name : '',
          role: ''
        } as PermisoOtorgado;
  /*    }  NO HAY EDICION DE PERMISOS
      else {
        this.titulo = 'Editar Permiso';
      }*/
      
    }
    return this.formBuilder.group({
      client_id: [this.permisoOtorgado.client_id, [Validators.required]],
      company_id: [this.permisoOtorgado.company_id],
      farm_id: [this.permisoOtorgado.farm_id],      
     // password: [this.usuario.password, [Validators.minLength(8)]],
      role: [this.permisoOtorgado.role, [Validators.required]]
    });
  }

  findRole(list: Array<any>, id: number): any {
    for (var i = 0; i < list.length; i++) {
      if (list[i]['id'] == id) {
        return list[i]['role'];
      }
    }
    return undefined;
  }

  onClientSelect(client: number) { //Cliente) {
    if (this.selectedClient && this.selectedClient === client) {
      return;
    }
    this.selectedClient = client;
    this.selectedCompany =  null;
    this.selectedFarm =  null;
    this.selectedClientUserRole = this.findRole(this.clientList$,client);
    this.selectedCompanyUserRole = null;    
    this.selectedFarmUserRole = null;       
  //  console.log('Cliente ',this.selectedClient,this.selectedClientUserRole);

  // Si es ADMIN a nivel de cliente, hereda hacia abajo
  if (this.selectedClientUserRole == 'ADMIN') {
    this.companyList$ = [];
    var e: ICompanyRole;
    this.empresaService.getEmpresas(client).subscribe((empresas) => {
       for (var i = 0; i < empresas.length; i++) {
        e = {'id' : empresas[i]['id'], 'name' : empresas[i]['name'], 'role' : 'ADMIN'};
        this.companyList$.push(e);
      } 
      // Solo si el usuario que esta dando el alta es admin del cliente, permito seleccionar todas las compañias
      if (this.companyList$.length >= 1) {
        e = {'id' : -1, 'name' : 'Todas las empresas', 'role' : 'ADMIN'};
        this.companyList$.unshift(e);
      }  
    }); 
  }
  else {
    this.empresaService.getEmpresasAdmin(client).subscribe((empresas) => {
      this.companyList$ = empresas;
    });    
  }
  
  }

  onCompanySelect(company: number) {
    if (this.selectedCompany && (this.selectedCompany != null) && (this.selectedCompany == company)) {
      return;
    }
    // SI eligio todas las compañias tiene que elegir todos los campos
    if (company && (company == -1)) {
      this.selectedCompany = company;
      this.selectedCompanyUserRole = 'ADMIN';  
      this.farmList$ = [];
      e = {'id' : -1, 'name' : 'Todos los campos', 'role' : 'ADMIN'};
      this.farmList$.push(e); 
      this.selectedFarm =  null;
      this.selectedFarmUserRole = null;      
      return;    
    }    
    
    this.selectedCompany = company;
    this.selectedCompanyUserRole = this.findRole(this.companyList$,company);
    this.selectedFarm =  null;
    this.selectedFarmUserRole = null;      
    
  // Si es ADMIN a nivel de cliente, hereda hacia abajo
  if (this.selectedCompanyUserRole == 'ADMIN') {
    
    var e: IFarmRole;
    this.campoService.getCampos(company).subscribe((campos) => {
      this.farmList$ = [];
      for (var i = 0; i < campos.length; i++) {
        e = {'id' : campos[i]['id'], 'name' : campos[i]['name'], 'role' : 'ADMIN'};
        this.farmList$.push(e);
      }
      // Solo si el usuario que esta dando el alta es admin de la empresa, permito seleccionar todas las compañias
      if (this.farmList$.length >= 1) {
        e = {'id' : -1, 'name' : 'Todos los campos', 'role' : 'ADMIN'};
        this.farmList$.unshift(e);
      } 
    }); 
  }
  else {    
    this.campoService.getCamposAdmin(company).subscribe((campos) => {
      this.farmList$ = campos;
    });  
  }  
   
  }

  onFarmSelect(farm: number) {
    if (this.selectedFarm && (this.selectedFarm === farm )) {
      return;
    }
    if (farm && (farm == -1 )) {
      this.selectedFarm = farm;
      this.selectedFarmUserRole = 'ADMIN';
    }
    else {
      this.selectedFarm = farm;
      this.selectedFarmUserRole = this.findRole(this.farmList$,farm);
    } 
    
  } 

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  async rol_nodo(tipo_nodo, id_nodo, user_id) : Promise<string> {
    if (!(id_nodo) || (id_nodo == null)) {
      return '';
    }  
    return await this.permisoService.getPermisoEfectivo(tipo_nodo,id_nodo,user_id).toPromise()
    .then((permisos) => {if ((!permisos) || (permisos.length < 1)) {return '';} else {return permisos[0].role;}});
  }

  async cliente_del_usuario(user_id) : Promise<number> {
    if (!(user_id) || (user_id == null)) {
      return -1;
    }  
    return await this.permisoService.getClientforUser(user_id).toPromise()
    .then((clientes) => {if ((!clientes) || (clientes.length < 1)) {return -1;} else {return clientes[0].client_id;}});
  }

  async permiso_valido(): Promise<boolean> {
    // obtener permiso actual sobre el cliente
    // si ya es admin, el resto no tiene sentido

  try {
    if (!(this.selectedClient) || (this.selectedClient == null)) {
      this.toastService.openSnackBar(`Minimamente debe seleccionar un cliente`, 'OK', 300000);
      return false;
    }

    let permiso = await this.rol_nodo('client',this.selectedClient,this.permisoOtorgado.user_id);
    if (permiso == 'ADMIN') {
      this.toastService.openSnackBar(`El usuario ya es ADMIN en el cliente, por lo tanto ya tiene todos los permisos de administracion otorgados sobre el cliente, las empresas y sus campos`, 'OK', 300000);
      return false;
    } 

    // obtener permiso actual sobre la empresa
    // Si eligio permiso sobre una empresa pero ya era admin sobre la empresa 
    if (!(this.selectedCompany) || (this.selectedCompany == null)) {
      return true;
    }

    if (this.selectedCompany == null) {return true;}

    let permiso_empresa = await this.rol_nodo('company',this.selectedCompany,this.permisoOtorgado.user_id);
    if (permiso_empresa == 'ADMIN') {
      this.toastService.openSnackBar(`El usuario ya es ADMIN en la empresa, por lo tanto ya tiene todos los permisos de administracion otorgados sobre la empresa elegida y sus campos`, 'OK', 300000);
      return false;
    } 

    // Si eligio permiso sobre un campo pero ya era admin sobre el campo no tiene sentido
    if (!(this.selectedFarm) || (this.selectedFarm == null)) {
      return true;
    }

    if (this.selectedFarm == null) {return true;}

    let permiso_campo = await this.rol_nodo('farm',this.selectedFarm,this.permisoOtorgado.user_id);
    if (permiso_campo == 'ADMIN') {
      this.toastService.openSnackBar(`El usuario ya es ADMIN en el campo, por lo tanto ya tiene todos los permisos de administracion otorgados sobre el campo elegido`, 'OK', 300000);
      return false;
    } 

    // Validar que el usuario no pertenezca a mas de un cliente (esto solo lo podria hacer un usuario que es SUPERPUNTAL al otorgarle permisos a un usuario que es USER)
    let client_user = await this.cliente_del_usuario(this.permisoOtorgado.user_id);
 /*   if ((client_user != -1) && (client_user != this.selectedClient)) {
      this.toastService.openSnackBar(`El usuario ya está asociado a otro cliente`, 'OK', 300000);
      return false;
    } */
    return true;

  }
  catch (err) {
    console.error(err);
    this.toastService.openSnackBar('Se produjo un error al validar permisos para el usuario ('+err+')', 'OK', 300000);
    return false;
  }
}



  save() {
    if (this.permisosFormControl.invalid) { return; }
    let permisoData = Object.assign(this.permisoOtorgado, this.permisosFormControl.value);
    if (permisoData.company_id < 0) {permisoData.company_id == null;}
    if (permisoData.farm_id < 0) {permisoData.farm_id == null;}    
    this.permiso_valido().then((data) => {
      const allowed = data;
      if (!allowed)  { return; }
      this.dialogRef.close(permisoData);
    });
   
  }    

  close() {
    this.dialogRef.close(null);
  }

}
