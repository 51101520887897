import { Component, OnInit } from '@angular/core';
import maplibregl from 'maplibre-gl';
//import { Map, LngLatBounds } from 'maplibre-gl';
import { parseString } from 'xml2js';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss']
})

export class MapaComponent implements OnInit {
  map: maplibregl.Map;

  ngOnInit() {
    const mapContainer = document.getElementById('map-container');
    this.map = new maplibregl.Map({
      container: 'map', // container id
      //style: 'maplibre://styles/maplibre/streets-v11',
      style: 'https://demotiles.maplibre.org/style.json', // style URL
      //style: 'mapbox://styles/mapbox/satellite-streets-v12',
      //style: 'mapbox://styles/mapbox/hybrid',
      center: [-60.953158169694298, -34.8950081956134], // Coordenadas iniciales
      zoom: 6 // Nivel de zoom inicial
  });

  // Carga del archivo GeoJSON
  this.map.on('load', () => {
    this.map.addSource('geojson-data', {
      type: 'geojson',
      data: 'http://158.69.146.130:3001/doc.geojson' // Ruta a tu archivo GeoJSON
    });

    this.map.addLayer({
      id: 'geojson-layer',
      type: 'circle',
      source: 'geojson-data',
      paint: {
        'circle-color': '#FF0000',
        'circle-radius': 6
      }
    });
  });

  }


  
}
