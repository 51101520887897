import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminGuard } from './_services/admin.guard';
import { UserGuard } from './_services/user.guard';
import { UserAdminGuard } from './_services/useradmin.guard';


import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
//import { BoardUserComponent } from './board-user/board-user.component';
//import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
//import { BoardAdminComponent } from './board-admin/board-admin.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ClientFormComponent } from './clientes/forms/cliente-form/cliente-form.component';
import { PermisosComponent } from './permisos/permisos.component';

import { UsuariosComponent } from './usuarios/usuarios.component';
import { CamposComponent } from './campos/campos.component';
import { LotesComponent } from './lotes/lotes.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { CampaniasComponent } from './campanias/campanias.component';
import { SubmenuComponent } from './submenu/submenu/submenu.component';
import { ActividadesComponent } from './actividades/actividades.component';
import { ScoutsComponent } from './actividades/scouts/scouts.component';
import { ScoutComponent } from './actividades/scouts/scout/scout.component';
import { CatalogosComponent } from './catalogos/catalogos.component';
import { CatalogosViewComponent } from './catalogos/catalogos-view/catalogos-view.component';
//import { MapaComponent } from './mapa/mapa.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
/*  { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent },*/
  { path: 'admin', component:  SubmenuComponent, canActivate: [UserAdminGuard]},
  { path: 'usuarios', component:  UsuariosComponent, canActivate: [UserAdminGuard]},
  { path: 'permisos/:usuarioId', component:  PermisosComponent, canActivate: [UserAdminGuard]},
  { path: 'clientes', component: ClientesComponent, canActivate: [UserGuard]},
 /* { path: 'clientes', loadChildren: () => import('./clientes/clientes.module').then(m => m.ClientesModule) },*/

  { path: 'clientes/:clientId/empresas', component: EmpresasComponent, canActivate: [UserGuard]},
  { path: 'clientes/:clientId/empresas/:empresaId/campos', component: CamposComponent, canActivate: [UserGuard]},
  { path: 'clientes/:clientId/empresas/:empresaId/campos/:campoId/lotes', component: LotesComponent, canActivate: [UserGuard]},
  { path: 'campanias', component: CampaniasComponent , canActivate: [UserGuard]},
  { path: 'activities', component: ActividadesComponent , canActivate: [UserGuard]},
  { path: 'activities/:activityId/scouts', component: ScoutsComponent , canActivate: [UserGuard]},
  { path: 'activities/:activityId/scouts/:scoutId/scout', component: ScoutComponent , canActivate: [UserGuard]},  
  { path: 'catalogos', component: CatalogosComponent , canActivate: [AdminGuard]},
  { path: 'catalogos/:catalogId', component: CatalogosViewComponent, canActivate: [AdminGuard]},
 // { path: 'mapa/:farmId', component: MapaComponent , canActivate: [AdminGuard]},

  { path: '', redirectTo: 'home', pathMatch: 'full' } // decia redirect to home
];
/*
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
*/

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
  providers: [AdminGuard]
})

export class AppRoutingModule { }
