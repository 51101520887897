import {Component, OnInit, Inject, Input} from '@angular/core';

//import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ActividadService } from '../../../../_services/actividad.service';
import { ToastService } from '../../../../shared/toast/toast.service';

@Component({
  selector: 'app-notes-form',
  templateUrl: './notes-form.component.html',
  styleUrls: ['./notes-form.component.scss']
})

/*
scout.observations
scout_adversities.observations
scout_ambient.observations
scout_crop_yield.observations
scout_diseases.observations
scout_implementation_quality.observations
scout_harvest_quality.observations
scout_pests.observations
scout_weeds.observations
scout_sowing.observations
*/
export class NotesFormComponent implements OnInit {
 // readonly billingGetter = {get: () => null};
  public titulo : string;
  public operacion: string;
  public loading = true;
  public valores_notas = [
    {'tabla': 'scout', 'rotulo': 'Observaciones generales', 'value': '', 'modificado': false},
    {'tabla': 'scout_ambient', 'rotulo': 'Ambiente', 'value': '', 'modificado': false},
    {'tabla': 'scout_sowing', 'rotulo': 'Siembra', 'value': '', 'modificado': false},    
    {'tabla': 'scout_implementation_quality', 'rotulo': 'Calidad de implantación', 'value': '', 'modificado': false},
    {'tabla': 'scout_weeds', 'rotulo': 'Malezas', 'value': '', 'modificado': false},
    {'tabla': 'scout_diseases', 'rotulo': 'Enfermedades', 'value': '', 'modificado': false},    
    {'tabla': 'scout_pests', 'rotulo': 'Plagas', 'value': '', 'modificado': false},
    {'tabla': 'scout_adversities', 'rotulo': 'Adversidades', 'value': '', 'modificado': false},
    {'tabla': 'scout_harvest_quality', 'rotulo': 'Calidad de cosecha', 'value': '', 'modificado': false},    
    {'tabla': 'scout_crop_yield', 'rotulo': 'Rendimiento', 'value': '', 'modificado': false}   
  ]; 

constructor(
    @Inject(MAT_DIALOG_DATA) public lote: any,
    private dialogRef: MatDialogRef<NotesFormComponent>,
    private actividadService: ActividadService,
    private toastService: ToastService
    ) { }

  ngOnInit() {
    this.loading = true;
    this.titulo = this.lote.titulo;
    this.operacion = this.lote.operacion;
    this.retrieveNotes(this.lote.scout_id);
    this.loading = false;
  }

  onChange(valor,indice) {
  //  console.log('ANtes',this.valores_notas[indice].value);
  //  console.log('Ahora',valor);
      if (this.valores_notas[indice].value != valor) {
      this.valores_notas[indice].value = valor;
      this.valores_notas[indice].modificado = true;
    }
  //  console.log('modificado',this.valores_notas[indice].modificado);
  }
  
  async retrieveNotes (scout_id) {
      //var promise_array = [];
      //var index = 0;
      try {    
       // this.valores_notas.forEach((element, index) => {
      //    promise_array.push(this.retrieveNote(scout_id,element.tabla, index));
      //  });
      // await Promise.all(promise_array);  
        const fetchGeneralPromise = this.retrieveNote(scout_id, 0);
        const fetchAmbientPromise = this.retrieveNote(scout_id, 1);
        const fetchSowingPromise = this.retrieveNote(scout_id, 2);
        const fetchImplemPromise = this.retrieveNote(scout_id, 3);
        const fetchSweedsPromise = this.retrieveNote(scout_id, 4);
        const fetchDiseasesPromise = this.retrieveNote(scout_id, 5);
        const fetchPestsPromise = this.retrieveNote(scout_id, 6);
        const fetchAdversitiesPromise = this.retrieveNote(scout_id, 7);
        const fetchHarvestPromise = this.retrieveNote(scout_id, 8);
        const fetchYieldPromise = this.retrieveNote(scout_id, 9);

        await Promise.all([fetchGeneralPromise,fetchAmbientPromise,fetchSowingPromise,fetchImplemPromise,
          fetchSweedsPromise,fetchDiseasesPromise,fetchPestsPromise,fetchAdversitiesPromise,
          fetchHarvestPromise,fetchYieldPromise]);  

      } catch (error) {
        this.toastService.openSnackBar(`Error al obtener observaciones de la recorrida: ${error}`, 'OK');
        this.loading = false;
      } finally {
        this.loading = false;

      }
      return;        
  }
 
  retrieveNote(scoutId, indice){
    const tabla = this.valores_notas[indice].tabla;
    this.actividadService.getNotes(scoutId, tabla).subscribe(notas => {
      if ((notas) && (notas.length > 0)) {
        this.valores_notas[indice].value = notas[0].observations;
      }
      else {
        this.valores_notas[indice].value = '';
      }
    },
    error => {
      this.toastService.openSnackBar(`Error al leer  ${this.valores_notas[indice].rotulo} de la recorrida: ${error}`, 'OK');
    });
  }

  updateNote(indice){
    const scoutId = this.lote.scout_id;
    const tabla = this.valores_notas[indice].tabla;
    if (this.valores_notas[indice].modificado) {
      this.actividadService.updateNotes(scoutId, tabla, this.valores_notas[indice].value).subscribe(()  => {
       },
      error => {
        this.toastService.openSnackBar(`Error al actualizar notas de  ${this.valores_notas[indice].rotulo} de la recorrida: ${error}`, 'OK');
      });
    }
  }


  async save() {
    this.loading = true;
    try {    
      const updGeneralPromise = this.updateNote(0);
      const updAmbientPromise = this.updateNote(1);
      const updSowingPromise = this.updateNote(2);
      const updImplemPromise = this.updateNote(3);
      const updSweedsPromise = this.updateNote(4);
      const updDiseasesPromise = this.updateNote(5);
      const updPestsPromise = this.updateNote(6);
      const updAdversitiesPromise = this.updateNote(7);
      const updHarvestPromise = this.updateNote(8);
      const updYieldPromise = this.updateNote(9);

      await Promise.all([updGeneralPromise,updAmbientPromise,updSowingPromise,updImplemPromise,
        updSweedsPromise,updDiseasesPromise,updPestsPromise,updAdversitiesPromise,
        updHarvestPromise,updYieldPromise]);  
        
    } catch (error) {
      this.toastService.openSnackBar(`Error al registrar observaciones de la recorrida: ${error}`, 'OK');
      this.loading = false;
      return;

    } finally {
      this.dialogRef.close(null);
    }
 }

 close() {
     this.dialogRef.close(null);
 }


}
