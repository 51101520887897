
<ng-container *ngIf="loading">
  <!-- <ng-container *ngIf="!scout "> -->
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>

<div class="container" *ngIf="!loading">
  <h2 class="title">{{title}}</h2>
  <form class="example-form" [formGroup]="loteFormControl">

    <mat-form-field class="example-full-width">
      <input matInput placeholder="Nombre Lote" formControlName="name" [errorStateMatcher]="matcher" required>
      <mat-error *ngIf="loteFormControl.get('name')?.invalid">{{getNameErrorMessage()}}</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput placeholder="Superficie (ha)" autocomplete="off" formControlName="area" type="number"  [errorStateMatcher]="matcher" required>
      <mat-error *ngIf="loteFormControl.get('area')?.invalid">{{getAreaErrorMessage()}}</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput placeholder="Poligono url" isdisabled formControlName="polygon" [errorStateMatcher]="matcher">
      <mat-error *ngIf="loteFormControl.get('polygon')?.invalid">{{getPolygonErrorMessage()}}</mat-error>
    </mat-form-field>

    <div *ngIf="operacion == 'C'" class="buttons">
      <button mat-stroked-button color="primary" (click)="close()">Cerrar</button>
    </div>

    <div *ngIf="operacion != 'C'" class="buttons">
      <button mat-stroked-button color="primary" (click)="close()">Cancelar</button>
      <button mat-raised-button color="primary" class="accept-button" [disabled]="loteFormControl.invalid" (click)="save()">Guardar</button>
    </div>

  </form>
</div>
