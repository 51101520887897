import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit  } from '@angular/core';
// import { ElementRef } from '@angular/core';
import { Actividad, IActivityFull, ISubType, IActivityFullPage } from '../modelo/actividad';
import { Scout } from '../modelo/scout';
import { ActividadService } from '../_services/actividad.service';
import { ITableHeader, ITableOptions, PuntalTableComponent} from '../shared/table/table.component';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActividadFormComponent } from './forms/actividad-form/actividad-form.component';
import { ConfirmedDialogComponent } from '../shared/dialogs/confirmed-dialog/confirmed-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { CampaniaService } from '../_services/campania.service';
import { Campania } from '../modelo/campania';
import { Cliente } from '../modelo/cliente';
import { Campo } from '../modelo/campo';
import { Empresa } from '../modelo/empresa';

import { ClienteService } from '../_services/cliente.service';
import { EmpresaService } from '../_services/empresa.service';
import { CampoService } from '../_services/campo.service';
import { Filter, Matcher } from './multi-filter/multi-filter.component';
import { MatSelectChange } from '@angular/material/select';
import { TokenStorageService } from '../_services/token-storage.service';
import { ACTIVITY_FIELDS } from './actividades.fields';
import { isEmpty } from '../shared/utility-functions';
import {buildDecisionFilters, buildSempahoresFilters} from './multi-filter/multi-filter.common.factory';
import {scoutDecisionFiler, scoutSempahoreFilter} from '../_helpers/semaphore.helper';
import * as converter from 'json-2-csv';
import * as scoutConverter from '../_services/scout-report.service';
import { AuthService } from '../_services/auth.service';
import { UserService } from '../_services/user.service';
import { ToastService } from '../shared/toast/toast.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { CatalogoService } from '../_services/catalogo.service';
//import { NotesFormComponent } from './scouts/forms/notes-form/notes-form.component';
import { MediaFormComponent } from './scouts/forms/media-form/media-form.component';
/*import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';*/
import * as moment from 'moment';

@Component({
  selector: 'app-actividades',
  templateUrl: './actividades.component.html',
  styleUrls: ['./actividades.component.scss']
})


  
export class ActividadesComponent implements OnInit {
  loading = false;
  sus: any;
  sus_campania : any;
  sus_subtype: any;
  sus_media: any;
  isLoggedIn = false;
  paginar = false;
  first_time = true;
  mostrarDiv = true;
  showFilters = true;
//  anchoReferencia: number;
  public clientList$: Cliente[] = [];
  public companyList$: Empresa[] = [];
  public farmList$: Campo[] = [];
  selectedClientBuscar: number | null; //Cliente | null;
  selectedCompanyBuscar: Empresa | null;
  selectedFarmBuscar: Campo | null;
//  selectedFarmControl: FormControl = new FormControl();

  @ViewChild(PuntalTableComponent,{static:true}) table: PuntalTableComponent<any>;
  

  public actividadList: IActivityFull[] = []; 
  private actividadListFull: IActivityFull[] = []; 
  
  public campaniaList: Campania[] = []; 
  public subtypeList: ISubType[] = [];

  selectedCampaign: number = -1;
//  selectedClient: number = -1;
//  selectedCompany: number = -1;  
//  selectedFarm: number = -1;
//  selectedPlot: number = -1;  
  selectedSubType: string = '-1';
  campania_y_cultivo_elegidos = 'Campaña/Cultivo';
  subtype_elegido = 'Todos';
  campania_elegida = '';
  

  dateFilter = {
    activo: 0,
    fdesde: null,
    fhasta: null
  };
  
    range = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
    

    totalRows = 0;
    pageSize = 5;
    currentPage = 0;
    pageSizeOptions: number[] = [5, 10, 25, 100];
  


  //  if (paginar) {
    dataSource: MatTableDataSource<IActivityFull> = new MatTableDataSource();
    @ViewChild(MatPaginator) paginator!: MatPaginator;
  
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
      this.Setear_Ancho_Tabla();
    }
//  }

	readonly header: ITableHeader<IActivityFull>[] = [
    ...ACTIVITY_FIELDS,
  /*  {
      label: 'Reporte',
      value: 'DESCARGAR CSV',
      valueFunction: (activity: IActivityFull) => activity.scout_count ? `CSV ${activity.scout_count} recorridas` : 'Sin Datos',
    //  button: (activity) => this.downloadActivityReport(activity)
    }    */
  ];

  readonly tableOptions: ITableOptions<IActivityFull> = {
    canEdit: (actividad) => false,
    canDelete: (actividad) => (this.canAddRecord == 'S'),
    canDownload: (actividad) => (Number(actividad.scout_count) > 0),
    canShowDetail: (actividad) => (Number(actividad.scout_count) > 0)
  };
  canAddRecord = 'N';
  showFilterMode = 'H';

  readonly semaphoreFilters = buildSempahoresFilters<IActivityFull>({
    general: (activity) => activity.last_scout ? (((activity.last_scout.general_state) || (activity.last_scout.general_state == 0)) ? activity.last_scout.general_state : 'no-data') : 'no-data',
    implementation_quality: semaphore('implementation_quality'),
    weeds: semaphore('weeds'),
    pests: semaphore('pests'),
    diseases: semaphore('diseases'),
    adversities: semaphore('adversities')
  });

  readonly decisionFilters = buildDecisionFilters<IActivityFull>({
    implementation_quality: decision('implementation_quality'),
    weeds: decision('weeds'),
    pests: decision('pests'),
    diseases: decision('diseases'),
    adversities: decision('adversities')
  });

/*  readonly clientFilter: Filter<any> = {
    info: {
      client: {
        name: 'Cliente',
        getter: (activity) => activity.client_name
      },
      company: {
        name: 'Empresa',
        getter: (activity) => activity.company_name
      },
      farm: {
        name: 'Campo',
        getter: (activity) => activity.farm_name
      },
    },
    order: ['client', 'company', 'farm'],
    values: {},
    select: {},
    changed: {},
  };  
*/

  @ViewChild('select') select: MatSelect;
  //@ViewChild('SelectFarm') SelectFarm: MatSelect;

  allDataSelected=false;
  crop_stage_generic_List : any[] = [];
  crop_stage_List : string[] = [];
  crop_specie_stage_List : (any | undefined) [] = []; 
  last_crop_stage_List_search : string[] = [];

  toggleAllSelection() {
    if (this.allDataSelected) {
      this.select.options.forEach((item: MatOption) => {item.select();});
    } else {
      this.select.options.forEach((item: MatOption) => {item.deselect();});
    }
  }

   optionClick() {
    let newStatus = true;
    //var i = 0;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
      //i++;
    });
    this.allDataSelected = newStatus;
  }

  constructor(private actividadService: ActividadService,
    private catalogoService: CatalogoService, private campaniaService: CampaniaService,  
    private clienteService: ClienteService, 
    private empresaService: EmpresaService, 
    private campoService: CampoService,  
    private dialog: MatDialog, private location: Location ,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private toastService: ToastService, 
    private tokenStorageService: TokenStorageService) {
  /*    this.clientFilter.order.forEach(filter => {
      this.clientFilter.select[filter] = new Set();
      this.clientFilter.values[filter] = new Set();
    });*/
    
   }

  ngOnInit(): void {
    var last_campaign_search = -1;
    var last_subtype_search = '-1';
    var last_dateFilter_search = {
      activo: 0,
      fdesde: null,
      fhasta: null
    };
    
    var last_allDataSelected_search : false;
    var last_campaign : Campania | undefined;

    this.loading = true;
    this.dateFilter.activo = 0;
    
    if ((this.authService.isSuperUser()) ||(this.authService.isUserAdmin())) {
      this.canAddRecord = 'S';
    }

      var stFilter = this.tokenStorageService.getLastActivitiesFilter();
    //  this.selectedClient = -1;
      if (stFilter && stFilter.prevPage === 'st-actividades') {
        this.first_time = false;
      //  this.selectedClient = -1 ;
        this.selectedCompanyBuscar = null ;
        this.selectedFarmBuscar = null ;
        this.selectedClientBuscar = -1 ;

      //  this.onClientSelect(stFilter['selectedClient'], stFilter['selectedCompany'], stFilter['selectedFarm']);

        last_campaign_search = stFilter['selectedCampaign'];
        last_subtype_search = stFilter['selectedSubType'];
        last_dateFilter_search = {
          activo: stFilter['dateFilter_activo'],
          fdesde: stFilter['dateFilter_fdesde'],
          fhasta: stFilter['dateFilter_fhasta']
        };
        let lista_etapas = JSON.parse(stFilter['crop_stage_List']);
        this.last_crop_stage_List_search = lista_etapas;
        last_allDataSelected_search = stFilter['allDataSelected'];
        if (last_subtype_search != '-1') {
          this.selectedSubType = last_subtype_search;
          this.subtype_elegido =  last_subtype_search; //this.subtypeList.find(e => e.id === last_subtype_search);
          this.campania_y_cultivo_elegidos = 'Campaña '+ this.campania_elegida + '  -  Cultivo: '+this.subtype_elegido;      
          this.allDataSelected = last_allDataSelected_search; 
        }        
        if (last_dateFilter_search.activo) {
          this.dateFilter.fdesde = last_dateFilter_search.fdesde;
          this.dateFilter.fhasta = last_dateFilter_search.fhasta;
          this.range.get('start')?.setValue(this.dateFilter.fdesde);
          this.range.get('end')?.setValue(this.dateFilter.fhasta);
        }
      }

      
      this.clienteService.getClientesActivos().subscribe((clientes) => {
        this.clientList$ = clientes;
        const client_todos: Cliente = {
          id: -1,
          name: 'Todos'
        };
        this.clientList$.unshift(client_todos);          
        if (stFilter && stFilter.prevPage === 'st-actividades') {
          this.onClientSelect(stFilter['selectedClient'], stFilter['selectedCompany'], stFilter['selectedFarm']);
        }

      this.sus_campania = this.campaniaService.getCampanias().subscribe((campanias) => {
      this.campaniaList = campanias;
      this.campania_elegida = ' - ';
      this.campania_y_cultivo_elegidos = 'Campaña NO SELECCIONADA  -  Cultivo: '+this.subtype_elegido;      
      this.selectedCampaign = -1;
      if (last_campaign_search != -1) {
         last_campaign = this.campaniaList.find(e => e.id === last_campaign_search);
         if (last_campaign == undefined) {last_campaign = this.tokenStorageService.getLastCampaign();}
      }
      else {
        last_campaign = this.tokenStorageService.getLastCampaign();
      }

      if (!(isEmpty(last_campaign))) {
        let lc = last_campaign;
        if (lc != undefined) {
          this.selectedCampaign = + lc.id;
          this.campania_elegida = lc.name;
        }
        this.campania_y_cultivo_elegidos = 'Campaña '+ this.campania_elegida + '  -  Cultivo: '+this.subtype_elegido;      
        this.retrieveStages(); 
       
      }
      else {
        this.campaniaService.getActiveCampaign().subscribe((campania) => {
          if (campania) {
            console.log('levante campañas activas ',campania,' y elegi a ',campania[0].id);
            this.selectedCampaign = campania[0].id;
            this.campania_elegida = campania[0].name;
            this.campania_y_cultivo_elegidos = 'Campaña '+ this.campania_elegida + '  -  Cultivo: '+this.subtype_elegido;      
            this.tokenStorageService.saveCampaign(campania);
            this.retrieveStages(); 
          } 
          else {
              this.toastService.openSnackBar(`Error al leer campañas`, 'OK');
              this.loading = false;
          }
        },
        error => {
          this.toastService.openSnackBar(`Error al leer campaña activa: ${error}`, 'OK');
          this.loading = false;
        }
      );
    }
  },
  error => {
    this.toastService.openSnackBar(`Error al leer campañas: ${error}`, 'OK');
    this.loading = false;
  })
},
error => {
  this.toastService.openSnackBar(`Error al leer clientes: ${error}`, 'OK');
  this.loading = false;
});

  }

  private retrieveSubtypes(){
    this.sus_subtype = this.actividadService.getSubTypes().subscribe((subtypes) => {
      this.subtypeList = subtypes;
      const subtype_todos: ISubType = {
        sub_type: 'Todos'
      };
      this.subtypeList.unshift(subtype_todos);
      if (this.selectedSubType != '-1') {
        this.UpdateStages();      
      }
      else {
        if  (!this.first_time) {
          this.first_time = true; 
          this.buscar();
        }
        else {this.loading = false;}
      }
    },
    error => {
      this.loading = false;
      this.toastService.openSnackBar(`Error al leer cultivos: ${error}`, 'OK');
    });
  }

  private retrieveStages() {
    this.catalogoService.getCropStageList().subscribe((list) => {
      this.crop_stage_generic_List = list; 
       this.retrieveSubtypes();
      
    },
    error => {
      this.toastService.openSnackBar(`Error al leer estapas de cultivos: ${error}`, 'OK');
      this.loading = false;
    });

  }

  private buscar(){

    let client_id = -1;
    let company_id = -1;
    let farm_id = -1;

    if ((this.selectedClientBuscar) && (this.selectedClientBuscar != null)) {client_id = this.selectedClientBuscar;}
    if ((this.selectedCompanyBuscar) && (this.selectedCompanyBuscar != null)) {company_id = this.selectedCompanyBuscar.id;}
    if ((this.selectedFarmBuscar) && (this.selectedFarmBuscar != null)) {farm_id = this.selectedFarmBuscar.id;}

    this.retrieveActivities(this.selectedCampaign, client_id, company_id, farm_id, -1/*his.selectedPlot*/, this.selectedSubType, this.dateFilter.activo, this.dateFilter.fdesde, this.dateFilter.fhasta);
  }

  private retrieveActivities(campaign_id,client_id, company_id, farm_id, plot_id, subtype, filtro_activo_por_fecha, fdesde, fhasta ) {
    var fechaD = 'x';
    var fechaH = 'x';
    var lista_etapas : string[] = [];

    
    try {
      if (filtro_activo_por_fecha == 1) {
        fechaD = moment(fdesde).format('YYYY-MM-DD');
        var mD = moment(fechaD, 'YYYY-MM-DD');
        if (!(mD.isValid())) {
          this.toastService.openSnackBar(`Verifique la fecha "DESDE" ingresada (${fechaD})`, 'OK');
          this.loading = false;
          return;
        }
        fechaH = moment(fhasta).format('YYYY-MM-DD');
        var mH = moment(fechaH, 'YYYY-MM-DD');
        if (!(mH.isValid())) {
          this.toastService.openSnackBar(`Verifique la fecha "HASTA" ingresada (${fechaH})`, 'OK');
          this.loading = false;
          return;
        }
      }
    }
    catch(error) {
      this.toastService.openSnackBar(`Verifique las fechas ingresadas`, 'OK');
      this.loading = false;
      return;
    }
    
    //  this.crop_stage_List = [];
    if (!this.allDataSelected) {
      lista_etapas = this.crop_stage_List;
    }
    if (! (this.paginar)) {
    //  this.sus = this.actividadService.getActividadesFull(campaign_id,client_id, company_id, farm_id, , , , fechaD, fechaH).subscribe((actividades) => {      
      var condiciones = {'campaniaId':campaign_id,'clienteId':client_id,'companyId':company_id,'farmId':farm_id,
      'plotId':plot_id, 'sub_type': subtype, 'filtro_fecha': filtro_activo_por_fecha, 'fdesde': fechaD, 
      'fhasta' : fechaH, 'etapas': lista_etapas/*this.crop_stage_List*/
      }

      this.sus = this.actividadService.getActividadesFullp(condiciones).subscribe((actividades) => {   
      //  console.log('ACTIVIDADES',actividades);
      this.actividadList = actividades;
      this.actividadListFull = actividades;
      this.dataSource.data = actividades;
 /*     this.clientFilter.order.forEach(filter => {
        this.clientFilter.select[filter] = new Set();
        this.clientFilter.values[filter] = new Set();
      });
      this.refreshClientFilterValues(this.actividadList); 
      this.updateFilter({match: (activity) => this.matchFilter(activity, this.clientFilter)});*/
      setTimeout(() => {
        this.Setear_Ancho_Tabla(); 
      }, 300); 
      this.loading = false;
      },
      error => {
        this.toastService.openSnackBar(`Error al leer actividades: ${error}`, 'OK');
        this.loading = false;
      });
    }
    else {
      var condicionesPage = {'campaniaId':campaign_id,'clienteId':client_id,'companyId':company_id,'farmId':farm_id,
      'plotId':plot_id, 'sub_type': subtype, 'filtro_fecha': filtro_activo_por_fecha, 'fdesde': fechaD, 
      'fhasta' : fechaH, 'etapas': lista_etapas, 'currentpage':this.currentPage, 'pagesize': this.pageSize
      }
    //    this.sus = this.actividadService.getActividadesFullPage(campaign_id,client_id, company_id, farm_id, plot_id, subtype, filtro_activo_por_fecha, fechaD, fechaH, this.currentPage,this.pageSize).subscribe((actividades) => {      
        this.sus = this.actividadService.getActividadesFullPagep(condicionesPage).subscribe((actividades) => {            
              
        this.actividadList = actividades.data;
        this.actividadListFull = actividades.data;
        this.dataSource.data = actividades.data;
        if (this.paginar) {
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = actividades.cuantos; //this.actividadList.length;
          });
          }
   /*       this.refreshClientFilterValues(this.actividadList); 
          this.updateFilter({match: (activity) => this.matchFilter(activity, this.clientFilter)});*/
          setTimeout(() => {
            this.Setear_Ancho_Tabla(); 
          }, 300); 
          this.loading = false;
        },
        error => {
          this.toastService.openSnackBar(`Error al leer actividades: ${error}`, 'OK');
          this.loading = false;
        });

    }
  }


  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.buscar();
    //this.retrieveActivities(this.selectedCampaign, this.selectedClient, this.selectedCompany, this.selectedFarm, this.selectedPlot, this.selectedSubType, this.dateFilter.activo, this.dateFilter.fdesde, this.dateFilter.fhasta);
  }

  async downloadRow(activity: IActivityFull){
    if (!activity.scout_count) {
      return;
    }
    const scouts = this.actividadService.getScoutsCSV(activity.id,-1).subscribe((scouts) => {
      var options = {
        unwindArrays: false,
        expandArrayObjects: true,
        excelBOM: true,
        emptyFieldValue: '',
        delimiter: { field : ';', eol: '\r\n' }
      }

      let sct = scouts.map(scout => scoutConverter.convert(scout));
      converter.json2csv(sct, function (err, csv) {
        if (err) {
            throw err;
        }
    
      let blob = new Blob(['\ufeff' + csv], {type: 'text/csv;charset=utf-8;'});
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 ;
      navigator.userAgent.indexOf('Chrome') == -1;
      
      //if Safari open in new window to save file with random filename.
      if (isSafariBrowser) { 
          dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", "Recorridas " + activity.farm_name + '-' + activity.plot_name + '-' + activity.sub_type + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }, options);
  },
  error => {
    this.toastService.openSnackBar(`Error al obtener actividades a descargar : ${error}`, 'OK');
  });
}

 
/*  editRow(row: Actividad) {
    const dialogRef = this.openModal(row);
    dialogRef.afterClosed().subscribe(
      (newCampaign: Actividad) => {
        if (newCampaign) {
          this.actividadService.updateActividad(newCampaign).subscribe( ()  => {
            },
            error => {
              this.actividadService.getActividadesFull(this.selectedCampaign, this.selectedClient, this.selectedCompany, this.selectedFarm, this.selectedPlot, this.selectedSubType).subscribe((actividades) => this.actividadList = actividades);
              this.toastService.openSnackBar(`Error al actualizar la actividad: ${error}`, 'OK');
            });
        }
      }
    );
  }*/

  deleteRow(row: IActivityFull) {
    const data = {
      title: `ATENCIÓN: Borrar actividad`,
      message: 'Está seguro?' /* Al borrar actividad se perderan todas sus recorridas*/
    };
    const dialogRef = this.dialog.open(ConfirmedDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      width: 'auto',
      data
    });
    const id_a_borrar = row.id;
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          if (Number(row.scout_count) == 0) {
            this.actividadService.deleteFisicoActividad(id_a_borrar).subscribe(
              ()  => {
                this.buscar();
              }
            );
          }
          else {
            this.actividadService.deleteActividad(id_a_borrar).subscribe(
              ()  => {
                this.buscar();
              }
            );            
          }

        }
      }
    );
  }


  navigateTo(row: IActivityFull) {
    if (Number(row.scout_count) > 0) {
      let lista_etapas = JSON.stringify(this.crop_stage_List);
      let stFilter: any = {
        selectedCampaign: this.selectedCampaign, 
        selectedClient: this.selectedClientBuscar /*this.selectedClient*/, 
        selectedCompany: this.selectedCompanyBuscar /*this.selectedCompany */, 
        selectedFarm: this.selectedFarmBuscar /*this.selectedFarm*/, 
    //    selectedPlot: this.selectedPlot, 
        selectedSubType: this.selectedSubType, 
        dateFilter_activo: this.dateFilter.activo, 
        dateFilter_fdesde: this.dateFilter.fdesde, 
        dateFilter_fhasta: this.dateFilter.fhasta,
        allDataSelected: this.allDataSelected,
        crop_stage_List: lista_etapas, 
        prevPage: 'st-actividades'
      };
      this.tokenStorageService.saveLastActivitiesFilter(stFilter);

      this.router.navigate([`${row.id}/scouts`], {state: {data:row.plot_name + ' ('+row.sub_type+')'}, relativeTo: this.route});
    }
  }


  goBack() {
    this.location.back();
  }

/*  getClientIdFiltered(): number{
    if (this.clientFilter.values['client'].size > 0) {
      if (this.actividadList.length > 0) {
        return this.actividadList[0].client_id;
      }
    }
    return this.selectedClient;
  }

  getCompanyIdFiltered(): number{
    if (this.clientFilter.values['company'].size > 0) {
      if (this.actividadList.length > 0) {
        return this.actividadList[0].company_id;
      }
    }
    return this.selectedCompany;
  }

  getFarmIdFiltered(): number{
    if (this.clientFilter.values['farm'].size > 0) {
      if (this.actividadList.length > 0) {
        return this.actividadList[0].farm_id;
      }
    }
    return this.selectedFarm;
  }
*/

 /* downloadGrid() {
    let jsPdf = new jsPDF('p', 'pt', 'a4');
    var htmlElement = document.getElementById('htmlData');
    const options = {
      background: 'white',
      scale: 3
    };
    if (htmlElement) {
      html2canvas(htmlElement, options).then((canvas) => {
    // you need to load html2canvas (and dompurify if you pass a string to html)
    //const img = canvas.toDataURL('image/PNG');
    const opt = {
        callback: function (jsPdf) {
            jsPdf.save("Test.pdf");
            // to open the generated PDF in browser window
            // window.open(jsPdf.output('bloburl'));
        },
        margin: [72, 72, 72, 72],
        autoPaging: 'text',
        html2canvas: {
            allowTaint: true,
            dpi: 300,
            letterRendering: true,
            logging: false,
            scale: .8
        }
    };

    jsPdf.html(htmlElement, opt);
  });
}
}*/

insertarSaltosDeLineaHTML(texto: string): string {
  return '<p>'+texto.replace(/\n/g, '<br>')+'</p>';
}

eliminarUltimoSaltoDeLinea(cadena) {
  const lastIndex = cadena.lastIndexOf('\n');
  
  if (lastIndex !== -1) { // Comprobar si '\n' se encontró en la cadena
    return cadena.slice(0, lastIndex); // Eliminar el último '\n'
  } else {
    return cadena; // No se encontró '\n', devuelve la cadena original sin cambios
  }
}

downloadGrid(){
  let printContents, popupWin;
  const fecha = moment().format('DD-MM-YYYY');
    const html_id = document.getElementById('htmlData');
    if (html_id) {
      printContents = html_id.innerHTML;
      

      const tbody = html_id.querySelector('tbody');
      if (tbody) {
        var miTablaHTML = printContents;
        const tempEl = document.createElement('div');
        tempEl.innerHTML = miTablaHTML; 
        
        
        var div_table_wrapper = tempEl.querySelector('#table_wrapper');
        if (div_table_wrapper) {
          div_table_wrapper.removeAttribute("style");
        }

        const headers = tempEl.querySelectorAll('th');
        if (headers) {
          for (var i = 0; i < headers.length; i++) {
            var th = headers[i];
          //  console.log('i',i,th.textContent);
            switch (th.textContent) {
              case 'spa':
                th.innerText = 'IMPLANT.';
                break;
              case 'local_florist':
                th.innerText = 'MALEZAS';
                break;
              case 'adb':
                th.innerText = 'PLAGAS';
                break;
              case 'local_hospital':
                th.innerText = 'ENFERM.';
                break; 
              case 'ac_unit':
                th.innerText = 'ADVERS.';
                break;                                                                        
              default:
                break;
            }
           
          }
        }        
        
      
        const tbody = tempEl.querySelector('tbody');
        if (tbody) {
          const filas = tbody.querySelectorAll('tr');
          filas.forEach((fila) => {
            const nuevaFila = document.createElement('tr');
            const dataIndex = fila.getAttribute('data-row-index');
          //  console.log('dataIndex',dataIndex,'fila',fila);

            // agregar aquí el contenido de la nueva fila, como celdas (<td>) y contenido de texto
            if ((fila.parentNode) && dataIndex) {
              fila.classList.add("top-border-row");
              const rowData = this.actividadListFull[dataIndex];
              const observations = rowData.observations;
              if (observations && (observations != '')) {
                const celda1 = document.createElement('td');
                celda1.textContent = '';
                celda1.colSpan = 3;
                celda1.classList.add('no-border');
                nuevaFila.appendChild(celda1);

                const celda2 = document.createElement('td');
                celda2.innerHTML = this.eliminarUltimoSaltoDeLinea(this.insertarSaltosDeLineaHTML(observations));
                celda2.colSpan = 13;
                celda2.classList.add('no-border');
                nuevaFila.appendChild(celda2);

                fila.parentNode.insertBefore(nuevaFila, fila.nextSibling);
              }
            }
            
          });
          miTablaHTML = tempEl.innerHTML;
          printContents = miTablaHTML;
        }
      }
   // console.log(tbody?.innerHTML);
    if (tbody)
    popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto');
    popupWin.document.open();
  //  popupWin.document.write(`<style>@page{size:landscape;}</style>
  
/*  td.mat-cell, td.mat-footer-cell {
    padding: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }*/
  /*  .mat-row {
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: rgba(0, 0, 0, 0.12);
    }*/
    /*  .td.mat-cell {
      padding: 10px;
      font-size: 10px;
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: rgba(0, 0, 0, 0.12);
    }*/
/* .top-border-row {
      border-top: gray solid thin;
    }*/

    popupWin.document.write(`<style media="all">
    body {
      print-color-adjust: exact !important; 
      -webkit-print-color-adjust: exact !important;
    }
  
    .mat-sort-header-content {
      font-size: 11px;
    }
    th {
      font-size: 11px;
    }
    table {
      border-spacing: 0px 10px;
      border-collapse: collapse;
    }
    td {
      padding: 10px !important;
      font-size: 12px;
      border-top: lightgray solid thin;
      margin-left: 0px;
      margin-right: 0px;
    }

    
   

    .no-border {
      border-top: none;
    }
    .ocultar_al_imprimir {display:none;}
    .mostrar_al_imprimir {display:all;}
    .puntal-action-column {display:none;}
    .semaphore {border: 1px solid rgba(128, 128, 128, 0.36);border-radius: 4px;margin-left: 5px; font-size: 10px; text-align: center;width: 50px;
      min-height: 20px;}
    .s0 {
      background-color: rgb(158, 158, 158);
    }
    .s1 {
      background-color: rgb(76, 175, 80);
    }
    .s2 {
      background-color: rgb(255, 235, 59);
    }
    .s3 {
      background-color: rgb(255, 152, 0);
    }
    .s4 {
      background-color: rgb(244, 67, 54);
    }
    .s0g-fill {
      background-color: rgb(2, 68, 27);
      color: transparent;
    }
    .s1g-fill {
      background-color: rgb(76, 175, 80);
      color: transparent;
    }
    .s2g-fill {
      background-color: rgb(158, 158, 158);
      color: transparent;
    }
    .s3g-fill {
      background-color: rgb(255, 152, 0);
      color: transparent;
    }
    .s4g-fill {
      background-color: rgb(244, 67, 54);
      color: transparent;
    }    
    .no-data {
      padding: 0;
      background-color: transparent;
      
    }
    .no-data-fill {
      visibility: hidden;
    }     
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
    }
    .title {
      text-align: left;
    }
    
    .logo {
      float: right;
      margin-top: 0px;
      width: 24px; /* Tamaño deseado del logotipo */
      height: auto;
    }
    </style><html>
    <head>
    
      <title>Actividades ${fecha}</title>
     
    </head>
<body onload="window.print();window.close()">
<header>
<h4>Reporte de actividades al ${fecha}</h4> <img class="logo" src="/assets/icon.png" alt="Logo">
</header>

<table class="table table-bordered">${printContents}</table></body>
  </html>`
    );
    
     // <img class="logo" src="/assets/icon.png" alt="Logo">  --> esto junto al header pero se ve mal
    popupWin.document.close();
  }
}

/*  downloadGrid2() {
      const DATA = document.getElementById('htmlData');
    
      if (DATA) {
      // let iconos_accion = Array.prototype.slice.call(document.getElementsByClassName("puntal-action-column"), 0);
      //  for(var element of iconos_accion){
      //    element.remove();
      //  }  
        
      const doc = new jsPDF('p', 'pt', 'a4');  //'l', 'mm', [297, 210]
      const options = {
        background: 'white',
        scale: 3,
        autoPaging: 'text'
      };
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text('Reporte de actividades', 10, 10);
      html2canvas(DATA, options).then((canvas) => {
        
        const img = canvas.toDataURL('image/PNG');

        // Add image Canvas to PDF
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      //doc.html(DATA, options);
        return doc;
      }).then((docResult) => {
        docResult.save(`actividades_${new Date().toISOString()}.pdf`);
        
      })
      .catch(function(e){
        
      });
    }
  }
*/
  addRow() {
    let client_id = -1;
    let company_id = -1;
    let farm_id = -1;

    if ((this.selectedClientBuscar) && (this.selectedClientBuscar != null)) {client_id = this.selectedClientBuscar;}
    if ((this.selectedCompanyBuscar) && (this.selectedCompanyBuscar != null)) {company_id = this.selectedCompanyBuscar.id;}
    if ((this.selectedFarmBuscar) && (this.selectedFarmBuscar != null)) {farm_id = this.selectedFarmBuscar.id;}

    const row = {
      id: -1,
      plot_id: -1, //this.selectedPlot,
      farm_id: farm_id, //this.getFarmIdFiltered(),
      company_id: company_id, //this.getCompanyIdFiltered(),
      client_id: client_id, //this.getClientIdFiltered(),
      start_date: new Date(0),
      end_date: new Date(0),
      type: 'Cultivo',
      sub_type: this.selectedSubType,
      campaign_id: this.selectedCampaign
    } as Actividad;
    const dialogRef = this.openModal(row);
    dialogRef.afterClosed().subscribe(
      (newActivity: Actividad) => {
        if (newActivity) {
          this.buscar();
          //  this.retrieveActivities(this.selectedCampaign, this.selectedClient, this.selectedCompany, this.selectedFarm, this.selectedPlot, this.selectedSubType, this.dateFilter.activo, this.dateFilter.fdesde, this.dateFilter.fhasta);
      }
    }
    )
  }

  private openModal(actividad?: Actividad): MatDialogRef<ActividadFormComponent, any> {
    return this.dialog.open(ActividadFormComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: true,
      data: actividad || {}
    });
  }
 
  get canAdd() {
  //  return this.authService.canAdd();
  return true;
  }

  get canDownload() {
    //  return this.authService.canAdd();
    return true;
    }

  onCampaignSelect(event: MatSelectChange) {
    if (this.selectedCampaign && this.selectedCampaign === event.value) {
      return;
    }
    
    this.selectedCampaign = event.value;
    this.campania_elegida = event.source.triggerValue;

  /*  const campaign = {
      'id' : this.selectedCampaign,
      'name': this.campania_elegida
    }*/
    const campaign = this.campaniaList.find(e => e.id === this.selectedCampaign);
    this.tokenStorageService.saveCampaign(campaign); 
    this.userService.saveLastCampaign(this.selectedCampaign).subscribe();  
    this.campania_y_cultivo_elegidos = 'Campaña '+this.campania_elegida + '  -  Cultivo: '+this.subtype_elegido; 
/*    this.sus = this.actividadService.getActividadesFull(this.selectedCampaign, this.selectedClient, this.selectedCompany, this.selectedFarm, this.selectedPlot, this.selectedSubType).subscribe((actividades) => {
      this.actividadList = actividades;
      this.actividadListFull = actividades;
      this.refreshClientFilterValues(this.actividadList);
    });*/
  //  this.retrieveActivities(this.selectedCampaign, this.selectedClient, this.selectedCompany, this.selectedFarm, this.selectedPlot, this.selectedSubType, this.dateFilter.activo, this.dateFilter.fdesde, this.dateFilter.fhasta);    
  }

  private UpdateStages(){
    this.crop_stage_List = [];
    this.crop_specie_stage_List = [];

    if (this.selectedSubType != '-1') {
      for(let i = 0; i< this.crop_stage_generic_List.length; i++) {
        if (this.crop_stage_generic_List[i]['cultivo_etapa'][this.selectedSubType]) {
          this.crop_specie_stage_List = this.crop_stage_generic_List[i]['cultivo_etapa'][this.selectedSubType];
          break;
        }
      }
      if (this.allDataSelected) {
        this.crop_stage_List = this.crop_specie_stage_List.map(e => e.name);
        this.toggleAllSelection();
      }
      else {
        this.crop_stage_List = this.last_crop_stage_List_search;
     }
     
     if (!this.first_time) {this.first_time = true; this.buscar();}
     
     this.loading = false;
    }    
  }

  onSubTypeSelect(event: MatSelectChange) {
    if (this.selectedSubType && this.selectedSubType === event.value) {
      return;
    }
    const cultivo = (event.value == 'Todos' ? '-1' : event.value);
    this.selectedSubType = cultivo;
    this.subtype_elegido = event.source.triggerValue; 
    this.campania_y_cultivo_elegidos = 'Campaña '+ this.campania_elegida + '  -  Cultivo: '+this.subtype_elegido;      
    this.UpdateStages();
  }

/*  changeClientSelect($event: MatSelectChange, field: string) {
    // Aca llamar a algo que actualice los campos elegidos para cliente/empresa/campo
    this.clientFilter.select[field] = new Set([$event.value]);
    this.clientFilter.changed[field] = $event.source;
    Object.keys(this.clientFilter.select)
      .filter(selectField => selectField !== field)
      .forEach(selectField => {
        this.clientFilter.select[selectField] = new Set();
      });
    this.clientFilter.values = {[field]: this.clientFilter.values[field]};
    this.updateFilter({match: (activity) => this.matchFilter(activity, this.clientFilter)});
  }
*/
  private matchFilter(activity: IActivityFull, filter: Filter<IActivityFull>) {
    const matchAll = Object.entries(filter.info).map(([key, data]) => {
      const set = filter.select[key];
      if (set.size === 0 || set.size === filter.values.length) {
        return true;
      }
      const value = data.getter(activity);
      return set.has(value);
    });
    return matchAll.every(Boolean);
  }  

/*  updateFilter(matcher: Matcher<IActivityFull>) {
    this.actividadList = this.actividadListFull;
    this.actividadList = this.actividadList.filter(activity => matcher.match(activity));
  //  this.refreshClientFilterValues(this.actividadList);
  }  */

  closed(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    if ((this.dateFilter.fdesde == null) && (this.dateFilter.fhasta == null)) {
      return;
    }
    if (!dateRangeEnd.value) {
      dateRangeEnd.value = dateRangeStart.value;
      this.dateFilter.fhasta = this.dateFilter.fdesde;
    //  this.retrieveActivities(this.selectedCampaign, this.selectedClient, this.selectedCompany, this.selectedFarm, this.selectedPlot, this.selectedSubType, this.dateFilter.activo, this.dateFilter.fdesde, this.dateFilter.fhasta);          
      return;
    }
    if (!dateRangeStart.value) {
      dateRangeStart.value = dateRangeEnd.value;
      this.dateFilter.fdesde = this.dateFilter.fhasta;
      this.dateFilter.activo = 1;
    }    
  }


  cleanDateFilters(){
    this.dateFilter.activo = 0;
    this.dateFilter.fdesde = null;
    this.dateFilter.fhasta = null;
    this.range.reset();
  }

  dateRangeChange(dateRangeStart) {
    this.dateFilter.fdesde = dateRangeStart;
    this.dateFilter.activo = 1;
  }

  dateEndRangeChange(dateRangeEnd) {
    this.dateFilter.fhasta = dateRangeEnd;
    this.dateFilter.activo = 1;
  }

/*  cleanFilters(activityFilter: Filter<IActivityFull>) {
    activityFilter.order.forEach(filter => {
      activityFilter.select[filter] = new Set();
    });
    Object.values(activityFilter.changed).forEach(model => {
      setTimeout(() => {
        model._selectionModel.clear();
        model.toggle();
        model.close();
      }, 100);
    });
    activityFilter.changed = {};
    this.selectedClient = -1;
    this.selectedCompany = -1;
    this.selectedFarm = -1;        
    this.updateFilter({match: (activity) => this.matchFilter(activity, this.clientFilter)});
  }

  cleanClientFilters() {
    this.selectedClient = -1;
    this.selectedCompany = -1;    
    this.selectedFarm = -1;
    this.cleanFilters(this.clientFilter);
  }
  */
  anyFilter(filter: Filter<IActivityFull>) {
    return Object.values(filter.changed).length > 0;
  }

  allSelected(filter: Filter<IActivityFull>, field: string) {
    const set = filter.select[field] || new Set();
    return set.size === 0 || set.size === filter.values.length;
  }

  textPrettyFilter(selectElement: Set<any>) {
    const values = Array.from(selectElement);
    return values.join(', ');
  }

/*  valueIfOne(field: string) {
    const set = this.clientFilter.values[field];
    if (set && set.size === 1) {
      this.clientFilter.select[field] = set;
      return Array.from(set)[0];
    }
    return undefined;
  }

  disableClientSelect(field: string) {
    return !!this.valueIfOne(field);
  }


  private refreshClientFilterValues(activities: IActivityFull[]) {
    activities
      .filter(Boolean)
      .forEach(activity => {
        this.clientFilter.order.forEach(order => {
          const set = this.clientFilter.values[order] || new Set();
          const value = this.clientFilter.info[order].getter(activity);
          set.add(value);
          this.clientFilter.values[order] = set;
        });
      });
  }  */
/*
  private openNotes(row: IActivityFull): MatDialogRef<NotesFormComponent, any> {
    let operacion = ((this.canAddRecord == 'S')?'E':'C');
    let titulo = 'Observaciones última recorrida lote ' + row.plot_name;
    
    return this.dialog.open(NotesFormComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: true,
      data: {'scout_id' : row.last_scout?.id, 'operacion' : operacion, 'titulo' : titulo}
    });
  }
*/
  /*private openMedia(row: IActivityFull): MatDialogRef<MediaFormComponent, any> {
   
    let operacion = ((this.canAddRecord == 'S')?'E':'C');
    let titulo = 'Observaciones última recorrida lote ' + row.plot_name;
  
    return this.dialog.open(MediaFormComponent, {
      id: 'dialog_media',
      width: '90%',
      height: '90%',
      disableClose: true,
      autoFocus: true,
      panelClass: ['panel_oscuro'], 
      data: {'scout_id' : row.last_scout?.id, 'operacion' : operacion, 'titulo' : titulo, 'notas': row.observations}
    });
  }*/
  
  private openMedia(row: IActivityFull) {
    
    if ((row.farm_id == 35) || (row.farm_id == 36)) {
    //  console.log('voy a '+`/mapa/${row.farm_id}`);
    //  this.router.navigate([`/mapa/${row.farm_id}`], {state: {data:row.plot_name + ' ('+row.sub_type+')'}, relativeTo: this.route});

   // const stateData = { data: row.plot_name + ' (' + row.sub_type + ')' };

 /*   const url = this.router.createUrlTree([`/mapa/${row.farm_id}`], {
    //  state: stateData,
      relativeTo: this.route
    }).toString();*/

    const url = 'http://www.puntalagro.com'; // '/mapa?farm='+`${row.farm_id}`;
    // Abre el enlace en una nueva pestaña
    window.open(url, '_blank', 'noopener,noreferrer');    

      return;
    }
    let operacion = ((this.canAddRecord == 'S')?'E':'C');
    let titulo = 'Observaciones última recorrida lote ' + row.plot_name;

    const dialogRef = this.dialog.open(MediaFormComponent, {
      id: 'dialog_media',
      width: '90%',
      height: '90%',
      disableClose: true,
      autoFocus: true,
      panelClass: ['panel_oscuro'], 
      data: {'scout_id' : row.last_scout?.id, 'operacion' : operacion, 'titulo' : titulo, 'observations': row.observations}
    });
    dialogRef.afterClosed().subscribe(
      (notes: string) => {
        if (notes != null) {
          row.observations = notes;
        }
        
      }
    );
  }
  
  ngOndestroy() {
    this.sus.unsubscribe();
    this.sus_campania.unsubscribe();
    this.sus_subtype.unsubscribe();
    this.sus_media.unsubscribe();
  }

  isAuthenticated() {
    return true;
  }

  onClientSelect(client: number, empresa: Empresa|null, campo: Campo|null) { //Cliente) {
    if (this.selectedClientBuscar && this.selectedClientBuscar === client) {
    //  return;
    }
    this.selectedClientBuscar = client;
    this.selectedCompanyBuscar =  null;
    this.selectedFarmBuscar =  null;
    const company_todos: Empresa = {
      id: -1,
      name: 'Todas',
      client_id: client
    };
    const farm_todos: Campo = {
      id: -1,
      name: 'Todos',
      company_id: -1
    };
    this.farmList$ = [];
    
    
    if (client && (client != -1)) {
      this.empresaService.getEmpresasActivas(client).subscribe((empresas) => {
        this.companyList$ = empresas;
        if (this.companyList$.length != 1) {
          this.companyList$.unshift(company_todos);
          if (empresa != null) {
            this.onCompanySelect(empresa,campo);
          }
          else {
            this.onCompanySelect(company_todos,null);
          }
        }
        else {
           this.onCompanySelect(empresas[0],campo);
        }
      });    
    } 
    else {
    //  console.log('2 Voy a llamar a onCompanySelect con "todos" y ',campo);
      this.companyList$ = [];
      this.companyList$.unshift(company_todos);
      this.onCompanySelect(company_todos,campo);
    }
    
  }

  onCompanySelect(company: Empresa,campo: Campo | null) {
  //  console.log('estoy en onCompanySelect (1) ',company,campo);
 /*   if (this.selectedCompanyBuscar && this.selectedCompanyBuscar.id === company.id) {
      console.info('Vuelvo sin buscar ',company.id,this.selectedCompanyBuscar);
      return;
    }*/
    this.selectedCompanyBuscar = company;
    //this.selectedFarmBuscar =  campo;
    const farm_todos: Campo = {
      id: -1,
      name: 'Todos',
      company_id: company.id
    };    
    if (company.id != -1) {
      console.info('Campo activo de compania ',company.id);
      this.campoService.getCamposActivos(company.id).subscribe((campos) => {
        this.farmList$ = campos;
        console.log('Cargo campos',campos);
        if (this.farmList$.length != 1) {
          this.farmList$.unshift(farm_todos);
          if (campo != null) {
          //  this.selectedFarmBuscar =  campo;
            this.onFarmSelect(campo);  
          }
          else {
            this.onFarmSelect(farm_todos);
          }
        }
        else {
          this.onFarmSelect(campos[0]);
        }
    }); 
    }
    else {
      this.farmList$ = [];
      this.farmList$.unshift(farm_todos);
      this.onFarmSelect(farm_todos);      
    }     
  }
  
  onFarmSelect(farm: Campo) {
  /*  if (this.selectedFarmBuscar && this.selectedFarmBuscar.id === farm.id) {
      return;
    }*/
  if (farm){
    const farm_selected = this.farmList$.find(e => e.id === farm.id);
    if (farm_selected) {this.selectedFarmBuscar = farm_selected;}
  }
    
   // this.selectedFarmBuscar = farm;
  //  this.selectedFarmControl.setValue(this.selectedFarmBuscar);
  //  console.log('this.selectedFarmBuscar ',this.selectedFarmBuscar);
  }
  
  
  Setear_Ancho_Tabla(){
    const table_wrapper_id = document.getElementById('table_wrapper');
    if (table_wrapper_id) {
      if (this.showFilters){
        table_wrapper_id.style.height = '180px';
      }
      else {
        table_wrapper_id.style.height = '460px';
      }    
    }   
  }
  
  toggleDiv() {
    this.showFilters = !this.showFilters;
    if (this.showFilters) {
      this.showFilterMode = 'H';
    }
    else {
      this.showFilterMode = 'S';
    }
     
    this.Setear_Ancho_Tabla();
    
  }

}

function decision(field: string) {
  return activity => scoutDecisionFiler(activity.last_scout as Scout, field);
}

function semaphore(field: string) {
  return activity => {
    return scoutSempahoreFilter(activity.last_scout as Scout, field);
  }
}



