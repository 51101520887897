import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators'
import { environment } from './../../environments/environment';
import { TokenStorageService } from '../_services/token-storage.service';
import { PermisoService } from '../_services/permiso.service';
import { Router, ActivatedRoute } from '@angular/router';

const AUTH_API = environment.API_URL + 'auth/'; 

interface IPermission {
  permiso: boolean;
  tipo?: string;
  mensaje?: string;
}

interface TokenData {
  id: string;
  token: string;
  role: UserRole;
  username: string;
}

interface IIsUserAdmin {
  es_admin: number;
}

enum UserRole {
  PUNTAL_SUPER = 'PUNTAL_SUPER',
  ADMIN = 'USER_ADMIN',
  USER = 'USER'
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

/* Se podria crear una variable this.isUser con el id del usuario actual llamando al tokenStorage.getuser.id */
export class AuthService {
  private permiso : IPermission;
//  private token: string;
  private tokenData: TokenData;

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private permisoService: PermisoService) { 
    this.loadToken();
  }

  private goTSignIn() {
    this.tokenStorage.signOut();
    this.router.navigate(['/login']).catch(err => console.log(err));
  }

  public setUserStorage(data) {
    this.tokenStorage.saveUser(data);
    this.loadToken();
  }    

  private loadToken() {
    if (!this.isAuthenticated()) {
      this.goTSignIn();
      return;
    }
    // decode token to read the payload details
 /*   this.token = this.tokenStorage.getUser();
    if (this.jwtHelperService.isTokenExpired(this.token)) {
      this.signout();
      return;
    }*/

    this.tokenData = this.tokenStorage.getUser();
    // check if it was decoded successfully, if not the token is not valid, deny access
    if (!this.tokenData) {
      console.log('Invalid token');
      this.goTSignIn();
    }
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signin', {
      email,
      password
    }, httpOptions);
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username,
      email,
      password
    }, httpOptions);
  }

  isAuthenticated(){
    return (this.tokenStorage.getToken() != null);
  }

  getId(): number {
    let userId = this.tokenStorage.getUser().id;
    return userId;
  }

  getRoles(): any {
    return this.tokenStorage.getUser().roles;
  }
  
  isSuperUser() {
  //  this.tokenData = this.tokenStorage.getUser();
    return !!this.tokenData && this.tokenData.role === UserRole.PUNTAL_SUPER;
  }

  isPuntalUser() {
   return !!this.tokenData && this.tokenData.role === UserRole.PUNTAL_SUPER;
  }

  isUserAdmin() {
    let esUserAdmin = this.tokenStorage.getUser().isUserAdmin;
    return esUserAdmin;
  }  

async GetisUserAdmin(): Promise<boolean> {
  if (this.isSuperUser()) { return true;}
  return await this.http.get<IIsUserAdmin>(AUTH_API + 'permiso/getIsAdmin', { responseType: 'json' }).toPromise()
  .then(resultado => {
    if (resultado) {
      return (resultado[0].es_admin > 0);
    }
    else {
      return false;
    }
    
  }).catch(error => {
    return false;
  });
  
}


// get PERMISOS ---------------------------------------------------------------------------------------------
  // adds ---------------------------------------------------------------------------------------------------
  getPermisoAddClient(): Observable<IPermission> {
    return this.http.get<IPermission>(AUTH_API + 'permiso/create_client', { responseType: 'json' });
  }

  getPermisoAddCompany(clientId): Observable<IPermission> {
    return this.http.get<IPermission>(AUTH_API + 'permiso/create_company/'+clientId, { responseType: 'json' });
  }

  getPermisoAddFarm(companyId): Observable<IPermission> {
    return this.http.get<IPermission>(AUTH_API + 'permiso/create_farm/'+companyId, { responseType: 'json' });
  }  

  getPermisoAddPlot(farmId): Observable<IPermission> {
      return this.http.get<IPermission>(AUTH_API + 'permiso/create_plot/'+farmId, { responseType: 'json' });
  }  
    
  getPermisoAddActivities(): Observable<IPermission> {
    return this.http.get<IPermission>(AUTH_API + 'permiso/create_activities', { responseType: 'json' });
  }    
  // edits ----------------------------------------------------------------------------------------------
  getPermisoEditCompany(companyId): Observable<IPermission> {
    return this.http.get<IPermission>(AUTH_API + 'permiso/edit_company/'+companyId, { responseType: 'json' });
  }
  getPermisoEditFarm(farmId): Observable<IPermission> {
    return this.http.get<IPermission>(AUTH_API + 'permiso/edit_farm/'+farmId, { responseType: 'json' });
  }  

  // deletes ---------------------------------------------------------------------------------------------
  getPermisoDeleteCompany(clientId): Observable<IPermission> {
    return this.http.get<IPermission>(AUTH_API + 'permiso/delete_company/'+clientId, { responseType: 'json' });
  }

  getPermisoDeleteFarm(companyId): Observable<IPermission> {
    return this.http.get<IPermission>(AUTH_API + 'permiso/delete_farm/'+companyId, { responseType: 'json' });
  }  

  getPermisoDeletePlot(farmId): Observable<IPermission> {
    return this.http.get<IPermission>(AUTH_API + 'permiso/delete_plot/'+farmId, { responseType: 'json' });
  }  
    
  getPermisoDeleteActivities(): Observable<IPermission> {
    return this.http.get<IPermission>(AUTH_API + 'permiso/delete_activities', { responseType: 'json' });
  }    

  async CanAdd(nodo: string, clientId?, empresaId?, farmId?, plotId?): Promise<boolean> {
    // obtener permiso actual sobre la fila
    let userLoggedIn = this.getId();
    try {
      if (this.isSuperUser()) {return true;} 
      if (nodo == 'client')   {return this.isSuperUser();} 
      if (nodo == 'company') {
        let permiso_userLoggin = await this.permisoService.rol_nodo('client',clientId, userLoggedIn);
        return (permiso_userLoggin == 'ADMIN');
      } else {
        if (nodo == 'farm') {
          let permiso_userLoggin = await this.permisoService.rol_nodo('company',empresaId, userLoggedIn);
          return (permiso_userLoggin == 'ADMIN');
        } else {
          if (nodo == 'plot') {
            let permiso_userLoggin = await this.permisoService.rol_nodo('farm',farmId, userLoggedIn);
            return (permiso_userLoggin == 'ADMIN');
          } else { return false;}
        }
      }
      return false;
    }
    catch (err) {
      console.error(err);
  //    this.toastService.openSnackBar('Se produjo un error al validar permisos para el usuario ('+err+')', 'OK', 300000);
      return false;
    }
  }
  async CanEdit(nodo: string, id: number) : Promise<boolean> {
    let userLoggedIn = this.getId();
    try {
      if (this.isSuperUser()) {return true;} 
      if (nodo == 'client')   {return this.isSuperUser();} 
      let permiso_userLoggin = await this.permisoService.rol_nodo(nodo,id, userLoggedIn);
      return (permiso_userLoggin == 'ADMIN');
    }
    catch (err) {
      console.error(err);
  //    this.toastService.openSnackBar('Se produjo un error al validar permisos para el usuario ('+err+')', 'OK', 300000);
      return false;
    }
  }
// can ADD - EDIT - DELETE ---------------------------------------------------------------------------------
/*  public CanAdd(nodo: string, clientId?, empresaId?, farmId?, plotId?) : boolean {
    if (this.isSuperUser()) {return true;}
    return this.CanAdminNodo(nodo, clientId, empresaId, farmId, plotId).then((data) => {
      if (data) {return data}
      else {return false;}
    }).catch(error => {
      return false;
    });
  }
*/
/* // can ADD - EDIT - DELETE ---------------------------------------------------------------------------------
 public CanAdd(nodo: string, clientId?, empresaId?, farmId?, plotId?) : boolean {
  var can: boolean = false;
  if (this.isSuperUser()) {return true;}
  switch (nodo) {
    case 'client':
      return this.isSuperUser();
      break;
    case 'company':
      this.getPermisoAddCompany(clientId).subscribe((permiso) => {
        if (permiso) {
          can = ((permiso.tipo == 'ADMIN') || this.isSuperUser());
        }});
      break;
    case 'farm':
      this.getPermisoAddFarm(empresaId).subscribe((permiso) => {
        if (permiso) {
          can = ((permiso.tipo == 'ADMIN') || this.isSuperUser());
        }});
      break;
    case 'plot':
      this.getPermisoAddPlot(farmId).subscribe((permiso) => {
        if (permiso) {
          can = ((permiso.tipo == 'ADMIN')  || this.isSuperUser());
        }});
      break;
    default:
      return false;                                    
  }
  return can;
}*/

/*  CanEdit(nodo: string, clientId, companyId?, farmId?, plotId?) {
    var can: boolean = false;
    if (this.isSuperUser()){
      return true;
    }
    switch (nodo) {
      case 'client':
        return this.isSuperUser();
        break;
      case 'company':
        this.getPermisoEditCompany(companyId).subscribe((permiso) => {
          if (permiso) {
            can = (permiso.tipo == 'ADMIN');
          }});
        break;
      case 'farm':
      case 'plot':
        this.getPermisoEditFarm(farmId).subscribe((permiso) => {
          if (permiso) {
            can = (permiso.tipo == 'ADMIN');
          }});
        break;
        default:
        return false;                                    
    }
    return can;
  }
  */
  CanDelete(clientId, empresaId?, campoId?, loteId?) {
    if (this.isSuperUser()) {return true;}
    return this.CanAdd(clientId, empresaId, campoId, loteId);  
  }


}
